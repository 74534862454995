import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import configureStore, { history } from "./store/index";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";

const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Route component={App} />
		</ConnectedRouter>
	</Provider>,
	document.getElementById("root")
);
