import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { FeedbackViewModel } from "../../models/viewmodels/feedback/FeedbackViewModel";

export enum FeedbackActionTypes {
    FETCH_FEEDBACK_STARTED = "FETCH_FEEDBACK_STARTED",
    FETCH_FEEDBACK_SUCCESS = "FETCH_FEEDBACK_SUCCESS",
    FETCH_FEEDBACK_FAILURE = "FETCH_FEEDBACK_FAILURE",

    FETCH_FEEDBACK_BY_ID_STARTED = "FETCH_FEEDBACK_BY_ID_STARTED",
    FETCH_FEEDBACK_BY_ID_SUCCESS = "FETCH_FEEDBACK_BY_ID_SUCCESS",
    FETCH_FEEDBACK_BY_ID_FAILURE = "FETCH_FEEDBACK_BY_ID_FAILURE",

	UPDATE_FEEDBACK_ITEM_VALUE_SUCCESS = "UPDATE_FEEDBACK_ITEM_VALUE_SUCCESS",
	UPDATE_FEEDBACK_ITEM_VALUE_FAILURE = "UPDATE_FEEDBACK_ITEM_VALUE_FAILURE",
    
    UPDATE_ADD_FEEDBACK_COMMENT_VALUE_STARTED = "UPDATE_ADD_FEEDBACK_COMMENT_VALUE_STARTED",
	UPDATE_ADD_FEEDBACK_COMMENT_VALUE_SUCCESS = "UPDATE_ADD_FEEDBACK_COMMENT_VALUE_SUCCESS",
	UPDATE_ADD_FEEDBACK_COMMENT_VALUE_FAILURE = "UPDATE_ADD_FEEDBACK_COMMENT_VALUE_FAILURE",

	DELETE_FEEDBACK_VALUE_STARTED = "DELETE_FEEDBACK_VALUE_STARTED",
	DELETE_FEEDBACK_VALUE_SUCCESS = "DELETE_FEEDBACK_VALUE_SUCCESS",
	DELETE_FEEDBACK_VALUE_FAILURE = "DELETE_FEEDBACK_VALUE_FAILURE",
    
	RESET_SUCCESS = "RESET_SUCCESS",
	RESET_ERROR = "RESET_ERROR",
}

export type FeedbackState = Readonly<{
    listItems: FeedbackViewModel[];
    listErrorMessage: string;
    listIsLoading: boolean;

    editPanelIsLoading: boolean;
    editPanelErrorMessage: string;
    editPanelItem: FeedbackViewModel;

    deletePanelIsLoading: boolean;
    deletePanelErrorMessage: string;
    deletePanelItem: FeedbackViewModel;

    error: IErrorState;
	success: ISuccessState;
}>;