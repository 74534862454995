import { TaxonomyActionTypes, TaxonomyState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums/SuccessTypes";
import { ITaxonomy } from "../../models/responseModels/taxonomy/ITaxonomy";
import { sortArrayIgnoreCase } from "../../common/helperFunctions/ObjectsAndArrayHelpers";
import { TaxonomyItem } from "../../models/enums/TaxonomyITems";
import { ITerm } from "../../models/responseModels/taxonomy/ITerm";

const initialState: TaxonomyState = {
	taxonomy: { termGroups: [] },

	isFetchingTaxonomy: false,
	isUpdatingOrCreating: false,

	success: {
		type: SuccessTypes.None,
		forType: null,
	},
	error: {
		type: ErrorTypes.None,
		forType: null,
		message: "",
	},
};

export function taxonomyReducer(
	state = initialState,
	action: { type: TaxonomyActionTypes; payload: any }
): TaxonomyState {
	switch (action.type) {
		case TaxonomyActionTypes.FETCH_TAXONOMY_STARTED:
			return {
				...state,
				isFetchingTaxonomy: true,
				error: {
					type: ErrorTypes.None,
					forType: null,
					message: "",
				},
			};
		case TaxonomyActionTypes.FETCH_TAXONOMY_SUCCESS:
			return {
				...state,
				taxonomy: action.payload,
				isFetchingTaxonomy: false,
			};
		case TaxonomyActionTypes.FETCH_TAXONOMY_FAILURE:
			return {
				...state,
				isFetchingTaxonomy: false,
				error: {
					forType: TaxonomyItem.Taxonomy,
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Add Term ==--
		case TaxonomyActionTypes.ADD_TERM_STARTED:
			return {
				...state,
				isUpdatingOrCreating: true,
				success: {
					type: SuccessTypes.None,
					forType: null,
				},
				error: {
					forType: null,
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TaxonomyActionTypes.ADD_TERM_SUCCESS:
			let taxAfterTermAdd: ITaxonomy = { ...state.taxonomy };
			const isNestedTerm =
				action.payload.parent !== null && action.payload.parent !== undefined;

			// Update the term in the existing taxonomy
			for (let groupIndex = 0; groupIndex < taxAfterTermAdd.termGroups.length; groupIndex++) {
				const group = taxAfterTermAdd.termGroups[groupIndex];

				for (let termSetIndex = 0; termSetIndex < group.termSets.length; termSetIndex++) {
					const termSet = group.termSets[termSetIndex];

					if (termSet.id === action.payload.termSetId) {
						if (isNestedTerm) {
							// Get the parentIds in order from top to bottom.
							const parentIds = determineParentIdsInOrder(action.payload.parent);
							let termToFilterOn =
								taxAfterTermAdd.termGroups[groupIndex].termSets[termSetIndex].terms;

							// Loop through the parentIds
							for (
								let parentIdIndex = 0;
								parentIdIndex < parentIds.length;
								parentIdIndex++
							) {
								const parentId = parentIds[parentIdIndex];
								// Find the next term to filter on.
								termToFilterOn = termToFilterOn.filter((t) => t.id === parentId)[0]
									.childTerms;
							}

							// Add the newly created term to the store.
							termToFilterOn.push(action.payload);
						} else {
							taxAfterTermAdd.termGroups[groupIndex].termSets[
								termSetIndex
							].terms.push(action.payload);
							taxAfterTermAdd.termGroups[groupIndex].termSets[termSetIndex].terms
								.sort(sortArrayIgnoreCase("name"))
								.reverse();
						}
						break;
					}
				}
			}

			return {
				...state,
				taxonomy: taxAfterTermAdd,
				success: {
					type: SuccessTypes.OnCreate,
					forType: isNestedTerm ? TaxonomyItem.Term : TaxonomyItem.TermSet,
				},
				isUpdatingOrCreating: false,
			};
		case TaxonomyActionTypes.ADD_TERM_FAILURE:
			return {
				...state,
				isUpdatingOrCreating: false,
				error: {
					forType: TaxonomyItem.TermSet,
					type: ErrorTypes.OnCreate,
					message: action.payload.errorMessage,
				},
			};

		// --== Update Term ==--
		case TaxonomyActionTypes.UPDATE_TERM_STARTED:
			return {
				...state,
				isUpdatingOrCreating: true,
				success: {
					type: SuccessTypes.None,
					forType: null,
				},
				error: {
					forType: null,
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TaxonomyActionTypes.UPDATE_TERM_SUCCESS:
			let newTaxonomy: ITaxonomy = { ...state.taxonomy };

			// Update the term in the existing taxonomy
			for (let groupIndex = 0; groupIndex < newTaxonomy.termGroups.length; groupIndex++) {
				const group = newTaxonomy.termGroups[groupIndex];
				for (let termSetIndex = 0; termSetIndex < group.termSets.length; termSetIndex++) {
					const termSet = group.termSets[termSetIndex];
					if (termSet.id === action.payload.termSetId) {
						if (action.payload.parent !== null && action.payload.parent !== undefined) {
							// Get the parentIds in order from top to bottom.
							const parentIds = determineParentIdsInOrder(action.payload);
							let termToFilterOn =
								newTaxonomy.termGroups[groupIndex].termSets[termSetIndex].terms;
							let termToUpdate: ITerm = {} as ITerm;

							// Loop through the parentIds
							for (
								let parentIdIndex = 0;
								parentIdIndex < parentIds.length;
								parentIdIndex++
							) {
								const parentId = parentIds[parentIdIndex];
								// Find the next term to filter on.
								const result = termToFilterOn.filter((t) => t.id === parentId)[0];
								termToFilterOn = result.childTerms;
								termToUpdate = result;
							}

							// We want to keep the reference of the object, this is why we delete the old keys
							// and add the new keys.
							Object.keys(termToUpdate).forEach(function (key) {
								delete termToUpdate[key];
							});

							Object.keys(action.payload).forEach(function (key) {
								termToUpdate[key] = action.payload[key];
							});
						} else {
							for (let termIndex = 0; termIndex < termSet.terms.length; termIndex++) {
								const term = termSet.terms[termIndex];

								if (term.id === action.payload.id) {
									newTaxonomy.termGroups[groupIndex].termSets[termSetIndex].terms[
										termIndex
									] = action.payload;

									newTaxonomy.termGroups[groupIndex].termSets[termSetIndex].terms
										.sort(sortArrayIgnoreCase("name"))
										.reverse();
									break;
								}
							}
						}
					}
				}
			}

			return {
				...state,
				taxonomy: newTaxonomy,
				success: {
					type: SuccessTypes.OnUpdate,
					forType: TaxonomyItem.Term,
				},
				isUpdatingOrCreating: false,
			};
		case TaxonomyActionTypes.UPDATE_TERM_FAILURE:
			return {
				...state,
				isUpdatingOrCreating: false,
				error: {
					forType: TaxonomyItem.Term,
					type: ErrorTypes.OnUpdate,
					message: action.payload.errorMessage,
				},
			};

		// --== Add TermSet ==--
		case TaxonomyActionTypes.ADD_TERMSET_STARTED:
			return {
				...state,
				isUpdatingOrCreating: true,
				success: {
					type: SuccessTypes.None,
					forType: null,
				},
				error: {
					forType: null,
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TaxonomyActionTypes.ADD_TERMSET_SUCCESS:
			// Rebuild the taxonomy
			let taxAfterTermSetAdd: ITaxonomy = {
				termGroups: state.taxonomy.termGroups.map((group) => {
					if (group.id === action.payload.termGroupId) {
						// Rebuild the termSet
						const newTermSets = group.termSets.map((termSet) => {
							return { ...termSet, terms: [...termSet.terms] };
						});

						// Add the newly added termSet
						newTermSets.push(action.payload);
						return {
							...group,
							termSets: newTermSets.sort(sortArrayIgnoreCase("name")).reverse(),
						};
					} else {
						return {
							...group,
							termSets: group.termSets.map((termSet) => {
								return { ...termSet, terms: [...termSet.terms] };
							}),
						};
					}
				}),
			};

			return {
				...state,
				taxonomy: taxAfterTermSetAdd,
				success: {
					type: SuccessTypes.OnCreate,
					forType: TaxonomyItem.TermGroup,
				},
				isUpdatingOrCreating: false,
			};
		case TaxonomyActionTypes.ADD_TERMSET_FAILURE:
			return {
				...state,
				isUpdatingOrCreating: false,
				error: {
					forType: TaxonomyItem.TermGroup,
					type: ErrorTypes.OnCreate,
					message: action.payload.errorMessage,
				},
			};

		// --== Update TermSet ==--
		case TaxonomyActionTypes.UPDATE_TERMSET_STARTED:
			return {
				...state,
				isUpdatingOrCreating: true,
				success: {
					type: SuccessTypes.None,
					forType: null,
				},
				error: {
					forType: null,
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TaxonomyActionTypes.UPDATE_TERMSET_SUCCESS:
			// Rebuild the taxonomy
			let taxAfterTermSetUpdate: ITaxonomy = {
				termGroups: state.taxonomy.termGroups.map((group) => {
					return {
						...group,
						termSets: group.termSets
							.map((termSet) => {
								// If the termSet is the newly updated termSet, replace it with the updated values. Add the terms as a new array.
								if (termSet.id === action.payload.id) {
									return { ...action.payload, terms: [...termSet.terms] };
								} else {
									return { ...termSet, terms: [...termSet.terms] };
								}
							})
							.sort(sortArrayIgnoreCase("name"))
							.reverse(),
					};
				}),
			};

			return {
				...state,
				taxonomy: taxAfterTermSetUpdate,
				success: {
					type: SuccessTypes.OnUpdate,
					forType: TaxonomyItem.TermSet,
				},
				isUpdatingOrCreating: false,
			};
		case TaxonomyActionTypes.UPDATE_TERMSET_FAILURE:
			return {
				...state,
				isUpdatingOrCreating: false,
				error: {
					forType: TaxonomyItem.TermSet,
					type: ErrorTypes.OnUpdate,
					message: action.payload.errorMessage,
				},
			};

		// --== Update TermGroup ==--
		case TaxonomyActionTypes.UPDATE_TERMGROUP_STARTED:
			return {
				...state,
				isUpdatingOrCreating: true,
				success: {
					type: SuccessTypes.None,
					forType: null,
				},
				error: {
					forType: null,
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TaxonomyActionTypes.UPDATE_TERMGROUP_SUCCESS:
			// Rebuild the taxonomy
			let taxAfterTermGroupUpdate: ITaxonomy = {
				termGroups: state.taxonomy.termGroups
					.map((group) => {
						if (group.id === action.payload.id) {
							return {
								...action.payload,
								termSets: group.termSets.map((termSet) => {
									return { ...termSet, terms: [...termSet.terms] };
								}),
							};
						} else {
							return {
								...group,
								termSets: group.termSets.map((termSet) => {
									return { ...termSet, terms: [...termSet.terms] };
								}),
							};
						}
					})
					.sort(sortArrayIgnoreCase("name"))
					.reverse(),
			};

			return {
				...state,
				taxonomy: taxAfterTermGroupUpdate,
				success: {
					type: SuccessTypes.OnUpdate,
					forType: TaxonomyItem.TermGroup,
				},
				isUpdatingOrCreating: false,
			};
		case TaxonomyActionTypes.UPDATE_TERMGROUP_FAILURE:
			return {
				...state,
				isUpdatingOrCreating: false,
				error: {
					forType: TaxonomyItem.TermGroup,
					type: ErrorTypes.OnUpdate,
					message: action.payload.errorMessage,
				},
			};

		// --== Reset ==--
		case TaxonomyActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
					forType: null,
				},
			};
		case TaxonomyActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					forType: null,
					type: ErrorTypes.None,
					message: "",
				},
			};

		default:
			return state;
	}
}

const determineParentIdsInOrder = (input: ITerm) => {
	const parentIds: number[] = [];

	const addParentId = (term: ITerm) => {
		if (term.parent !== null && term.parent !== undefined) {
			addParentId(term.parent);
		}
		parentIds.push(term.id);
	};

	addParentId(input);
	return parentIds;
};
