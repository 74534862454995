import { SettingsActionTypes, SettingsState } from "./types";
import { SettingResponseModel } from "../../models/responseModels/settings/SettingResponse";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums/SuccessTypes";
import { SettingsTypes } from "../../models/enums/SettingsTypes";
import { GroupSetting } from "@microsoft/microsoft-graph-types";

const initialState: SettingsState = {
	applicationSettings: [] as SettingResponseModel[],
	tenantSettings: [] as SettingResponseModel[],
	userSettings: [] as SettingResponseModel[],
	groupSettings: [] as GroupSetting[],
	appSettingsById: {
		settings: [] as SettingResponseModel[],
	},

	isLoading: false,
	isUpdating: false,
	isFetchingAppSettingsById: false,
	isUpdatingAppSettingsById: false,
	isFetchingGroupSettings: false,

	success: {
		type: SuccessTypes.None,
	},
	error: {
		type: ErrorTypes.None,
		message: "",
	},
};

export function settingsReducer(state = initialState, action: { type: SettingsActionTypes; payload: any }): SettingsState {
	switch (action.type) {
		case SettingsActionTypes.FETCH_SETTINGS_STARTED:
			return {
				...state,
				isLoading: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.FETCH_SETTINGS_SUCCESS:
			return {
				...state,
				applicationSettings: action.payload.settings.applicationSettings,
				tenantSettings: action.payload.settings.tenantSettings,
				userSettings: action.payload.settings.userSettings,
				isLoading: false,
			};
		case SettingsActionTypes.FETCH_SETTINGS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Fetch app setting by ID ==--
		case SettingsActionTypes.FETCH_APP_SETTINGS_BY_ID_STARTED:
			return {
				...state,
				isFetchingAppSettingsById: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.FETCH_APP_SETTINGS_BY_ID_SUCCESS:
			return {
				...state,
				appSettingsById: { settings: action.payload.settings.applicationSettings },
				isFetchingAppSettingsById: false,
			};
		case SettingsActionTypes.FETCH_APP_SETTINGS_BY_ID_FAILURE:
			return {
				...state,
				isFetchingAppSettingsById: false,
				error: {
					type: ErrorTypes.OnDetailDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Fetch Group settings from graph ==--
		case SettingsActionTypes.FETCH_GROUP_SETTINGS_GRAPH_STARTED:
			return {
				...state,
				isFetchingGroupSettings: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.FETCH_GROUP_SETTINGS_GRAPH_SUCCESS:
			return {
				...state,
				groupSettings: action.payload.groupSettings,
				isFetchingGroupSettings: false,
			};
		case SettingsActionTypes.FETCH_GROUP_SETTINGS_GRAPH_FAILURE:
			return {
				...state,
				isFetchingGroupSettings: false,
				error: {
					type: ErrorTypes.OnDetailDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== SET settings from graph - allowToAddGuests ==--
		case SettingsActionTypes.SET_GROUP_SETTING_GUESTS_STARTED:
			return {
				...state,
				isFetchingGroupSettings: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.SET_GROUP_SETTING_GUESTS_SUCCESS:
			let newGroupSettings: GroupSetting[] = [];

			if (action.payload.id === "") {
				newGroupSettings = [...state.groupSettings];
				newGroupSettings.push(action.payload.result);
			} else {
				//we do not update the store because it is used in the component itself.
				//also we do not know what property + value we need to update. If this is required
				//you can use somethinh like the code below.
				// let cur = newGroupSettings.filter((v) => v.id === action.payload.id)[0];
				// let index = newGroupSettings.indexOf(cur);
				// newGroupSettings[index] = action.payload.result;
			}

			return {
				...state,
				groupSettings: newGroupSettings,
				isFetchingGroupSettings: false,
			};
		case SettingsActionTypes.SET_GROUP_SETTING_GUESTS_FAILURE:
			return {
				...state,
				isFetchingGroupSettings: false,
				error: {
					type: ErrorTypes.OnUpdate,
					message: action.payload.errorMessage,
				},
			};

		// --== UPDATE SETTINGS by ID==--
		case SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_STARTED:
			return {
				...state,
				isUpdatingAppSettingsById: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_SUCCESS:
			let appSettingsByIdAfterUpdate = [...state.appSettingsById.settings];
			appSettingsByIdAfterUpdate = state.appSettingsById.settings.map((existingSetting) => {
				for (let i = 0; i < action.payload.settings.length; i++) {
					const newSetting = action.payload.settings[i];
					if (existingSetting.key === newSetting.key) {
						return newSetting;
					}
				}

				return existingSetting;
			});

			return {
				...state,
				isUpdatingAppSettingsById: false,
				appSettingsById: { settings: appSettingsByIdAfterUpdate },
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};
		case SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_FAILURE:
			return {
				...state,
				isUpdatingAppSettingsById: false,
				error: {
					type: ErrorTypes.OnUpdate,
					message: "",
				},
			};
		// --== UPDATE SETTINGS Team crawl==--
		case SettingsActionTypes.UPDATE_TEAM_CRAWL_SETTING_STARTED:
			return {
				...state,
				isUpdatingAppSettingsById: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.UPDATE_TEAM_CRAWL_SETTING_SUCCESS:
			let appSettings_UPDATE_TEAM_CRAWL_SETTING_SUCCESS = [...state.appSettingsById.settings];
			appSettings_UPDATE_TEAM_CRAWL_SETTING_SUCCESS = state.appSettingsById.settings.map((existingSetting) => {
				if (existingSetting.key === action.payload.key) {
					return action.payload;
				}

				return existingSetting;
			});

			return {
				...state,
				isUpdatingAppSettingsById: false,
				appSettingsById: { settings: appSettings_UPDATE_TEAM_CRAWL_SETTING_SUCCESS },
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};
		case SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_FAILURE:
			return {
				...state,
				isUpdatingAppSettingsById: false,
				error: {
					type: ErrorTypes.OnUpdate,
					message: "",
				},
			};
		// --== UPDATE APP SETTINGS  ==--
		case SettingsActionTypes.UPDATE_SETTINGS_STARTED:
			return {
				...state,
				isUpdating: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case SettingsActionTypes.UPDATE_SETTINGS_SUCCESS:
			let tenantSettingsAfterUpdate = [...state.tenantSettings];
			let applicationSettingsAfterUpdate = [...state.applicationSettings];

			if (action.payload.type === SettingsTypes.Tenant) {
				tenantSettingsAfterUpdate = state.tenantSettings.map((existingSetting) => {
					for (let i = 0; i < action.payload.settings.length; i++) {
						const newSetting = action.payload.settings[i];
						if (existingSetting.key === newSetting.key) {
							return newSetting;
						}
					}

					return existingSetting;
				});
			}
			if (action.payload.type === SettingsTypes.Application) {
				applicationSettingsAfterUpdate = state.applicationSettings.map((existingSetting) => {
					for (let i = 0; i < action.payload.settings.length; i++) {
						const newSetting = action.payload.settings[i];
						if (existingSetting.key === newSetting.key) {
							return newSetting;
						}
					}

					return existingSetting;
				});
			}

			return {
				...state,
				isUpdating: false,
				tenantSettings: tenantSettingsAfterUpdate,
				applicationSettings: applicationSettingsAfterUpdate,
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};
		case SettingsActionTypes.UPDATE_SETTINGS_FAILURE:
			return {
				...state,
				isUpdating: false,
				error: {
					type: ErrorTypes.OnUpdate,
					message: "",
				},
			};
		case SettingsActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};
		case SettingsActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};

		default:
			return state;
	}
}
