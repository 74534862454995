import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { LookupListsState, LookupListsActionTypes } from "./types";
import { adalOneApiFetch } from "../../adalConfig";
import { NewLookupListValue } from "../../components/routes/lookupListsComponent/createListValuePanelComponent/CreateListValuePanelComponent";
import { LookupListsResponse } from "../../models/responseModels/lookupLists/LookupListsResponse";
import { LookupListValueResponse } from "../../models/responseModels/lookupLists/LookupListValueResponse";

export const updateShouldClosePanel = (shouldClosePanel: boolean) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: LookupListsActionTypes.UPDATE_SHOULD_CLOSE_PANEL,
			payload: {
				shouldClosePanel,
			},
		});
	};
};
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: LookupListsActionTypes.RESET_SUCCESS,
		});
	};
};
export const clearError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: LookupListsActionTypes.CLEAR_ERROR,
		});
	};
};

// --== Fetch Lookup Lists
export const fetchLookupLists = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchLookupListsRequest());
	};
};

const fetchLookupListsRequest: ActionCreator<ThunkAction<Promise<any>, LookupListsState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchLookupListsStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/lookupLists`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchLookupListsFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(fetchLookupListsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchLookupListsFailure("error"));
		}
	};
};

const fetchLookupListsStarted = () => ({
	type: LookupListsActionTypes.FETCH_LOOKUPLIST_STARTED,
});

const fetchLookupListsSuccess = (lookupLists: any) => ({
	type: LookupListsActionTypes.FETCH_LOOKUPLIST_SUCCESS,
	payload: {
		lookupLists,
	},
});

const fetchLookupListsFailure = (errorMessage: string | object) => ({
	type: LookupListsActionTypes.FETCH_LOOKUPLIST_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Create Lookup List Value
export const createLookupListValue = (lookupList: LookupListsResponse, newListValue: NewLookupListValue) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(createLookupListValueRequest(lookupList, newListValue));
	};
};

const createLookupListValueRequest: ActionCreator<ThunkAction<Promise<any>, LookupListsState, null, any>> = (
	lookupList: LookupListsResponse,
	newListValue: NewLookupListValue
) => {
	return async (dispatch: Dispatch) => {
		dispatch(createLookupListValueStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/lookupLists/${lookupList.name}`,
				{
					method: "PUT",
					body: JSON.stringify(newListValue),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 201 && response.status !== 204) {
					dispatch(createLookupListValueFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(createLookupListValueSuccess(lookupList, result));
			}
		} catch (e) {
			dispatch(createLookupListValueFailure("error"));
		}
	};
};

const createLookupListValueStarted = () => ({
	type: LookupListsActionTypes.CREATE_LOOKUPLIST_VALUE_STARTED,
});

const createLookupListValueSuccess = (lookupList: LookupListsResponse, newLookupListValue: any) => ({
	type: LookupListsActionTypes.CREATE_LOOKUPLIST_VALUE_SUCCESS,
	payload: {
		lookupList,
		newLookupListValue,
	},
});

const createLookupListValueFailure = (errorMessage: string | object) => ({
	type: LookupListsActionTypes.CREATE_LOOKUPLIST_VALUE_FAILURE,
	payload: {
		errorMessage,
	},
});
// --== Create Lookup List Value
export const updateLookupListValue = (lookupList: LookupListsResponse, updatedListValue: LookupListValueResponse) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateLookupListValueRequest(lookupList, updatedListValue));
	};
};

const updateLookupListValueRequest: ActionCreator<ThunkAction<Promise<any>, LookupListsState, null, any>> = (
	lookupList: LookupListsResponse,
	updatedListValue: LookupListValueResponse
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateLookupListValueStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/lookupLists/${lookupList.name}`,
				{
					method: "PATCH",
					body: JSON.stringify(updatedListValue),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(updateLookupListValueFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateLookupListValueSuccess(lookupList, result));
			}
		} catch (e) {
			dispatch(updateLookupListValueFailure("error"));
		}
	};
};

const updateLookupListValueStarted = () => ({
	type: LookupListsActionTypes.UPDATE_LOOKUPLIST_VALUE_STARTED,
});

const updateLookupListValueSuccess = (lookupList: LookupListsResponse, updatedListValue: any) => ({
	type: LookupListsActionTypes.UPDATE_LOOKUPLIST_VALUE_SUCCESS,
	payload: {
		lookupList,
		updatedListValue,
	},
});

const updateLookupListValueFailure = (errorMessage: string | object) => ({
	type: LookupListsActionTypes.UPDATE_LOOKUPLIST_VALUE_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Delete Lookup List Value
export const deleteLookupListValue = (lookupList: LookupListsResponse, valueIdToRemove: number) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(deleteLookupListValueRequest(lookupList, valueIdToRemove));
	};
};

const deleteLookupListValueRequest: ActionCreator<ThunkAction<Promise<any>, LookupListsState, null, any>> = (
	lookupList: LookupListsResponse,
	valueIdToRemove: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch(deleteLookupListValueStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/lookupLists/${lookupList.name}/${valueIdToRemove}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(deleteLookupListValueFailure(response.statusText));
					return;
				}

				dispatch(deleteLookupListValueSuccess(lookupList, valueIdToRemove));
			}
		} catch (e) {
			dispatch(deleteLookupListValueFailure("error"));
		}
	};
};

const deleteLookupListValueStarted = () => ({
	type: LookupListsActionTypes.DELETE_LOOKUPLIST_VALUE_STARTED,
});

const deleteLookupListValueSuccess = (lookupList: LookupListsResponse, valueIdToRemove: number) => ({
	type: LookupListsActionTypes.DELETE_LOOKUPLIST_VALUE_SUCCESS,
	payload: {
		lookupList,
		valueIdToRemove,
	},
});

const deleteLookupListValueFailure = (errorMessage: string | object) => ({
	type: LookupListsActionTypes.DELETE_LOOKUPLIST_VALUE_FAILURE,
	payload: {
		errorMessage,
	},
});
