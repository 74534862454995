import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "@fluentui/react";

export const UnauthorizedComponent = () => {
	return (
		<div className="page-not-found-container">
			<Icon iconName="BlockedSite" />
			<h1>
				<FormattedMessage id="unauthorized.headertext" defaultMessage="Toegang geweigerd" />
			</h1>
			<p>
				<FormattedMessage
					id="unauthorized.subtext"
					defaultMessage="U heeft geen toegang tot deze pagina, mocht u toch toegang willen neem dan contact op met de site beheerder."
				/>
			</p>
		</div>
	);
};
