import Loader from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupSetting } from "@microsoft/microsoft-graph-types";
import { Checkbox, Label, PrimaryButton } from "@fluentui/react";
import { FormattedMessage, injectIntl } from "react-intl";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { setAllowToAddGuests } from "../../../../../store/settings/actions";
import { AlertComponent } from "../../../../reusable/alertComponent/AlertComponent";

const GroupSettings = () => {
	const dispatch = useDispatch();
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const [currentGroupSetting, setCurrentGroupSetting] = useState<GroupSetting | null>(null);
	const [allowToAddUserSettingValue, setAllowToAddUserSettingValue] = useState<boolean | undefined>(undefined);

	const onSubmit = async () => {
		try {
			dispatch(
				setAllowToAddGuests(
					allowToAddUserSettingValue !== undefined ? allowToAddUserSettingValue : false,
					currentGroupSetting !== null ? currentGroupSetting.id : ""
				)
			);
		} catch (e) {
			console.log("error", e);
		}
	};

	const checkGroupSettingsForAllowToAddUserSetting = (groupSettings: GroupSetting[]) => {
		if (groupSettings) {
			groupSettings.forEach((groupSetting) => {
				if (groupSetting.values) {
					for (let i = 0; i < groupSetting.values.length; i++) {
						const settingValue = groupSetting.values[i];
						if (settingValue.name === "AllowToAddGuests") {
							if (settingValue.value === undefined) {
								setAllowToAddUserSettingValue(false);
							} else {
								setAllowToAddUserSettingValue(settingValue.value?.toLowerCase() === "true");
							}
							setCurrentGroupSetting(groupSetting);
							break;
						}
					}
				}
			});
		}
	};

	useEffect(() => {
		checkGroupSettingsForAllowToAddUserSetting(settingsSlice.groupSettings);
	}, [settingsSlice.groupSettings]);

	let checkboxStyle = { root: { alignItems: "center", height: "100%" } };

	let checkboxRender =
		allowToAddUserSettingValue === true ? (
			<Checkbox styles={checkboxStyle} checked={true} onChange={(e, checked) => setAllowToAddUserSettingValue(checked)} />
		) : (
			<Checkbox styles={checkboxStyle} checked={false} onChange={(e, checked) => setAllowToAddUserSettingValue(checked)} />
		);

	return (
		<div className="settings-setting-wrapper" test-name="settings-tile">
			<div className="settings-tile-body form-wrapper">
				{settingsSlice.isFetchingGroupSettings ? (
					<div className="loaderWrapper">
						<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
					</div>
				) : (
					<>
						<AlertComponent message="Deze actie kan alleen uitgevoerd worden door een Global Admin." type="warning" />

						<h3 style={{ marginBottom: "10px" }}>
							<FormattedMessage id="settings.teams.groupSettings" defaultMessage="Groep instellingen" />
						</h3>
						<p>
							<FormattedMessage
								id="settings.teams.groupSettings.description"
								defaultMessage="Bij het aanvragen van een team is het mogelijk om te specificeren of externen zijn toegestaan. De onderstaande instelling heeft invloed op het veld in het aanvraagformulier."
							/>
						</p>
						<div className="group-setting-wrapper">
							<div>
								<Label>
									<FormattedMessage
										id="settings.teams.groupSettings.allowToAddGuests"
										defaultMessage="Externen toelaten"
									/>
								</Label>
								{checkboxRender}
							</div>
						</div>

						<PrimaryButton disabled={settingsSlice.isFetchingGroupSettings} onClick={() => onSubmit()}>
							<FormattedMessage id="general.save" defaultMessage="Opslaan" />
						</PrimaryButton>
					</>
				)}
			</div>
		</div>
	);
};

export const GroupSettingsComponent = injectIntl(GroupSettings);
