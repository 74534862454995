import { SettingResponseModel } from "../../models/responseModels/settings/SettingResponse";
import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { GroupSetting } from "@microsoft/microsoft-graph-types";

export enum SettingsActionTypes {
	FETCH_SETTINGS_STARTED = "FETCH_SETTINGS_STARTED",
	FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS",
	FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE",

	FETCH_APP_SETTINGS_BY_ID_STARTED = "FETCH_APP_SETTINGS_BY_ID_STARTED",
	FETCH_APP_SETTINGS_BY_ID_SUCCESS = "FETCH_APP_SETTINGS_BY_ID_SUCCESS",
	FETCH_APP_SETTINGS_BY_ID_FAILURE = "FETCH_APP_SETTINGS_BY_ID_FAILURE",

	FETCH_GROUP_SETTINGS_GRAPH_STARTED = "FETCH_GROUP_SETTINGS_GRAPH_STARTED",
	FETCH_GROUP_SETTINGS_GRAPH_SUCCESS = "FETCH_GROUP_SETTINGS_GRAPH_SUCCESS",
	FETCH_GROUP_SETTINGS_GRAPH_FAILURE = "FETCH_GROUP_SETTINGS_GRAPH_FAILURE",

	SET_GROUP_SETTING_GUESTS_STARTED = "SET_GROUP_SETTING_GUESTS_STARTED",
	SET_GROUP_SETTING_GUESTS_SUCCESS = "SET_GROUP_SETTING_GUESTS_SUCCESS",
	SET_GROUP_SETTING_GUESTS_FAILURE = "SET_GROUP_SETTING_GUESTS_FAILURE",

	UPDATE_SETTINGS_STARTED = "UPDATE_SETTINGS_STARTED",
	UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS",
	UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE",

	UPDATE_APP_SETTINGS_BY_ID_STARTED = "UPDATE_APP_SETTINGS_BY_ID_STARTED",
	UPDATE_APP_SETTINGS_BY_ID_SUCCESS = "UPDATE_APP_SETTINGS_BY_ID_SUCCESS",
	UPDATE_APP_SETTINGS_BY_ID_FAILURE = "UPDATE_APP_SETTINGS_BY_ID_FAILURE",
	UPDATE_TEAM_CRAWL_SETTING_STARTED = "UPDATE_TEAM_CRAWL_SETTING_STARTED",
	UPDATE_TEAM_CRAWL_SETTING_SUCCESS = "UPDATE_TEAM_CRAWL_SETTING_SUCCESS",
	UPDATE_TEAM_CRAWL_SETTING_FAILURE = "UPDATE_TEAM_CRAWL_SETTING_FAILURE",

	RESET_SUCCESS = "RESET_SUCCESS",
	CLEAR_ERROR = "CLEAR_ERROR",
}

export type SettingsState = Readonly<{
	applicationSettings: SettingResponseModel[];
	tenantSettings: SettingResponseModel[];
	userSettings: SettingResponseModel[];
	groupSettings: GroupSetting[];
	appSettingsById: {
		settings: SettingResponseModel[];
	};

	isLoading: boolean;
	isUpdating: boolean;
	isFetchingAppSettingsById: boolean;
	isUpdatingAppSettingsById: boolean;
	isFetchingGroupSettings: boolean;

	success: ISuccessState;
	error: IErrorState;
}>;
