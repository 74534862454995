import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelType, Icon, PrimaryButton, Toggle } from "@fluentui/react";
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";

import { updateLookupListValue } from "../../../../store/lookupLists/actions";
import { LookupListsResponse } from "../../../../models/responseModels/lookupLists/LookupListsResponse";
import { LookupListValueResponse } from "../../../../models/responseModels/lookupLists/LookupListValueResponse";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ErrorTypes } from "../../../../models/enums/ErrorTypes";
import { AlertComponent } from "../../../reusable/alertComponent/AlertComponent";

export interface EditListValuePanelProps {
	lookupList: LookupListsResponse | null;
	listValue: LookupListValueResponse | null;
	isOpen: boolean;
	onClose: () => void;
}

const EditListValuePanel = (props: WrappedComponentProps & EditListValuePanelProps) => {
	const { lookupList, listValue, isOpen, onClose, intl } = props;
	const dispatch = useDispatch();
	const error = useSelector((state: IApplicationState) => state.lookupListsSlice.error);

	if (lookupList === null || listValue === null || !isOpen) {
		return null;
	}

	const initialValues: LookupListValueResponse = {
		id: listValue.id,
		name: listValue.name,
		isActive: listValue.isActive,
	};
	return (
		<Panel
			className="one-panel"
			isOpen={isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={onClose}
			isLightDismiss
			onLightDismissClick={onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage id="general.editShort" defaultMessage="Bewerk" />{" "}
						{lookupList.displayName}
					</h1>

					<div className="one-panel__panel-close" onClick={onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			<section className="one-panel__panel-content">
				{error.type === ErrorTypes.OnUpdate && (
					<AlertComponent type="danger" message={error.message} />
				)}

				<div className="form-wrapper">
					<Formik
						enableReinitialize
						initialValues={initialValues}
						onSubmit={(values) => {
							dispatch(updateLookupListValue(lookupList, values));
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.min(
									2,
									intl.formatMessage({
										id:
											"lookup.panel.create.create.validation.name.minTwoChars",
										defaultMessage:
											"De waarde moet ten minste twee tekens lang zijn.",
									})
								)
								.required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
						})}
					>
						{(props) => {
							const {
								values,
								touched,
								errors,
								isSubmitting,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
							} = props;
							return (
								<form onSubmit={handleSubmit}>
									<div className="form-field-label">
										<label>
											<FormattedMessage
												id="lookup.panel.create.label.name"
												defaultMessage="Waarde"
											/>
										</label>
										<span className="form-field-label__required-star">*</span>
									</div>
									{errors.name && touched.name && (
										<div className="input-feedback">{errors.name}</div>
									)}

									<input
										id="name"
										placeholder={intl.formatMessage({
											id: "lookup.panel.create.placeholder.name",
											defaultMessage: "Vul een waarde in",
										})}
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										className={errors.name && touched.name ? "error" : ""}
									/>

									<div className="form-field-label">
										<label>
											<FormattedMessage
												id="lookup.panel.create.label.isActive"
												defaultMessage="In gebruik"
											/>
										</label>
										<span className="form-field-label__required-star">*</span>
									</div>
									{errors.isActive && touched.isActive && (
										<div className="input-feedback">{errors.isActive}</div>
									)}
									<Toggle
										checked={values.isActive}
										className="toggle-input"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={() => setFieldValue("isActive", !values.isActive)}
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.save",
											defaultMessage: "Opslaan",
										})}
										type="submit"
										disabled={
											isSubmitting ||
											Object.keys(touched).length === 0 ||
											(Object.keys(errors).length !== 0 &&
												errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</section>
		</Panel>
	);
};

export const EditListValuePanelComponent = injectIntl(EditListValuePanel);
