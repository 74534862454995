import React, { useState, useEffect } from "react";
import {
	Icon,
	PrimaryButton,
	IDropdownOption,
	IDatePickerStrings,
	DayOfWeek,
	DatePicker,
} from "@fluentui/react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { AddReleaseViewModel } from "../../../../../models/responseModels/Releases/AddReleaseViewModel";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { updateRelease } from "../../../../../store/releases/actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchApplications } from "../../../../../store/applications/actions";
import { ApplicationsResponse } from "../../../../../models/responseModels/tenant/ApplicationsResponse";
import { ReleasesViewModel } from "../../../../../models/responseModels/Releases/ReleasesViewModel";

interface UpdateReleaseFormComponentProps {
	toggleUpdatePanel: () => void;
	applicationsList: Array<IDropdownOption>;
	release: ReleasesViewModel;
}

function UpdateReleaseForm(props: WrappedComponentProps & UpdateReleaseFormComponentProps) {
	const { intl } = props;
	const applicationsSlice = useSelector((state: IApplicationState) => state.applicationsSlice);

	const [firstDayOfWeek] = React.useState(DayOfWeek.Sunday);
	const [internalDate, setInternalDate] = useState<Date>(new Date());
	const [releaseToUse, setReleaseToUse] = useState({} as ReleasesViewModel);
	const [bodyText, setBodyText] = useState("");

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchApplications());
	}, [dispatch]);

	useEffect(() => {
		setBodyText(props.release.body);
		setInternalDate(new Date(props.release.publishingDate));
	}, [props.release.body, props.release.publishingDate]);

	if (
		Object.keys(props.release).length !== 0 &&
		props.release.constructor === Object &&
		Object.keys(releaseToUse).length === 0
	) {
		if (props.release.title) {
			setReleaseToUse(props.release);
		}
	}

	const initialValues: AddReleaseViewModel = {
		id: releaseToUse.id,
		versionNumber: releaseToUse.versionNumber,
		title: releaseToUse.title,
		body: releaseToUse.body,
		link: releaseToUse.link,
		status: 0,
		publishingDate: releaseToUse.publishingDate,
		applicationId: releaseToUse.applicationId,
	};

	const DayPickerStrings: IDatePickerStrings = {
		months: [
			intl.formatMessage({ id: "dateTimePicker.Months.January", defaultMessage: "januari" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.February",
				defaultMessage: "februari",
			}),
			intl.formatMessage({ id: "dateTimePicker.Months.March", defaultMessage: "maart" }),
			intl.formatMessage({ id: "dateTimePicker.Months.April", defaultMessage: "april" }),
			intl.formatMessage({ id: "dateTimePicker.Months.May", defaultMessage: "mei" }),
			intl.formatMessage({ id: "dateTimePicker.Months.June", defaultMessage: "juni" }),
			intl.formatMessage({ id: "dateTimePicker.Months.July", defaultMessage: "juli" }),
			intl.formatMessage({ id: "dateTimePicker.Months.August", defaultMessage: "augustus" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.September",
				defaultMessage: "september",
			}),
			intl.formatMessage({ id: "dateTimePicker.Months.October", defaultMessage: "oktober" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.November",
				defaultMessage: "november",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.December",
				defaultMessage: "december",
			}),
		],

		shortMonths: [
			intl.formatMessage({
				id: "dateTimePicker.Months.short.January",
				defaultMessage: "jan",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.February",
				defaultMessage: "feb",
			}),
			intl.formatMessage({ id: "dateTimePicker.Months.short.March", defaultMessage: "mar" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.April", defaultMessage: "apr" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.May", defaultMessage: "mei" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.June", defaultMessage: "jun" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.July", defaultMessage: "jul" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.August", defaultMessage: "aug" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.September",
				defaultMessage: "sep",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.October",
				defaultMessage: "okt",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.November",
				defaultMessage: "nov",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.December",
				defaultMessage: "dec",
			}),
		],

		days: [
			intl.formatMessage({ id: "dateTimePicker.days.sunday", defaultMessage: "zondag" }),
			intl.formatMessage({ id: "dateTimePicker.days.monday", defaultMessage: "maandag" }),
			intl.formatMessage({ id: "dateTimePicker.days.tuesday", defaultMessage: "dinsdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.wednesday", defaultMessage: "woensdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.thrusday", defaultMessage: "donderdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.friday", defaultMessage: "vrijdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.saturday", defaultMessage: "zaterdag" }),
		],

		shortDays: [
			intl.formatMessage({ id: "dateTimePicker.days.short.sunday", defaultMessage: "Z" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.monday", defaultMessage: "M" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.tuesday", defaultMessage: "D" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.wednesday", defaultMessage: "W" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.thrusday", defaultMessage: "D" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.friday", defaultMessage: "V" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.saturday", defaultMessage: "Z" }),
		],

		goToToday: intl.formatMessage({
			id: "dateTimePicker.goToToday",
			defaultMessage: "Ga naar vandaag",
		}),
		prevMonthAriaLabel: intl.formatMessage({
			id: "dateTimePicker.prevMonthAriaLabel",
			defaultMessage: "Ga naar vorige maand",
		}),
		nextMonthAriaLabel: intl.formatMessage({
			id: "dateTimePicker.nextMonthAriaLabel",
			defaultMessage: "Ga naar volgende maand",
		}),
		prevYearAriaLabel: intl.formatMessage({
			id: "dateTimePicker.prevYearAriaLabel",
			defaultMessage: "Ga naar vorig jaar",
		}),
		nextYearAriaLabel: intl.formatMessage({
			id: "dateTimePicker.nextYearAriaLabel",
			defaultMessage: "Ga naar volgend jaar",
		}),
		closeButtonAriaLabel: intl.formatMessage({
			id: "dateTimePicker.closeButtonAriaLabel",
			defaultMessage: "Sluit de datum selectie",
		}),
	};

	return (
		<div>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage
							id="releases.page.updateRelease"
							defaultMessage="Release bijwerken"
						/>
					</h1>
					<div
						className="one-panel__panel-close"
						onClick={() => props.toggleUpdatePanel()}
					>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			<section className="one-panel__panel-content form-wrapper ">
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={(values) => {
						values.body = bodyText;
						//@ts-ignore
						values.applicationId = parseInt(values.applicationId);
						values.publishingDate = internalDate.toLocaleDateString().substring(0, 10);

						props.toggleUpdatePanel();
						dispatch(updateRelease(values));
					}}
					validationSchema={Yup.object().shape({
						title: Yup.string()
							.min(
								2,
								intl.formatMessage({
									id: "lookup.panel.create.create.validation.name.minTwoChars",
									defaultMessage:
										"De waarde moet ten minste twee tekens lang zijn.",
								})
							)
							.required(
								intl.formatMessage({
									id: "general.validation.required",
									defaultMessage: "Dit veld is verplicht.",
								})
							),

						versionNumber: Yup.string().required(
							intl.formatMessage({
								id: "general.validation.required",
								defaultMessage: "Dit veld is verplicht.",
							})
						),
					})}
				>
					{(props: any) => {
						const {
							values,
							touched,
							errors,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
						} = props;
						return (
							<form onSubmit={handleSubmit}>
								<input type="hidden" name="id" value={releaseToUse.id} />

								<label>
									<span>
										<FormattedMessage
											id="releases.createForm.Application"
											defaultMessage="Applicatie"
										/>
									</span>
								</label>
								{errors.applicationId && touched.applicationId && (
									<div className="input-feedback">{errors.applicationId}</div>
								)}

								<Field
									className="form-input-group"
									component="select"
									value={values.applicationId}
									name="applicationId"
									autoFocus={true}
								>
									{applicationsSlice.applications.map(
										(app: ApplicationsResponse) => {
											return (
												<option value={app.id} key={app.id}>
													{app.name}
												</option>
											);
										}
									)}
								</Field>

								<div className="form-field-label">
									<label>
										<FormattedMessage
											id="releases.createForm.versionNumber"
											defaultMessage="Versienummer"
										/>
									</label>
									<span className="form-field-label__required-star">*</span>
								</div>

								{errors.versionNumber && touched.versionNumber && (
									<div className="input-feedback">{errors.versionNumber}</div>
								)}

								<input
									id="versionNumber"
									placeholder={intl.formatMessage({
										id: "lookup.panel.create.placeholder.name",
										defaultMessage: "Vul een waarde in",
									})}
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.versionNumber}
									style={{ width: "100%" }}
									className={
										errors.versionNumber && touched.versionNumber ? "error" : ""
									}
								/>

								{errors.applicationId && touched.applicationId && (
									<div className="input-feedback">{errors.applicationId}</div>
								)}

								<div className="form-field-label">
									<label>
										<FormattedMessage
											id="releases.createForm.PublishingDate"
											defaultMessage="Publicatiedatum"
										/>
									</label>
								</div>

								{errors.publishingDate && touched.publishingDate && (
									<div className="input-feedback">{errors.publishingDate}</div>
								)}

								<DatePicker
									className="date-picker-control"
									id="publishingDate"
									firstDayOfWeek={firstDayOfWeek}
									strings={DayPickerStrings}
									placeholder="Select a date..."
									ariaLabel="Select a date"
									value={internalDate}
									onSelectDate={(date: Date | null | undefined): void => {
										if (date) {
											values.publishingDate = date
												.toLocaleDateString()
												.substring(0, 10);
											setInternalDate(date);
										}
									}}
								/>

								<div className="form-field-label">
									<label>
										<FormattedMessage
											id="releases.createForm.title"
											defaultMessage="Titel"
										/>
									</label>
									<span className="form-field-label__required-star">*</span>
								</div>

								{errors.title && touched.title && (
									<div className="input-feedback">{errors.title}</div>
								)}

								<input
									id="title"
									placeholder={intl.formatMessage({
										id: "lookup.panel.create.placeholder.name",
										defaultMessage: "Vul een waarde in",
									})}
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.title}
									className={errors.title && touched.title ? "error" : ""}
								/>

								<div className="form-field-label">
									<label>
										<FormattedMessage
											id="releases.createForm.body"
											defaultMessage="Inhoud"
										/>
									</label>
								</div>

								{errors.body && touched.body && (
									<div className="input-feedback">{errors.body}</div>
								)}

								<ReactQuill
									id="body"
									value={bodyText}
									onChange={(text) => setBodyText(text)}
								/>

								<div className="form-field-label">
									<label>
										<FormattedMessage
											id="releases.createForm.Link"
											defaultMessage="Link"
										/>
									</label>
								</div>

								{errors.link && touched.link && (
									<div className="input-feedback">{errors.link}</div>
								)}

								<input
									id="link"
									placeholder={intl.formatMessage({
										id: "lookup.panel.create.placeholder.name",
										defaultMessage: "Vul een waarde in",
									})}
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.link}
									className={errors.link && touched.link ? "error" : ""}
								/>

								<PrimaryButton
									text={intl.formatMessage({
										id: "general.edit",
										defaultMessage: "Bewerken",
									})}
									type="submit"
									disabled={
										isSubmitting ||
										Object.keys(touched).length === 0 ||
										(Object.keys(errors).length !== 0 &&
											errors.constructor === Object)
									}
								/>
							</form>
						);
					}}
				</Formik>
			</section>
		</div>
	);
}

export const UpdateReleaseFormComponent = injectIntl(UpdateReleaseForm);
