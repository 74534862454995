import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ISelectedJob, JobsActionTypes, JobsState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";
import { IJobDefinition } from "../../models/viewmodels/jobs/IJobDefinition";
import { IJobHistory } from "../../models/viewmodels/jobs/IJobHistory";
import { IJobSchedule } from "../../models/viewmodels/jobs/IJobSchedule";
import { TenantResponse } from "../../models/responseModels/tenant/TenantResponse";
import { ICreateJobSchedule } from "../../models/viewmodels/jobs/ICreateJobSchedule";
import { IUpdateJobSchedule } from "../../models/viewmodels/jobs/IUpdateJobSchedule";

export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: JobsActionTypes.RESET_SUCCESS,
		});
	};
};
export const resetError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: JobsActionTypes.RESET_ERROR,
		});
	};
};

export const setSelectedJob = (selectedJob: ISelectedJob | null) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: JobsActionTypes.SET_SELECTED_JOB,
			payload: {
				selectedJob: selectedJob,
			},
		});
	};
};

export const clearSelectedJobError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: JobsActionTypes.CLEAR_SELECTED_JOB_ERROR,
		});
	};
};

export const setSelectedTenant = (newValue: TenantResponse | null) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: JobsActionTypes.SET_SELECTED_TENANT,
			payload: {
				selectedTenant: newValue,
			},
		});
	};
};

// --== Fetch jobs Lists
export const fetchJobDefinitions = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchJobDefinitionsRequest());
	};
};

const fetchJobDefinitionsRequest: ActionCreator<
	ThunkAction<Promise<any>, JobsState, null, any>
> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchJobDefinitionsStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/jobs/definitions`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchJobDefinitionsFailure(response.statusText));
					return;
				}

				const result: IJobDefinition[] = await response.json();

				dispatch(fetchJobDefinitionsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchJobDefinitionsFailure("error"));
		}
	};
};

const fetchJobDefinitionsStarted = () => ({
	type: JobsActionTypes.FETCH_JOB_DEFINITIONS_STARTED,
});

const fetchJobDefinitionsSuccess = (jobDefinitions: IJobDefinition[]) => ({
	type: JobsActionTypes.FETCH_JOB_DEFINITIONS_SUCCESS,
	payload: {
		jobDefinitions,
	},
});

const fetchJobDefinitionsFailure = (errorMessage: string | object) => ({
	type: JobsActionTypes.FETCH_JOB_DEFINITIONS_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch jobs schedule by ID
export const fetchJobScheduleById = (id: number, tenant: TenantResponse) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchJobScheduleByIdRequest(id, tenant));
	};
};

const fetchJobScheduleByIdRequest: ActionCreator<
	ThunkAction<Promise<any>, JobsState, null, any>
> = (id: number, tenant: TenantResponse) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchJobScheduleByIdStarted(id));
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/${tenant.id}/jobs/definitions/${id}/schedule`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					return;
				}

				const result: IJobSchedule[] = await response.json();
				if (result[0] !== undefined) {
					dispatch(fetchJobScheduleByIdSuccess(result[0]));
				} else {
					dispatch(fetchJobScheduleByIdFailure(id, response.statusText));
				}
			}
		} catch (e) {
			dispatch(fetchJobScheduleByIdFailure(id, "error"));
		}
	};
};

const fetchJobScheduleByIdStarted = (id: number) => ({
	type: JobsActionTypes.FETCH_JOB_SCHEDULE_BY_ID_STARTED,
	payload: {
		id,
	},
});

const fetchJobScheduleByIdSuccess = (jobSchedule: IJobSchedule) => ({
	type: JobsActionTypes.FETCH_JOB_SCHEDULE_BY_ID_SUCCESS,
	payload: {
		jobSchedule,
	},
});

const fetchJobScheduleByIdFailure = (id: number, errorMessage: string | object) => ({
	type: JobsActionTypes.FETCH_JOB_SCHEDULE_BY_ID_FAILURE,
	payload: {
		id,
		errorMessage,
	},
});

// --== Fetch jobs Lists
export const fetchJobHistory = (id: number, tenantId: number) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchJobHistoryRequest(id, tenantId));
	};
};

const fetchJobHistoryRequest: ActionCreator<ThunkAction<Promise<any>, JobsState, null, any>> = (
	id: number,
	tenantId: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchJobHistoryStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/${tenantId}/jobs/definitions/${id}/history`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchJobHistoryFailure(response.statusText));
					return;
				}

				const result: IJobHistory[] = await response.json();

				dispatch(fetchJobHistorySuccess(result));
			}
		} catch (e) {
			dispatch(fetchJobHistoryFailure("error"));
		}
	};
};

const fetchJobHistoryStarted = () => ({
	type: JobsActionTypes.FETCH_JOB_HISTORY_STARTED,
});

const fetchJobHistorySuccess = (history: IJobHistory[]) => ({
	type: JobsActionTypes.FETCH_JOB_HISTORY_SUCCESS,
	payload: {
		history,
	},
});

const fetchJobHistoryFailure = (errorMessage: string | object) => ({
	type: JobsActionTypes.FETCH_JOB_HISTORY_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Create Job Schedule
export const createJobSchedule = (
	definitionId: number,
	tenantId: number,
	createJobSchedule: ICreateJobSchedule
) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(createJobScheduleRequest(definitionId, tenantId, createJobSchedule));
	};
};

const createJobScheduleRequest: ActionCreator<ThunkAction<Promise<any>, JobsState, null, any>> = (
	definitionId: number,
	tenantId: number,
	createJobSchedule: ICreateJobSchedule
) => {
	return async (dispatch: Dispatch) => {
		dispatch(createJobScheduleStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/${tenantId}/jobs/definitions/${definitionId}/schedule`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(createJobSchedule),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
					dispatch(createJobScheduleFailure(response.statusText));
					return;
				}

				const result: IJobSchedule = await response.json();

				dispatch(createJobScheduleSuccess(result));
			}
		} catch (e) {
			dispatch(createJobScheduleFailure("error"));
		}
	};
};

const createJobScheduleStarted = () => ({
	type: JobsActionTypes.CREATE_JOB_SCHEDULE_STARTED,
});

const createJobScheduleSuccess = (jobSchedule: IJobSchedule) => ({
	type: JobsActionTypes.CREATE_JOB_SCHEDULE_SUCCESS,
	payload: {
		jobSchedule,
	},
});

const createJobScheduleFailure = (errorMessage: string | object) => ({
	type: JobsActionTypes.CREATE_JOB_SCHEDULE_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update Job Schedule
export const updateJobSchedule = (
	definitionId: number,
	tenantId: number,
	updateJobSchedule: IUpdateJobSchedule
) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateJobScheduleRequest(definitionId, tenantId, updateJobSchedule));
	};
};

const updateJobScheduleRequest: ActionCreator<ThunkAction<Promise<any>, JobsState, null, any>> = (
	definitionId: number,
	tenantId: number,
	updateJobSchedule: IUpdateJobSchedule
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateJobScheduleStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/${tenantId}/jobs/definitions/${definitionId}/schedule`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updateJobSchedule),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
					dispatch(updateJobScheduleFailure(response.statusText));
					return;
				}

				const result: IJobSchedule = await response.json();

				dispatch(updateJobScheduleSuccess(result));
			}
		} catch (e) {
			dispatch(updateJobScheduleFailure("error"));
		}
	};
};

const updateJobScheduleStarted = () => ({
	type: JobsActionTypes.UPDATE_JOB_SCHEDULE_STARTED,
});

const updateJobScheduleSuccess = (jobSchedule: IJobSchedule) => ({
	type: JobsActionTypes.UPDATE_JOB_SCHEDULE_SUCCESS,
	payload: {
		jobSchedule,
	},
});

const updateJobScheduleFailure = (errorMessage: string | object) => ({
	type: JobsActionTypes.UPDATE_JOB_SCHEDULE_FAILURE,
	payload: {
		errorMessage,
	},
});
