export enum TeamTypeSettingsApproveBy {
	TeamMembers,
	TeamOwners,
	TeamContactPersons,
	Archivaris,
	TeamManagers
}

export enum TeamTypeSettingsRequestBy {
	TeamMembers,
	TeamOwners,
	TeamContactPersons,
	Archivaris,
	TeamManagers
}
