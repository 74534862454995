import React from "react";
import CommandBarBase from "./commandBarBase/commandbarBase";

const DefaultCommandBar = props => {
  return (
    <div className="navbar-auxiliary-row">
      <CommandBarBase items={props.items} />
    </div>
  );
};

export default DefaultCommandBar;
