import * as React from "react";
import { Collapse } from "react-collapse";

export interface AutoHeightAnimatorProps {
	children?: React.ReactNode;
}

/**
 * A wrapper which allows for a smooth transition whenever the height of its children changes.
 */
export const AutoHeightAnimator = (props: AutoHeightAnimatorProps) => {
	return <Collapse isOpened={true}>{props.children}</Collapse>;
};
3;
