// Framework & thid-party
import {
	ActionButton,
	DefaultButton,
	IColumn,
	Icon,
	IconButton,
	MessageBar,
	MessageBarButton,
	MessageBarType,
	PrimaryButton,
	SelectionMode,
	ShimmeredDetailsList,
} from "@fluentui/react";
import { SlicePieceStatus } from "@one/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl, WrappedComponentProps } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { adalOneApiFetch } from "../../../../adalConfig";
import { SuccessTypes } from "../../../../models/enums";

// Models
import { JobStatus } from "../../../../models/enums/JobStatus";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { IJobHistory } from "../../../../models/viewmodels/jobs/IJobHistory";

// Store & actions
import { clearSelectedJobError, fetchJobHistory, resetSuccess, setSelectedJob } from "../../../../store/jobs/actions";
import { CreateJobSchedulePanelComponent } from "./createJobSchedulePanelComponent/CreateJobSchedulePanelComponent";
import { UpdateJobSchedulePanelComponent } from "./updateJobSchedulePanelComponent/UpdateJobSchedulePanelComponent";

const SelectedJob = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const jobsSlice = useSelector((state: IApplicationState) => state.jobsSlice);
	const { selectedJob, selectedTenant } = jobsSlice;

	const [createSchedulePanelIsOpen, setCreateSchedulePanelIsOpen] = useState(false);
	const [updateSchedulePanelIsOpen, setUpdateSchedulePanelIsOpen] = useState(false);
	const [runJobNowSuccessStatus, setRunJobNowSuccessStatus] = useState(SlicePieceStatus.None);

	// Close the create panel after a successfull creation.
	useEffect(() => {
		if (selectedJob?.jobDefinition.schedule !== undefined && createSchedulePanelIsOpen) {
			setCreateSchedulePanelIsOpen(false);
		}
	}, [createSchedulePanelIsOpen, selectedJob]);

	// Close the update panel after a successfull creation.
	useEffect(() => {
		if (jobsSlice.success.type === SuccessTypes.OnUpdate && updateSchedulePanelIsOpen) {
			setUpdateSchedulePanelIsOpen(false);
		}
	}, [updateSchedulePanelIsOpen, jobsSlice.success.type]);

	useEffect(() => {
		if (runJobNowSuccessStatus !== SlicePieceStatus.None && runJobNowSuccessStatus !== SlicePieceStatus.IsFetching) {
			setTimeout(() => {
				setRunJobNowSuccessStatus(SlicePieceStatus.None);
			}, 2500);
		}
	}, [runJobNowSuccessStatus]);

	useEffect(() => {
		if (
			selectedJob !== null &&
			selectedJob.jobDefinition !== undefined &&
			!selectedJob.isFetchingHistory &&
			selectedJob.history === null &&
			!selectedJob.hasFetchHistoryError
		) {
			if (selectedTenant !== null) {
				dispatch(fetchJobHistory(selectedJob.jobDefinition.id, selectedTenant.id));
			}
		}
	}, [dispatch, selectedJob, selectedTenant]);

	if (selectedJob === null || selectedJob.jobDefinition === undefined) {
		return null;
	}

	const hasSchedule = selectedJob.jobDefinition.schedule !== null && selectedJob.jobDefinition.schedule !== undefined;
	const columns: IColumn[] = [
		{
			key: "id",
			name: "id",
			fieldName: "id",
			minWidth: 50,
			maxWidth: 75,
			isResizable: true,
		},
		{
			key: "startTime",
			name: intl.formatMessage({
				id: "job.history.list.column.startTime",
				defaultMessage: "Starttijd",
			}),
			fieldName: "startTime",
			minWidth: 150,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "endTime",
			name: intl.formatMessage({
				id: "job.history.list.column.endTime",
				defaultMessage: "Eindtijd",
			}),
			fieldName: "endTime",
			minWidth: 150,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "status",
			name: intl.formatMessage({
				id: "job.history.list.column.status",
				defaultMessage: "Status",
			}),
			fieldName: "status",
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "statusReason",
			name: intl.formatMessage({
				id: "job.history.list.column.statusReason",
				defaultMessage: "Statusomschrijving",
			}),

			fieldName: "statusReason",
			minWidth: 250,
			isResizable: true,
		},
	];

	const _renderItemColumn = (item: IJobHistory, index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = item[column.fieldName as keyof IJobHistory] as string;
		switch (column.key) {
			case "startTime":
				return (
					<div className="column-field column-field-white-space-wrap">
						<span>
							<FormattedDate value={item.startTime} />
							{" - "}
							<FormattedTime value={item.startTime} />
						</span>
					</div>
				);
			case "endTime":
				return (
					<div className="column-field column-field-white-space-wrap">
						{item.endTime != null && item.endTime !== undefined ? (
							<span>
								<FormattedDate value={item.endTime} />
								{" - "}
								<FormattedTime value={item.endTime} />
							</span>
						) : (
							<span>-</span>
						)}
					</div>
				);
			case "status":
				let statusText;
				let classExtension;
				switch (item.status) {
					case JobStatus.Failed:
						statusText = <FormattedMessage id="jobs.status.failed" defaultMessage="Mislukt" />;
						classExtension = "__red";
						break;
					case JobStatus.Paused:
						statusText = <FormattedMessage id="jobs.status.paused" defaultMessage="Gepauzeerd" />;
						classExtension = "__orange";
						break;
					case JobStatus.Running:
						statusText = <FormattedMessage id="jobs.status.running" defaultMessage="Aan het lopen" />;
						break;
					case JobStatus.PartiallyDone:
					case JobStatus.Succeeded:
						statusText = <FormattedMessage id="jobs.status.succeeded" defaultMessage="Geslaagd" />;
						classExtension = "__green";
						break;
				}
				return (
					<div className={`column-field column-field-white-space-wrap column-field${classExtension}`}>
						<span>{statusText}</span>
					</div>
				);

			default:
				return (
					<div className="column-field column-field-white-space-wrap">
						<span>{fieldContent}</span>
					</div>
				);
		}
	};

	let runNowIconName = "LightningBolt";
	let runNowButtonBackground: string | undefined = undefined;
	if (runJobNowSuccessStatus === SlicePieceStatus.Success) {
		runNowIconName = "SkypeCircleCheck";
		runNowButtonBackground = "green";
	}
	if (runJobNowSuccessStatus === SlicePieceStatus.Error) {
		runNowIconName = "ErrorBadge";
		runNowButtonBackground = "rgb(168, 0, 0)";
	}

	return (
		<section>
			<h1 className="page-title">
				<IconButton
					iconProps={{ iconName: "Back" }}
					styles={{
						root: {
							width: "2rem",
							height: "2rem",
							color: "#333",
							marginRight: "1rem",
						},
					}}
					onClick={() => dispatch(setSelectedJob(null))}
				/>
				{selectedJob.jobDefinition.title}
			</h1>

			{jobsSlice.success.type === SuccessTypes.OnUpdate && (
				<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
					<FormattedMessage id="job.schedule.panel.success.onUpdate" defaultMessage="Het uitvoeringsschema is geüpdatet." />
				</MessageBar>
			)}
			{jobsSlice.success.type === SuccessTypes.OnCreate && (
				<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
					<FormattedMessage id="job.schedule.panel.success.onCreate" defaultMessage="Het uitvoeringsschema is aangemaakt." />
				</MessageBar>
			)}

			<div className="main-container">
				<div className="jobs-history-header">
					<div className="jobs-history-title">
						<Icon iconName="History" />
						<h3>
							<FormattedMessage id="job.history.title" defaultMessage="Historie" />
						</h3>
					</div>
					<div>
						<DefaultButton
							text={"run now"}
							primary
							disabled={runJobNowSuccessStatus !== SlicePieceStatus.None}
							iconProps={{
								iconName: runNowIconName,
							}}
							styles={{
								root: {
									background: runNowButtonBackground,
								},
							}}
							onClick={() => {
								const addJobToQueue = async () => {
									try {
										setRunJobNowSuccessStatus(SlicePieceStatus.IsFetching);
										const response: Response = await adalOneApiFetch(
											fetch,
											`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/jobs/queue`,
											{
												method: "POST",
												headers: {
													"Content-Type": "application/json",
												},
												body: JSON.stringify({
													jobDefinitionId: selectedJob.jobDefinition.id,
													tenantId: selectedTenant?.id,
												}),
											}
										);

										const result = await response.json();

										if (result === true && response.status === 200) {
											setRunJobNowSuccessStatus(SlicePieceStatus.Success);
										} else {
											setRunJobNowSuccessStatus(SlicePieceStatus.Error);
										}
									} catch (e) {
										setRunJobNowSuccessStatus(SlicePieceStatus.Error);
									}
								};
								addJobToQueue();
							}}
						/>
						{hasSchedule && (
							<ActionButton
								text={intl.formatMessage({
									id: "job.schedule.edit.button.label",
									defaultMessage: "Bewerk uitvoeringsschema",
								})}
								iconProps={{
									iconName: "Edit",
								}}
								onClick={() => {
									dispatch(resetSuccess());
									setUpdateSchedulePanelIsOpen(true);
								}}
							/>
						)}
					</div>
				</div>
				<div className="selected-job-history">
					<ShimmeredDetailsList
						items={selectedJob.history !== null ? selectedJob.history : []}
						enableShimmer={selectedJob.isFetchingHistory}
						selectionMode={SelectionMode.none}
						columns={columns}
						onRenderItemColumn={_renderItemColumn}
					/>

					{!hasSchedule && (
						<div className="job-no-schedule-container">
							<span>
								<FormattedMessage
									id="job.schedule.doesNotExist"
									defaultMessage="Deze job heeft nog geen uitvoeringsschema, dit houdt in dat deze job nog zal draaien."
								/>
							</span>
							<PrimaryButton onClick={() => setCreateSchedulePanelIsOpen(true)}>
								<FormattedMessage id="job.schedule.create.button.label" defaultMessage="Maak een uitvoeringsschema" />
							</PrimaryButton>
						</div>
					)}

					{hasSchedule && selectedJob.history !== null && selectedJob.history.length === 0 && (
						<div className="no-job-history-container">
							<span>
								<FormattedMessage id="job.history.no.history" defaultMessage="Deze job heeft nog niet gelopen." />
							</span>
						</div>
					)}
					{selectedJob.hasFetchHistoryError && (
						<div className="job-history-error-container">
							<MessageBar
								messageBarType={MessageBarType.error}
								actions={<MessageBarButton text="Retry" onClick={() => dispatch(clearSelectedJobError())} />}
								isMultiline={false}
							>
								<FormattedMessage id="job.history.error.fetch" defaultMessage="Kon de historie niet ophalen." />
							</MessageBar>
						</div>
					)}
				</div>
			</div>

			<CreateJobSchedulePanelComponent isOpen={createSchedulePanelIsOpen} onClose={() => setCreateSchedulePanelIsOpen(false)} />
			<UpdateJobSchedulePanelComponent isOpen={updateSchedulePanelIsOpen} onClose={() => setUpdateSchedulePanelIsOpen(false)} />
		</section>
	);
};

export const SelectedJobComponent = injectIntl(SelectedJob);
