export enum ProvisioningRequestType {
	TeamSite = 0,
	CommunicationSite = 1,
	UnifiedGroup = 2,
	Team = 3,
	// Channel = 4,
	//CreateGroup = 1000,
	UpgradeGroup = 1001,
	//CreateTeam = 2000,
	//UpdateTeam = 2001,
	//ArchiveTeam = 2002,
	//UnarchiveTeam = 2003,
	//CreateChannel = 3000,
	//UpdateChannel = 3001,
	//DeleteChannel = 3002,
}
