import { AnalyticsActionTypes, AnalyticsState } from "./types";
import { IAiTablesResponse } from "../../models/responseModels/ApplicationInsights/IAiTablesResponse";
import { Site } from "@microsoft/microsoft-graph-types";

const initialState: AnalyticsState = {
    intranetWideUniqueVisitors: {} as IAiTablesResponse,
    siteGraphSearchedSitesCollection: [] as Site[],
    siteGraphSearchedSitesSearchTerm: '',
    siteAnalyticalData: {} as IAiTablesResponse,
    siteData90Days: {} as IAiTablesResponse,
    errorMessage: "",
    isLoading: false,
    isLoadingSpSiteData: false,
    errorMessageSpSiteData: "",
    pageViewsDataIsLoading: false,
    pageViewsDataErrorMessage: '',
    pageViewsPerSite: {} as IAiTablesResponse,
    pageUniqueVistorsSelectedSiteId: '',
    pageUniqueVisitorsIsLoading: false,
    pageUniqueVisitorsErrorMessage: '',
    pageUniqueVisitorsData: {} as IAiTablesResponse
};

export function analyticsReducer(
    state = initialState,
    action: { type: AnalyticsActionTypes; payload: any }
): AnalyticsState {
    switch (action.type) {
        case AnalyticsActionTypes.FETCH_INTRANET_WIDE_UNIQUE_VISITORS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case AnalyticsActionTypes.FETCH_INTRANET_WIDE_UNIQUE_VISITORS_SUCCESS:
            return {
                ...state,
                intranetWideUniqueVisitors: action.payload.visitors,
                errorMessage: "",
                isLoading: false
            };
        case AnalyticsActionTypes.FETCH_INTRANET_WIDE_UNIQUE_VISITORS_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isLoading: false
            };

        case AnalyticsActionTypes.FETCH_SP_SITE_STARTED:
            return {
                ...state,
                isLoadingSpSiteData: true,
                siteGraphSearchedSitesSearchTerm: action.payload.searchValue,
                errorMessage: ''
            };

        case AnalyticsActionTypes.FETCH_SP_SITE_SUCCESS:
            return {
                ...state,
                isLoadingSpSiteData: false,
                siteGraphSearchedSitesCollection: action.payload.sites.value
            }

        case AnalyticsActionTypes.FETCH_SP_SITE_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isLoadingSpSiteData: false
            };

        case AnalyticsActionTypes.EMPTY_SEARCHED_SITE_COLLECTION:
            return {
                ...state,
                siteGraphSearchedSitesCollection: []
            }







        case AnalyticsActionTypes.FETCH_SP_SITE_ANALYTIC_DATA_STARTED:
            return {
                ...state,
                isLoadingSpSiteData: true,
                errorMessageSpSiteData: ''
            }

        case AnalyticsActionTypes.FETCH_SP_SITE_ANALYTIC_DATA_SUCCESS:
            return {
                ...state,
                isLoadingSpSiteData: false,
                siteAnalyticalData: action.payload.analytics,
            }

        case AnalyticsActionTypes.FETCH_SP_SITE_ANALYTIC_DATA_FAILURE:
            return {
                ...state,
                errorMessageSpSiteData: action.payload.errorMessage,
                isLoadingSpSiteData: false
            };

        case AnalyticsActionTypes.FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_STARTED:
            return {
                ...state
            }

        case AnalyticsActionTypes.FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_SUCCESS:
            return {
                ...state,
                isLoadingSpSiteData: false,
                siteData90Days: action.payload.analytics
            }

        case AnalyticsActionTypes.FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_FAILURE:
            return {
                ...state
            }



        case AnalyticsActionTypes.FETCH_PAGE_VIEWS_STARTED:
            return {
                ...state,
                pageViewsDataIsLoading: true,
                pageViewsDataErrorMessage: ''
            }

        case AnalyticsActionTypes.FETCH_PAGE_VIEWS_SUCCESS:
            return {
                ...state,
                pageViewsDataIsLoading: false,
                pageViewsPerSite: action.payload.analytics
            }


        case AnalyticsActionTypes.FETCH_PAGE_VIEWS_FAILURE:
            return {
                ...state,
                pageViewsDataIsLoading: false,
                pageViewsDataErrorMessage: action.payload.errorMessage
            }

        case AnalyticsActionTypes.FETCH_USER_ANALYTICS_PER_SITE_STARTED: {
            return {
                ...state,
                pageUniqueVisitorsIsLoading: true,

            }
        }

        case AnalyticsActionTypes.FETCH_USER_ANALYTICS_PER_SITE_SUCCESS: {
            return {
                ...state,
                pageUniqueVistorsSelectedSiteId: action.payload.pageUrl,
                pageUniqueVisitorsIsLoading: false,
                pageUniqueVisitorsErrorMessage: '',
                pageUniqueVisitorsData: action.payload.analytics
            }
        }

        case AnalyticsActionTypes.FETCH_USER_ANALYTICS_PER_SITE_FAILURE: {
            return {
                ...state,
                pageUniqueVistorsSelectedSiteId: '',
                pageUniqueVisitorsIsLoading: false,
                pageUniqueVisitorsErrorMessage: action.payload.errorMessage,
                pageUniqueVisitorsData: {} as IAiTablesResponse
            }
        }


        default:
            return state;
    }
}
