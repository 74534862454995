// Framework & Third-pary
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrappedComponentProps, FormattedMessage } from "react-intl";
import Loader from "react-loader-spinner";
import {
	PrimaryButton,
	TextField,
} from "@fluentui/react";

// Images
import topdeskImage from "../../../../../assets/images/topdesk-logo-80.png";

// Models
import { SuccessTypes, ErrorTypes } from "../../../../../models/enums";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";

// Components & functions
import { Formik } from "formik";
import { createConnection, updateConnectionById } from "../../../../../store/connection/actions";
import { IUpdateConnection } from "../../../../../models/responseModels/connection/IUpdateConnection";
import { IUpdateConnectionValue } from "../../../../../models/responseModels/connection/IUpdateConnectionValue";
import { IAddConnectionValue } from "../../../../../models/responseModels/connection/IAddConnectionValue";
import { IAddConnection } from "../../../../../models/responseModels/connection/IAddConnection";
import { ConnectionType } from "../../../../../models/enums/ConnectionType";

export interface TopdeskTileProps {
	intl: WrappedComponentProps["intl"];
}

export const TopdeskTile = (props: TopdeskTileProps) => {
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const connectionSlice = useSelector((state: IApplicationState) => state.connectionSlice);
	const dispatch = useDispatch();

	const handleSubmit = (values) => {
		let connections = connectionSlice.connections.data.filter((d) => d.name === "Topdesk");
		if (connections.length > 0) {
			//update
			let updateValues: IUpdateConnectionValue[] = [
				{
					key: "apiUrl",
					value: values.TopdeskApiUrl,
				},
				{
					key: "username",
					value: values.TopdeskApiUsername,
				},
				{
					key: "password",
					value: values.TopdeskApiPassword,
				},
			];

			let updateConnection: IUpdateConnection = {
				id: connections[0].id,
				values: updateValues,
			};
			dispatch(updateConnectionById(updateConnection));
		} else {
			//create
			let createValues: IAddConnectionValue[] = [
				{
					key: "apiUrl",
					value: values.TopdeskApiUrl,
				},
				{
					key: "username",
					value: values.TopdeskApiUsername,
				},
				{
					key: "password",
					value: values.TopdeskApiPassword,
				},
			];

			let createCon: IAddConnection = {
				name: "Topdesk",
				connectionTypeId: ConnectionType.TopDesk,
				values: createValues,
			};
			dispatch(createConnection(createCon));
		}
	};
	
	const renderTopdeskContentUi = () => {
		if (settingsSlice.isLoading) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		let initialValues = {
			TopdeskApiUrl: "",
			TopdeskApiUsername: "",
			TopdeskApiPassword: "",
		};

		let connections = connectionSlice.connections.data.filter((d) => d.name === "Topdesk");
		if (connections && connections.length > 0) {
			let connection = connections[0];

			if (connection.values && connection.values.length > 0) {
				let apiUrl =
					connection.values.filter((c) => c.key === "apiUrl").length > 0
						? connection.values.filter((c) => c.key === "apiUrl")[0].value
						: "";

				let username =
					connection.values.filter((c) => c.key === "username").length > 0
						? connection.values.filter((c) => c.key === "username")[0].value
						: "";

				let password =
					connection.values.filter((c) => c.key === "password").length > 0
						? connection.values.filter((c) => c.key === "password")[0].value
						: "";

				initialValues = {
					TopdeskApiUrl: apiUrl !== null ? apiUrl : "",
					TopdeskApiUsername: username !== null ? username : "",
					TopdeskApiPassword: password !== null ? password : "",
				};
			}
		}

		return (
			<div className="youSettings">
				<div className="youSettings-topdesk-logo">
					<img src={topdeskImage} alt="Topdesk logo" />
					<h1>
						<FormattedMessage id="settings.you.topdesk.title" defaultMessage="Topdesk connecties" />
					</h1>
				</div>

				<Formik
					initialValues={initialValues}
					enableReinitialize
					onSubmit={handleSubmit}
				>
					{(formikProps) => {
						const { values, errors, isSubmitting, setSubmitting, handleSubmit, handleChange, handleBlur } = formikProps;
						if (
							isSubmitting &&
							(connectionSlice.success.type !== SuccessTypes.None || connectionSlice.error.type !== ErrorTypes.None)
						) {
							setSubmitting(false);
						}
						return (
							<div className="ilx-form-wrapper">
								<form onSubmit={handleSubmit}>
									<TextField
										id="TopdeskApiUrl"
										label={props.intl.formatMessage({
											id: "settings.you.topdesk.api.url",
											defaultMessage: "API URL",
										})}
										placeholder={"https://"}
										type="text"
										errorMessage={errors.TopdeskApiUrl}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.TopdeskApiUrl}
										className="ilx-text-field-small"
										autoComplete="Off"
									/>

									<TextField
										id="TopdeskApiUsername"
										label={props.intl.formatMessage({
											id: "settings.you.topdesk.api.username",
											defaultMessage: "API Username",
										})}
										type="text"
										errorMessage={errors.TopdeskApiUsername}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.TopdeskApiUsername}
										className="ilx-text-field-small"
										autoComplete="Off"
									/>

									<TextField
										id="TopdeskApiPassword"
										label={props.intl.formatMessage({
											id: "settings.you.topdesk.api.password",
											defaultMessage: "API Password",
										})}
										type="password"
										errorMessage={errors.TopdeskApiPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.TopdeskApiPassword}
										className="ilx-text-field-small"
										autoComplete="Off"
									/>

									<PrimaryButton type="submit" disabled={isSubmitting}>
										<FormattedMessage id="general.save" defaultMessage="Opslaan" />
									</PrimaryButton>
								</form>
							</div>
						);
					}}
				</Formik>
			</div>
		);
	};

	return (
		<div className="settings-setting-wrapper" test-name="settings-tile">
			<div className="settings-tile-body form-wrapper">{renderTopdeskContentUi()}</div>
		</div>
	);
};
