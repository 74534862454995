import { MailTemplatesActionTypes, MailTemplatesState } from "./types";

import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums";

const initialState: MailTemplatesState = {
	mailTemplates: [],
	currentManageMailTemplate: null,

	loaders: {
		isLoadingInitialData: false,
		isLoadingDetailData: false,
		isCreating: false,
		isUpdating: false,
		isDeleting: false,
	},

	error: {
		message: "",
		type: ErrorTypes.None,
	},
	success: {
		type: SuccessTypes.None,
	},
};

export function mailTemplatesReducer(
	state = initialState,
	action: { type: MailTemplatesActionTypes; payload: any }
): MailTemplatesState {
	switch (action.type) {
		case MailTemplatesActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case MailTemplatesActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};

		// --== Fetch mail templates ==--
		case MailTemplatesActionTypes.FETCH_MAIL_TEMPLATES_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isLoadingInitialData: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case MailTemplatesActionTypes.FETCH_MAIL_TEMPLATES_SUCCESS:
			return {
				...state,
				mailTemplates: action.payload.mailTemplates,
				loaders: {
					...state.loaders,
					isLoadingInitialData: false,
				},
			};
		case MailTemplatesActionTypes.FETCH_MAIL_TEMPLATES_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isLoadingInitialData: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnInitialDataLoad,
				},
			};

		// --== Fetch manage mail template ==--
		case MailTemplatesActionTypes.FETCH_MANAGE_MAIL_TEMPLATE_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isLoadingDetailData: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case MailTemplatesActionTypes.FETCH_MANAGE_MAIL_TEMPLATE_SUCCESS:
			return {
				...state,
				currentManageMailTemplate: action.payload.currentManageMailTemplate,
				loaders: {
					...state.loaders,
					isLoadingDetailData: false,
				},
			};
		case MailTemplatesActionTypes.FETCH_MANAGE_MAIL_TEMPLATE_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isLoadingDetailData: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnDetailDataLoad,
				},
			};

		// --== Fetch manage mail template ==--
		case MailTemplatesActionTypes.ADD_MAIL_TEMPLATES_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isCreating: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case MailTemplatesActionTypes.ADD_MAIL_TEMPLATES_SUCCESS:
			return {
				...state,
				currentManageMailTemplate:
					state.currentManageMailTemplate !== null
						? {
								...state.currentManageMailTemplate,
								customMailTemplate: action.payload.mailTemplate,
						  }
						: null,
				loaders: {
					...state.loaders,
					isCreating: false,
				},
				success: { type: SuccessTypes.OnCreate },
			};
		case MailTemplatesActionTypes.ADD_MAIL_TEMPLATES_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isCreating: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnCreate,
				},
			};

		// --== UPDATE mail template ==--
		case MailTemplatesActionTypes.UPDATE_MAIL_TEMPLATES_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isUpdating: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case MailTemplatesActionTypes.UPDATE_MAIL_TEMPLATES_SUCCESS:
			return {
				...state,
				currentManageMailTemplate:
					state.currentManageMailTemplate !== null
						? {
								...state.currentManageMailTemplate,
								customMailTemplate: action.payload.mailTemplate,
						  }
						: null,
				loaders: {
					...state.loaders,
					isUpdating: false,
				},
				success: { type: SuccessTypes.OnUpdate },
			};
		case MailTemplatesActionTypes.UPDATE_MAIL_TEMPLATES_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isUpdating: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnUpdate,
				},
			};

		// --== DELETE template ==--
		case MailTemplatesActionTypes.DELETE_MAIL_TEMPLATES_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isDeleting: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
				success: { type: SuccessTypes.None },
			};
		case MailTemplatesActionTypes.DELETE_MAIL_TEMPLATES_SUCCESS:
			return {
				...state,
				currentManageMailTemplate:
					state.currentManageMailTemplate !== null
						? {
								...state.currentManageMailTemplate,
								customMailTemplate: null,
						  }
						: null,
				loaders: {
					...state.loaders,
					isDeleting: false,
				},
				success: { type: SuccessTypes.OnDelete },
			};
		case MailTemplatesActionTypes.DELETE_MAIL_TEMPLATES_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isDeleting: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnDelete,
				},
			};

		default:
			return state;
	}
}
