import React from "react";
import { UserResponse } from "../../../../../models/responseModels/user/UserResponse";
import { FormattedMessage } from "react-intl";

export interface InformationTabProps {
	user: UserResponse;
}

export const InformationTabComponent = (props: InformationTabProps) => {
	const { user } = props;
	return (
		<div className="user-modal-content user-modal-content__information-tab">
			<div className="user-info-column">
				<h3>
					<FormattedMessage id="users.modal.info.contact.header" defaultMessage="Contactinformatie" />
				</h3>
				<label>
					<FormattedMessage id="users.modal.info.contact.name" defaultMessage="Naam" />
				</label>
				<span>
					{user.name || <FormattedMessage id="general.notSpecified" defaultMessage="Niet gespecificeerd" />}
				</span>
				<label>
					<FormattedMessage id="users.modal.info.contact.email" defaultMessage="E-mailadres" />
				</label>
				<span>
					{user.emailAddress || (
						<FormattedMessage id="general.notSpecified" defaultMessage="Niet gespecificeerd" />
					)}
				</span>
			</div>
			<div className="user-info-column">
				<h3>
					<FormattedMessage id="users.modal.info.occupation.header" defaultMessage="Werkzaamheden" />
				</h3>
				<label>
					<FormattedMessage id="users.modal.info.occupation.jobTitle" defaultMessage="Functie" />
				</label>
				<span>
					{user.jobTitle || (
						<FormattedMessage id="general.notSpecified" defaultMessage="Niet gespecificeerd" />
					)}
				</span>
				<label>
					<FormattedMessage id="users.modal.info.occupation.department" defaultMessage="Afdeling" />
				</label>
				<span>
					{user.department || (
						<FormattedMessage id="general.notSpecified" defaultMessage="Niet gespecificeerd" />
					)}
				</span>
				<label>
					<FormattedMessage id="users.modal.info.occupation.officeLocation" defaultMessage="Locatie" />
				</label>
				<span>
					{user.officeLocation || (
						<FormattedMessage id="general.notSpecified" defaultMessage="Niet gespecificeerd" />
					)}
				</span>
			</div>
		</div>
	);
};
