import { ISettingsBody } from "../../models/interfaces/ISettingsBody";

export class Settings {
	settings: any;

	constructor(settingsObj) {
		this.settings = settingsObj;
	}

	// args:
	// @param {String} subArray         - Name of the subarray you want to get the setting from.
	// @param {string} keyToFind        - Key of the setting you want to get.
	// @return {object} valueToReturn   - the settings object.
	getSettingByKey(subArray, keyToFind): { key: string; value: string } | null {
		let valueToReturn = null;

		if (typeof this.settings != "undefined") {
			const settingsSubArray = this.settings[subArray];

			if (typeof this.settings[subArray] != "undefined" && settingsSubArray.length > 0) {
				for (let se of settingsSubArray) {
					if (se.key === keyToFind) {
						valueToReturn = se;
						break;
					}
				}
			}
		}

		return valueToReturn;
	}

	// args:
	// @param {Array} values         	- Array of the values that need to be changed to a settings format
	// @return {object} valueToReturn   - settingsObject to be send to the api.
	convertSettingsToApiSaveFormat(values): ISettingsBody {
		const keys = Object.keys(values);
		const items = Object.values(values);

		const valuesToSend = keys.map((key, i) => {
			return { key, value: items[i] as string };
		});

		return { settings: valuesToSend };
	}
}
