// Framework & third-party
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import {
	ActionButton,
	Icon,
	MessageBar,
	MessageBarType,
	Separator,
	TextField,
	Toggle,
} from "@fluentui/react";

// Store

// Models
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { ITermGroup } from "../../../../../models/responseModels/taxonomy/ITermGroup";
import { AddTermSetPanelComponent } from "./addTermSetPanelComponent/AddTermSetPanelComponent";
import { useEffect, useState } from "react";
import { ErrorTypes, SuccessTypes } from "../../../../../models/enums";
import { TaxonomyItem } from "../../../../../models/enums/TaxonomyITems";
import { resetError } from "../../../../../store/audiences/actions";
import { resetSuccess } from "../../../../../store/settings/actions";
import { IUpdateTermGroup } from "../../../../../models/viewmodels/taxonomy/IUpdateTermGroup";
import { updateTermGroup } from "../../../../../store/taxonomy/actions";

export interface ISelectedGroupProps {
	group: ITermGroup;
	shouldOpenPanel?: boolean;
	resetShouldOpenPanel: () => void;
}

const SelectedGroup = (props: WrappedComponentProps & ISelectedGroupProps) => {
	const { group, intl } = props;
	const dispatch = useDispatch();
	const taxonomySlice = useSelector((state: IApplicationState) => state.taxonomySlice);

	const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (props.shouldOpenPanel) {
			setPanelIsOpen(true);
			props.resetShouldOpenPanel();
		}
	}, [props]);

	React.useEffect(() => {
		if (
			taxonomySlice.success.type === SuccessTypes.OnCreate &&
			taxonomySlice.success.forType === TaxonomyItem.TermGroup &&
			panelIsOpen
		) {
			setPanelIsOpen(false);
		}
	}, [taxonomySlice.success.type, taxonomySlice.success.forType, panelIsOpen]);

	const renderMessageBar = () => {
		if (
			taxonomySlice.error.type !== ErrorTypes.None &&
			taxonomySlice.error.forType === TaxonomyItem.TermGroup
		) {
			if (taxonomySlice.error.type === ErrorTypes.OnUpdate) {
				return (
					<MessageBar
						messageBarType={MessageBarType.error}
						onDismiss={() => dispatch(resetError())}
						styles={{
							root: {
								margin: "-4rem -4rem 0.8rem -4rem",
								width: "calc(100% + 8rem)",
							},
						}}
					>
						<FormattedMessage
							id="taxonomy.termGroup.updated.failure"
							defaultMessage="Er ging iets mis bij het updaten van de termengroep."
						/>
					</MessageBar>
				);
			}
		}
		if (
			taxonomySlice.success.type !== SuccessTypes.None &&
			taxonomySlice.success.forType === TaxonomyItem.TermGroup
		) {
			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					onDismiss={() => dispatch(resetSuccess())}
					styles={{
						root: {
							margin: "-4rem -4rem 0.8rem -4rem",
							width: "calc(100% + 8rem)",
						},
					}}
				>
					{taxonomySlice.success.type === SuccessTypes.OnUpdate && (
						<FormattedMessage
							id="taxonomy.termGroup.updated.success"
							defaultMessage="De termengroep is successlvol geüpdatet"
						/>
					)}
					{taxonomySlice.success.type === SuccessTypes.OnCreate && (
						<FormattedMessage
							id="taxonomy.termSet.created.success"
							defaultMessage="De termenset is succesvol aangemaakt."
						/>
					)}
				</MessageBar>
			);
		}
	};

	return (
		<section className="taxonomy-card-wrapper taxonomy-selected-wrapper ">
			{renderMessageBar()}
			<div className="taxonomy-selected">
				<div className="taxonomy-selected-topbar">
					<div className="taxonomy-selected-topbar-title">
						<h3>{group.name}</h3>
						<Icon iconName="FabricFolder" />
					</div>
					<div>
						<ActionButton
							iconProps={{ iconName: "add" }}
							text="TermSet"
							onClick={() => {
								dispatch(resetSuccess());
								setPanelIsOpen(true);
							}}
						/>
					</div>
				</div>
				<Separator />
				<div className="ilx-form-wrapper">
					<Formik
						enableReinitialize
						initialValues={{
							name: group.name !== null ? group.name : "",
							isTenantWide: group.isTenantWide,
						}}
						onSubmit={(values) => {
							const requestBody = {
								id: group.id,
								name: values.name || "",
								isTenantWide: values.isTenantWide,
							} as IUpdateTermGroup;

							dispatch(updateTermGroup(requestBody));
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.min(
									2,
									intl.formatMessage({
										id: "taxonomy.validation.name.minTwoChars",
										defaultMessage:
											"De naam moet ten minste twee tekens lang zijn.",
									})
								)
								.required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
							} = props;

							return (
								<form onSubmit={handleSubmit}>
									<TextField
										id="name"
										disabled
										label={intl.formatMessage({
											id: "taxonomy.term.name.label",
											defaultMessage: "Naam",
										})}
										required
										placeholder={intl.formatMessage({
											id: "taxonomy.term.name.placeholder",
											defaultMessage: "De naam van de term",
										})}
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										errorMessage={errors.name}
										className={"ilx-text-field"}
									/>
									<Toggle
										checked={values.isTenantWide}
										disabled
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="taxonomy.group.isTenantWide.label"
														defaultMessage="Is organisatie breed"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.group.isTenantWide.description"
														defaultMessage="Deze termgroep kan door de hele organisatie gebruikt worden."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) =>
											setFieldValue("isTenantWide", checked)
										}
									/>

									{/* <PrimaryButton
										text={intl.formatMessage({
											id: "general.save",
											defaultMessage: "Opslaan",
										})}
										type="submit"
										disabled={
											taxonomySlice.isUpdatingOrCreating ||
											(Object.keys(errors).length !== 0 &&
												errors.constructor === Object)
										}
									/> */}
								</form>
							);
						}}
					</Formik>
				</div>
			</div>

			<AddTermSetPanelComponent
				isOpen={panelIsOpen}
				onClose={() => setPanelIsOpen(false)}
				termGroup={group}
			/>
		</section>
	);
};

export const SelectedGroupComponent = injectIntl(SelectedGroup);
