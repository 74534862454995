let timeout: any = 0;

export function delay(fn: any, ms: any) {
	if (timeout) clearTimeout(timeout);
	timeout = setTimeout(fn, ms);
}

export const calculateYAxisScale = (dataInput, aggregateFieldName) => {
	let highestValue = 0;
	dataInput.forEach((row, i) => {
		if (row[aggregateFieldName] > highestValue) {
			highestValue = row[aggregateFieldName];
		}
	});
	return highestValue < 50 ? Math.floor(highestValue + 5) : Math.floor(highestValue * 1.1);
};

export const stringHasValue = (string: string | null | undefined) => {
	return string !== null && string !== undefined && string !== "";
};
