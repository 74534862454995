import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { TeamTypesState, TeamTypesActionTypes } from "./types";
import { adalOneApiFetch } from "../../adalConfig";
import { ITeamType } from "@one/core";

export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: TeamTypesActionTypes.RESET_SUCCESS,
		});
	};
};
export const clearError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: TeamTypesActionTypes.CLEAR_ERROR,
		});
	};
};

// --== Fetch team types
export const fetchTeamTypes = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchTeamTypesRequest());
	};
};

const fetchTeamTypesRequest: ActionCreator<ThunkAction<Promise<any>, TeamTypesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchTeamTypesStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teamtypes`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchTeamTypesFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchTeamTypesSuccess(result));
			}
		} catch (e) {
			dispatch(fetchTeamTypesFailure("error"));
		}
	};
};

const fetchTeamTypesStarted = () => ({
	type: TeamTypesActionTypes.FETCH_TEAMTYPES_STARTED,
});

const fetchTeamTypesSuccess = (teamTypes: any) => ({
	type: TeamTypesActionTypes.FETCH_TEAMTYPES_SUCCESS,
	payload: {
		teamTypes,
	},
});

const fetchTeamTypesFailure = (errorMessage: string | object) => ({
	type: TeamTypesActionTypes.FETCH_TEAMTYPES_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== create team types
export const createTeamType = (createTeamType: Omit<ITeamType, "id">) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(createTeamTypeRequest(createTeamType));
	};
};

const createTeamTypeRequest: ActionCreator<ThunkAction<Promise<any>, TeamTypesState, null, any>> = (createTeamType: Omit<ITeamType, "id">) => {
	return async (dispatch: Dispatch) => {
		dispatch(createTeamTypeStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teamtypes`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ ...createTeamType }),
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
					dispatch(createTeamTypeFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(createTeamTypeSuccess(result));
			}
		} catch (e) {
			dispatch(createTeamTypeFailure("error"));
		}
	};
};

const createTeamTypeStarted = () => ({
	type: TeamTypesActionTypes.CREATE_TEAMTYPE_STARTED,
});

const createTeamTypeSuccess = (createdTeamType: ITeamType) => ({
	type: TeamTypesActionTypes.CREATE_TEAMTYPE_SUCCESS,
	payload: {
		createdTeamType,
	},
});

const createTeamTypeFailure = (errorMessage: string | object) => ({
	type: TeamTypesActionTypes.CREATE_TEAMTYPE_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== update team types
export const updateTeamType = (updateTeamType: ITeamType) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateTeamTypeRequest(updateTeamType));
	};
};

const updateTeamTypeRequest: ActionCreator<ThunkAction<Promise<any>, TeamTypesState, null, any>> = (updateTeamType: ITeamType) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateTeamTypeStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/teamtypes`, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(updateTeamType),
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
					dispatch(updateTeamTypeFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(updateTeamTypeSuccess(result));
			}
		} catch (e) {
			dispatch(updateTeamTypeFailure("error"));
		}
	};
};

const updateTeamTypeStarted = () => ({
	type: TeamTypesActionTypes.UPDATE_TEAMTYPE_STARTED,
});

const updateTeamTypeSuccess = (updatedTeamType: ITeamType) => ({
	type: TeamTypesActionTypes.UPDATE_TEAMTYPE_SUCCESS,
	payload: {
		updatedTeamType,
	},
});

const updateTeamTypeFailure = (errorMessage: string | object) => ({
	type: TeamTypesActionTypes.UPDATE_TEAMTYPE_FAILURE,
	payload: {
		errorMessage,
	},
});
