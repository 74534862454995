import React, { useEffect, useState } from "react";
import { Modal, Icon, MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { User } from "@microsoft/microsoft-graph-types";

import anonymousUserImage from "../../../../assets/images/anonymous-user-gravatar.png";

import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ErrorTypes, SuccessTypes } from "../../../../models/enums";
import { addUser, fetchUserByObjectId, resetFetchedUserById, resetSuccess } from "../../../../store/users/actions";

import { TabsComponent, TabDataObject } from "../../../reusable/tabsComponent/TabsComponent";
import { RolesTabComponent } from "./rolesTabComponent/RolesTabComponent";
import { fetchUserPhotoUrl } from "../../../../common/httpFunctions/Users";
import { InformationTabComponent } from "./informationTabComponent/InformationTabComponent";
import { CreateUserProfileComponent } from "./createUserProfileComponent/CreateUserProfileComponent";
import { UserActionsTabComponent } from "./userActionsTabComponent/UserActionsTabComponent";

export interface UserModalProps {
	user: User;
	isOpen: boolean;
	onDismiss: () => void;
}

const UserModal = (props: WrappedComponentProps & UserModalProps) => {
	const dispatch = useDispatch();
	const usersSlice = useSelector((state: IApplicationState) => state.usersSlice);

	const [activeTabId, setActiveTabId] = useState(0);
	const [userImage, setUserImage] = useState(anonymousUserImage);

	const oneUser = usersSlice.fetchedUserById;
	const graphUser = props.user;
	const user = usersSlice.fetchedUserById === null || usersSlice.isFetchingUserById ? props.user : usersSlice.fetchedUserById;

	useEffect(() => {
		if (props.user.id !== undefined) {
			dispatch(fetchUserByObjectId(props.user.id));
		}
	}, [dispatch, props.user.id]);

	useEffect(() => {
		const fetchUserImage = async () => {
			try {
				if (graphUser.id !== undefined) {
					const userImageUrl = await fetchUserPhotoUrl(graphUser.id);
					setUserImage(userImageUrl);
				}
			} catch (e) {
				setUserImage(anonymousUserImage);
			}
		};

		if (!usersSlice.isFetchingUserById && usersSlice.fetchedUserById !== null) {
			fetchUserImage();
		}
	}, [usersSlice.isFetchingUserById, usersSlice.fetchedUserById, user, graphUser.id]);

	const _closeModal = () => {
		dispatch(resetFetchedUserById());
		props.onDismiss();
	};

	const determineModalContent = () => {
		if (usersSlice.isFetchingUserById) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		if (
			(usersSlice.error.type === ErrorTypes.OnDetailDataLoad && usersSlice.error.code === 404) ||
			usersSlice.error.type === ErrorTypes.OnCreate ||
			usersSlice.isCreatingUser
		) {
			return (
				<CreateUserProfileComponent
					onAddUserProfile={() => (graphUser.id !== undefined ? dispatch(addUser(graphUser.id)) : undefined)}
					onCancel={() => _closeModal()}
					isLoading={usersSlice.isCreatingUser}
				/>
			);
		}

		if (usersSlice.error.type === ErrorTypes.OnDetailDataLoad) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<PrimaryButton
						iconProps={{ iconName: "Refresh" }}
						text={props.intl.formatMessage({
							id: "general.retry",
							defaultMessage: "Probeer opnieuw",
						})}
						onClick={() => (graphUser.id !== undefined ? dispatch(fetchUserByObjectId(graphUser.id)) : undefined)}
					/>
				</div>
			);
		}

		let activeTabUi;
		if (oneUser !== null) {
			switch (activeTabId) {
				case 2:
					activeTabUi = <UserActionsTabComponent user={oneUser} />;
					break;
				case 1:
					activeTabUi = <RolesTabComponent user={oneUser} />;
					break;
				default:
					activeTabUi = <InformationTabComponent user={oneUser} />;
			}
		}

		return (
			<>
				<TabsComponent activeTabId={activeTabId} setActiveTab={setActiveTabId} tabData={tabData} />
				{activeTabUi}
			</>
		);
	};

	const tabData: TabDataObject[] = [
		{
			id: 0,
			name: props.intl.formatMessage({
				id: "general.information",
				defaultMessage: "Informatie",
			}),
			title: props.intl.formatMessage({
				id: "general.information",
				defaultMessage: "Informatie",
			}),
		},
		{
			id: 1,
			name: props.intl.formatMessage({ id: "general.roles", defaultMessage: "Rollen" }),
			title: props.intl.formatMessage({ id: "general.roles", defaultMessage: "Rollen" }),
		},
		{
			id: 2,
			name: props.intl.formatMessage({
				id: "users.modal.actions.header",
				defaultMessage: "Acties",
			}),
			title: props.intl.formatMessage({
				id: "users.modal.actions.header",
				defaultMessage: "Acties",
			}),
		},
	];

	const modalContent = determineModalContent();

	return (
		<Modal
			onDismiss={() => _closeModal()}
			isBlocking={false}
			scrollableContentClassName="model-body"
			containerClassName="modal-wrapper"
			isOpen={props.isOpen}
		>
			<section className="user-modal-header-wrapper">
				<div className="user-wrapper">
					<img src={userImage} className="user-wrapper__avatar" alt="userphoto" />
					<div className="user-wrapper__info">
						<h1>{graphUser.displayName}</h1>
						{user.jobTitle !== "" && user.jobTitle !== null && <span>{user.jobTitle}</span>}
					</div>
				</div>
				<div className="user-modal-close-button" onClick={() => _closeModal()}>
					<Icon iconName="ChromeClose" />
				</div>
			</section>

			{usersSlice.error.type === ErrorTypes.OnDetailDataLoad && usersSlice.error.code !== 404 && (
				<MessageBar messageBarType={MessageBarType.error}>
					<FormattedMessage
						id="users.modal.load.error"
						defaultMessage="Er ging iets mis bij het ophalen van de informatie over deze gebruiker."
					/>
				</MessageBar>
			)}
			{usersSlice.success.type === SuccessTypes.OnCreate && (
				<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
					<FormattedMessage id="users.successMessage.onCreate" defaultMessage="Gebruiker is succesvol aangemaakt." />
				</MessageBar>
			)}

			<section className="user-modal-body">{modalContent}</section>
		</Modal>
	);
};

export const UserModalComponent = injectIntl(UserModal);
