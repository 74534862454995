import { LookupListsActionTypes, LookupListsState } from "./types";
import { LookupListsResponse } from "../../models/responseModels/lookupLists/LookupListsResponse";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums";

const initialState: LookupListsState = {
	lookupLists: [] as LookupListsResponse[],
	shouldClosePanel: false,
	isCreating: false,
	isLoadingInitialData: false,
	isDeleting: false,
	error: {
		message: "",
		type: ErrorTypes.None,
	},
	success: {
		type: SuccessTypes.None,
	},
};

export function lookupListsReducer(
	state = initialState,
	action: { type: LookupListsActionTypes; payload: any }
): LookupListsState {
	switch (action.type) {
		case LookupListsActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case LookupListsActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};
		case LookupListsActionTypes.UPDATE_SHOULD_CLOSE_PANEL:
			return {
				...state,
				shouldClosePanel: action.payload.shouldClosePanel,
			};

		// --== Fetch Lookup List ==--
		case LookupListsActionTypes.FETCH_LOOKUPLIST_STARTED:
			return {
				...state,
				isLoadingInitialData: true,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case LookupListsActionTypes.FETCH_LOOKUPLIST_SUCCESS:
			return {
				...state,
				lookupLists: action.payload.lookupLists,
				isLoadingInitialData: false,
			};
		case LookupListsActionTypes.FETCH_LOOKUPLIST_FAILURE:
			return {
				...state,
				isLoadingInitialData: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnInitialDataLoad,
				},
			};

		// --== Create Lookup List ==--
		case LookupListsActionTypes.CREATE_LOOKUPLIST_VALUE_STARTED:
			return {
				...state,
				isCreating: true,
				shouldClosePanel: false,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
				success: {
					type: SuccessTypes.None,
				},
			};
		case LookupListsActionTypes.CREATE_LOOKUPLIST_VALUE_SUCCESS:
			const newLookuplists = [...state.lookupLists];
			for (let i = 0; i < state.lookupLists.length; i++) {
				const item = state.lookupLists[i];

				if (item.id === action.payload.lookupList.id) {
					newLookuplists[i].lookupListValues.push(action.payload.newLookupListValue);
				}
			}
			return {
				...state,
				lookupLists: newLookuplists,
				isCreating: false,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnCreate,
				},
			};
		case LookupListsActionTypes.CREATE_LOOKUPLIST_VALUE_FAILURE:
			return {
				...state,
				isCreating: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnCreate,
				},
			};
		// --== Update Lookup List ==--
		case LookupListsActionTypes.UPDATE_LOOKUPLIST_VALUE_STARTED:
			return {
				...state,
				isCreating: true,
				shouldClosePanel: false,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
				success: {
					type: SuccessTypes.None,
				},
			};
		case LookupListsActionTypes.UPDATE_LOOKUPLIST_VALUE_SUCCESS:
			const newLookuplistsAfterUpdate = [...state.lookupLists];

			for (let i = 0; i < state.lookupLists.length; i++) {
				const item = state.lookupLists[i];

				if (item.id === action.payload.lookupList.id) {
					const newListValues = state.lookupLists[i].lookupListValues.map((value) =>
						value.id === action.payload.updatedListValue.id ? action.payload.updatedListValue : value
					);

					newLookuplistsAfterUpdate[i].lookupListValues = newListValues;
					break;
				}
			}
			return {
				...state,
				lookupLists: newLookuplistsAfterUpdate,
				isCreating: false,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};

		case LookupListsActionTypes.UPDATE_LOOKUPLIST_VALUE_FAILURE:
			return {
				...state,
				isCreating: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnUpdate,
				},
			};

		// --== Delete Lookup List ==--
		case LookupListsActionTypes.DELETE_LOOKUPLIST_VALUE_STARTED:
			return {
				...state,
				isDeleting: true,
				shouldClosePanel: false,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
				success: {
					type: SuccessTypes.None,
				},
			};
		case LookupListsActionTypes.DELETE_LOOKUPLIST_VALUE_SUCCESS:
			const passedList: LookupListsResponse = action.payload.lookupList;
			const newLookupListValues = passedList.lookupListValues.filter(
				(value) => value.id !== action.payload.valueIdToRemove
			);

			const newLookupLists = [...state.lookupLists];
			for (let i = 0; i < state.lookupLists.length; i++) {
				const list = state.lookupLists[i];

				if (list.id === passedList.id) {
					list.lookupListValues = newLookupListValues;
				}
			}

			return {
				...state,
				lookupLists: newLookupLists,
				isDeleting: false,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnDelete,
				},
			};
		case LookupListsActionTypes.DELETE_LOOKUPLIST_VALUE_FAILURE:
			return {
				...state,
				isDeleting: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnDelete,
				},
			};
		default:
			return state;
	}
}
