import { Icon, IDropdownOption, Separator } from "@fluentui/react";
import { Card, CardContent } from "@one/web";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FormattedMessage } from "react-intl";
import { ITeamTypeFormValues } from "../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";
import Accessibility from "./components/Accessibility";
import DeleteTeam from "./components/DeleteTeam";
import ArchiveTeam from "./components/ArchiveTeam";
import AddMembers from "./components/AddMembers";
import UpgradeGroup from "./components/UpgradeGroup";
import NewTeam from "./components/NewTeam";
import { ActionType } from "../../../../types/teamTypeSettings/ActionType";
import { SettingType } from "../../../../types/teamTypeSettings/SettingType";

export interface ISettingsProps {
	formikProps: FormikProps<ITeamTypeFormValues>;
	hasSubmitted: boolean;
	allowToAddUser?: boolean;
	defaultOwnersRequired?: boolean;
	mode: "edit" | "create";
}

export const Settings = (props: ISettingsProps) => {
	const { values, setFieldValue } = props.formikProps;

	const [isOpen, setIsOpen] = useState<{ [id: string]: boolean | undefined }>({});

	const updateRequestOrApprovalBySettings = (action: ActionType, type: SettingType, option: IDropdownOption) => {
		if (!values.settings[action]) {
			return;
		}

		const newSettings = { ...values.settings };

		if (!Array.isArray(newSettings[action][type])) {
			return;
		}

		if (option.selected) {
			newSettings[action][type].push(option.key);
		} else {
			newSettings[action][type] = newSettings[action][type].filter((role) => role !== option.key);
		}

		setFieldValue("settings", newSettings);
	};

	const settings = [
		{
			id: "newTeam",
			title: "Nieuw team",
			children: (
				<NewTeam
					values={props.formikProps.values}
					updateRequestOrApprovalBySettings={updateRequestOrApprovalBySettings}
					formikProps={props.formikProps}
					hasSubmitted={props.hasSubmitted}
					defaultOwnersRequired={props.defaultOwnersRequired}
				/>
			)
		},
		{
			id: "upgradeGroup",
			title: "Upgrade groep",
			children: <UpgradeGroup
				values={props.formikProps.values}
				updateRequestOrApprovalBySettings={updateRequestOrApprovalBySettings}
			/>
		},
		{
			id: "addMembers",
			title: "Leden toevoegen",
			children: <AddMembers
				values={props.formikProps.values}
				updateRequestOrApprovalBySettings={updateRequestOrApprovalBySettings}
			/>
		},
		{
			id: "archiveTeam",
			title: "Archiveer team",
			children: <ArchiveTeam
				values={props.formikProps.values}
				updateRequestOrApprovalBySettings={updateRequestOrApprovalBySettings}
			/>
		},
		{
			id: "deleteTeam",
			title: "Verwijder team",
			children: <DeleteTeam
				values={props.formikProps.values}
				updateRequestOrApprovalBySettings={updateRequestOrApprovalBySettings}
			/>
		},
		{
			id: "accessibility",
			title: "Toegankelijkheid",
			children: <Accessibility formikProps={props.formikProps} allowToAddUser={props.allowToAddUser} />
		}
	];

	return (
		<section style={{ marginBottom: "15px" }}>
			<Separator />

			<h2>
				<FormattedMessage id="settings.general" defaultMessage="Instellingen" />
			</h2>

			{settings.map((setting) => {
				return (
					<Card enableBorderTop>
						<CardContent className="collapsible">
							<div onClick={() => setIsOpen({ ...isOpen, [setting.id]: isOpen[setting.id] !== undefined ? !isOpen[setting.id] : true })}>
								<Icon iconName={isOpen ? "ChevronDown" : "ChevronRight"} />
								<h3>{setting.title}</h3>
							</div>

							<Collapse isOpened={isOpen[setting.id] === true}>
								{setting.children}
							</Collapse>
						</CardContent>
					</Card>
				)
			})}
		</section>
	);
};
