import React from "react";
import "./navTitle.css";

const NavTitle = ({ title }) => {
  return (
    <div id="navbar-title" className="navbar-title">
      {title}
    </div>
  );
};

export default NavTitle;
