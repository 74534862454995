import * as React from "react";
import { Formik } from "formik";
import {
	Icon,
	MessageBar,
	MessageBarType,
	Panel,
	PanelType,
	PrimaryButton,
	TextField,
	Toggle,
} from "@fluentui/react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { ErrorTypes } from "../../../../../../models/enums";
import { IApplicationState } from "../../../../../../models/interfaces/IApplicationState";
import { addTermSet, resetError } from "../../../../../../store/taxonomy/actions";
import { IAddTermSet } from "../../../../../../models/viewmodels/taxonomy/IAddTermSet";
import { ITermGroup } from "../../../../../../models/responseModels/taxonomy/ITermGroup";

export interface IAddTermSetPanelProps {
	termGroup: ITermGroup;
	isOpen: boolean;
	onClose: () => void;
}

const AddTermSetPanel = (props: WrappedComponentProps & IAddTermSetPanelProps) => {
	const { termGroup, isOpen, onClose, intl } = props;
	const dispatch = useDispatch();
	const taxonomySlice = useSelector((state: IApplicationState) => state.taxonomySlice);

	if (termGroup === null || !isOpen) {
		return null;
	}

	const initialValues: IAddTermSet = {
		name: "",
		isRequired: false,
		isActive: true,
		isReassignable: true,
		isClassification: false,
		isTeamType: false,
		termGroupId: termGroup.id,
	};
	return (
		<Panel
			className="one-panel"
			isOpen={isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={onClose}
			isLightDismiss
			onLightDismissClick={onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage
							id="taxonomy.termSet.new"
							defaultMessage="Nieuwe termenset"
						/>
					</h1>

					<div className="one-panel__panel-close" onClick={onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			{taxonomySlice.error.type === ErrorTypes.OnCreate && (
				<MessageBar
					messageBarType={MessageBarType.error}
					onDismiss={() => dispatch(resetError())}
				>
					{taxonomySlice.error.message}
				</MessageBar>
			)}
			<section className="one-panel__panel-content">
				<div className="ilx-form-wrapper">
					<Formik
						enableReinitialize
						initialValues={initialValues}
						onSubmit={(values) => {
							dispatch(addTermSet(values));
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.min(
									2,
									intl.formatMessage({
										id: "taxonomy.validation.name.minTwoChars",
										defaultMessage:
											"De naam moet ten minste twee tekens lang zijn.",
									})
								)
								.required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
							} = props;
							return (
								<form onSubmit={handleSubmit}>
									<TextField
										id="name"
										label={intl.formatMessage({
											id: "taxonomy.termSet.name.label",
											defaultMessage: "Naam",
										})}
										required
										placeholder={intl.formatMessage({
											id: "taxonomy.termSet.name.placeholder",
											defaultMessage: "De naam van de termenset",
										})}
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										autoComplete={"off"}
										errorMessage={errors.name}
										className={"ilx-text-field"}
									/>
									<Toggle
										checked={values.isRequired}
										disabled={!values.isActive && !values.isRequired}
										label={
											<div className="ilx-label-with-tooltip-panel">
												<label>
													<FormattedMessage
														id="taxonomy.termSet.isRequired.label"
														defaultMessage="Is verplicht"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.isRequired.description"
														defaultMessage="Deze termenset is verplicht om in te vullen."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) =>
											setFieldValue("isRequired", checked)
										}
									/>
									<Toggle
										checked={values.isActive}
										label={
											<div className="ilx-label-with-tooltip-panel">
												<label>
													<FormattedMessage
														id="general.isActive"
														defaultMessage="In gebruik"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.isActive.description"
														defaultMessage="Deze termenset is actief."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) => {
											setFieldValue("isActive", checked);
											if (!checked && values.isRequired) {
												setFieldValue("isRequired", false);
											}
										}}
									/>
									<Toggle
										checked={values.isReassignable}
										label={
											<div className="ilx-label-with-tooltip-panel">
												<label>
													<FormattedMessage
														id="taxonomy.termSet.isReassignable.label"
														defaultMessage="Is aanpasbaar"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.isReassignable.description"
														defaultMessage="De geselecteerde waarde van deze termenset mag gewijzigd worden."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) =>
											setFieldValue("isReassignable", checked)
										}
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.create",
											defaultMessage: "Aanmaken",
										})}
										type="submit"
										disabled={
											taxonomySlice.isUpdatingOrCreating ||
											(Object.keys(errors).length !== 0 &&
												errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</section>
		</Panel>
	);
};

export const AddTermSetPanelComponent = injectIntl(AddTermSetPanel);
