import { User } from "@microsoft/microsoft-graph-types";
import { delay, IOneUser, SlicePieceStatus } from "@one/core";
import {
	DetailsList,
	IColumn,
	Icon,
	MessageBar,
	MessageBarType,
	PrimaryButton,
	SearchBox,
	SelectionMode,
	ShimmeredDetailsList,
	TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { IRoleEnrichedOneUser } from "../../../../../models/interfaces/IRoleEnrichedOneUser";
import { addRoleToUser, searchUsersToAddToRole } from "../../../../../store/roles/actions";
import { RolesActionTypes } from "../../../../../store/roles/types";

export const AddUserToRoleTab = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const rolesSlice = useSelector((state: IApplicationState) => state.rolesSlice);
	const { roleModal } = rolesSlice;

	const columns: IColumn[] = [
		{
			key: "displayName",
			name: intl.formatMessage({
				id: "users.list.column.displayName",
				defaultMessage: "Naam",
			}),
			fieldName: "displayName",
			minWidth: 125,
			maxWidth: 150,
			isResizable: true,
		},
		{
			key: "jobTitle",
			name: intl.formatMessage({
				id: "users.list.column.jobTitle",
				defaultMessage: "Functie",
			}),
			fieldName: "jobTitle",
			minWidth: 150,
			maxWidth: 150,
			isResizable: true,
		},
		{
			key: "emailAddress",
			name: intl.formatMessage({
				id: "users.list.column.email",
				defaultMessage: "E-mailadres",
			}),
			fieldName: "emailAddress",
			minWidth: 150,
			maxWidth: 300,
			isResizable: true,
		},
		{
			key: "actions",
			name: "",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
		},
	];

	// Add custom rendering for specific columns
	const _renderItemColumn = (user: IRoleEnrichedOneUser, _index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = user[column.fieldName as keyof IRoleEnrichedOneUser] as string;

		switch (column.key) {
			case "actions":
				return (
					<div className="column-field">
						<TooltipHost
							content={
								user.hasCurrentRole
									? intl.formatMessage({
											id: "roles.modal.add.tooltip.alreadyExists",
									  })
									: undefined
							}
						>
							<PrimaryButton
								text={intl.formatMessage({ id: "general.add" })}
								disabled={
									roleModal.role === null ||
									user.hasCurrentRole ||
									roleModal.addRoleToUserStatus === SlicePieceStatus.IsFetching
								}
								onClick={() => {
									if (roleModal.addRoleToUserStatus !== SlicePieceStatus.IsFetching) {
										dispatch(addRoleToUser(user, roleModal.role));
									}
								}}
							/>
						</TooltipHost>
					</div>
				);
			default:
				return (
					<div className="column-field" title={fieldContent}>
						<span>{fieldContent || "-"}</span>
					</div>
				);
		}
	};

	return (
		<div>
			<MessageBar messageBarType={MessageBarType.info}>
				<FormattedMessage id="users.modal.roles.add.info" />
			</MessageBar>

			<SearchBox
				styles={{
					root: {
						marginTop: "15px",
					},
				}}
				placeholder={intl.formatMessage({ id: "users.search.placeholder" })}
				onChange={(_e, query) => delay(() => dispatch(searchUsersToAddToRole(query)), query !== "" ? 500 : 0)}
				onSearch={(query) => delay(() => dispatch(searchUsersToAddToRole(query)), query !== "" ? 500 : 0)}
				onClear={() => dispatch({ type: RolesActionTypes.RESET_SEARCH_USERS_FOR_ROLE })}
			/>

			{roleModal.userSearchResult.fetchStatus !== SlicePieceStatus.None && (
				<>
					<ShimmeredDetailsList
						items={roleModal.userSearchResult.data}
						selectionMode={SelectionMode.none}
						columns={columns}
						onRenderItemColumn={_renderItemColumn}
						enableShimmer={roleModal.userSearchResult.fetchStatus === SlicePieceStatus.IsFetching}
					/>
					{roleModal.userSearchResult.data.length === 0 && roleModal.userSearchResult.fetchStatus === SlicePieceStatus.Success && (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<span>
								<FormattedMessage id="general.noResults" />
							</span>
						</div>
					)}
				</>
			)}
		</div>
	);
};
