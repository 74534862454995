import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Icon, Panel, PanelType } from "@fluentui/react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import DefaultCommandBar from "./commandbar/Commandbar";
import StickyNavbar from "./stickyNavbar/StickyNavbar";
import anonymousUserImage from "../../../assets/images/anonymous-user-gravatar.png";
import OneLogo from "../../../assets/images/One.png";
import { AccountPanelComponent } from "./accountPanelComponent/AccountPanelComponent";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchUserPhotoUrl } from "../../../common/httpFunctions/Users";
import { hasAccess, PermissionCodes } from "@one/core";
import NavWaffle from "./navWaffle/NavWaffle";
import NavTitle from "./navTitle/NavTitle";
import NavButton from "./navButton/NavButton";
import SectionWrapper from "./sectionWrapper/SectionWrapper";
import HamburgerUi from "./hamburgerUi/HamburgerUi";
import HamburgerUiListItem from "./hamburgerUi/hamburgerUiListITem/HamburgerUiListItem";
import NavbarBase from "./navbarBase/NavbarBase";

const NavBarComponent = (props: WrappedComponentProps) => {
	const { intl } = props;
	const currentUser = useSelector((state: IApplicationState) => state.currentUserSlice.user);

	const [hamburgerMenuIsOpen, hamburgerMenuToggle] = useState(false);
	const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
	const [accountIsOpen, setAccountIsOpen] = useState(false);
	const [panelIsOpen, setPanelIsOpen] = useState(false);
	const [userImage, setUserImage] = useState(anonymousUserImage);

	useEffect(() => {
		const fetchUserImage = async () => {
			try {
				const userImageUrl = await fetchUserPhotoUrl(currentUser.objectId);
				setUserImage(userImageUrl);
			} catch (e) {
				setUserImage(anonymousUserImage);
			}
		};

		if (currentUser.objectId !== undefined) {
			fetchUserImage();
		}
	}, [currentUser.objectId]);

	let hamburgerMenuUi: JSX.Element | null = null;
	if (hamburgerMenuIsOpen) {
		hamburgerMenuUi = (
			<HamburgerUi>
				<NavLink to="/home">
					<HamburgerUiListItem
						title={intl.formatMessage({
							id: "home.navigation.item",
							defaultMessage: "Start",
						})}
						icon="Home"
						hamburgerMenuToggle={hamburgerMenuToggle}
					/>
				</NavLink>

				{hasAccess(currentUser as any, [PermissionCodes.ManageLookupList]) && (
					<NavLink to="/lookUpLists">
						<HamburgerUiListItem
							title={intl.formatMessage({
								id: "lookup.navigation.item",
								defaultMessage: "Opzoeklijsten",
							})}
							icon="BulletedList"
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}
				{hasAccess(currentUser as any, [PermissionCodes.ManageUsers]) && (
					<NavLink to="/users">
						<HamburgerUiListItem
							title={intl.formatMessage({
								id: "users.navigation.item",
								defaultMessage: "Gebruikers",
							})}
							icon="Group"
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}
				{hasAccess(currentUser as any, [PermissionCodes.ManageUsers]) && (
					<NavLink to="/roles">
						<HamburgerUiListItem
							title={intl.formatMessage({
								id: "roles.navigation.item",
								defaultMessage: "Rollen",
							})}
							icon="Permissions"
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}
				{hasAccess(currentUser as any, [PermissionCodes.ManageAudiences]) && (
					<NavLink to="/audiences">
						<HamburgerUiListItem
							title={intl.formatMessage({
								id: "audiences.navigation.item",
								defaultMessage: "Doelgroepen",
							})}
							icon="People"
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}

				{hasAccess(currentUser as any, [PermissionCodes.ManageTaxonomy]) && (
					<NavLink to="/taxonomy">
						<HamburgerUiListItem
							title={intl.formatMessage({
								id: "taxonomy.navigation.item",
								defaultMessage: "Taxonomy",
							})}
							icon="BulletedTreeList"
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}
				{hasAccess(currentUser as any, [
					PermissionCodes.ManageOneForYouSettings,
					PermissionCodes.ManageEventsSettings,
					PermissionCodes.ManageTeams,
					PermissionCodes.ManageTeamTypes,
					PermissionCodes.ManageApplicationSettings,
				]) && (
					<NavLink to="/settings">
						<HamburgerUiListItem
							title={intl.formatMessage({
								id: "settings.general",
								defaultMessage: "Instellingen",
							})}
							icon="Settings"
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}

				{hasAccess(currentUser as any, [PermissionCodes.ViewApplicationReleases]) && (
					<NavLink to="/releases">
						<HamburgerUiListItem
							icon="ProductRelease"
							title={intl.formatMessage({
								id: "releases.navigationItem.title",
								defaultMessage: "Release informatie",
							})}
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}
				{hasAccess(currentUser as any, [PermissionCodes.ManageJobs]) && (
					<NavLink to="/jobs" activeClassName="navigation-item__active">
						<HamburgerUiListItem
							icon="ScheduleEventAction"
							title={intl.formatMessage({
								id: "jobs.navigationItem.title",
								defaultMessage: "Jobs",
							})}
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}

				{hasAccess(currentUser as any, [PermissionCodes.ViewRolePermissions]) && (
					<NavLink to="/rolePermissions" activeClassName="navigation-item__active">
						<HamburgerUiListItem
							icon="ScheduleEventAction"
							title={intl.formatMessage({
								id: "rolePermissions.navigationItem.title",
							})}
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)}
				{/* {(hasPermission(currentUser, [
					PermissionCodes.ManageFeedback_OneForMedia,
					PermissionCodes.ManageFeedback_OneQualityManagement,
				]) ||
					userIsAdmin) && (
					<NavLink to="/feedback" activeClassName="navigation-item__active">
						<HamburgerListItem
							icon="Feedback"
							title={intl.formatMessage({
								id: "feedback.navigationItem.title",
								defaultMessage: "Feedback",
							})}
							hamburgerMenuToggle={hamburgerMenuToggle}
						/>
					</NavLink>
				)} */}
			</HamburgerUi>
		);
	}

	const commandBarList = [
		// {
		// 	key: "openPanel",
		// 	name: "Open panel",
		// 	cacheKey: "myCacheKey", // changing this key will invalidate this items cache
		// 	iconProps: {
		// 		iconName: "SidePanelMirrored",
		// 	},
		// 	ariaLabel: "Open panel",
		// 	onClick: () => setPanelIsOpen(true),
		// },
	];
	return (
		<React.Fragment>
			<StickyNavbar>
				<NavbarBase>
					{/* Renders the left portion of the navbar */}
					<SectionWrapper>
						<NavWaffle hamburgerMenuIsOpen={hamburgerMenuIsOpen} hamburgerMenuToggle={hamburgerMenuToggle} />
						<NavTitle title="One for Admins" />
					</SectionWrapper>

					<SectionWrapper>
						<div className="company-logo">
							<img src={OneLogo} alt="company logo" />
						</div>
					</SectionWrapper>

					{/* Renders the right section of the navbar */}
					<SectionWrapper>
						{/* <NavButton
							faIcon={faBell}
							setNotificationsIsOpenHandler={setNotificationsIsOpen}
							notificationsIsOpen={notificationsIsOpen}
							type="notifications"
						/> */}
						{/* <NavButton
							type="settings"
							link={
								<NavLink to="/settings" activeClassName="active-link ">
									<div className="navbar__navbutton">
										<Icon iconName="settings" />
									</div>
								</NavLink>
							}
						/> */}

						<NavButton photoUrl={userImage} type="account" setAccountIsOpenHandler={setAccountIsOpen} iconName={null} faIcon={null} setNotificationsIsOpenHandler={null} link={null} />
					</SectionWrapper>
				</NavbarBase>
				{hamburgerMenuUi}

				<DefaultCommandBar items={commandBarList} />
			</StickyNavbar>

			<Panel
				className="one-panel"
				isOpen={panelIsOpen}
				type={PanelType.smallFixedFar}
				isLightDismiss={true}
				onDismiss={() => setPanelIsOpen(false)}
				onLightDismissClick={() => setPanelIsOpen(false)}
				closeButtonAriaLabel="Close"
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>Panel</h1>

						<div className="one-panel__panel-close" onClick={() => setPanelIsOpen(false)}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<section className="one-panel__panel-content">Panel</section>
			</Panel>

			<Panel
				className="one-panel"
				isOpen={notificationsIsOpen}
				type={PanelType.smallFixedFar}
				onDismiss={() => setNotificationsIsOpen(false)}
				isLightDismiss
				onLightDismissClick={() => setNotificationsIsOpen(false)}
				closeButtonAriaLabel="Close"
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>Notifications Panel</h1>

						<div className="one-panel__panel-close" onClick={() => setNotificationsIsOpen(false)}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<section className="one-panel__panel-content">Notifications Panel</section>
			</Panel>

			<AccountPanelComponent isOpen={accountIsOpen} onDismiss={() => setAccountIsOpen(false)} userImage={userImage} />
		</React.Fragment>
	);
};

export const NavBar = injectIntl(NavBarComponent);
