export enum ConnectionType {
	TopDesk = 1,
	Ubeeo = 2,
	Recruitee = 3,
	AzureOpenAIService = 4,
	AzureAISearch = 5,
	AzureFaceAPI = 6,
	Copilot = 7,
	Teamtailor = 8
}
