import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import { PrimaryButton, Dropdown, IDropdownOption } from "@fluentui/react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import OneTooltip from "./CustomTooltip";
import { calculateYAxisScale } from "../../../../../common/helperFunctions/GeneralHelpers";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";

export interface Props {
	aggregatedData: any;
	avgVisitorsADay: number;
	changeViewUniqueVisitorsAmountDays: (n: number) => void;
	numberOfDays: number;
	errorMessage: string;
	activeAudienceFilters: IDropdownOption[];
	setActiveAudienceFilters: (newOptions: IDropdownOption[]) => void;
}
const UniqueVisitorIntranetChart = (props: WrappedComponentProps & Props) => {
	const { intl } = props;
	const checkActiveState = (days: number) => {
		if (props.numberOfDays === days) {
			return "active-item";
		}
		return "";
	};
	const audiencesSlice = useSelector((state: IApplicationState) => state.audiencesSlice);
	const analyticsSlice = useSelector((state: IApplicationState) => state.analyticsSlice);

	const sevenDaysClasses = checkActiveState(7);
	const thirtyDaysClasses = checkActiveState(30);
	const ninetyDaysClasses = checkActiveState(90);
	const yAxisScale = calculateYAxisScale(props.aggregatedData, "Users");

	const onDropdownChange = (changedOption: IDropdownOption | undefined) => {
		if (changedOption === undefined) {
			return;
		}

		let newOptions = [...props.activeAudienceFilters];
		if (changedOption.selected) {
			newOptions.push(changedOption);
		} else {
			newOptions = newOptions.filter((option) => option.key !== changedOption.key);
		}

		props.setActiveAudienceFilters(newOptions);
	};

	const determineDropDownOptions = (): IDropdownOption[] => {
		const options = audiencesSlice.audiences.map((audience) => {
			return {
				key: audience.id,
				text: audience.name !== null ? audience.name : audience.id.toString(),
			};
		});

		return options;
	};

	const renderChartUi = () => {
		return (
			<ResponsiveContainer width="100%" aspect={4.0 / 0.8}>
				<BarChart
					data={props.aggregatedData}
					margin={{ top: 30, right: 0, bottom: 20, left: 20 }}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="timestamp"
						label={{
							value: intl.formatMessage({
								id: "usage.uniqueVisitorsChart.xLabel",
								defaultMessage: "Datum",
							}),
							position: "bottom",
						}}
					/>
					<YAxis
						label={{
							value: intl.formatMessage({
								id: "usage.uniqueVisitorsChart.yLabel",
								defaultMessage: "Gebruikers",
							}),
							angle: -90,
							position: "insideLeft",
						}}
						domain={[0, yAxisScale]}
					/>
					{props.aggregatedData.length > 0 && <Tooltip content={<OneTooltip />} />}
					<Bar barSize={40} dataKey="Users" fill="#0078d4" />
				</BarChart>
			</ResponsiveContainer>
		);
	};

	let content: JSX.Element;
	if (props.errorMessage !== "") {
		content = (
			<p className="error-message">
				<FormattedMessage
					id="general.something-went-wrong"
					defaultMessage="Er is iets fout gegaan."
				/>
			</p>
		);
	} else if (analyticsSlice.pageViewsDataIsLoading) {
		content = (
			<div className="loading-wrapper">
				<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
			</div>
		);
	} else {
		content = (
			<section className="chart-unique-wrapper" style={{ width: "100%" }}>
				<div className="chart-wrapper">
					<div className="graphButtons-wrapper-small">
						<div style={{ marginLeft: "-9px" }}>
							<strong>
								<FormattedMessage
									id="usage.uniqueVisitorsChart.averageUniqueVisitors"
									defaultMessage="Gemiddelde unieke gebruikers per dag"
								/>
							</strong>
							: {props.avgVisitorsADay}
						</div>
						<div className="chart-filter-options">
							<div className="chart-audience-filter">
								<Dropdown
									placeholder={intl.formatMessage({
										id: "usage.uniqueVisitorsChart.audienceFiltersPlaceholder",
										defaultMessage: "Filter op doelgroepen",
									})}
									options={determineDropDownOptions()}
									multiSelect
									onChange={(e, option) => onDropdownChange(option)}
									onRenderTitle={() => (
										<div>
											<FormattedMessage
												id="usage.uniqueVisitorsChart.audienceFiltersText"
												defaultMessage="Doelgroepen"
											/>{" "}
											({props.activeAudienceFilters.length})
										</div>
									)}
								/>
							</div>
							<PrimaryButton
								text={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.pastSevenDaysButtonText",
									defaultMessage: "Afgelopen 7 dagen",
								})}
								className={sevenDaysClasses}
								onClick={() => props.changeViewUniqueVisitorsAmountDays(7)}
							/>

							<PrimaryButton
								text={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.pastThirtyDaysButtonText",
									defaultMessage: "Afgelopen 30 dagen",
								})}
								className={thirtyDaysClasses}
								onClick={() => props.changeViewUniqueVisitorsAmountDays(30)}
							/>

							<PrimaryButton
								text={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.pastNinetyDaysButtonText",
									defaultMessage: "Afgelopen 90 dagen",
								})}
								className={ninetyDaysClasses}
								onClick={() => props.changeViewUniqueVisitorsAmountDays(90)}
							/>
						</div>
					</div>

					{renderChartUi()}
				</div>
			</section>
		);
	}
	return (
		<section>
			<h1 style={{ marginLeft: "11px" }}>
				<FormattedMessage
					id="usage.uniqueVisitorsChart.visitorsPerDay"
					defaultMessage="Unieke gebruikers per dag"
				/>
			</h1>
			{content}
		</section>
	);
};

export const UniqueVisitorsChartComponent = injectIntl(UniqueVisitorIntranetChart);
