import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelType, Icon, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import { IApplicationState } from "../../../../../../models/interfaces/IApplicationState";
import { ErrorTypes } from "../../../../../../models/enums";
import { updateTeamType } from "../../../../../../store/teamTypes/actions";
import { BasicInformation } from "./parts/BasicInformation";
import { fetchGroupSettingsGraph } from "../../../../../../store/settings/actions";
import { ITeamType, TeamTypeSettingsGuestAccess, TeamTypeSettingsPrivacyLevel } from "@one/core";
import { ITeamTypeFormValues } from "../../../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";
import { Settings } from "../../../../../reusable/teamTypes/settings/Settings";

export interface EditTeamTypePanelProps {
	teamType: ITeamType | null;
	isOpen: boolean;
	allowToAddUser: boolean;
	onClose: () => void;
}

const EditTeamTypePanel = (props: WrappedComponentProps & EditTeamTypePanelProps) => {
	const { teamType, isOpen, onClose, intl } = props;
	const dispatch = useDispatch();
	const teamTypesSlice = useSelector((state: IApplicationState) => state.teamTypesSlice);
	const [hasSubmitted, setHasSubmitted] = useState(false);

	useEffect(() => {
		if (!isOpen) {
			setHasSubmitted(false);
		}

		if (isOpen) {
			dispatch(fetchGroupSettingsGraph());
		}
	}, [ isOpen ]);

	const createValidationSchema = () => {
		return Yup.object().shape({
			name: Yup.string()
				.min(
					2,
					intl.formatMessage({
						id: "lookup.panel.create.create.validation.name.minTwoChars",
						defaultMessage: "De waarde moet ten minste twee tekens lang zijn.",
					})
				)
				.required(
					intl.formatMessage({
						id: "general.validation.required",
						defaultMessage: "Dit veld is verplicht.",
					})
				),
			defaultOwners: Yup.array().when("settings", {
				is: (value) => value.newTeam.makeContactPersonOwner === 0,
				then: Yup.array().min(1, "Dit veld is verplicht.")
			})
		});
	};

	if (teamType === null) {
		return null;
	}

	const initialValues = (): ITeamTypeFormValues => {
		return {
			name: teamType.name || "",
			domain: teamType.domain || "",
			prefix: teamType.prefix || "",
			templateId: teamType.templateId || "",
			isActive: teamType.isActive,
			approvalRequired: false,
			defaultOwners: teamType.defaultOwners,
			settings: {
				newTeam: teamType.settings.newTeam
					? {
						...teamType.settings.newTeam,
						makeContactPersonOwner: teamType.settings.newTeam.makeContactPersonOwner,
						secondOwnerRequired: teamType.settings.newTeam.secondOwnerRequired
					}
					: {
						approvalBy: [],
						requestBy: [],
						makeContactPersonOwner: true,
						secondOwnerRequired: false,
					},
				addMembers: teamType.settings.addMembers
					? { ...teamType.settings.addMembers }
					: {
						approvalBy: [],
						requestBy: [],
					},
				archiveTeam: teamType.settings.archiveTeam
					? { ...teamType.settings.archiveTeam }
					: {
						approvalBy: [],
						requestBy: [],
					},
				deleteTeam: teamType.settings.deleteTeam
					? { ...teamType.settings.deleteTeam }
					: {
						approvalBy: [],
						requestBy: [],
					},
				upgradeGroup: teamType.settings.upgradeGroup
					? { ...teamType.settings.upgradeGroup }
					: {
						approvalBy: [],
						requestBy: [],
					},
				guestAccess: teamType.settings.guestAccess ?? TeamTypeSettingsGuestAccess.DeterminedByApplicant,
				privacyLevel: teamType.settings.privacyLevel ?? TeamTypeSettingsPrivacyLevel.Private,
			}
		};
	};

	return (
		<Panel
			className="one-panel"
			isOpen={isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={onClose}
			isLightDismiss
			onLightDismissClick={onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage id="settings.teams.types.update" defaultMessage="Teamtype bewerken" />
					</h1>

					<div className="one-panel__panel-close" onClick={onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			<section className="one-panel__panel-content">
				{teamTypesSlice.error.type === ErrorTypes.OnUpdate && (
					<MessageBar messageBarType={MessageBarType.error}>
						<FormattedMessage
							id="settings.teams.types.error.onUpdate"
							defaultMessage="Er ging iets mis met het bijwerken van het teamtype."
						/>
					</MessageBar>
				)}

				<div className="ilx-form-wrapper">
					<Formik
						// enableReinitialize
						initialValues={initialValues()}
						onSubmit={(values) => {
							dispatch(
								updateTeamType({
									id: teamType.id,
									name: values.name,
									domain: values.domain ?? "",
									prefix: values.prefix ?? "",
									templateId: values.templateId ?? "",
									approvalRequired: values.approvalRequired,
									isActive: values.isActive,
									defaultOwners: values.defaultOwners,
									settings: {
										...values.settings,
										newTeam: {
											...values.settings.newTeam,
											makeContactPersonOwner: !!values.settings.newTeam.makeContactPersonOwner,
										},
									}
								}),
							);
						}}
						validationSchema={createValidationSchema()}
					>
						{(formikProps) => {
							const { errors, handleSubmit, values } = formikProps;

							return (
								<form onSubmit={handleSubmit}>
									<BasicInformation formikProps={formikProps} hasSubmitted={hasSubmitted} />

									<Settings
										formikProps={formikProps}
										hasSubmitted={hasSubmitted}
										allowToAddUser={props.allowToAddUser}
										defaultOwnersRequired={!values.settings.newTeam.makeContactPersonOwner}
										mode="edit"
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.edit",
											defaultMessage: "",
										})}
										onClick={() => setHasSubmitted(true)}
										type="submit"
										disabled={
											teamTypesSlice.loaders.isUpdating
											|| (Object.keys(errors).length !== 0 && errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</section>
		</Panel>
	);
};

export const EditTeamTypePanelComponent = injectIntl(EditTeamTypePanel);
