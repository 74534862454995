import { adalGraphFetch } from "./../../adalConfig";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { AnalyticsActionTypes, AnalyticsState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";

// Get all unique users for the tenant
export const fetchTenantWideUniqueVisitors = (daysToFetch: number, audienceIdsCSV: string = "") => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchTenantWideUniqueVisitorsRequest(daysToFetch, audienceIdsCSV));
	};
};

const fetchTenantWideUniqueVisitorsRequest: ActionCreator<ThunkAction<Promise<any>, AnalyticsState, null, any>> = (
	daysToFetch: number,
	audienceIdsCSV: string
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchTenantWideUniqueVisitorsStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/analytics/applications/OneForYou/uniqueVisitors?numOfDays=${daysToFetch}&audienceIDs=${audienceIdsCSV}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchTenantWideUniqueVisitorsFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchTenantWideUniqueVisitorsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchTenantWideUniqueVisitorsFailure("error"));
		}
	};
};

const fetchTenantWideUniqueVisitorsStarted = () => ({
	type: AnalyticsActionTypes.FETCH_INTRANET_WIDE_UNIQUE_VISITORS_STARTED,
});

const fetchTenantWideUniqueVisitorsSuccess = (visitors: any) => ({
	type: AnalyticsActionTypes.FETCH_INTRANET_WIDE_UNIQUE_VISITORS_SUCCESS,
	payload: {
		visitors,
	},
});

const fetchTenantWideUniqueVisitorsFailure = (errorMessage: string | object) => ({
	type: AnalyticsActionTypes.FETCH_INTRANET_WIDE_UNIQUE_VISITORS_FAILURE,
	payload: {
		errorMessage,
	},
});

// Select a site via Ms graph
export const fetchSearchedSitesCollection = (searchValue) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchSearchedSitesCollectionRequest(searchValue));
	};
};

const fetchSearchedSitesCollectionRequest: ActionCreator<ThunkAction<Promise<any>, AnalyticsState, null, any>> = (
	searchValue: string
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchSearchedSitesCollectionStarted(searchValue));

		// we use the wildcard to create a contains functionality in the microsoft graph
		try {
			const response: Response = await adalGraphFetch(
				fetch,
				`https://graph.microsoft.com/v1.0/sites?search=${searchValue}*`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchSearchedSitesCollectionFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchSearchedSitesCollectionSuccess(result));
			}
		} catch (e) {
			dispatch(fetchSearchedSitesCollectionFailure("error"));
		}
	};
};

const fetchSearchedSitesCollectionStarted = (searchValue) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_STARTED,
	payload: {
		searchValue,
	},
});

const fetchSearchedSitesCollectionSuccess = (sites: any) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_SUCCESS,
	payload: {
		sites,
	},
});

const fetchSearchedSitesCollectionFailure = (errorMessage: string | object) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_FAILURE,
	payload: {
		errorMessage,
	},
});

export const emptySearchedSitesCollection = () => ({
	type: AnalyticsActionTypes.EMPTY_SEARCHED_SITE_COLLECTION,
});

// Get the analytics data per site
export const fetchSpSiteAnalyticData = (siteId, daysTimespan, audienceIdsCSV: string = "") => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchSpSiteAnalyticDataRequest(siteId, daysTimespan, audienceIdsCSV));
	};
};

const fetchSpSiteAnalyticDataRequest: ActionCreator<ThunkAction<Promise<any>, AnalyticsState, null, any>> = (
	siteId: string,
	daysTimespan: number,
	audienceIdsCSV: string
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchSpSiteAnalyticDataStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/analytics/applications/OneForYou/sites/${
					siteId.split(".com,")[1].split(",")[0]
				}/uniqueVisitors?numOfDays=${daysTimespan}&audienceIDs=${audienceIdsCSV}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchSpSiteAnalyticDataFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchSpSiteAnalyticDataSuccess(result));
			}
		} catch (e) {
			dispatch(fetchSpSiteAnalyticDataFailure("error"));
		}
	};
};

const fetchSpSiteAnalyticDataStarted = () => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_ANALYTIC_DATA_STARTED,
});

const fetchSpSiteAnalyticDataSuccess = (analytics: any) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_ANALYTIC_DATA_SUCCESS,
	payload: {
		analytics,
	},
});

const fetchSpSiteAnalyticDataFailure = (errorMessage: string | object) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_ANALYTIC_DATA_FAILURE,
	payload: {
		errorMessage,
	},
});

// check the 90 days data to decide whether analtytics is enabled

// Get the analytics data per site
export const fetchSpSiteAnalyticData90Days = (siteId, daysTimespan) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchSpSiteAnalyticData90DaysRequest(siteId, 90));
	};
};

const fetchSpSiteAnalyticData90DaysRequest: ActionCreator<ThunkAction<Promise<any>, AnalyticsState, null, any>> = (
	siteId: string,
	daysTimespan: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchSpSiteAnalyticData90DaysStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/analytics/applications/OneForYou/sites/${
					siteId.split(".com,")[1].split(",")[0]
				}/uniqueVisitors?numOfDays=${daysTimespan}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchSpSiteAnalyticData90DaysFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchSpSiteAnalyticData90DaysSuccess(result));
			}
		} catch (e) {
			dispatch(fetchSpSiteAnalyticData90DaysFailure("error"));
		}
	};
};

const fetchSpSiteAnalyticData90DaysStarted = () => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_STARTED,
});

const fetchSpSiteAnalyticData90DaysSuccess = (analytics: any) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_SUCCESS,
	payload: {
		analytics,
	},
});

const fetchSpSiteAnalyticData90DaysFailure = (errorMessage: string | object) => ({
	type: AnalyticsActionTypes.FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_FAILURE,
	payload: {
		errorMessage,
	},
});

// Get the pageviews devided per site.
export const fetchPageViewssPerSite = (daysTimespan, audienceIdsCSV: string = "") => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchUniqueVisitorsPerSiteRequest(daysTimespan, audienceIdsCSV));
	};
};

const fetchUniqueVisitorsPerSiteRequest: ActionCreator<ThunkAction<Promise<any>, AnalyticsState, null, any>> = (
	daysTimespan: string,
	audienceIdsCSV: string
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchUniqueVisitorsPerSiteStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/analytics/applications/OneForYou/pageViews?numOfDays=${daysTimespan}&audienceIDs=${audienceIdsCSV}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchUniqueVisitorsPerSiteFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchUniqueVisitorsPerSiteSuccess(result));
			}
		} catch (e) {
			dispatch(fetchUniqueVisitorsPerSiteFailure("error"));
		}
	};
};

const fetchUniqueVisitorsPerSiteStarted = () => ({
	type: AnalyticsActionTypes.FETCH_PAGE_VIEWS_STARTED,
});

const fetchUniqueVisitorsPerSiteSuccess = (analytics: any) => ({
	type: AnalyticsActionTypes.FETCH_PAGE_VIEWS_SUCCESS,
	payload: {
		analytics,
	},
});

const fetchUniqueVisitorsPerSiteFailure = (errorMessage: string | object) => ({
	type: AnalyticsActionTypes.FETCH_PAGE_VIEWS_FAILURE,
	payload: {
		errorMessage,
	},
});

// Get the pageviews devided per site.
export const fetchUsersPerSite = (pageUrl: string, daysTimespan: number) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchUsersPerSiteRequest(pageUrl, daysTimespan));
	};
};

const fetchUsersPerSiteRequest: ActionCreator<ThunkAction<Promise<any>, AnalyticsState, null, any>> = (
	pageUrl: string,
	daysTimespan: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchUsersPerSiteStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,

				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/analytics/applications/OneForYou/pages/uniqueVisitors?pageUrl=${pageUrl}&numOfDays=${daysTimespan}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchUsersPerSiteFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchUsersPerSiteSuccess(result, pageUrl));
			}
		} catch (e) {
			dispatch(fetchUsersPerSiteFailure("error"));
		}
	};
};

const fetchUsersPerSiteStarted = () => ({
	type: AnalyticsActionTypes.FETCH_USER_ANALYTICS_PER_SITE_STARTED,
});

const fetchUsersPerSiteSuccess = (analytics: any, pageUrl) => ({
	type: AnalyticsActionTypes.FETCH_USER_ANALYTICS_PER_SITE_SUCCESS,
	payload: {
		analytics,
		pageUrl,
	},
});

const fetchUsersPerSiteFailure = (errorMessage: string | object) => ({
	type: AnalyticsActionTypes.FETCH_USER_ANALYTICS_PER_SITE_FAILURE,
	payload: {
		errorMessage,
	},
});
