import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import Loader from "react-loader-spinner";
import * as Yup from "yup";
import { PrimaryButton, MessageBar, MessageBarType, Dropdown } from "@fluentui/react";

import { updateSettings, resetSuccess } from "../../../../store/settings/actions";
import { Settings } from "../../../../common/helperFunctions/SettingsHelpers";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ErrorTypes, SettingsTypes, SuccessTypes } from "../../../../models/enums";
import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";

const TenantSettings = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (settingsSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
		}
	}, [dispatch, firstRenderOccured, settingsSlice.success.type]);

	const pathToPage = [
		{
			text: intl.formatMessage({
				id: "settings.general",
				defaultMessage: "Instellingen",
			}),
			key: "settings",
		},
		{
			text: intl.formatMessage({
				id: "settings.card.tenant.title",
				defaultMessage: "Tenant-instellingen",
			}),
			key: "settings/tenantSettings",
		},
	];

	const renderLanguageSettingUi = () => {
		const settings = new Settings(settingsSlice);
		const languageSetting = settings.getSettingByKey("tenantSettings", "Language");

		const initialValues = {
			Language: languageSetting !== null ? languageSetting.value : "nl",
		};

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					dispatch(updateSettings(SettingsTypes.Tenant, settings.convertSettingsToApiSaveFormat(values)));
				}}
				validationSchema={Yup.object().shape({})}
			>
				{(props) => {
					const { values, touched, errors, isSubmitting, setSubmitting, handleSubmit, setFieldValue } = props;
					if (
						isSubmitting &&
						(settingsSlice.success.type !== SuccessTypes.None || settingsSlice.error.type !== ErrorTypes.None)
					) {
						setSubmitting(false);
					}
					return (
						<form onSubmit={handleSubmit}>
							<label>
								<span>
									<FormattedMessage id="settings.tenant.language.text" defaultMessage="De taal op tenant niveau" />
								</span>
							</label>
							{errors.Language && touched.Language && <div className="input-feedback">{errors.Language}</div>}

							<Dropdown
								selectedKey={values.Language.toLowerCase()}
								style={{
									margin: "1rem 0 3rem",
								}}
								onChange={(e, value) => (value ? setFieldValue("Language", value.key) : undefined)}
								options={[
									{
										key: "nl",
										text: "Nederlands",
									},
									{
										key: "en",
										text: "English",
									},
								]}
							/>

							<PrimaryButton type="submit" disabled={isSubmitting}>
								<FormattedMessage id="general.save" defaultMessage="Opslaan" />
							</PrimaryButton>
						</form>
					);
				}}
			</Formik>
		);
	};

	// Either render a loader, or render the desired settings.
	const determineContent = () => {
		if (settingsSlice.isLoading) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className="settings-setting-wrapper" test-name="settings-tile">
					<div className="settings-tile-body form-wrapper">{renderLanguageSettingUi()}</div>
				</div>
			</React.Fragment>
		);
	};

	return (
		<div>
			<BreadCrumbComponent path={pathToPage} />

			{settingsSlice.success.type !== SuccessTypes.None && (
				<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
					<FormattedMessage id="settings.save.success" defaultMessage="Instellingen zijn succesvol opgeslagen." />
				</MessageBar>
			)}

			<section className="setting-pages-wrapper">{determineContent()}</section>
		</div>
	);
};

export const TenantSettingsComponent = injectIntl(TenantSettings);
