import * as React from "react";

export interface ICardProps {
	/**
	 * Whether or not to animate the box-shadow on hover.
	 * @defaultvalue false
	 */
	enableHover?: boolean;

	/**
	 * Whether or not to show the border top.
	 * @defaultvalue false
	 */
	enableBorderTop?: boolean;

	/**
	 * The animation className from FluentUI.
	 * @examplevalue AnimationClassNames.scaleDownIn100
	 */
	animationClassName?: string;

	children?: React.ReactNode;

	/**
	 * Additional custom inline styles on the root
	 */
	style?: React.CSSProperties;
	/**
	 * Additional custom className on the root
	 */
	className?: string;

	/**
	 * Callback when the card is clicked.
	 */
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

/**
 * Renders a card.
 */
export const Card = (props: ICardProps) => {
	const { enableHover, enableBorderTop, animationClassName, style = {} } = props;

	let classNames = "one-card";
	if (enableHover) {
		classNames += " one-card-hover";
	}
	if (enableBorderTop) {
		classNames += " one-card-border-top";
	}
	if (animationClassName !== undefined) {
		classNames += " " + animationClassName;
	}
	if (props.className !== undefined) {
		classNames += " " + props.className;
	}

	return (
		<div className={classNames} style={{ ...style }} onClick={props.onClick}>
			{props.children}
		</div>
	);
};
