import * as React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import "./LoadingOverlay.scss";

export const LoadingOverlay = () => {
	return (
		<div className={"ilx-loading-overlay"}>
			<Spinner size={SpinnerSize.large} />
		</div>
	);
};
