import React from "react";

const HamburgerUi = props => {
  return (
    <div>
      <div className="hamburger-menu">
        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

export default HamburgerUi;
