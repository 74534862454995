import React from "react";

export interface TabDataObject {
	id: number;
	name: string;
	title: string;
	includeExclamationMark?: boolean;
}

export interface TabsProps {
	activeTabId: number;
	setActiveTab: (id: number) => void;
	tabData: TabDataObject[];
}

export const TabsComponent = (props: TabsProps) => {
	const tabs = props.tabData.map(tab => {
		let isActive = false;
		let exclamantionMarkUi: JSX.Element | null = null;

		if (props.activeTabId === tab.id) {
			isActive = true;
		}

		if (tab.includeExclamationMark) {
			exclamantionMarkUi = <span className="data-is-missing"> ! </span>;
		}

		return (
			<li
				key={tab.name}
				title={tab.title}
				onClick={() => props.setActiveTab(tab.id)}
				className={isActive ? "active" : ""}
			>
				{tab.name} {exclamantionMarkUi}
			</li>
		);
	});
	return (
		<section>
			<ul className="tabs-ul-wrapper">{tabs}</ul>
		</section>
	);
};
