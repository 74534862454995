import { ReleasesViewModel } from "../../models/responseModels/Releases/ReleasesViewModel";

export enum ReleasesActionTypes {
    FETCH_RELEASES_STARTED = "FETCH_RELEASES_STARTED",
    FETCH_RELEASES_SUCCESS = "FETCH_RELEASES_SUCCESS",
    FETCH_RELEASES_FAILURE = "FETCH_RELEASES_FAILURE",

    FETCH_ADDRELEASE_STARTED = "FETCH_ADDRELEASE_STARTED",
    FETCH_ADDRELEASE_SUCCESS = "FETCH_ADDRELEASE_SUCCESS",
    FETCH_ADDRELEASE_FAILURE = "FETCH_ADDRELEASE_FAILURE",
    RESET_ADDREQUESTNOTIFICATION = "RESET_ADDREQUESTNOTIFICATION",

    DELETE_RELEASE_STARTED = "DELETE_RELEASE_STARTED",
    DELETE_RELEASE_SUCCESS = "DELETE_RELEASE_SUCCESS",
    DELETE_RELEASE_FAILURE = "DELETE_RELEASE_FAILURE",

    UPDATE_RELEASE_STARTED = "UPDATE_RELEASE_STARTED",
    UPDATE_RELEASE_SUCCESS = "UPDATE_RELEASE_SUCCESS",
    UPDATE_RELEASE_FAILURE = "UPDATE_RELEASE_FAILURE"
}

export type ReleasesState = Readonly<{
    releases: ReleasesViewModel[];
    errorMessage: string;
    isLoading: boolean;
    deleteIsLoading: boolean;
    deleteErrorMessage: string;
    createErrorMessage: string;
    createRequestFired: boolean;
    updateRequestErrorMessage: string;
    updateRequestFired: boolean;
}>;