import { AudiencesActionTypes, AudiencesState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums/SuccessTypes";
import { sortArray } from "../../common/helperFunctions/ObjectsAndArrayHelpers";

const initialState: AudiencesState = {
	audiences: [],
	groups: [],

	isFetchingAudiences: false,
	isFetchingGroups: false,
	isCreating: false,

	shouldClosePanel: false,

	success: {
		type: SuccessTypes.None,
	},
	error: {
		type: ErrorTypes.None,
		message: "",
	},
};

export function audiencesReducer(
	state = initialState,
	action: { type: AudiencesActionTypes; payload: any }
): AudiencesState {
	switch (action.type) {
		// --== Fetch Groups ==--
		case AudiencesActionTypes.FETCH_GROUPS_STARTED:
			return {
				...state,
				isFetchingGroups: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case AudiencesActionTypes.FETCH_GROUPS_SUCCESS:
			return {
				...state,
				groups: action.payload.groups,
				isFetchingGroups: false,
			};
		case AudiencesActionTypes.FETCH_GROUPS_FAILURE:
			return {
				...state,
				isFetchingGroups: false,
				error: {
					type: ErrorTypes.OnMetaDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Fetch Audiences ==--
		case AudiencesActionTypes.FETCH_AUDIENCES_STARTED:
			return {
				...state,
				isFetchingAudiences: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case AudiencesActionTypes.FETCH_AUDIENCES_SUCCESS:
			const audiencesFetchedSorted = action.payload.audiences.sort(sortArray("name")).reverse();
			return {
				...state,
				audiences: audiencesFetchedSorted,
				isFetchingAudiences: false,
			};
		case AudiencesActionTypes.FETCH_AUDIENCES_FAILURE:
			return {
				...state,
				isFetchingAudiences: false,
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Add Audience ==--
		case AudiencesActionTypes.ADD_AUDIENCES_STARTED:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case AudiencesActionTypes.ADD_AUDIENCES_SUCCESS:
			let newAudiencesAfterAdd = [...state.audiences];
			newAudiencesAfterAdd.push(action.payload.addedAudience);
			newAudiencesAfterAdd = newAudiencesAfterAdd.sort(sortArray("name")).reverse();

			return {
				...state,
				audiences: newAudiencesAfterAdd,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnCreate,
				},
			};
		case AudiencesActionTypes.ADD_AUDIENCES_FAILURE:
			return {
				...state,
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --== Update Audience ==--
		case AudiencesActionTypes.UPDATE_AUDIENCES_STARTED:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case AudiencesActionTypes.UPDATE_AUDIENCES_SUCCESS:
			const newAudiencesAfterUpdate = [...state.audiences].map((a) => {
				if (a.id === action.payload.updatedAudience.id) {
					return action.payload.updatedAudience;
				}
				return a;
			});

			return {
				...state,
				audiences: newAudiencesAfterUpdate,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};
		case AudiencesActionTypes.UPDATE_AUDIENCES_FAILURE:
			return {
				...state,
				error: {
					type: ErrorTypes.OnUpdate,
					message: action.payload.errorMessage,
				},
			};

		// --== Delete Audience ==--
		case AudiencesActionTypes.DELETE_AUDIENCES_STARTED:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
				success: {
					type: SuccessTypes.None,
				},
			};
		case AudiencesActionTypes.DELETE_AUDIENCES_SUCCESS:
			let newAudiences = [...state.audiences].filter((a) => a.id !== action.payload.deletedAudienceId);

			return {
				...state,
				audiences: newAudiences,
				shouldClosePanel: true,
				success: {
					type: SuccessTypes.OnDelete,
				},
			};
		case AudiencesActionTypes.DELETE_AUDIENCES_FAILURE:
			return {
				...state,
				error: {
					type: ErrorTypes.OnDelete,
					message: action.payload.errorMessage,
				},
			};

		// Other
		case AudiencesActionTypes.RESET_SHOULD_CLOSE_PANEL:
			return {
				...state,
				shouldClosePanel: false,
			};
		case AudiencesActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case AudiencesActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};

		default:
			return state;
	}
}
