import { SlicePieceStatus, IPermission, sortArray } from "@one/core";
import { IColumn, ShimmeredDetailsList, SelectionMode, IGroup } from "@fluentui/react";
import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";

export const PermissionsTab = () => {
	const intl = useIntl();
	const rolesSlice = useSelector((state: IApplicationState) => state.rolesSlice);
	const { roleModal } = rolesSlice;
	const [groupedPermissions, setGroupedPermissions] = useState<IPermission[]>([]);
	const [groups, setGroups] = useState<IGroup[]>([]);

	const columns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({
				id: "roles.modal.permissions.list.name",
				defaultMessage: "Naam",
			}),
			fieldName: "name",
			minWidth: 125,
			maxWidth: 200,
			isResizable: true,
		},
	];

	// Add custom rendering for specific columns
	const _renderItemColumn = (permission: IPermission, _index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = permission[column.fieldName as keyof IPermission] as string;

		switch (column.key) {
			default:
				return (
					<div className="column-field" title={fieldContent}>
						<span>{fieldContent || "-"}</span>
					</div>
				);
		}
	};

	React.useEffect(() => {
		const mappedObject = {};
		let permissionResult: IPermission[] = [];
		let groupResult: IGroup[] = [];

		roleModal.permissions.data.forEach((permission) => {
			if (permission.application !== undefined) {
				// If the key does not exist yet, add it.
				if (mappedObject[permission.application.name] === undefined) {
					mappedObject[permission.application.name] = [];
				}

				mappedObject[permission.application.name].push(permission);
			}
		});

		const sortedKeys = Object.keys(mappedObject).sort();
		let startIndex = 0;
		sortedKeys.forEach((key) => {
			const sortedPermissionsForApplication = mappedObject[key].sort(sortArray("name")).reverse();

			permissionResult = [...permissionResult, ...sortedPermissionsForApplication];
			groupResult = [
				...groupResult,
				{
					key,
					name: key,
					count: sortedPermissionsForApplication.length,
					startIndex,
				},
			];

			startIndex = startIndex + sortedPermissionsForApplication.length;
		});

		setGroupedPermissions(permissionResult);
		setGroups(groupResult);
	}, [roleModal.permissions.data]);

	return (
		<div>
			<ShimmeredDetailsList
				items={groupedPermissions}
				selectionMode={SelectionMode.none}
				groups={roleModal.permissions.fetchStatus === SlicePieceStatus.IsFetching ? undefined : groups}
				columns={columns}
				onRenderItemColumn={_renderItemColumn}
				enableShimmer={roleModal.permissions.fetchStatus === SlicePieceStatus.IsFetching}
			/>
			{roleModal.permissions.data.length === 0 && roleModal.permissions.fetchStatus === SlicePieceStatus.Success && (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<span>
						<FormattedMessage id="general.noResults" />
					</span>
				</div>
			)}
		</div>
	);
};
