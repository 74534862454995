import React from "react";
import { Panel, PanelType, Icon } from "@fluentui/react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { FormattedMessage } from "react-intl";
import { authContext } from "../../../../adalConfig";

export interface AccountPanelProps {
	isOpen: boolean;
	onDismiss: () => void;
	userImage: string;
}

export const AccountPanelComponent = (props: AccountPanelProps) => {
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	const myProfileUrl =
		currentUserSlice.user.tenant !== undefined
			? `https://${currentUserSlice.user.tenant.name}-my.sharepoint.com/person.aspx`
			: "";

	return (
		<Panel
			className="one-panel"
			isOpen={props.isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={props.onDismiss}
			isLightDismiss
			onLightDismissClick={props.onDismiss}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage
							id="account.panel.headertext"
							defaultMessage="Mijn account"
						/>
					</h1>

					<div className="one-panel__panel-close" onClick={props.onDismiss}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			<section className="one-panel__panel-content">
				<div className="personal-info-wrapper">
					<div className="personal-info-wrapper__image-wrapper">
						<img className="account-panel-user-image" src={props.userImage} alt="" />
					</div>

					<div className="personal-info-wrapper__details">
						<div className="details__wrapper">
							<p
								className="details__wrapper--name"
								title={currentUserSlice.user.name}
							>
								{currentUserSlice.user.name}
							</p>
							<p
								className="details__wrapper--email"
								title={currentUserSlice.user.emailAddress}
							>
								{currentUserSlice.user.emailAddress}
							</p>
						</div>
					</div>
				</div>

				<div className="account-actions-wrapper">
					<ul>
						<li>
							<a href={myProfileUrl} target="_blank" rel="noopener noreferrer">
								<FormattedMessage
									id="account.panel.myProfile"
									defaultMessage="Mijn profiel"
								/>
							</a>
						</li>
						<li>
							<span
								onClick={() => authContext.logOut()}
								className="needs-underline-on-hover"
							>
								<FormattedMessage
									id="account.panel.logout"
									defaultMessage="Uitloggen"
								/>
							</span>
						</li>
					</ul>
				</div>
			</section>
		</Panel>
	);
};
