import { DatePicker, IDatePickerStrings, PrimaryButton, Spinner } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import moment from "moment";

import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";
import { adalOneApiFetch } from "../../../../adalConfig";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { useSelector } from "react-redux";

const UsageDownload = (props: WrappedComponentProps) => {
	const { intl } = props;
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
	const [isDownloading, setIsDownloading] = useState(false);
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	const DatePickerStrings: IDatePickerStrings = {
		months: [
			intl.formatMessage({ id: "dateTimePicker.Months.January", defaultMessage: "januari" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.February",
				defaultMessage: "februari",
			}),
			intl.formatMessage({ id: "dateTimePicker.Months.March", defaultMessage: "maart" }),
			intl.formatMessage({ id: "dateTimePicker.Months.April", defaultMessage: "april" }),
			intl.formatMessage({ id: "dateTimePicker.Months.May", defaultMessage: "mei" }),
			intl.formatMessage({ id: "dateTimePicker.Months.June", defaultMessage: "juni" }),
			intl.formatMessage({ id: "dateTimePicker.Months.July", defaultMessage: "juli" }),
			intl.formatMessage({ id: "dateTimePicker.Months.August", defaultMessage: "augustus" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.September",
				defaultMessage: "september",
			}),
			intl.formatMessage({ id: "dateTimePicker.Months.October", defaultMessage: "oktober" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.November",
				defaultMessage: "november",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.December",
				defaultMessage: "december",
			}),
		],

		shortMonths: [
			intl.formatMessage({
				id: "dateTimePicker.Months.short.January",
				defaultMessage: "jan",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.February",
				defaultMessage: "feb",
			}),
			intl.formatMessage({ id: "dateTimePicker.Months.short.March", defaultMessage: "mar" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.April", defaultMessage: "apr" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.May", defaultMessage: "mei" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.June", defaultMessage: "jun" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.July", defaultMessage: "jul" }),
			intl.formatMessage({ id: "dateTimePicker.Months.short.August", defaultMessage: "aug" }),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.September",
				defaultMessage: "sep",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.October",
				defaultMessage: "okt",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.November",
				defaultMessage: "nov",
			}),
			intl.formatMessage({
				id: "dateTimePicker.Months.short.December",
				defaultMessage: "dec",
			}),
		],

		days: [
			intl.formatMessage({ id: "dateTimePicker.days.sunday", defaultMessage: "zondag" }),
			intl.formatMessage({ id: "dateTimePicker.days.monday", defaultMessage: "maandag" }),
			intl.formatMessage({ id: "dateTimePicker.days.tuesday", defaultMessage: "dinsdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.wednesday", defaultMessage: "woensdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.thrusday", defaultMessage: "donderdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.friday", defaultMessage: "vrijdag" }),
			intl.formatMessage({ id: "dateTimePicker.days.saturday", defaultMessage: "zaterdag" }),
		],

		shortDays: [
			intl.formatMessage({ id: "dateTimePicker.days.short.sunday", defaultMessage: "Z" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.monday", defaultMessage: "M" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.tuesday", defaultMessage: "D" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.wednesday", defaultMessage: "W" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.thrusday", defaultMessage: "D" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.friday", defaultMessage: "V" }),
			intl.formatMessage({ id: "dateTimePicker.days.short.saturday", defaultMessage: "Z" }),
		],

		goToToday: intl.formatMessage({
			id: "dateTimePicker.goToToday",
			defaultMessage: "Ga naar vandaag",
		}),
		prevMonthAriaLabel: intl.formatMessage({
			id: "dateTimePicker.prevMonthAriaLabel",
			defaultMessage: "Ga naar vorige maand",
		}),
		nextMonthAriaLabel: intl.formatMessage({
			id: "dateTimePicker.nextMonthAriaLabel",
			defaultMessage: "Ga naar volgende maand",
		}),
		prevYearAriaLabel: intl.formatMessage({
			id: "dateTimePicker.prevYearAriaLabel",
			defaultMessage: "Ga naar vorig jaar",
		}),
		nextYearAriaLabel: intl.formatMessage({
			id: "dateTimePicker.nextYearAriaLabel",
			defaultMessage: "Ga naar volgend jaar",
		}),
		closeButtonAriaLabel: intl.formatMessage({
			id: "dateTimePicker.closeButtonAriaLabel",
			defaultMessage: "Sluit de datum selectie",
		}),
	};

	const pathToPage = [
		{
			text: intl.formatMessage({
				id: "usage.navigation.item",
				defaultMessage: "Gebruikersstatistieken",
			}),
			key: "usage",
		},
		{
			text: intl.formatMessage({
				id: "usage.download.navigation.label",
				defaultMessage: "Gebruiksgegevens downloaden",
			}),
			key: "usage/download",
		},
	];

	const downloadCsv = async (year: number, month: number) => {
		try {
			setIsDownloading(true);
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/analytics/applications/OneForYou/pageViews/${year}/${month}/csv`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const filename = `${currentUserSlice.user.tenant.name}_${year}_${month}.csv`;

			const blob = await response.blob();
			if (navigator.appVersion.toString().indexOf(".NET") > 0) {
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = filename;
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			}
		} catch (error) {
		} finally {
			setIsDownloading(false);
		}
	};

	return (
		<div>
			<BreadCrumbComponent path={pathToPage} />

			<div className="usage-download-wrapper">
				<section className="usage-card-wrapper-small">
					<div className="usage-download-action-container">
						<DatePicker
							label={intl.formatMessage({
								id: "usage.download.datepicker.label",
								defaultMessage: "Maand",
							})}
							minDate={new Date("10-01-2020")}
							maxDate={new Date()}
							disabled={isDownloading}
							value={selectedDate}
							onSelectDate={(date) => {
								setSelectedDate(date === null ? undefined : date);
							}}
							placeholder={intl.formatMessage({
								id: "usage.download.datepicker.placeholder",
								defaultMessage: "Selecteer een maand",
							})}
							showGoToToday={false}
							calendarProps={{
								strings: DatePickerStrings,
								isDayPickerVisible: false,
								showGoToToday: false,
							}}
							textField={{
								value: selectedDate === undefined ? undefined : moment(selectedDate).format("MMMM YYYY"),
							}}
						/>

						<PrimaryButton
							disabled={selectedDate === undefined || isDownloading}
							onClick={() => {
								if (selectedDate) {
									downloadCsv(selectedDate.getFullYear(), selectedDate.getMonth() + 1);
								}
							}}
						>
							{isDownloading ? <Spinner /> : <FormattedMessage id="general.download" defaultMessage="Download" />}
						</PrimaryButton>
					</div>
				</section>
				<section className="usage-card-wrapper">
					<div className="usage-download-instructions ">
						<h2>
							<FormattedMessage id="usage.download.instructions.header" defaultMessage="One for You gebruiksrapportages" />
						</h2>
						<p>
							<FormattedMessage
								id="usage.download.instructions.description"
								defaultMessage="Naast de standaard rapportages die in dit portaal worden aangeboden heeft u de mogelijkheid uitgebreide rapportages te bekijken en uw eigen analyzes uit te voeren met de Power BI desktop applicatie. Hiervoor dient u de volgende stappen te doorlopen:"
							/>
						</p>

						<ol>
							<li>
								<a
									href={`https://go.microsoft.com/fwlink/?LinkId=526478&clcid=0x413`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FormattedMessage
										id="usage.download.instructions.list.one"
										defaultMessage="Download en installeer Power BI desktop."
									/>
								</a>
							</li>

							<li>
								<a
									href={`https://oneproductionstorage.blob.core.windows.net/one-container/One%20for%20You%20-%20Usage.pbix`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FormattedMessage
										id="usage.download.instructions.list.two"
										defaultMessage="Download het Power BI report bestand."
									/>
								</a>
							</li>

							<li>
								<FormattedMessage
									id="usage.download.instructions.list.three"
									defaultMessage="Download de bestanden met de gebruiksgegevens. Deze kunt u met maand selecteren en moeten in één folder opgeslagen worden."
								/>
							</li>

							<li>
								<FormattedMessage
									id="usage.download.instructions.list.four"
									defaultMessage="Open het Power BI report bestand in Power BI Desktop."
								/>
							</li>
						</ol>

						<p>
							<FormattedMessage
								id="usage.download.instructions.endDescription"
								defaultMessage="Een instructie om uw bestanden in te lezen vindt u in het Power BI report bestand."
							/>
						</p>
					</div>
				</section>
			</div>
		</div>
	);
};

export const UsageDownloadComponent = injectIntl(UsageDownload);
