import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { adalOneApiFetch } from "../../adalConfig";
import { MailTemplateCodes } from "../../models/enums/MailTemplateCodes";
import { IAddMailTemplate } from "../../models/viewmodels/mailTemplates/IAddMailTemplate";
import { IMailTemplate } from "../../models/viewmodels/mailTemplates/IMailTemplate";
import { IManageMailTemplate } from "../../models/viewmodels/mailTemplates/IManageMailTemplate";
import { IUpdateMailTemplate } from "../../models/viewmodels/mailTemplates/IUpdateMailTemplate";
import { MailTemplatesActionTypes, MailTemplatesState } from "./types";

export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: MailTemplatesActionTypes.RESET_SUCCESS,
		});
	};
};
export const clearError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: MailTemplatesActionTypes.CLEAR_ERROR,
		});
	};
};

// ----
export const fetchMailTemplates = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchMailTemplatesRequest());
	};
};

const fetchMailTemplatesRequest: ActionCreator<
	ThunkAction<Promise<any>, MailTemplatesState, null, any>
> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchMailTemplatesStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/mail_templates`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchMailTemplatesFailure(response.statusText));
					return;
				}

				const result: IMailTemplate[] = await response.json();

				dispatch(fetchMailTemplatesSuccess(result));
			}
		} catch (e) {
			dispatch(fetchMailTemplatesFailure("error"));
		}
	};
};

const fetchMailTemplatesStarted = () => ({
	type: MailTemplatesActionTypes.FETCH_MAIL_TEMPLATES_STARTED,
});

const fetchMailTemplatesSuccess = (mailTemplates: IMailTemplate[]) => ({
	type: MailTemplatesActionTypes.FETCH_MAIL_TEMPLATES_SUCCESS,
	payload: {
		mailTemplates,
	},
});

const fetchMailTemplatesFailure = (errorMessage: string | object) => ({
	type: MailTemplatesActionTypes.FETCH_MAIL_TEMPLATES_FAILURE,
	payload: {
		errorMessage,
	},
});

// ----
export const fetchManageMailTemplate = (code: MailTemplateCodes) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchManageMailTemplateRequest(code));
	};
};

const fetchManageMailTemplateRequest: ActionCreator<
	ThunkAction<Promise<any>, MailTemplatesState, null, any>
> = (code: MailTemplateCodes) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchManageMailTemplateStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/mail_templates/codes/${code}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchMailTemplatesFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(fetchManageMailTemplateSuccess(result));
			}
		} catch (e) {
			dispatch(fetchManageMailTemplateFailure("error"));
		}
	};
};

const fetchManageMailTemplateStarted = () => ({
	type: MailTemplatesActionTypes.FETCH_MANAGE_MAIL_TEMPLATE_STARTED,
});

const fetchManageMailTemplateSuccess = (currentManageMailTemplate: IManageMailTemplate) => ({
	type: MailTemplatesActionTypes.FETCH_MANAGE_MAIL_TEMPLATE_SUCCESS,
	payload: {
		currentManageMailTemplate,
	},
});

const fetchManageMailTemplateFailure = (errorMessage: string | object) => ({
	type: MailTemplatesActionTypes.FETCH_MANAGE_MAIL_TEMPLATE_FAILURE,
	payload: {
		errorMessage,
	},
});

// ----
export const addMailTemplate = (addMailTemplate: IAddMailTemplate) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addMailTemplateRequest(addMailTemplate));
	};
};

const addMailTemplateRequest: ActionCreator<
	ThunkAction<Promise<any>, MailTemplatesState, null, any>
> = (addMailTemplate: IAddMailTemplate) => {
	return async (dispatch: Dispatch) => {
		dispatch(addMailTemplateStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/mail_templates`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(addMailTemplate),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
					dispatch(addMailTemplateFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(addMailTemplateSuccess(result));
			}
		} catch (e) {
			dispatch(addMailTemplateFailure("error"));
		}
	};
};

const addMailTemplateStarted = () => ({
	type: MailTemplatesActionTypes.ADD_MAIL_TEMPLATES_STARTED,
});

const addMailTemplateSuccess = (mailTemplate: IMailTemplate) => ({
	type: MailTemplatesActionTypes.ADD_MAIL_TEMPLATES_SUCCESS,
	payload: {
		mailTemplate,
	},
});

const addMailTemplateFailure = (errorMessage: string | object) => ({
	type: MailTemplatesActionTypes.ADD_MAIL_TEMPLATES_FAILURE,
	payload: {
		errorMessage,
	},
});

// ----
export const updateMailTemplate = (updateMailTemplate: IUpdateMailTemplate) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateMailTemplateRequest(updateMailTemplate));
	};
};

const updateMailTemplateRequest: ActionCreator<
	ThunkAction<Promise<any>, MailTemplatesState, null, any>
> = (updateMailTemplate: IUpdateMailTemplate) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateMailTemplateStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/mail_templates`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updateMailTemplate),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
					dispatch(updateMailTemplateFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(updateMailTemplateSuccess(result));
			}
		} catch (e) {
			dispatch(updateMailTemplateFailure("error"));
		}
	};
};

const updateMailTemplateStarted = () => ({
	type: MailTemplatesActionTypes.UPDATE_MAIL_TEMPLATES_STARTED,
});

const updateMailTemplateSuccess = (mailTemplate: IMailTemplate) => ({
	type: MailTemplatesActionTypes.UPDATE_MAIL_TEMPLATES_SUCCESS,
	payload: {
		mailTemplate,
	},
});

const updateMailTemplateFailure = (errorMessage: string | object) => ({
	type: MailTemplatesActionTypes.UPDATE_MAIL_TEMPLATES_FAILURE,
	payload: {
		errorMessage,
	},
});

// ----
export const deleteMailTemplate = (mailTemplateToDelete: IMailTemplate) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(deleteMailTemplateRequest(mailTemplateToDelete));
	};
};

const deleteMailTemplateRequest: ActionCreator<
	ThunkAction<Promise<any>, MailTemplatesState, null, any>
> = (mailTemplateToDelete: IMailTemplate) => {
	return async (dispatch: Dispatch) => {
		dispatch(deleteMailTemplateStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/mail_templates/${mailTemplateToDelete.id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(deleteMailTemplateFailure(response.statusText));
					return;
				}

				dispatch(deleteMailTemplateSuccess(mailTemplateToDelete));
			}
		} catch (e) {
			dispatch(deleteMailTemplateFailure("error"));
		}
	};
};

const deleteMailTemplateStarted = () => ({
	type: MailTemplatesActionTypes.DELETE_MAIL_TEMPLATES_STARTED,
});

const deleteMailTemplateSuccess = (deletedMailTemplate: IMailTemplate) => ({
	type: MailTemplatesActionTypes.DELETE_MAIL_TEMPLATES_SUCCESS,
	payload: {
		deletedMailTemplate,
	},
});

const deleteMailTemplateFailure = (errorMessage: string | object) => ({
	type: MailTemplatesActionTypes.DELETE_MAIL_TEMPLATES_FAILURE,
	payload: {
		errorMessage,
	},
});
