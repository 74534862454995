export default {
	// --== General ==--
	"general.makeAChoice": "Selecteer een optie",
	"general.yes": "Ja",
	"general.no": "Nee",
	"general.done": "Klaar",
	"general.new": "Nieuw",
	"general.edit": "Bewerken",
	"general.editShort": "Bewerk",
	"general.create": "Aanmaken",
	"general.save": "Opslaan",
	"general.delete": "Verwijder",
	"general.role": "Rol",
	"general.roles": "Rollen",
	"general.information": "Informatie",
	"general.notSpecified": "Niet gespecificeerd",
	"general.noResults": "Geen resultaten",
	"general.actions": "Acties",
	"general.navigationHeader": "Beheer",
	"general.overview": "Overzicht",
	"general.superadmin": "Super admin beheer",
	"general.close": "Sluit",
	"general.retry": "Probeer opnieuw",
	"general.something-went-wrong": "Er is iets fout gegaan.",
	"general.isActive": "In gebruik",
	"general.download": "Download",
	"general.add": "Toevoegen",
	"general.remove": "Verwijder",
	"general.noAccess": "Geen toegang",
	"general.navigate": "Navigeer",

	"general.validation.required": "Dit veld is verplicht.",

	// --== Pickers ==--
	"general.pickers.people.placeholder": "Zoek naar gebruikers op basis van naam",
	"general.pickers.noResults": "Geen resultaten",

	// --== Home ==--
	"home.navigation.item": "Start",
	"home.welcome": "Welkom",
	"home.subtext": "Navigeer naar een van de pagina's om te beginnen met beheren.",

	// --== Settings ==--
	"settings.general": "Instellingen",
	"settings.save.success": "De instelling is succesvol opgeslagen.",
	"settings.save.error": "Er ging iets mis bij het opslaan van de instellingen.",

	"settings.consent.required.prov.engine":
		"Om gebruik te maken van deze instellingen is goedkeuring voor de One Provisioning Engine nodig. Geef deze goedkeuring indien u dat nog niet gedaan heeft:",
	"settings.consent.button": "Admin consent",

	"settings.card.tenant.title": "Tenant-instellingen",
	"settings.card.tenant.description": "Wijzig de tenant-instellingen",
	"settings.card.teams.title": "One for Teams instellingen",
	"settings.card.teams.description": "Wijzig de One for Teams instellingen",
	"settings.card.events.title": "One for Events instellingen",
	"settings.card.events.description": "Wijzig de One for Events instellingen",
	"settings.card.you.title": "One for You instellingen",
	"settings.card.you.description": "Wijzig de One for You instellingen",

	"settings.tenant.importUsers.text": "Synchroniseer gebruikers",
	"settings.tenant.enableTeamProvisioning.text": "Maak gebruik van de Teams Provisioning",
	"settings.tenant.enableTeamSync.text": "Synchroniseer teams en kanalen",
	"settings.tenant.language.text": "De taal op tenant niveau",
	"settings.teams.showProvisioningForm.text": "Toon aanvraagformulier voor nieuwe teams aan gebruikers",
	"settings.teams.archivingChangeOwnersToMembers": "Wijzig eigenaren in leden bij het archiveren van een team, waardoor SharePoint-gebruikers naar de groep Bezoekers worden verplaatst",
	"settings.teams.language.text": "De taal voor One for Teams",
	"settings.teams.groupSettings": "Groep instellingen",
	"settings.teams.groupSettings.description":
		"Bij het aanvragen van een team is het mogelijk om te specificeren of externen zijn toegestaan. De onderstaande instelling heeft invloed op het veld in het aanvraagformulier.",
	"settings.teams.groupSettings.allowToAddGuests": "Externen toelaten",
	"settings.teams.groupSettings.defaultValue": "Standaardwaarde",

	"settings.teams.privacy.header": "Teams synchronisatie instellingen",
	"settings.teams.privacy.description":
		"Om de One for Teams oplossing te laten functioneren worden bestaande teams periodiek gesynchroniseerd. Hierbij heeft u keuze in de hoeveelheid informatie die wordt opgeslagen.",
	"settings.teams.privacy.option.noStorage": "Weinig",
	"settings.teams.privacy.option.noStorage.desc1": "Metadata van teams wordt opgeslagen.",
	"settings.teams.privacy.option.minimalStorage": "Gemiddeld",
	"settings.teams.privacy.option.minimalStorage.desc1":
		"Informatie over de status van het team wordt ook opgeslagen, hiermee is een filter op het IsArchived veld mogelijk.",
	"settings.teams.privacy.option.optimalPerformance": "Meer",
	"settings.teams.privacy.option.optimalPerformance.desc1": "De titel en omschrijving van een team worden ook opgeslagen.",
	"settings.teams.privacy.option.optimalPerformance.desc2": "Tot 600% sneller.",

	"settings.teams.types.header": "Teamtypes",
	"settings.teams.types.add.button": "Teamtype toevoegen",
	"settings.teams.types.update": "Teamtype bewerken",
	"settings.teams.types.column.name": "Naam",
	"settings.teams.types.column.domain": "Domein",
	"settings.teams.types.column.isActive": "In gebruik",
	"settings.teams.types.column.templateId": "Sjabloon ID",
	"settings.teams.types.column.approvalRequired": "Goedkeuring vereist",
	"settings.teams.types.column.defaultOwners": "Standaard eigenaren",
	"settings.teams.types.column.actions": "Acties",
	"settings.teams.types.error.onUpdate": "Er ging iets mis met het bijwerken van het teamtype.",
	"settings.teams.types.error.onCreate": "Er ging iets mis met het aanmaken van het teamtype.",

	"settings.teams.types.owners.label": "Standaard eigenaren",
	"settings.teams.types.owners.tooltip":
		"Gebruikers die hier zijn geselecteerd worden automatisch toegevoegd als eigenaar wanneer een nieuw team wordt aangemaakt.",

	"settings.you.topdesk.title": "Topdesk connecties",
	"settings.you.topdesk.api.url": "API URL",
	"settings.you.topdesk.api.username": "API Gebruikersnaam",
	"settings.you.topdesk.api.password": "API Wachtwoord",

	"settings.you.teamtailor.title": "Teamtailor connecties",
	"settings.you.teamtailor.api.key": "API Key",

	"settings.you.connections.update.success": "Gegevens zijn successvol bijgewerkt.",
	"settings.you.connections.update.error": "Er is iets misgegaan bij het opslaan van de gegevens.",
	"settings.you.connections.fetch.error": "Er is iets misgegaan bij het ophalen van de gegevens.",

	"settings.events.dashboardUrl.label": "Evenementen dashboard locatie",
	"settings.events.dashboardUrl.description": "Specificieer hier naar welke pagina gedeelde evenementen naartoe dienen te verwijzen.",
	"settings.events.centraleMailbox.label": "Event mailbox instellingen",
	"settings.events.centraleMailbox.description": "Specificeer hier het centrale mailbox e-mailadres.",	
	"settings.events.centraleMailbox.message": "Voer een geldig e-mailadres in.",
	"settings.events.centraleMailbox.ORG": "Gebruik de mailbox van de organizator (Standaard)",
	"settings.events.centraleMailbox.CMB": "Gebruik een centrale mailbox",
	"settings.events.access.label": "Toegang",
	"settings.events.restrictEventCreation.description": "Beperk het aanmaken van evenementen tot een gebruikersgroep.",
	"settings.events.restrictEventCreation.option.allUsers": "Alle gebruikers",
	"settings.events.restrictEventCreation.option.adminOnly": "Administrators",

	// --== Taxonomy ==--
	"taxonomy.navigation.item": "Taxonomie",
	"taxonomy.term.new": "Nieuwe term",
	"taxonomy.termSet.new": "Nieuwe termenset",
	"taxonomy.none.selected": "Selecteer een taxonomie-item om deze te bewerken, of om een onderliggend item aan te maken.",

	"taxonomy.term.name.label": "Naam",
	"taxonomy.term.name.placeholder": "De naam van de term",
	"taxonomy.term.isSelectable": "Is selecteerbaar",
	"taxonomy.term.isSelectable.description":
		"Deze term is te selecteren. Deze instelling heeft geen invloed op eventueel onderliggende termen.",
	"taxonomy.term.name.isActive": "In gebruik",
	"taxonomy.term.isActive.lastActive.tooltip":
		"Het is niet mogelijk om deze term inactief te maken omdat de bovenliggende termenset verplicht is en er hierbij minstens één term in gebruik moet blijven.",
	"taxonomy.term.notEditable": "Deze term is niet bewerkbaar.",

	"taxonomy.term.isActive.description":
		"Deze term en eventueel onderliggenden termen zijn actief. Een inactieve term zorgt ervoor dat deze term en de onderliggende termen niet worden getoond in de picker.",

	"taxonomy.termSet.name.label": "Naam",
	"taxonomy.termSet.name.placeholder": "De naam van de termenset",
	"taxonomy.termSet.isRequired.label": "Is verplicht",
	"taxonomy.termSet.isRequired.description": "Deze termenset is verplicht om in te vullen.",
	"taxonomy.termSet.hasNoActiveTerms.tooltip":
		"Het is niet mogelijk om deze termenset verplicht te maken omdat er geen actieve termen zijn.",
	"taxonomy.termSet.isActive.label": "In gebruik",
	"taxonomy.termSet.isActive.description": "Deze termenset is actief.",
	"taxonomy.termSet.isReassignable.label": "Is aanpasbaar",
	"taxonomy.termSet.allowMultipleValues.label": "Multi-select",
	"taxonomy.termSet.allowMultipleValues.description": "Bij deze termenset is het mogelijk om meerdere terms te selecteren.",
	"taxonomy.termSet.isReassignable.description": "De geselecteerde waarde van deze termenset mag gewijzigd worden.",
	"taxonomy.termSet.notEditable": "Deze termenset is niet bewerkbaar.",

	"taxonomy.term.created.success": "De term is succesvol aangemaakt.",
	"taxonomy.term.created.failure": "Er ging iets mis bij het updaten van de term.",
	"taxonomy.term.updated.success": "De term is successlvol geüpdatet.",
	"taxonomy.term.updated.failure": "Er ging iets mis bij het updaten van de term.",

	"taxonomy.termSet.created.success": "De termenset is succesvol aangemaakt.",
	"taxonomy.termSet.created.failure": "Er ging iets mis bij het updaten van de termenset.",
	"taxonomy.termSet.updated.success": "De termenset is successlvol geüpdatet.",
	"taxonomy.termSet.updated.failure": "Er ging iets mis bij het updaten van de termenset.",

	"taxonomy.group.name.label": "Naam",
	"taxonomy.group.isTenantWide.label": "Is organisatiebreed",
	"taxonomy.group.isTenantWide.description": "Deze termgroep kan door de hele organisatie gebruikt worden.",

	"taxonomy.validation.name.minTwoChars": "De naam moet ten minste twee tekens lang zijn.",

	// --== Page not found ==--
	"notfound.headertext": "Deze pagina bestaat helaas niet.",
	"notfound.subtext": "Navigeer naar een andere pagina door gebruik te maken van de navigatielinks.",

	// --== Page not found ==--
	"unauthorized.headertext": "Toegang geweigerd.",
	"unauthorized.subtext": "U heeft geen toegang tot deze pagina, mocht u toch toegang willen neem dan contact op met de site beheerder.",

	// --== Subscription ==--
	"subscription.inactive": "Uw abonnement is verlopen, neem contact op met uw beheerder",
	"subscription.none": "U heeft geen abonnement voor One.",
	"subscription.error": "Er is iets fout gegaan bij het ophalen van uw abonnement.",

	// --== AccountPanel ==--
	"account.panel.headertext": "Mijn account",
	"account.panel.myProfile": "Mijn profiel",
	"account.panel.logout": "Uitloggen",

	// --== Look up lists ==--
	"lookup.navigation.item": "Opzoeklijsten",
	"lookup.value.new": "Nieuw",
	"lookup.list.column.name": "Waarde",
	"lookup.list.column.isActive": "In gebruik",
	"lookup.list.column.actions": "Acties",
	"lookup.list.action.edit": "Bewerk",
	"lookup.list.action.delete": "Verwijder",

	"lookup.panel.create.label.name": "Waarde",
	"lookup.panel.create.label.isActive": "In gebruik",
	"lookup.panel.create.placeholder.name": "Vul een waarde in",
	"lookup.panel.create.validation.name.minTwoChars": "De waarde moet ten minste twee tekens lang zijn.",
	"lookup.panel.create.create.validation.name.minTwoChars": "De waarde moet ten minste twee tekens lang zijn.",

	"lookup.panel.delete.contentDescription":
		"Deze waarde kan alleen worden verwijderd wanneer deze op geen enkele plek wordt gebruikt. Indien u verder gebruik van deze waarde wilt voorkomen kunt u deze waarde ook op 'inactief' zetten in het bewerk paneel.",
	"lookup.panel.delete.contentTitle": "Weet u zeker dat u deze waarde wilt verwijderen?",

	"lookup.success.onCreate": "is succesvol aangemaakt.",
	"lookup.success.onUpdate": "is succesvol bijgewerkt.",
	"lookup.success.onDelete": "is succesvol verwijderd.",

	"lookup.error.onDelete": "Deze waarde is momenteel in gebruik. Verwijder alle referenties naar deze waarde om deze te verwijderen.",

	// --== Audiences ==--
	"audiences.navigation.item": "Doelgroepen",

	"audiences.new.button": "Doelgroep",

	"audiences.list.column.name": "Naam",
	"audiences.list.column.audienceGroups": "Beveiligingsgroepen",
	"audiences.list.column.isActive": "In gebruik",
	"audiences.list.column.actions": "Acties",

	"audiences.panel.create.header": "Nieuwe doelgroep",
	"audiences.panel.edit.header": "Bewerk doelgroep",

	"audiences.panel.label.name": "Naam",
	"audiences.panel.placeholder.name": "Vul een naam in",
	"audiences.panel.validation.name.minTwoChars": "De naam moet ten minste twee tekens lang zijn.",
	"audiences.panel.label.audienceGroups": "Beveiligingsgroepen",
	"audiences.panel.placeholder.audienceGroups": "Selecteer één of meer groepen",
	"audiences.panel.label.isActive": "In gebruik",

	"audiences.panel.delete.contentDescription":
		"Deze waarde kan alleen worden verwijderd wanneer deze op geen enkele plek wordt gebruikt. Indien u verder gebruik van deze waarde wilt voorkomen kunt u deze waarde ook op 'inactief' zetten in het bewerk paneel.",
	"audiences.panel.delete.contentTitle": "Weet u zeker dat u deze waarde wilt verwijderen?",

	"audiences.error.onDelete": "Deze waarde is momenteel in gebruik. Verwijder alle referenties naar deze waarde om deze te verwijderen.",
	"audiences.error.onCreate": "Er ging iets mis bij het aanmaken van deze doelgroep",
	"audiences.error.onUpdate": "Er ging iets mis bij het bijwerken van deze doelgroep",
	"audiences.error.onInitialDataLoad": "Er ging iets mis bij het ophalen van de doelgroepen.",
	"audiences.error.OnMetaDataLoad": "Er ging iets mis bij het ophalen van de beveiligingsgroepen.",

	"audiences.info.noSecurityGroups": "Er zijn geen beveiligingsgroepen gevonden.",

	"audiences.success.onCreate": "De doelgroep is succesvol aangemaakt.",
	"audiences.success.onUpdate": "De doelgroep is succesvol bijgewerkt.",
	"audiences.success.onDelete": "De doelgroep is succesvol verwijderd.",

	// Roles
	"roles.navigation.item": "Rollen",

	"roles.modal.tab.permissions": "Permissies",
	"roles.modal.tab.usersWithThisRole": "Gebruikers met deze rol",
	"roles.modal.tab.assignUsers": "Gebruikers toekennen",

	"roles.modal.permissions.list.name": "Naam",

	"roles.modal.add.tooltip.alreadyExists": "Deze gebruiker heeft deze rol al.",
	"roles.modal.add.message.success": "De rol is succesvol toegekend aan de gebruiker.",
	"roles.modal.add.message.error": "Kon de rol niet toekennen aan de gebruiker.",
	"roles.modal.remove.message.success": "De rol is succesvol verwijderd van de gebruiker.",
	"roles.modal.remove.message.error": "Kon de rol niet verwijderen van de gebruiker.",

	// --== Users == --
	"users.navigation.item": "Gebruikers",

	"users.succesMessage.onDelete": "Gebruiker is succesvol verwijderd.",
	"users.successMessage.onCreate": "Gebruiker is succesvol aangemaakt.",

	"users.search.placeholder": "Zoek naar gebruikers op basis van naam.",
	"users.search.enterSearchTerm": "Voer een zoekterm in om te beginnen.",
	"users.search.noSearchResults": "Geen gebruikers gevonden die overeenkomen met de ingevulde zoekterm.",

	"users.list.column.givenName": "Voornaam",
	"users.list.column.displayName": "Naam",
	"users.list.column.surname": "Achternaam",
	"users.list.column.email": "E-mailadres",
	"users.list.column.userPrincipalName": "UPN",
	"users.list.column.scope": "Scope",
	"users.list.column.scope.global": "Globaal",
	"users.list.column.jobTitle": "Functie",
	"users.list.column.department": "Afdeling",
	"users.list.column.officeLocation": "Locatie",
	"users.list.column.hasAdminRole": "Is administrator",

	"users.delete.panel.headerText": "Verwijder gebruiker",
	"users.delete.panel.contentTitle": "Gebruiker:",
	"users.delete.panel.contentDescription": "Weet u zeker dat u deze gebruiker en alle bijbehorende gegevens wilt verwijderen?",
	"users.delete.error.onDelete": "Er ging iets mis bij het verwijderen van deze gebruiker.",

	"users.modal.load.error": "Er ging iets mis bij het ophalen van de informatie over deze gebruiker.",
	"users.modal.user.not.exists": "Deze gebruiker is niet bekend binnen One, wilt u deze toevoegen?",
	"users.modal.user.not.exists.add": "Voeg gebruiker toe",
	"users.modal.user.not.exists.cancel": "Annuleer",

	"users.modal.info.contact.header": "Contactinformatie",
	"users.modal.info.contact.name": "Naam",
	"users.modal.info.contact.email": "E-mailadres",
	"users.modal.info.occupation.header": "Werkzaamheden",
	"users.modal.info.occupation.jobTitle": "Functie",
	"users.modal.info.occupation.department": "Afdeling",
	"users.modal.info.occupation.officeLocation": "Locatie",

	"users.modal.actions.header": "Acties",
	"users.modal.actions.deleteUser": "Verwijder gebruiker",
	"users.modal.actions.deleteUser.description": "Via deze actie is het mogelijk om de gebruiker binnen One te verwijderen.",

	"users.modal.roles.add.info": "Als u een gebruiker aan deze rol koppelt zal de rol globaal gescoped zijn.",
	"users.modal.roles.name.label": "Rol",
	"users.modal.roles.isAdmin.label": "Is administrator rol",
	"users.modal.roles.actions.label": "Acties",
	"users.modal.roles.actions.cannotRemoveOwnAdminRole": "U kunt niet de adminstrator rol bij uzelf verwijderen.",
	"users.modal.roles.addRoles": "Ken een rol globaal gescoped toe",
	"users.modal.roles.noRoles": "Deze gebruiker heeft nog geen toegekende rollen.",

	// Role descriptions
	"users.modal.roles.address.text": "Beheert de adressen binnen One for You.",
	"users.modal.roles.admin.text": "Heeft alle rechten binnen de One applicaties",
	"users.modal.roles.announcement.text": "Beheert de mededelingen binnen One for You.",
	"users.modal.roles.event.text": "Beheert de evenementen binnen One for Events.",
	"users.modal.roles.shortcut.text": "Beheert de gedeelde snelkoppelingen binnen One for You.",
	"users.modal.roles.team.text": "Beheert de aanvragen voor een team die gebruikers indienen.",
	"users.modal.roles.usage.text": "Heeft het recht de gebruikersstatistieken van One for You in te zien.",
	"users.modal.roles.user.text": "Is een reguliere gebruiker en heeft geen speicale rechten.",
	"users.modal.roles.vacancy.text": "Beheert de (interne) vacatures binnen One for You.",
	"users.modal.roles.startup.text": "Beheert de startup messages die getoond worden binnen One for You.",
	"users.modal.roles.taxonomy.text": "Beheert de taxonomie binnen de One applicaties.",
	"users.modal.roles.quality.text": "Beheert handboeken en kan ingediende feedback bekijken.",
	"users.modal.roles.media.text": "Beheert One for Media en kan ingediende feedback bekijken.",
	"users.modal.roles.reporting.text": "Beheert One for Reporting.",
	"users.modal.roles.informationManager.text": "Voert informatie management gerelateerde werkzaamheden uit.",
	"users.modal.roles.news.text": "Heeft het recht om nieuwsberichten te pinnen.",

	"users.modal.roles.add.description": "Klik op een rol om deze toe te kennen aan de geselecteerde gebruiker.",
	"users.modal.roles.add.availableRoles": "Beschikbare rollen",
	"users.modal.roles.add.noAvailableRoles": "Er zijn geen beschikbare rollen om toe te kennen.",
	"users.modal.roles.remove.headerText": "Ontneem rol",
	"users.modal.roles.remove.contentDescription": "Weet u zeker dat u deze gebruiker de geselecteerde rol wilt ontnemen?",

	// --== Usage ==--
	"usage.navigation.item": "Gebruikersstatistieken",
	"usage.uniqueVisitorsChart.visitorsPerDay": "Unieke gebruikers per dag",
	"usage.uniqueVisitorsChart.audienceFiltersPlaceholder": "Filter op doelgroepen",
	"usage.uniqueVisitorsChart.audienceFiltersText": "Doelgroepen",
	"usage.uniqueVisitorsChart.pastSevenDaysButtonText": "7 dagen",
	"usage.uniqueVisitorsChart.pastThirtyDaysButtonText": "30 dagen",
	"usage.uniqueVisitorsChart.pastNinetyDaysButtonText": "90 dagen",
	"usage.uniqueVisitorsChart.usersText": "Gebruikers",
	"usage.uniqueVisitorsChart.xLabel": "Datum",
	"usage.uniqueVisitorsChart.yLabel": "Gebruikers",
	"usage.uniqueVisitorsChart.averageUniqueVisitors": "Gemiddelde unieke gebruikers per dag",

	"usage.uniqueVisitorsChartPerSite.noResults": "Er zijn geen resultaten.",
	"usage.uniqueVisitorsChartPerSite.uniqueUsersPerSite": "Unieke gebruikers per site",
	"usage.uniqueVisitorsChartPerSite.uniqueUsersFor": "Unieke gebruikers voor ",
	"usage.uniqueVisitorsChartPerSite.backToSiteCollection": "Terug naar site selecteren",
	"usage.uniqueVisitorsChartPerSite.noData": "Er is niet genoeg data beschikbaar.",
	"usage.usage.uniqueVisitorsChartPerSite.noDataAiLinkText": "Installeer de One for You Analytics App",
	"usage.search.placeholder": "Zoek naar sites op basis van naam.",

	"usage.pageviewsPerSite.title": "Paginaweergaven",
	"usage.pageviewsPerSite.tableName": "Naam",
	"usage.pageviewsPerSite.tableSiteTitle": "Site",
	"usage.pageviewsPerSite.tableViewCount": "Weergaven",
	"usage.allOptionsPlaceholder": "Alle sites",

	"usage.download.navigation.label": "Gebruiksgegevens downloaden",
	"usage.download.datepicker.label": "Maand",
	"usage.download.datepicker.placeholder": "Selecteer een maand",

	"usage.download.instructions.header": "One for You gebruiksrapportages",
	"usage.download.instructions.description":
		"Naast de standaard rapportages die in dit portaal worden aangeboden heeft u de mogelijkheid uitgebreide rapportages te bekijken en uw eigen analyzes uit te voeren met de Power BI desktop applicatie. Hiervoor dient u de volgende stappen te doorlopen:",
	"usage.download.instructions.list.one": "Download en installeer Power BI desktop.",
	"usage.download.instructions.list.two": "Download het Power BI report bestand.",
	"usage.download.instructions.list.three":
		"Download de bestanden met de gebruiksgegevens. Deze kunt u met maand selecteren en moeten in één folder opgeslagen worden.",
	"usage.download.instructions.list.four": "Open het Power BI report bestand in Power BI Desktop.",
	"usage.download.instructions.endDescription": "Een instructie om uw bestanden in te lezen vindt u in het Power BI report bestand.",

	// Role permissions overview
	"rolePermissions.navigationItem.title": "Rollen en rechten",

	// Release page translations
	"releases.navigationItem.title": "Release informatie",
	"releases.createForm.title": "Titel",
	"releases.createForm.body": "Inhoud",
	"releases.createForm.versionNumber": "Versienummer",
	"releases.createForm.Application": "Applicatie",
	"releases.createForm.Link": "Link",
	"releases.createForm.PublishingDate": "Publicatiedatum",
	"releases.page.createNewRelease": "Nieuwe release aanmaken",
	"releases.page.noReleaseNotesYet": "Er zijn nog geen releases.",
	"releases.page.version": "Versie:",
	"releases.page.releaseNoteAddedSuccessMessage": "U heeft een nieuwe release toegevoegd.",
	"releases.page.releaseNoteAddedFailedMessage": "Helaas, er is iets fout gegaan, probeer het later opnieuw.",
	"releases.page.updateRelease": "Release bijwerken",
	releases_viewmore: "Toon meer",

	"deleteReleasePanel-header": "Verwijder release",
	"deleteReleasePanel-contentTitle": "Release: ",
	"deleteReleasePanel-contentDescription": "Weet u zeker dat u deze release wilt verwijderen?",
	"deleteReleasePanel-contentDeleteError": "Er ging iets mis bij het verwijderen van deze release.",

	"releases.table.title": "Titel",
	"releases.table.versionNumber": "Versienummer",
	"releases.table.displayDate": "Publicatiedatum",
	"releases.table.application": "Applicatie",

	"dateTimePicker.Months.January": "januari",
	"dateTimePicker.Months.February": "februari",
	"dateTimePicker.Months.March": "maart",
	"dateTimePicker.Months.April": "april",
	"dateTimePicker.Months.May": "mei",
	"dateTimePicker.Months.June": "juni",
	"dateTimePicker.Months.July": "juli",
	"dateTimePicker.Months.August": "augustus",
	"dateTimePicker.Months.September": "september",
	"dateTimePicker.Months.October": "oktober",
	"dateTimePicker.Months.November": "november",
	"dateTimePicker.Months.December": "december",

	"dateTimePicker.Months.short.January": "jan",
	"dateTimePicker.Months.short.February": "feb",
	"dateTimePicker.Months.short.March": "mar",
	"dateTimePicker.Months.short.April": "apr",
	"dateTimePicker.Months.short.May": "mei",
	"dateTimePicker.Months.short.June": "jun",
	"dateTimePicker.Months.short.July": "jul",
	"dateTimePicker.Months.short.August": "aug",
	"dateTimePicker.Months.short.September": "sep",
	"dateTimePicker.Months.short.October": "okt",
	"dateTimePicker.Months.short.November": "nov",
	"dateTimePicker.Months.short.December": "dec",

	"dateTimePicker.days.monday": "maandag",
	"dateTimePicker.days.tuesday": "dinsdag",
	"dateTimePicker.days.wednesday": "woensdag",
	"dateTimePicker.days.thrusday": "donderdag",
	"dateTimePicker.days.friday": "vrijdag",
	"dateTimePicker.days.saturday": "zaterdag",
	"dateTimePicker.days.sunday": "zondag",

	"dateTimePicker.days.short.monday": "M",
	"dateTimePicker.days.short.tuesday": "D",
	"dateTimePicker.days.short.wednesday": "W",
	"dateTimePicker.days.short.thrusday": "D",
	"dateTimePicker.days.short.friday": "V",
	"dateTimePicker.days.short.saturday": "Z",
	"dateTimePicker.days.short.sunday": "Z",

	"dateTimePicker.goToToday": "Ga naar vandaag",
	"dateTimePicker.prevMonthAriaLabel": "Ga naar vorige maand",
	"dateTimePicker.nextMonthAriaLabel": "Ga naar volgende maand",
	"dateTimePicker.prevYearAriaLabel": "Ga naar vorig jaar",
	"dateTimePicker.nextYearAriaLabel": "Ga naar volgend jaar",
	"dateTimePicker.closeButtonAriaLabel": "Sluit de datum selectie",

	// Jobs
	"jobs.tenant.select.label": "Selecteer tenant",

	"jobs.navigationItem.title": "Jobs",
	"jobs.def.card.lastRunTime": "Laatst uitgevoerd",
	"jobs.def.card.interval": "Interval",
	"jobs.def.card.scheduleType": "Schema type",

	"jobs.status.failed": "Mislukt",
	"jobs.status.paused": "Gepauzeerd",
	"jobs.status.running": "Aan het lopen",
	"jobs.status.succeeded": "Geslaagd",

	"jobs.scheduleType.minute": "Minuut",
	"jobs.scheduleType.hourly": "Uur",
	"jobs.scheduleType.daily": "Dag",
	"jobs.scheduleType.weekly": "Week",
	"jobs.scheduleType.monthly": "Maand",
	"jobs.scheduleType.yearly": "Jaar",
	"jobs.scheduleType.onetime": "Eenmalig",

	"job.history.title": "Historie",
	"job.history.no.history": "Deze job heeft nog niet gelopen.",
	"job.history.error.fetch": "Kon de historie niet ophalen.",

	"job.history.list.column.startTime": "Starttijd",
	"job.history.list.column.endTime": "Eindtijd",
	"job.history.list.column.status": "Status",
	"job.history.list.column.statusReason": "Statusomschrijving",

	"job.schedule.doesNotExist": "Deze job heeft nog geen uitvoeringsschema, dit houdt in dat deze job nog zal draaien.",
	"job.schedule.create.button.label": "Maak een uitvoeringsschema",
	"job.schedule.edit.button.label": "Bewerk uitvoeringsschema",
	"job.schedule.panel.create.header": "Nieuw uitvoeringsschema",
	"job.schedule.panel.update.header": "Bewerk uitvoeringsschema",
	"job.schedule.panel.isActive.label": "In gebruik",
	"job.schedule.panel.retry.label": "Probeer opnieuw indien job faalt",
	"job.schedule.panel.scheduleType.label": "Type uitvoeringsschema",
	"job.schedule.panel.interval.label": "Interval",
	"job.schedule.panel.interval.tooltip": "Dit is enkel instelbaar voor het uitvoeringsschema 'Minuut'.",

	"job.schedule.panel.success.onCreate": "Het uitvoeringsschema is aangemaakt.",
	"job.schedule.panel.success.onUpdate": "Het uitvoeringsschema is geüpdatet.",
	"job.schedule.error.onCreate": "Er ging iets mis bij het aanmaken van dit uitvoeringsschema.",

	// Mail templates
	"mailTemplates.navigation.item": "E-mail templates",
	"mail.template.header": "Template",
	"mail.template.subject.label": "Onderwerp",
	"mail.template.body.label": "Inhoud",
	"mail.template.revertToDefault": "Herstel origineel",
	"mail.template.revertToDefault.error": "Er ging iets mis bij het herstellen van het originiele template.",
	"mail.template.variable.tooltip": "Sleep mij op de juiste plaats!",

	"mail.template.revert.confirm.title": "Weet u zeker dat u het template wil herstellen?",
	"mail.template.revert.confirm.description":
		"Met deze actie maakt u alle wijzigingen aan het geselecteerde template ongedaan en wordt het standaard template weer gebruikt.",

	"mail.template.label.header.events": "Evenementen",
	"mail.template.label.eventRegistrationSpareListMail": "Evenement reservelijst registratie",
	"mail.template.label.eventRegistrationSeatAvailable": "Reservelijst - Plaats vrijgekomen",
	"mail.template.label.organizerEventCalendarItem": "Agenda uitnodiging evenement - Organisator",
	"mail.template.label.organizerEventTopicCalendarItem": "Agenda uitnodiging sessie - Organisator",
	"mail.template.label.header.other": "Overig",
	"mail.template.label.feedbackMail": "Nieuwe feedback ingediend",

	"mail.template.variable.header.event": "Evenement",
	"mail.template.variable.header.organizer": "Organisator",
	"mail.template.variable.header.eventregistration": "Registratie",
	"mail.template.variable.header.topic": "Sessie",
	"mail.template.variable.header.attendee": "Deelnemer",

	"mail.template.variable.header.feedback": "Feedback",
	"mail.template.variable.header.sender": "Afzender",

	"mail.template.variable.[EventDate]": "Datum",
	"mail.template.variable.[EventLink]": "Link",
	"mail.template.variable.[EventLocation]": "Locatie",
	"mail.template.variable.[EventName]": "Naam",
	"mail.template.variable.[EventDescriptionIntro]": "Introduction text",
	"mail.template.variable.[EventDescription]": "Description",
	"mail.template.variable.[EventRegistrationAllergyDescription]": "Allergie-informatie",
	"mail.template.variable.[EventRegistrationIsAttendingMeals]": "Eet mee (ja/nee)",
	"mail.template.variable.[EventRegistrationEmailAddress]": "E-mail",
	"mail.template.variable.[EventRegistrationJobTitle]": "Functietitel",
	"mail.template.variable.[EventRegistrationName]": "Naam",
	"mail.template.variable.[EventRegistrationStatus]": "Status",
	"mail.template.variable.[EventRegistrationPhone]": "Telefoon",
	"mail.template.variable.[TopicDate]": "Datum",
	"mail.template.variable.[TopicEventName]": "Naam evenement",
	"mail.template.variable.[TopicLink]": "Link",
	"mail.template.variable.[TopicLocation]": "Locatie",
	"mail.template.variable.[TopicName]": "Naam",
	"mail.template.variable.[TopicDescription]": "Omschrijving",
	"mail.template.variable.[AttendeeGivenName]": "Weergavenaam",
	"mail.template.variable.[AttendeeFullName]": "Volledige naam",
	"mail.template.variable.[AttendeeDepartment]": "Afdeling",
	"mail.template.variable.[AttendeeEmailAddres]": "E-mail",
	"mail.template.variable.[OrganizerGivenName]": "Weergavenaam",
	"mail.template.variable.[OrganizerFullName]": "Volledige naam",
	"mail.template.variable.[OrganizerDepartment]": "Afdeling",
	"mail.template.variable.[OrganizerEmailAddress]": "E-mail",

	"mail.template.variable.[FeedbackDescription]": "Omschrijving",
	"mail.template.variable.[FeedbackType]": "Type",
	"mail.template.variable.[FeedbackSource]": "Omgeving",
	"mail.template.variable.[FeedbackLink]": "Link",
	"mail.template.variable.[SenderGivenName]": "Voornaam",
	"mail.template.variable.[SenderFullName]": "Volledige naam",
	"mail.template.variable.[SenderDepartment]": "Afdeling",
	"mail.template.variable.[SenderEmailAddres]": "E-mail",
	"mail.template.variable.[SenderEmailAddress]": "E-mail",

	"mail.template.error.onCreateOrUpdate": "Er ging iets mis bij het bewerken van het e-mail template.",
	"mail.template.error.onDetailDataLoad": "Er ging iets mis bij het ophalen van het template.",
	"mail.template.success.onCreateOrUpdate": "Het e-mail template is succesvol bijgewerkt.",
	"mail.template.success.onDelete": "Succesvol het originele template hersteld.",
	/*
        EventRegistrationMail
        Mail wanneer iemand registreert. "Hallo en welkom bij dit event"


        EventRegistrationSpareListMail
        Bij inschrijven op de reservelijst


        EventRegistrationSeatAvailable
        mail wanneer er een plek vrijkomt en jij op reservelijst staat, hierdoor wordt jij ingeschreven.


        EventRegistrationCalendarItem
        De inhoud van de uitnodiging in je agenda bij het inschrijven opeen event.
        
    


        EventTopicRegistrationCalendarItem
                De inhoud van de uitnodiging in je agenda bij het inschrijven opeen sessie.


        OrganizerEventCalendarItem
            Mail na aanmaken van een event


        OrganizerEventTopicCalendarItem
            Mail na aanmaken van een sessie.

    */

	//feedback
	"feedback.navigationItem.title": "Feedback",
	"feedback.page.noFeedbackYet": "Er zijn nog geen feedback items.",
	"feedback.filter.placeholder": "Toon alle feedback items",
	"feedback.table.actions.edit": "Bewerk",
	"feedback.table.actions.view": "Bekijk",
	"feedback.table.actions.delete": "Verwijder",
	"feedback.table.createDate": "Aanmaakdatum",
	"feedback.table.createdByUser": "Ingediend door",
	"feedback.table.status": "Status",
	"feedback.table.description": "Omschrijving",
	"feedback.table.assignedTo": "Toegekend aan",
	"feedback.table.application": "Applicatie",
	"feedback.table.feedbackType": "Type",
	"feedback.panel.edit.label.filename": "Bestandsnaam",
	"feedback.panel.edit.label.contentTypeName": "Content type",
	"feedback.panel.edit.label.fileSize": "Bestandsgrootte",
	"feedback.panel.edit.label.description": "Omschrijving",
	"feedback.panel.edit.placeholder.description": "Omschrijf de feedback",
	"feedback.panel.edit.label.itemId": "Gekoppelde item (ID)",
	"feedback.panel.edit.label.item": "Gekoppelde item",
	"feedback.panel.edit.label.modifiedDate": "Laatst gewijzigd op",
	"feedback.panel.edit.label.modifiedByUser": "Laatst gewijzigd door",
	"feedback.panel.edit.placeholder.assigedToUserId": "Selecteer een persoon",
	"feedback.panel.edit.label.assigedToUserId": "Toegekend aan",
	"feedback.panel.edit.placeholder.status": "Selecteer een status",
	"feedback.panel.edit.label.status": "Status",
	"feedback.panel.edit.comment.add": "Voeg comment toe",
	"feedback.panel.edit.placeholder.statusReason": "Omschrijf de reden van wijzigen",
	"feedback.panel.edit.label.statusReason": "Reden status wijziging",
	"feedback.panel.edit.success.onUpdate": "Feedback succesvol bijgewerkt",
	"feedback.panel.edit.error.onUpdate": "Er ging iets mis bij het verwerken van de wijzigingen.",
	"feedback.panel.edit.user.noResults": "Geen resulaten",
	"feedback.panel.edit.user.loading": "Laden..",
	"feedback.panel.edit.user.status.notStarted": "Niet gestart",
	"feedback.panel.edit.user.status.inProgress": "Opgepakt",
	"feedback.panel.edit.user.status.rejected": "Afgewezen",
	"feedback.panel.edit.user.status.completed": "Voltooid",
	"feedback.panel.edit.label.comment": "Commentaar",
	"feedback.panel.edit.placeholder.comment": "Plaats hier commentaar ter aanvulling van dit item",
	"feedback.panel.edit.label.comment.noResults": "Geen commentaar beschikbaar",
	"feedback.panel.error.delete": "Kon dit item niet verwijderen. Mogelijk is dit item al verwijderd.",
	"feedback.panel.error.contentTitle": "Weet u zeker dat u deze waarde wilt verwijderen?",
	"feedback.panel.delete.contentDescription": "Wanneer dit item verwijderd wordt is dit item niet meer toegankelijk.",
};