import * as React from "react";
import "../../themes/themeDefault.scss";

export interface IThemeProviderDefaultProps {
	children?: React.ReactNode;
}

export const ThemeProviderDefault = (props: IThemeProviderDefaultProps) => {
	return (
		<div id="one-web-theme" className="one-web-theme-default">
			{props.children}
		</div>
	);
};
