import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { Group } from "@microsoft/microsoft-graph-types";

import { AudiencesActionTypes, AudiencesState } from "./types";
import { adalOneApiFetch, adalGraphFetch } from "../../adalConfig";
import { IAudience } from "../../models/viewmodels/audiences/IAudience";
import { IAddAudience } from "../../models/viewmodels/audiences/IAddAudience";
import { IUpdateAudience } from "../../models/viewmodels/audiences/IUpdateAudience";

// --== RESET ==--
export const resetShouldClosePanel = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: AudiencesActionTypes.RESET_SHOULD_CLOSE_PANEL,
		});
	};
};
export const resetError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: AudiencesActionTypes.RESET_ERROR,
		});
	};
};
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: AudiencesActionTypes.RESET_SUCCESS,
		});
	};
};

// --== Fetch Groups ==--
export const fetchGroups = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchGroupsRequest());
	};
};

const fetchGroupsRequest: ActionCreator<ThunkAction<Promise<any>, AudiencesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchGroupsStarted());

		try {
			const fetchGroups = async (nextLink?: string, previousResults?: Group[]) => {
				const urlToUse = nextLink
					? nextLink
					: `https://graph.microsoft.com/v1.0/groups?$top=999&$filter=securityEnabled eq true`;
				const response: Response = await adalGraphFetch(fetch, urlToUse, {});

				if (response) {
					// If the reponse code is not positive, throw an error.
					if (response.status !== 200) {
						return -1;
					}

					let jsonResponse = await response.json();

					// Create a new list or use the previousResults if available. Add the jsonResponse.value to this list.
					let fetchedGroups: Group[] = previousResults ? previousResults : [];
					fetchedGroups = fetchedGroups.concat(jsonResponse.value);

					// If there is a nextLink, execute the function again.
					if (jsonResponse["@odata.nextLink"]) {
						return fetchGroups(jsonResponse["@odata.nextLink"], fetchedGroups);
					} else {
						return fetchedGroups;
					}
				}
			};

			const result = await fetchGroups();
			if (result === -1 || result === undefined) {
				dispatch(fetchGroupsFailure("error"));
				return;
			} else {
				dispatch(fetchGroupsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchGroupsFailure("error"));
		}
	};
};

const fetchGroupsStarted = () => ({
	type: AudiencesActionTypes.FETCH_GROUPS_STARTED,
});

const fetchGroupsSuccess = (groups: Group[]) => ({
	type: AudiencesActionTypes.FETCH_GROUPS_SUCCESS,
	payload: {
		groups,
	},
});

const fetchGroupsFailure = (errorMessage: string | object) => ({
	type: AudiencesActionTypes.FETCH_GROUPS_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch Audience ==--
export const fetchAudiences = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchAudiencesRequest());
	};
};

const fetchAudiencesRequest: ActionCreator<ThunkAction<Promise<any>, AudiencesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchAudiencesStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/audiences`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchAudiencesFailure(response.statusText));
					return;
				}

				const result: IAudience[] = await response.json();

				dispatch(fetchAudiencesSuccess(result));
			}
		} catch (e) {
			dispatch(fetchAudiencesFailure("error"));
		}
	};
};

const fetchAudiencesStarted = () => ({
	type: AudiencesActionTypes.FETCH_AUDIENCES_STARTED,
});

const fetchAudiencesSuccess = (audiences: IAudience[]) => ({
	type: AudiencesActionTypes.FETCH_AUDIENCES_SUCCESS,
	payload: {
		audiences: audiences,
	},
});

const fetchAudiencesFailure = (errorMessage: string | object) => ({
	type: AudiencesActionTypes.FETCH_AUDIENCES_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== add Audience ==--
export const addAudience = (newAdience: IAddAudience) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addAudienceRequest(newAdience));
	};
};

const addAudienceRequest: ActionCreator<ThunkAction<Promise<any>, AudiencesState, null, any>> = (
	newAdience: IAddAudience
) => {
	return async (dispatch: Dispatch) => {
		dispatch(addAudienceStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/audiences`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(newAdience),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 201) {
					dispatch(addAudienceFailure(response.statusText));
					return;
				}

				const result: IAudience = await response.json();
				dispatch(addAudienceSuccess(result));
			}
		} catch (e) {
			dispatch(addAudienceFailure("error"));
		}
	};
};

const addAudienceStarted = () => ({
	type: AudiencesActionTypes.ADD_AUDIENCES_STARTED,
});

const addAudienceSuccess = (addedAudience: IAudience) => ({
	type: AudiencesActionTypes.ADD_AUDIENCES_SUCCESS,
	payload: {
		addedAudience,
	},
});

const addAudienceFailure = (errorMessage: string | object) => ({
	type: AudiencesActionTypes.ADD_AUDIENCES_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== update Audience ==--
export const updateAudience = (updatedAudience: IUpdateAudience) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateAudienceRequest(updatedAudience));
	};
};

const updateAudienceRequest: ActionCreator<ThunkAction<Promise<any>, AudiencesState, null, any>> = (
	updatedAudience: IUpdateAudience
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateAudienceStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/audiences`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updatedAudience),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(updateAudienceFailure(response.statusText));
					return;
				}

				const result: IAudience = await response.json();
				dispatch(updateAudienceSuccess(result));
			}
		} catch (e) {
			dispatch(updateAudienceFailure("error"));
		}
	};
};

const updateAudienceStarted = () => ({
	type: AudiencesActionTypes.UPDATE_AUDIENCES_STARTED,
});

const updateAudienceSuccess = (updatedAudience: IAudience) => ({
	type: AudiencesActionTypes.UPDATE_AUDIENCES_SUCCESS,
	payload: {
		updatedAudience,
	},
});

const updateAudienceFailure = (errorMessage: string | object) => ({
	type: AudiencesActionTypes.UPDATE_AUDIENCES_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Delete Audience ==--
export const deleteAudience = (id: number) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(deleteAudienceRequest(id));
	};
};

const deleteAudienceRequest: ActionCreator<ThunkAction<Promise<any>, AudiencesState, null, any>> = (id: number) => {
	return async (dispatch: Dispatch) => {
		dispatch(deleteAudienceStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/audiences/${id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(deleteAudienceFailure(response.statusText));
					return;
				}

				const result: boolean = await response.json();
				if (result) {
					dispatch(deleteAudienceSuccess(id));
				} else {
					dispatch(deleteAudienceFailure(response.statusText));
				}
			}
		} catch (e) {
			dispatch(deleteAudienceFailure("error"));
		}
	};
};

const deleteAudienceStarted = () => ({
	type: AudiencesActionTypes.DELETE_AUDIENCES_STARTED,
});

const deleteAudienceSuccess = (deletedAudienceId: number) => ({
	type: AudiencesActionTypes.DELETE_AUDIENCES_SUCCESS,
	payload: {
		deletedAudienceId,
	},
});

const deleteAudienceFailure = (errorMessage: string | object) => ({
	type: AudiencesActionTypes.DELETE_AUDIENCES_FAILURE,
	payload: {
		errorMessage,
	},
});
