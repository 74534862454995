export const themeOrange = {
	defaultFontStyle: { fontFamily: "https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700" },
	palette: {
		themePrimary: "#e3940b",
		themeLighterAlt: "#fefaf4",
		themeLighter: "#fbedd4",
		themeLight: "#f7ddb0",
		themeTertiary: "#eebc66",
		themeSecondary: "#e6a026",
		themeDarkAlt: "#cc850a",
		themeDark: "#ac7009",
		themeDarker: "#7f5306",
		neutralLighterAlt: "#faf9f8",
		neutralLighter: "#f3f2f1",
		neutralLight: "#edebe9",
		neutralQuaternaryAlt: "#e1dfdd",
		neutralQuaternary: "#d0d0d0",
		neutralTertiaryAlt: "#c8c6c4",
		neutralTertiary: "#a19f9d",
		neutralSecondary: "#605e5c",
		neutralPrimaryAlt: "#3b3a39",
		neutralPrimary: "#323130",
		neutralDark: "#201f1e",
		black: "#000000",
		white: "#ffffff",
	},
};
