import React from "react";
import "./navbarBase.css";

const NavbarBase = props => {
  return (
    <section id="navbar" className="navbar">
      <div className="navbar-area-wrapper">{props.children}</div>
    </section>
  );
};

export default NavbarBase;
