import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react";

interface BreadCrumbPath {
	key: string;
	text: string;
}

export interface BreadCrumbProps {
	path: BreadCrumbPath[];
}

const BreadCrumb = (props: BreadCrumbProps & RouteComponentProps) => {
	const _navigateToPage = (path) => {
		props.history.push(`/${path}`);
	};

	const _mapPropsToItems = (): IBreadcrumbItem[] => {
		// append isCurrentItem if it's the last item in the array. otherwise make sure the link works.
		return props.path.map((item, i) => {
			return props.path.length - 1 === i
				? { ...item, isCurrentItem: true }
				: { ...item, onClick: () => _navigateToPage(item.key) };
		});
	};

	const items = _mapPropsToItems();

	return <Breadcrumb className="breadcrumb" items={items} />;
};

export const BreadCrumbComponent = withRouter(BreadCrumb);
