export const array_move = (arr: any, old_index: any, new_index: any) => {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
};

// usage (desc):    const [name] = obj.sort(sortArray([property]))
// usage (asc):     const [name] = obj.sort(sortArray([property])).reverse();
export const sortArray = (property: string) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return (a: any, b: any) => {
		var result = a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
		return result * sortOrder;
	};
};

// usage (desc):    const [name] = obj.sort(sortArray([property]))
// usage (asc):     const [name] = obj.sort(sortArray([property])).reverse();
export const sortArrayIgnoreCase = (property: string) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return (a: any, b: any) => {
		var result =
			a[property].toLowerCase() < b[property].toLowerCase()
				? 1
				: a[property].toLowerCase() > b[property].toLowerCase()
				? -1
				: 0;
		return result * sortOrder;
	};
};

// usage:           const [name] = objectToArray([obj]);
export const objectToArray = (obj: any) => {
	var result = Object.keys(obj).map((key) => {
		return obj[key];
	});

	return result;
};
