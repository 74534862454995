import { adalOneApiFetch } from "../../adalConfig";
import { ISubscription, SubscriptionStatus } from "@one/core";

export const CheckTenantSubscriptionStatus = async (): Promise<SubscriptionStatus> => {
	try {
		const response: Response = await adalOneApiFetch(
			fetch,
			`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/checkSubscription`,
			{}
		);

		const jsonResponse: ISubscription = await response.json();
		if (response.status === 200) {
			if (jsonResponse.currentStatus === SubscriptionStatus.Active) {
				return SubscriptionStatus.Active;
			}
		}

		if (jsonResponse.subscriptionType === null) {
			return SubscriptionStatus.None;
		} else if (new Date(jsonResponse.endDate) < new Date() || new Date(jsonResponse.startDate) > new Date()) {
			return SubscriptionStatus.Inactive;
		} else {
			return SubscriptionStatus.Error;
		}
	} catch (e) {
		console.log("error", e);
		return SubscriptionStatus.Error;
	}
};
