export enum PermissionCodes {
	//One for Teams
	ManageTeams = "Manage_Teams",
	ManageInformationManagementTasks = "Manage_InformationManagementTasks",
	ManageDestructionLists = "Manage_DestructionLists",
	ManageTeamTypes = "Manage_TeamTypes",

	//One for Events
	ManageEvents = "Manage_Events",
	CreateEvents = "Create_Events",

	// One for You / Portal management
	ManageAddresses = "Manage_Addresses",
	ManageAnnouncements = "Manage_Announcements",
	ManageShortcuts = "Manage_Shortcuts",
	ManageVacancies = "Manage_Vacancies",
	ManageNews = "Manage_News",
	ManageSocial = "Manage_Social",
	ManageStartupMessages = "Manage_StartupMessages",
	ManageAdvertisements = "Manage_Advertisements",

	//One Admin Portal
	ViewTenants = "View_Tenants", //Special permission!
	ViewApplicationReleases = "View_ApplicationReleases",
	ManageApplicationReleases = "Manage_ApplicationReleases", //Special permission!
	ManageJobs = "Manage_Jobs", //Special permission!
	ManageLookupList = "Manage_LookupLists",
	ManageUsers = "Manage_Users",
	ManageTaxonomy = "Manage_Taxonomy",
	ManageAudiences = "Manage_Audiences",
	ManageApplicationSettings = "Manage_Application_Settings",
	ManageMailTemplates = "Manage_MailTemplates",
	ViewUsageReporting = "ViewUsageReporting",
	ManageEventsSettings = "Manage_Events_Settings",
	ManageTenantSettings = "Manage_Tenant_Settings",
	ManageTeamsSettings = "Manage_Teams_Settings",
	ManageOneForYouSettings = "Manage_OneForYou_Settings",
	ViewRolePermissions = "View_RolePermissions", //Special permission!

	// One for Media
	ManageOneForMedia = "Manage_OneForMedia",
	ManageFeedback_OneForMedia = "Manage_OneForMedia_Feedback",

	// One Quality Management
	ManageOneQualityManagement = "Manage_OneQualityManagement",
	ManageFeedback_OneQualityManagement = "Manage_OneQualityManagement_Feedback",
	View_OneQualityManagement_UsageReporting = "View_OneQualityManagement_UsageReporting",

	//One for Reporting
	ManageReportingPortalPages = "Manage_ReportingPortal_Pages",
	ManageReportingPortalSettings = "Manage_ReportingPortal_Settings",
}
