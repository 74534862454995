import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { ApplicationsResponse } from "../../models/responseModels/tenant/ApplicationsResponse";
import { TenantResponse } from "../../models/responseModels/tenant/TenantResponse";

export enum TenantActionTypes {
	FETCH_APPLICATIONS_STARTED = "FETCH_APPLICATIONS_STARTED",
	FETCH_APPLICATIONS_SUCCESS = "FETCH_APPLICATIONS_SUCCESS",
	FETCH_APPLICATIONS_FAILURE = "FETCH_APPLICATIONS_FAILURE",

	FETCH_TENANTS_STARTED = "FETCH_TENANTS_STARTED",
	FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS",
	FETCH_TENANTS_FAILURE = "FETCH_TENANTS_FAILURE",
}

export type TenantState = Readonly<{
	isFetchingApplications: boolean;
	applications: ApplicationsResponse[];

	isFetchingTenants: boolean;
	tenants: TenantResponse[];

	success: ISuccessState;
	error: IErrorState;
}>;
