import * as React from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

export interface ICreateUserProfileProps {
	onCancel: () => void;
	onAddUserProfile: () => void;
	isLoading: boolean;
}

const CreateUserProfile = (props: WrappedComponentProps & ICreateUserProfileProps) => {
	const { intl } = props;
	return (
		<div className="create-user-profile-container">
			<p>
				<FormattedMessage
					id="users.modal.user.not.exists"
					defaultMessage="Deze gebruiker is niet bekend binnen One, wilt u deze toevoegen?"
				/>
			</p>
			<div>
				<PrimaryButton
					text={intl.formatMessage({
						id: "users.modal.user.not.exists.add",
						defaultMessage: "Voeg gebruikersprofiel toe",
					})}
					onClick={props.onAddUserProfile}
					disabled={props.isLoading}
				/>
				<DefaultButton
					text={intl.formatMessage({
						id: "users.modal.user.not.exists.cancel",
						defaultMessage: "Annuleer",
					})}
					onClick={props.onCancel}
					disabled={props.isLoading}
				/>
			</div>
		</div>
	);
};

export const CreateUserProfileComponent = injectIntl(CreateUserProfile);
