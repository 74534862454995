import * as React from "react";
import "../../themes/themeOrange.scss";

export interface IThemeProviderOrangeProps {
	children?: React.ReactNode;
}

export const ThemeProviderOrange = (props: IThemeProviderOrangeProps) => {
	return (
		<div id="one-web-theme" className="one-web-theme-orange">
			{props.children}
		</div>
	);
};
