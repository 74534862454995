import * as React from "react";
import "../../themes/themeGreen.scss";

export interface IThemeProviderGreenProps {
	children?: React.ReactNode;
}

export const ThemeProviderGreen = (props: IThemeProviderGreenProps) => {
	return (
		<div id="one-web-theme" className="one-web-theme-green">
			{props.children}
		</div>
	);
};
