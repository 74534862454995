import * as React from "react";
import "../../themes/themeRed.scss";

export interface IThemeProviderRedProps {
	children?: React.ReactNode;
}

export const ThemeProviderRed = (props: IThemeProviderRedProps) => {
	return (
		<div id="one-web-theme" className="one-web-theme-red">
			{props.children}
		</div>
	);
};
