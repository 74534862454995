import { IOneUser, IPermission, IRole, IRoleAssignment, IRolePermissionsOveriew, ISlicePiece, SlicePieceStatus } from "@one/core";
import { IRoleEnrichedOneUser } from "../../models/interfaces/IRoleEnrichedOneUser";

export enum RolesActionTypes {
	FETCH_ROLES_STARTED = "FETCH_ROLES_STARTED",
	FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS",
	FETCH_ROLES_FAILED = "FETCH_ROLES_FAILED",

	FETCH_PERMISSIONS_FOR_ROLE_STARTED = "FETCH_PERMISSIONS_FOR_ROLE_STARTED",
	FETCH_PERMISSIONS_FOR_ROLE_SUCCESS = "FETCH_PERMISSIONS_FOR_ROLE_SUCCESS",
	FETCH_PERMISSIONS_FOR_ROLE_FAILED = "FETCH_PERMISSIONS_FOR_ROLE_FAILED",
	RESET_FETCH_PERMISSIONS_FOR_ROLE_STATUS = "RESET_FETCH_PERMISSIONS_FOR_ROLE_STATUS",

	SET_ROLE_MODAL_ROLE = "SET_ROLE_MODAL_ROLE",
	FETCH_ROLE_ASSIGNMENTS_STARTED = "FETCH_ROLE_ASSIGNMENTS_STARTED",
	FETCH_ROLE_ASSIGNMENTS_SUCCESS = "FETCH_ROLE_ASSIGNMENTS_SUCCESS",
	FETCH_ROLE_ASSIGNMENTS_FAILED = "FETCH_ROLE_ASSIGNMENTS_FAILED",

	SEARCH_USERS_FOR_ROLE_STARTED = "SEARCH_USERS_FOR_ROLE_STARTED",
	SEARCH_USERS_FOR_ROLE_SUCCESS = "SEARCH_USERS_FOR_ROLE_SUCCESS",
	SEARCH_USERS_FOR_ROLE_FAILED = "SEARCH_USERS_FOR_ROLE_FAILED",
	RESET_SEARCH_USERS_FOR_ROLE = "RESET_SEARCH_USERS_FOR_ROLE",

	ADD_ROLE_TO_USER_STARTED = "ADD_ROLE_TO_USER_STARTED",
	ADD_ROLE_TO_USER_SUCCESS = "ADD_ROLE_TO_USER_SUCCESS",
	ADD_ROLE_TO_USER_FAILED = "ADD_ROLE_TO_USER_FAILED",
	RESET_ADD_ROLE_TO_USER_STATUS = "RESET_ADD_ROLE_TO_USER_STATUS",

	REMOVE_ROLE_TO_USER_STARTED = "REMOVE_ROLE_TO_USER_STARTED",
	REMOVE_ROLE_TO_USER_SUCCESS = "REMOVE_ROLE_TO_USER_SUCCESS",
	REMOVE_ROLE_TO_USER_FAILED = "REMOVE_ROLE_TO_USER_FAILED",
	RESET_REMOVE_ROLE_TO_USER_STATUS = "RESET_REMOVE_ROLE_TO_USER_STATUS",

	FETCH_ROLE_PERMISSIONS_OVERVIEW_STARTED = "FETCH_ROLE_PERMISSIONS_OVERVIEW_STARTED",
	FETCH_ROLE_PERMISSIONS_OVERVIEW_SUCCESS = "FETCH_ROLE_PERMISSIONS_OVERVIEW_SUCCESS",
	FETCH_ROLE_PERMISSIONS_OVERVIEW_FAILED = "FETCH_ROLE_PERMISSIONS_OVERVIEW_FAILED",

	RESET_ROLE_MODAL = "RESET_ROLE_MODAL",
}

export type RolesState = Readonly<{
	roles: ISlicePiece<IRole[] | null>;
	roleModal: {
		role: IRole | null;
		roleAssignments: ISlicePiece<IRoleAssignment[]>;
		permissions: ISlicePiece<IPermission[]>;
		userSearchResult: ISlicePiece<IRoleEnrichedOneUser[]>;
		addRoleToUserStatus: SlicePieceStatus;
		removeRoleFromUserStatus: SlicePieceStatus;
	};
	rolePermissionsOverview: ISlicePiece<IRolePermissionsOveriew>;
}>;
