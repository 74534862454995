const TB = 1000000000000;
const GB = 1000000000;
const MB = 1000000;
const KB = 1000;

export const fileSizeConverter = (byteSize: number, decimals: number) => {
    let unit;
    let i;
    let remainder;

    // Get the whole number, and the remainder for the
    // decimals later on.
    if (byteSize > TB) {
        unit = "TB";
        i = Math.floor(byteSize / TB);
        remainder = byteSize - i * TB;
    } else if (byteSize > GB) {
        unit = "GB";
        i = Math.floor(byteSize / GB);
        remainder = byteSize - i * GB;
    } else if (byteSize > MB) {
        unit = "MB";
        i = Math.floor(byteSize / MB);
        remainder = byteSize - i * MB;
    } else if (byteSize > KB) {
        unit = "KB";
        i = Math.floor(byteSize / KB);
        remainder = byteSize - i * KB;
    } else {
        unit = "bytes";
        remainder = byteSize;

        return remainder.toString() + " " + unit;
    }

    if (decimals === 0) {
        return i.toString() + " " + unit;
    }

    // Now we calculate the eventual missing leading
    // zeroes to pad up if needed.
    let width;
    if (remainder > GB) {
        width = 12;
    } else if (remainder > MB) {
        width = 9;
    } else if (remainder > KB) {
        width = 6;
    } else {
        width = 3;
    }

    // Fill up leading zeroes if they are missing
    let remainderString = remainder.toString();

    for (let y = remainderString.length; y < width; y++) {
        remainderString = "0" + remainderString;
    }

    // Check to prevent unwanted behaviour
    if (decimals > remainderString.length) {
        decimals = remainderString.length;
    }

    return i.toString() + "." + remainderString.substr(0, decimals) + " " + unit;
};
