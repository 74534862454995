import React from "react";
import "./commandBarBase.css";
import { CommandBar } from "@fluentui/react";

const CommandBarBase = ({ items }) => {
  const itemsWithClass = items.map(item => {
    return {
      ...item,
      className: "commandbar-button"
    };
  });

  return (
    <section id="commandbar-wrapper" className="commandbar-wrapper">
      <CommandBar className="commandbar-fabric" items={itemsWithClass}></CommandBar>
    </section>
  );
};

export default CommandBarBase;
