import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { TaxonomyActionTypes, TaxonomyState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";
import { ITaxonomy } from "../../models/responseModels/taxonomy/ITaxonomy";
import { IUpdateTerm } from "../../models/viewmodels/taxonomy/IUpdateTerm";
import { ITerm } from "../../models/responseModels/taxonomy/ITerm";
import { IAddTerm } from "../../models/viewmodels/taxonomy/IAddTerm";
import { IUpdateTermSet } from "../../models/viewmodels/taxonomy/IUpdateTermSet";
import { ITermSet } from "../../models/responseModels/taxonomy/ITermSet";
import { IAddTermSet } from "../../models/viewmodels/taxonomy/IAddTermSet";
import { ITermGroup } from "../../models/responseModels/taxonomy/ITermGroup";
import { IUpdateTermGroup } from "../../models/viewmodels/taxonomy/IUpdateTermGroup";

// --== RESET ==--
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: TaxonomyActionTypes.RESET_SUCCESS,
		});
	};
};
export const resetError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: TaxonomyActionTypes.RESET_ERROR,
		});
	};
};

// --== Fetch Taxonomy ==--
export const fetchTaxonomy = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchTaxonomyRequest());
	};
};

const fetchTaxonomyRequest: ActionCreator<ThunkAction<
	Promise<any>,
	TaxonomyState,
	null,
	any
>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchTaxonomyStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(fetchTaxonomyFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(fetchTaxonomySuccess(result));
			}
		} catch (e) {
			dispatch(fetchTaxonomyFailure("error"));
		}
	};
};

const fetchTaxonomyStarted = () => ({
	type: TaxonomyActionTypes.FETCH_TAXONOMY_STARTED,
});

const fetchTaxonomySuccess = (response: ITaxonomy) => ({
	type: TaxonomyActionTypes.FETCH_TAXONOMY_SUCCESS,
	payload: response,
});

const fetchTaxonomyFailure = (errorMessage: string | object) => ({
	type: TaxonomyActionTypes.FETCH_TAXONOMY_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Add Term ==--
export const addTerm = (termToAdd: IAddTerm) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addTermRequest(termToAdd));
	};
};

const addTermRequest: ActionCreator<ThunkAction<Promise<any>, TaxonomyState, null, any>> = (
	termToAdd: IAddTerm
) => {
	return async (dispatch: Dispatch) => {
		dispatch(addTermStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/term`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(termToAdd),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 201) {
					dispatch(addTermFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(addTermSuccess(result));
			}
		} catch (e) {
			dispatch(addTermFailure("error"));
		}
	};
};

const addTermStarted = () => ({
	type: TaxonomyActionTypes.ADD_TERM_STARTED,
});

const addTermSuccess = (response: ITerm) => ({
	type: TaxonomyActionTypes.ADD_TERM_SUCCESS,
	payload: response,
});

const addTermFailure = (errorMessage: string | object) => ({
	type: TaxonomyActionTypes.ADD_TERM_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update Term ==--
export const updateTerm = (updatedTerm: IUpdateTerm) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateTermRequest(updatedTerm));
	};
};

const updateTermRequest: ActionCreator<ThunkAction<Promise<any>, TaxonomyState, null, any>> = (
	updatedTerm: IUpdateTerm
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateTermStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/term`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updatedTerm),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(updateTermFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateTermSuccess(result));
			}
		} catch (e) {
			dispatch(updateTermFailure("error"));
		}
	};
};

const updateTermStarted = () => ({
	type: TaxonomyActionTypes.UPDATE_TERM_STARTED,
});

const updateTermSuccess = (response: ITerm) => ({
	type: TaxonomyActionTypes.UPDATE_TERM_SUCCESS,
	payload: response,
});

const updateTermFailure = (errorMessage: string | object) => ({
	type: TaxonomyActionTypes.UPDATE_TERM_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update TermSet ==--
export const addTermSet = (addTermSet: IAddTermSet) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addTermSetRequest(addTermSet));
	};
};

const addTermSetRequest: ActionCreator<ThunkAction<Promise<any>, TaxonomyState, null, any>> = (
	addTermSet: IUpdateTermSet
) => {
	return async (dispatch: Dispatch) => {
		dispatch(addTermSetStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termset`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(addTermSet),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 201) {
					dispatch(addTermSetFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(addTermSetSuccess(result));
			}
		} catch (e) {
			dispatch(addTermSetFailure("error"));
		}
	};
};

const addTermSetStarted = () => ({
	type: TaxonomyActionTypes.ADD_TERMSET_STARTED,
});

const addTermSetSuccess = (response: ITermSet) => ({
	type: TaxonomyActionTypes.ADD_TERMSET_SUCCESS,
	payload: response,
});

const addTermSetFailure = (errorMessage: string | object) => ({
	type: TaxonomyActionTypes.ADD_TERMSET_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update TermSet ==--
export const updateTermSet = (updatedTermSet: IUpdateTermSet) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateTermSetRequest(updatedTermSet));
	};
};

const updateTermSetRequest: ActionCreator<ThunkAction<Promise<any>, TaxonomyState, null, any>> = (
	updatedTermSet: IUpdateTermSet
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateTermSetStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termset`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updatedTermSet),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(updateTermSetFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateTermSetSuccess(result));
			}
		} catch (e) {
			dispatch(updateTermSetFailure("error"));
		}
	};
};

const updateTermSetStarted = () => ({
	type: TaxonomyActionTypes.UPDATE_TERMSET_STARTED,
});

const updateTermSetSuccess = (response: ITermSet) => ({
	type: TaxonomyActionTypes.UPDATE_TERMSET_SUCCESS,
	payload: response,
});

const updateTermSetFailure = (errorMessage: string | object) => ({
	type: TaxonomyActionTypes.UPDATE_TERMSET_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update TermGroup ==--
export const updateTermGroup = (updatedTermGroup: IUpdateTermGroup) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateTermGroupRequest(updatedTermGroup));
	};
};

const updateTermGroupRequest: ActionCreator<ThunkAction<Promise<any>, TaxonomyState, null, any>> = (
	updatedTermGroup: IUpdateTermGroup
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateTermGroupStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/taxonomy/termgroup`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updatedTermGroup),
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(updateTermGroupFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateTermGroupSuccess(result));
			}
		} catch (e) {
			dispatch(updateTermGroupFailure("error"));
		}
	};
};

const updateTermGroupStarted = () => ({
	type: TaxonomyActionTypes.UPDATE_TERMGROUP_STARTED,
});

const updateTermGroupSuccess = (response: ITermGroup) => ({
	type: TaxonomyActionTypes.UPDATE_TERMGROUP_SUCCESS,
	payload: response,
});

const updateTermGroupFailure = (errorMessage: string | object) => ({
	type: TaxonomyActionTypes.UPDATE_TERMGROUP_FAILURE,
	payload: {
		errorMessage,
	},
});
