import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ErrorTypes } from "../../../../../models/enums";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { UserResponse } from "../../../../../models/responseModels/user/UserResponse";
import { deleteUser, resetError } from "../../../../../store/users/actions";
import { ConfirmPanel } from "../../../../reusable/confirmPanelComponent/ConfirmPanelComponent";

export interface IUserActionsProps {
	user: UserResponse;
}

const UserActionsTab = (props: WrappedComponentProps & IUserActionsProps) => {
	const dispatch = useDispatch();
	const usersSlice = useSelector((state: IApplicationState) => state.usersSlice);

	const [confirmPanelIsOpen, setConfirmPanelIsOpen] = useState(false);

	return (
		<>
			<div className="user-modal-content" onClick={() => setConfirmPanelIsOpen(true)}>
				<div className="user-modal-action">
					<PrimaryButton iconProps={{ iconName: "Delete" }} />
					<div>
						<h3>
							<FormattedMessage
								id="users.modal.actions.deleteUser"
								defaultMessage="Verwijder gebruiker"
							/>
						</h3>
						<p>
							<FormattedMessage
								id="users.modal.actions.deleteUser.description"
								defaultMessage="Via deze actie is het mogelijk om de gebruiker binnen One te verwijderen."
							/>
						</p>
					</div>
				</div>
			</div>

			<ConfirmPanel
				isOpen={confirmPanelIsOpen}
				onConfirm={() => dispatch(deleteUser(props.user))}
				onCancel={() => {
					setConfirmPanelIsOpen(false);
					if (usersSlice.error.type === ErrorTypes.OnDelete) {
						dispatch(resetError());
					}
				}}
				headerText={props.intl.formatMessage({
					id: "users.delete.panel.headerText",
					defaultMessage: "Verwijder gebruiker",
				})}
				contentTitle={`${props.intl.formatMessage({
					id: "users.delete.panel.contentTitle",
					defaultMessage: "Gebruiker:",
				})} ${props.user.name}`}
				contentDescription={props.intl.formatMessage({
					id: "users.delete.panel.contentDescription",
					defaultMessage:
						"Weet u zeker dat u deze gebruiker en alle bijbehorende gegevens wilt verwijderen?",
				})}
				errorMessage={
					usersSlice.error.type === ErrorTypes.OnDelete
						? props.intl.formatMessage({
								id: "users.delete.error.onDelete",
								defaultMessage:
									"Er ging iets mis bij het verwijderen van deze gebruiker.",
						  })
						: undefined
				}
			/>
		</>
	);
};

export const UserActionsTabComponent = injectIntl(UserActionsTab);
