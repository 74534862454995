import { ConnectionActionTypes, ConnectionState } from "./types";
import { SlicePieceStatus } from "@one/core";
import { SuccessTypes } from "../../models/enums/SuccessTypes";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { IConnectionResponse } from "../../models/responseModels/connection/IConnectionResponse";

const initialState: ConnectionState = {
	connections: {
		data: [],
		fetchStatus: SlicePieceStatus.None,
	},
	success: {
		type: SuccessTypes.None,
	},
	error: {
		type: ErrorTypes.None,
		message: "", //not using this one
	},
};

export function connectionReducer(state = initialState, action: { type: ConnectionActionTypes; payload: any }): ConnectionState {
	switch (action.type) {
		case ConnectionActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case ConnectionActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};

		//fetch
		case ConnectionActionTypes.FETCH_CONNECTION_BY_NAME_STARTED:
			return {
				...state,
				connections: {
					...state.connections,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case ConnectionActionTypes.FETCH_CONNECTION_BY_NAME_SUCCESS:
			let newData = [...state.connections.data];
			newData.push(action.payload);

			return {
				...state,
				connections: {
					...state.connections,
					fetchStatus: SlicePieceStatus.Success,
					data: newData,
				},
			};
		case ConnectionActionTypes.FETCH_CONNECTION_BY_NAME_FAILURE:
			return {
				...state,
				connections: {
					...state.connections,
					fetchStatus: SlicePieceStatus.Error,
				},
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: "",
				},
			};

		//update
		case ConnectionActionTypes.UPDATE_CONNECTION_BY_ID_STARTED:
			return {
				...state,
				connections: {
					...state.connections,
					//fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case ConnectionActionTypes.UPDATE_CONNECTION_BY_ID_SUCCESS:
			let newUpdateData = [...state.connections.data];
			let updatePayloadData = action.payload as IConnectionResponse;

			newUpdateData = newUpdateData.map((d) => {
				if (d.id === updatePayloadData.id)
					return {
						...d,
					};

				return d;
			});

			return {
				...state,
				connections: {
					...state.connections,
					//fetchStatus: SlicePieceStatus.Success,
					data: newUpdateData,
				},
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};
		case ConnectionActionTypes.UPDATE_CONNECTION_BY_ID_FAILURE:
			return {
				...state,
				connections: {
					...state.connections,
					//fetchStatus: SlicePieceStatus.Error,
				},
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		//Create
		case ConnectionActionTypes.CREATE_CONNECTION_STARTED:
			return {
				...state,
				connections: {
					...state.connections,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case ConnectionActionTypes.CREATE_CONNECTION_SUCCESS:
			let newCreateData = [...state.connections.data];
			let createPayloadData = action.payload as IConnectionResponse;
			newCreateData.push(createPayloadData);

			return {
				...state,
				connections: {
					...state.connections,
					data: newCreateData,
				},
				success: {
					type: SuccessTypes.OnCreate,
				},
			};
		case ConnectionActionTypes.CREATE_CONNECTION_FAILURE:
			return {
				...state,
				connections: {
					...state.connections,
				},
				error: {
					type: ErrorTypes.OnCreate,
					message: action.payload.errorMessage,
				},
			};

		default:
			return state;
	}
}
