import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState, applicationActionTypes } from "./types";
import { adalOneApiFetch } from "../../adalConfig";


export const fetchApplications = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(applicationsRequest());
	};
};

const applicationsRequest: ActionCreator<ThunkAction<Promise<any>, ApplicationState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(applicationsStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applications`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchApplicationsFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchApplicationsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchApplicationsFailure("error"));
		}
	};
};

const applicationsStarted = () => ({
	type: applicationActionTypes.FETCH_APPLICATIONS_STARTED
});

const fetchApplicationsSuccess = (applications: any) => ({

	type: applicationActionTypes.FETCH_APPLICATIONS_SUCCESS,
	payload: {
		applications
	}
});

const fetchApplicationsFailure = (errorMessage: string | object) => ({
	type: applicationActionTypes.FETCH_APPLICATIONS_FAILURE,
	payload: {
		errorMessage
	}
});
