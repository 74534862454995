import React, { useState, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";

import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { fetchPageViewssPerSite } from "../../../../../store/analytics/actions";
import {
	DetailsList,
	DetailsListLayoutMode,
	CheckboxVisibility,
	IDetailsListProps,
	IDetailsRowStyles,
	DetailsRow,
	IColumn,
	Dropdown,
	IDropdownOption,
	IDropdownStyles,
	PrimaryButton,
	DropdownMenuItemType,
} from "@fluentui/react";
import { sortArray } from "../../../../../common/helperFunctions/ObjectsAndArrayHelpers";
import { UniqueVisitorsPerPageChartComponent } from "../UniqueVisitorsPerPage/UniqueVisitorsPerPageChart";
import { delay } from "../../../../../common/helperFunctions/GeneralHelpers";

interface IDetailsListItem {
	name: number;
	siteTitle: string;
	viewCount: number;
}

const PageViewsPerSite = (props) => {
	const { intl } = props;
	const title = (
		<FormattedMessage id="usage.pageviewsPerSite.title" defaultMessage="Pagina weergaves" />
	);

	const dispatch = useDispatch();
	const audiencesSlice = useSelector((state: IApplicationState) => state.audiencesSlice);
	const analyticsSlice = useSelector((state: IApplicationState) => state.analyticsSlice);

	const [audienceIdsCSV, setAudienceIdsCSV] = useState<string>("");
	const [activeAudienceFilters, setActiveAudienceFilters] = useState<IDropdownOption[]>([]);
	const allOptionsTranslation = intl.formatMessage({
		id: "usage.allOptionsPlaceholder",
		defaultMessage: "Alle sites",
	});
	const dropdownStyles: Partial<IDropdownStyles> = {
		root: { marginLeft: "11px" },
		dropdown: { width: "100%" },
	};

	const [formattedPageViews, setFormattedPageViews] = useState([] as IDetailsListItem[]);
	const [uniqueSiteOptions, setuniqueSiteOptions] = useState([] as IDropdownOption[]);
	const [viewUniqueVisitorsAmountDays, setViewUniqueVisitorsAmountDays] = useState(30);

	const [showGraphForSiteMode, setShowGraphForSiteMode] = useState(false);
	const [graphForSiteUrl, setgGaphForSiteURl] = useState("");
	const [selectedSiteName, setSelectedSiteName] = useState<JSX.Element | string>(title);

	// we need the internal sitessubset to make sure that the page doesn't grow when selection a combobox option.
	// otherwise the dropdown will close itself.
	const [internalSitesSubset, setInternalSitesSubset] = useState([] as string[]);
	const [allSitesSelected, setallSitesSelected] = useState(false);

	const checkActiveState = (days: number) => {
		if (viewUniqueVisitorsAmountDays === days) {
			return "active-item";
		}
		return "";
	};

	const sevenDaysClasses = checkActiveState(7);
	const thirtyDaysClasses = checkActiveState(30);
	const ninetyDaysClasses = checkActiveState(90);

	// sets the data from the Sp format to an array of IDetailsListItem.
	const structureRows = () => {
		let columnByNameMap = {};
		analyticsSlice.pageViewsPerSite.tables[0].columns.map((column, i) => {
			return (columnByNameMap[i] = column.name);
		});

		// Loop through the row push an object with columnName: data in aggregatedData: {Host: *, USers: *, timestamp: *}
		const tempData: any = analyticsSlice.pageViewsPerSite.tables[0].rows.map((row, i) => {
			let obj = {};
			for (let i = 0; i < row.length; i++) {
				obj[columnByNameMap[i]] = row[i];
			}
			return obj;
		});

		setFormattedPageViews(tempData);
	};

	// create unique site list as dropdown options
	const calculateUniqueSites = () => {
		const uniqueSites: string[] = [];
		formattedPageViews.forEach((data) => {
			if (uniqueSites.indexOf(data["siteTitle"]) === -1) {
				uniqueSites.push(data["siteTitle"]);
				return;
			}
		});

		let uniqueSitesOption = uniqueSites.map((site) => {
			return { key: site, text: site };
		});

		const sortedSiteOptions: IDropdownOption[] = uniqueSitesOption
			.sort(sortArray("text"))
			.reverse();
		sortedSiteOptions.unshift({
			key: "divider_2",
			text: "-",
			itemType: DropdownMenuItemType.Divider,
		});
		sortedSiteOptions.unshift({ key: allOptionsTranslation, text: allOptionsTranslation });

		setuniqueSiteOptions(sortedSiteOptions);
	};

	useEffect(() => {
		dispatch(fetchPageViewssPerSite(viewUniqueVisitorsAmountDays, audienceIdsCSV));
	}, [dispatch, viewUniqueVisitorsAmountDays, allSitesSelected, audienceIdsCSV]);

	// sets the formattedData only once in the beginning of the component.
	useEffect(() => {
		if (
			analyticsSlice.pageViewsPerSite != null &&
			Object.keys(analyticsSlice.pageViewsPerSite).length !== 0 &&
			analyticsSlice.pageViewsPerSite.constructor === Object &&
			!analyticsSlice.pageViewsDataIsLoading
		) {
			if (analyticsSlice.pageViewsPerSite.tables != null) {
				structureRows();
			}
		}
	}, [analyticsSlice]); // eslint-disable-line react-hooks/exhaustive-deps

	// Once the sites are formatted, we get all the unique sites extracted for the dropdown.
	useEffect(() => {
		calculateUniqueSites();
	}, [formattedPageViews]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let newAudienceIdsCSV = "";
		for (let i = 0; i < activeAudienceFilters.length; i++) {
			const option = activeAudienceFilters[i];
			i > 0 ? (newAudienceIdsCSV += `,${option.key}`) : (newAudienceIdsCSV += option.key);
		}

		delay(
			() => {
				setAudienceIdsCSV(newAudienceIdsCSV);
			},
			newAudienceIdsCSV.length > 0 ? 1000 : 0
		);
	}, [activeAudienceFilters]);

	const determineDropDownOptions = (): IDropdownOption[] => {
		const options = audiencesSlice.audiences.map((audience) => {
			return {
				key: audience.id,
				text: audience.name !== null ? audience.name : audience.id.toString(),
			};
		});

		return options;
	};

	const onDropdownChange = (changedOption: IDropdownOption | undefined) => {
		if (changedOption === undefined) {
			return;
		}

		let newOptions = [...activeAudienceFilters];
		if (changedOption.selected) {
			newOptions.push(changedOption);
		} else {
			newOptions = newOptions.filter((option) => option.key !== changedOption.key);
		}

		setActiveAudienceFilters(newOptions);
	};

	let detailListColumns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({
				id: "usage.pageviewsPerSite.tableName",
				defaultMessage: "Naam",
			}),
			fieldName: "name",
			minWidth: 150,
			maxWidth: 250,
			isResizable: true,
		},
		{
			key: "siteTitle",
			name: intl.formatMessage({
				id: "usage.pageviewsPerSite.tableSiteTitle",
				defaultMessage: "Site",
			}),
			fieldName: "siteTitle",
			minWidth: 150,
			maxWidth: 150,
			isResizable: true,
			iconName: "Filter",
			iconClassName: "float-right",
			className: "column-full-width",
			headerClassName: "column-full-width",
			styles: { cellName: { width: "100%" } },
		},
		{
			key: "viewCount",
			name: intl.formatMessage({
				id: "usage.pageviewsPerSite.tableViewCount",
				defaultMessage: "Weergaven",
			}),
			fieldName: "viewCount",
			minWidth: 150,
			maxWidth: 150,
			isResizable: true,
		},
	];
	const _backToSiteOverview = () => {
		setShowGraphForSiteMode(false);
		setSelectedSiteName(title);
	};

	// dropdown on change event
	const _onChange = (
		event: React.FormEvent<HTMLDivElement>,
		item: IDropdownOption | undefined
	): void => {
		if (item) {
			// if the option all sites is selected, update the uniqueSiteOptions to contain all sites but alloptions
			if (item.key === allOptionsTranslation) {
				const allOptionsIsChecked =
					internalSitesSubset.indexOf(allOptionsTranslation) > -1 ? false : true;

				if (allOptionsIsChecked) {
					// set all sites to true and remove all other options from the internalSitesSubset except all sites.
					setallSitesSelected(true);
					setInternalSitesSubset([] as string[]);
					setInternalSitesSubset([allOptionsTranslation]);
				} else {
					// remove all subsites items if all sites gets unchecked.
					setallSitesSelected(false);
					setInternalSitesSubset([] as string[]);
				}
			} else {
				let prunedInternalSitesSubset: any = [...internalSitesSubset];
				// Check if all sites is in the array, if so remove it.
				if (prunedInternalSitesSubset.indexOf(allOptionsTranslation) !== -1) {
					prunedInternalSitesSubset = prunedInternalSitesSubset.filter(
						(site: { key: string; text: string }) => {
							if (site.text !== allOptionsTranslation) {
								return site.text;
							}
							return null;
						}
					);
				}
				// if the site is not in the array yet, add it.
				if (prunedInternalSitesSubset.indexOf(item.text) === -1) {
					prunedInternalSitesSubset.push(item.text);
					setInternalSitesSubset(prunedInternalSitesSubset);
					setallSitesSelected(false);
				} else {
					// remove item from array.
					setInternalSitesSubset(
						prunedInternalSitesSubset.filter((entry) => item.text !== entry)
					);
					setallSitesSelected(false);
				}
			}
		}
	};

	const openGraphForSite = (pageUrl: string, timeSpan: number, siteName: string) => {
		setShowGraphForSiteMode(true);
		setSelectedSiteName(siteName);

		let trimmedPageUrl = pageUrl;

		if (pageUrl.substr(0, pageUrl.length - 1) === "/") {
			trimmedPageUrl = encodeURIComponent(pageUrl.substr(0, pageUrl.lastIndexOf("/")));
		}

		setgGaphForSiteURl(trimmedPageUrl);
	};

	// returns the row with additional styling
	const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
		const customStyles: Partial<IDetailsRowStyles> = {};
		if (props) {
			if (props.itemIndex % 2 === 1) {
				customStyles.root = {
					backgroundColor: "#f9f9f9",
					paddingTop: "11px",
					cursor: "pointer",
				};
			} else {
				customStyles.root = {
					backgroundColor: "#fff",
					paddingTop: "11px",
					cursor: "pointer",
				};
			}

			// @ts-ignore
			return (
				<div onClick={() => openGraphForSite(props.item.url, 30, props.item.name)}>
					<DetailsRow {...props} styles={customStyles} />
				</div>
			);
		}
		return null;
	};

	const createDetailList = () => {
		let pageViewsToInclude;
		// if all sites is selected, we can return all pages without modification, otherwise filter out the pageviews for the selected sites.
		if (allSitesSelected) {
			pageViewsToInclude = formattedPageViews;
		} else {
			pageViewsToInclude = formattedPageViews.filter((pageView: IDetailsListItem) => {
				if (
					internalSitesSubset.length > 0 &&
					internalSitesSubset.indexOf(pageView.siteTitle) > -1
				) {
					return pageView;
				}
				return null;
			});
		}

		return (
			<div className="pageview-detailslist__filter-container">
				<DetailsList
					items={pageViewsToInclude}
					columns={detailListColumns}
					layoutMode={DetailsListLayoutMode.justified}
					checkboxVisibility={CheckboxVisibility.hidden}
					onRenderRow={_onRenderRow}
				/>
			</div>
		);
	};

	const pageViewsDetailList = createDetailList();

	let contentToShow;

	// if the user has not clicked on a site for more details
	if (!showGraphForSiteMode) {
		if (uniqueSiteOptions.length > 0 || internalSitesSubset.length > 0) {
			const table = internalSitesSubset.length > 0 ? pageViewsDetailList : null;

			contentToShow = (
				<React.Fragment>
					<div className="graphButtons-wrapper-small" style={{ marginBottom: "30px" }}>
						<div>&nbsp;</div>
						<div className="chart-filter-options">
							<div className="chart-audience-filter">
								<Dropdown
									placeholder={intl.formatMessage({
										id: "usage.uniqueVisitorsChart.audienceFiltersPlaceholder",
										defaultMessage: "Filter op doelgroepen",
									})}
									options={determineDropDownOptions()}
									multiSelect
									onChange={(e, option) => onDropdownChange(option)}
									onRenderTitle={() => (
										<div>
											<FormattedMessage
												id="usage.uniqueVisitorsChart.audienceFiltersText"
												defaultMessage="Doelgroepen"
											/>{" "}
											({activeAudienceFilters.length})
										</div>
									)}
								/>
							</div>
							<PrimaryButton
								text={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.pastSevenDaysButtonText",
									defaultMessage: "Afgelopen 7 dagen",
								})}
								className={sevenDaysClasses}
								onClick={() => setViewUniqueVisitorsAmountDays(7)}
							/>

							<PrimaryButton
								text={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.pastThirtyDaysButtonText",
									defaultMessage: "Afgelopen 30 dagen",
								})}
								className={thirtyDaysClasses}
								onClick={() => setViewUniqueVisitorsAmountDays(30)}
							/>

							<PrimaryButton
								text={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.pastNinetyDaysButtonText",
									defaultMessage: "Afgelopen 90 dagen",
								})}
								className={ninetyDaysClasses}
								onClick={() => setViewUniqueVisitorsAmountDays(90)}
							/>
						</div>
					</div>

					<div className="search-wrapper">
						<Dropdown
							className="dropdown-multi"
							placeholder="Filter sites"
							selectedKeys={internalSitesSubset}
							onChange={_onChange}
							multiSelect
							options={uniqueSiteOptions}
							styles={dropdownStyles}
						/>
					</div>
					{table}
				</React.Fragment>
			);
		} else {
			contentToShow = (
				<div className="loading-wrapper">
					<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
				</div>
			);
		}

		if (analyticsSlice.pageViewsDataErrorMessage !== "") {
			contentToShow = (
				<p className="error-message">
					<FormattedMessage
						id="general.something-went-wrong"
						defaultMessage="Er is iets fout gegaan."
					/>
				</p>
			);
		}
	} else {
		contentToShow = (
			<div className="site-graph-wrapper">
				<UniqueVisitorsPerPageChartComponent
					pageUrl={graphForSiteUrl}
					backToSiteSelection={_backToSiteOverview}
					parentTimeSpan={viewUniqueVisitorsAmountDays}
					setParentTimeSpan={setViewUniqueVisitorsAmountDays}
				/>
			</div>
		);
	}

	const content = (
		<section className="pageviews-table">
			<section>
				<h1>{selectedSiteName}</h1>
				{contentToShow}
			</section>
		</section>
	);

	return <React.Fragment>{content}</React.Fragment>;
};

export const PageViewsPerSiteComponent = injectIntl(PageViewsPerSite);
