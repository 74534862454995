// Framework & third party
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	IColumn,
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
	ActionButton,
	MessageBar,
	MessageBarType,
	Icon,
} from "@fluentui/react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import Loader from "react-loader-spinner";

// Models
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { IAudience } from "../../../models/viewmodels/audiences/IAudience";
import { ErrorTypes, SuccessTypes } from "../../../models/enums";

// Actions
import {
	fetchAudiences,
	resetError,
	deleteAudience,
	fetchGroups,
	resetSuccess,
	resetShouldClosePanel,
} from "../../../store/audiences/actions";

// Components
import { ConfirmPanel } from "../../reusable/confirmPanelComponent/ConfirmPanelComponent";
import { CreateAudiencePanelComponent } from "./createAudiencePanelComponent/CreateAudiencePanelComponent";
import { EditAudiencePanelComponent } from "./editAudiencePanelComponent/EditAudiencePanelComponent";
import { AudienceTypes } from "@one/core";

const Audiences = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const audiencesSlice = useSelector((state: IApplicationState) => state.audiencesSlice);

	const [createPanelIsOpen, setCreatePanelIsOpen] = useState(false);
	const [editPanelIsOpenFor, setEditPanelIsOpenFor] = useState<IAudience | null>(null);
	const [deletePanelIsOpenFor, setDeletePanelIsOpenFor] = useState<IAudience | null>(null);
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);

	const columns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({ id: "audiences.list.column.name", defaultMessage: "Naam" }),
			fieldName: "name",
			minWidth: 100,
			maxWidth: 125,
			isResizable: true,
		},
		{
			key: "audienceGroups",
			name: intl.formatMessage({
				id: "audiences.list.column.audienceGroups",
				defaultMessage: "Beveiligingsgroepen",
			}),
			fieldName: "audienceGroups",
			minWidth: 200,
			maxWidth: 500,
			isResizable: true,
		},
		{
			key: "type",
			name: "Type",
			fieldName: "type",
			minWidth: 100,
			maxWidth: 125,
			isResizable: true,
		},
		{
			key: "isActive",
			name: intl.formatMessage({
				id: "audiences.list.column.isActive",
				defaultMessage: "In gebruik",
			}),
			fieldName: "isActive",
			minWidth: 100,
			maxWidth: 125,
			isResizable: true,
		},
		{
			key: "actions",
			name: intl.formatMessage({
				id: "audiences.list.column.actions",
				defaultMessage: "Acties",
			}),
			fieldName: "actions",
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
		},
	];

	useEffect(() => {
		dispatch(fetchAudiences());
		dispatch(fetchGroups());
	}, [dispatch]);

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (audiencesSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
		}
	}, [dispatch, firstRenderOccured, audiencesSlice.success.type]);

	// Close any open panel when it should (after a create, an update, or a delete).
	useEffect(() => {
		if (audiencesSlice.shouldClosePanel) {
			if (createPanelIsOpen === true) setCreatePanelIsOpen(false);
			if (editPanelIsOpenFor !== null) setEditPanelIsOpenFor(null);
			if (deletePanelIsOpenFor !== null) setDeletePanelIsOpenFor(null);
			dispatch(resetShouldClosePanel());
		}
	}, [
		audiencesSlice.shouldClosePanel,
		createPanelIsOpen,
		editPanelIsOpenFor,
		deletePanelIsOpenFor,
		dispatch,
	]);

	const _renderItemColumn = (
		item: IAudience,
		index: number | undefined,
		column: IColumn | undefined
	) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = item[column.fieldName as keyof IAudience] as string;
		switch (column.key) {
			case "name":
				return (
					<div className="column-field">
						<span>{item.name}</span>
					</div>
				);
			case "audienceGroups":
				let groupsString = "";
				for (let i = 0; i < item.audienceGroups.length; i++) {
					const group = item.audienceGroups[i];
					groupsString += group.name;
					if (i !== item.audienceGroups.length - 1) {
						groupsString += ", ";
					}
				}
				return (
					<div className="column-field">
						<div className="selected-groups-container">
							<span>{groupsString}</span>
						</div>
					</div>
				);
			case "type":
				return (
					<div className="column-field column">
						{item.type === AudienceTypes.One ? (
							<span>One</span>
						) : (
							<span>SharePoint</span>
						)}
					</div>
				);
			case "isActive":
				return (
					<div className="column-field column-field__is-active">
						{item.isActive ? <Icon iconName="CheckMark" /> : null}
					</div>
				);
			case "actions":
				return (
					<div className="column-field column-field__action">
						<span onClick={() => setEditPanelIsOpenFor(item)}>
							{intl.formatMessage({ id: "general.edit", defaultMessage: "Bewerk" })}
						</span>
						<span>|</span>
						<span onClick={() => setDeletePanelIsOpenFor(item)}>
							{intl.formatMessage({
								id: "general.delete",
								defaultMessage: "Verwijder",
							})}
						</span>
					</div>
				);
			default:
				return <div className="column-field">{fieldContent}</div>;
		}
	};

	const renderAudiencePageContentUi = () => {
		if (audiencesSlice.isFetchingAudiences) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		return (
			<>
				<div className="audiences-new-button">
					<ActionButton
						iconProps={{ iconName: "Add" }}
						onClick={() => setCreatePanelIsOpen(true)}
					>
						{intl.formatMessage({
							id: "audiences.new.button",
							defaultMessage: "Doelgroep",
						})}
					</ActionButton>
				</div>
				<DetailsList
					items={audiencesSlice.audiences}
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={SelectionMode.none}
					columns={columns}
					onRenderItemColumn={(item, index, column) =>
						_renderItemColumn(item as IAudience, index, column)
					}
				/>
			</>
		);
	};

	const renderSuccessBarUi = () => {
		if (audiencesSlice.success.type !== SuccessTypes.None) {
			let message: JSX.Element | null = null;
			if (audiencesSlice.success.type === SuccessTypes.OnCreate) {
				message = (
					<FormattedMessage
						id="audiences.success.onCreate"
						defaultMessage="De doelgroep is succesvol aangemaakt."
					/>
				);
			} else if (audiencesSlice.success.type === SuccessTypes.OnUpdate) {
				message = (
					<FormattedMessage
						id="audiences.success.onUpdate"
						defaultMessage="De doelgroep is succesvol bijgewerkt."
					/>
				);
			} else if (audiencesSlice.success.type === SuccessTypes.OnDelete) {
				message = (
					<FormattedMessage
						id="audiences.success.onDelete"
						defaultMessage="De doelgroep is succesvol verwijderd."
					/>
				);
			}

			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					onDismiss={() => dispatch(resetSuccess())}
				>
					{message}
				</MessageBar>
			);
		}
	};
	const renderErrorBarUi = () => {
		if (audiencesSlice.error.type !== ErrorTypes.None) {
			let message: JSX.Element | null = null;
			if (audiencesSlice.error.type === ErrorTypes.OnInitialDataLoad) {
				message = (
					<FormattedMessage
						id="audiences.error.onInitialDataLoad"
						defaultMessage="Er ging iets mis bij het ophalen van de doelgroepen."
					/>
				);
			} else if (audiencesSlice.error.type === ErrorTypes.OnMetaDataLoad) {
				message = (
					<FormattedMessage
						id="audiences.error.OnMetaDataLoad"
						defaultMessage="Er ging iets mis bij het ophalen van de beveiligingsgroepen."
					/>
				);
			}

			return (
				<MessageBar
					messageBarType={MessageBarType.error}
					onDismiss={() => dispatch(resetError())}
				>
					{message}
				</MessageBar>
			);
		}
	};

	const successBarUi = renderSuccessBarUi();
	const errorBarUi = renderErrorBarUi();

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="audiences.navigation.item" defaultMessage="Doelgroepen" />
			</h1>
			{successBarUi}
			{errorBarUi}
			<div className="main-container">
				<div className="audiences-page">{renderAudiencePageContentUi()}</div>
			</div>

			<CreateAudiencePanelComponent
				isOpen={createPanelIsOpen}
				onClose={() => setCreatePanelIsOpen(false)}
			/>
			<EditAudiencePanelComponent
				isOpen={editPanelIsOpenFor !== null}
				onClose={() => setEditPanelIsOpenFor(null)}
				item={editPanelIsOpenFor}
			/>
			<ConfirmPanel
				isOpen={deletePanelIsOpenFor !== null}
				errorMessage={
					audiencesSlice.error.type === ErrorTypes.OnDelete
						? intl.formatMessage({
								id: "audiences.error.onDelete",
								defaultMessage:
									"Deze waarde is momenteel in gebruik. Verwijder alle referenties naar deze waarde om deze te verwijderen.",
						  })
						: undefined
				}
				contentDescription={intl.formatMessage({
					id: "audiences.panel.delete.contentDescription",
					defaultMessage:
						"Deze waarde kan alleen worden verwijderd wanneer deze op geen enkele plek wordt gebruikt. Indien u verder gebruik van deze waarde wilt voorkomen kunt u deze waarde op 'inactief' zetten in het bewerk paneel.",
				})}
				contentTitle={intl.formatMessage({
					id: "audiences.panel.delete.contentTitle",
					defaultMessage: "Weet u zeker dat u deze waarde wilt verwijderen?",
				})}
				headerText={
					deletePanelIsOpenFor?.name ||
					intl.formatMessage({ id: "general.delete", defaultMessage: "Verwijder" })
				}
				onCancel={() => {
					if (audiencesSlice.error.type !== ErrorTypes.None) {
						dispatch(resetError());
					}
					setDeletePanelIsOpenFor(null);
				}}
				onConfirm={() => {
					if (deletePanelIsOpenFor !== null) {
						dispatch(deleteAudience(deletePanelIsOpenFor.id));
					}
				}}
			/>
		</section>
	);
};

export const AudiencesComponent = injectIntl(Audiences);
