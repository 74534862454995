import { JobsActionTypes, JobsState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums";

const initialState: JobsState = {
	jobDefinitions: [],
	selectedJob: null,

	isFetchingDefinitions: false,
	isCreatingJobSchedule: false,
	isUpdatingJobSchedule: false,

	selectedTenant: null,

	error: {
		message: "",
		type: ErrorTypes.None,
	},
	success: {
		type: SuccessTypes.None,
	},
};

export function jobsReducer(
	state = initialState,
	action: { type: JobsActionTypes; payload: any }
): JobsState {
	switch (action.type) {
		// Fetch Jobs
		case JobsActionTypes.FETCH_JOB_DEFINITIONS_STARTED:
			return {
				...state,
				isFetchingDefinitions: true,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case JobsActionTypes.FETCH_JOB_DEFINITIONS_SUCCESS:
			return {
				...state,
				isFetchingDefinitions: false,
				jobDefinitions: action.payload.jobDefinitions,
			};
		case JobsActionTypes.FETCH_JOB_DEFINITIONS_FAILURE:
			return {
				...state,
				isFetchingDefinitions: false,
				error: {
					message: "",
					type: ErrorTypes.OnInitialDataLoad,
				},
			};

		// Fetch SCHEDULE for job
		case JobsActionTypes.FETCH_JOB_SCHEDULE_BY_ID_STARTED:
			const jobDefinitionScheduleStart = state.jobDefinitions.map((j) => {
				if (j.id === action.payload.id) {
					return { ...j, isFetchingSchedule: true };
				}
				return { ...j };
			});

			return {
				...state,
				jobDefinitions: jobDefinitionScheduleStart,
			};
		case JobsActionTypes.FETCH_JOB_SCHEDULE_BY_ID_SUCCESS:
			const jobDefinitionScheduleSuccess = state.jobDefinitions.map((j) => {
				if (j.id === action.payload.jobSchedule.jobDefinition.id) {
					return {
						...j,
						isFetchingSchedule: false,
						schedule: action.payload.jobSchedule,
					};
				}
				return { ...j };
			});
			return {
				...state,
				jobDefinitions: jobDefinitionScheduleSuccess,
			};
		case JobsActionTypes.FETCH_JOB_SCHEDULE_BY_ID_FAILURE:
			const jobDefinitionScheduleFail = state.jobDefinitions.map((j) => {
				if (j.id === action.payload.id) {
					return { ...j, isFetchingSchedule: false };
				}
				return { ...j };
			});

			return {
				...state,
				jobDefinitions: jobDefinitionScheduleFail,
			};

		// Fetch Jobs HISTORY
		case JobsActionTypes.FETCH_JOB_HISTORY_STARTED:
			if (state.selectedJob !== null) {
				return {
					...state,
					selectedJob: {
						...state.selectedJob,
						isFetchingHistory: true,
						hasFetchHistoryError: false,
					},
					error: {
						message: "",
						type: ErrorTypes.None,
					},
				};
			}
			return state;
		case JobsActionTypes.FETCH_JOB_HISTORY_SUCCESS:
			if (state.selectedJob !== null) {
				return {
					...state,
					selectedJob: {
						...state.selectedJob,
						isFetchingHistory: false,
						history: action.payload.history,
					},
					error: {
						message: "",
						type: ErrorTypes.None,
					},
				};
			}
			return state;
		case JobsActionTypes.FETCH_JOB_HISTORY_FAILURE:
			if (state.selectedJob !== null) {
				return {
					...state,
					selectedJob: {
						...state.selectedJob,
						isFetchingHistory: false,
						hasFetchHistoryError: true,
					},
					error: {
						message: "",
						type: ErrorTypes.OnDetailDataLoad,
					},
				};
			}
			return state;

		// Create Job Schedule
		case JobsActionTypes.CREATE_JOB_SCHEDULE_STARTED:
			if (state.selectedJob !== null) {
				return {
					...state,
					isCreatingJobSchedule: true,
					error: {
						message: "",
						type: ErrorTypes.None,
					},
				};
			}
			return state;
		case JobsActionTypes.CREATE_JOB_SCHEDULE_SUCCESS:
			if (state.selectedJob !== null) {
				return {
					...state,
					isCreatingJobSchedule: false,
					selectedJob: {
						...state.selectedJob,
						jobDefinition: {
							...state.selectedJob.jobDefinition,
							schedule: action.payload.jobSchedule,
						},
					},
					error: {
						message: "",
						type: ErrorTypes.None,
					},
					success: {
						type: SuccessTypes.OnCreate,
					},
				};
			}
			return state;
		case JobsActionTypes.CREATE_JOB_SCHEDULE_FAILURE:
			if (state.selectedJob !== null) {
				return {
					...state,
					isCreatingJobSchedule: false,
					error: {
						message: "",
						type: ErrorTypes.OnCreate,
					},
				};
			}
			return state;

		// Update Job Schedule
		case JobsActionTypes.UPDATE_JOB_SCHEDULE_STARTED:
			if (state.selectedJob !== null) {
				return {
					...state,
					isUpdatingJobSchedule: true,
					error: {
						message: "",
						type: ErrorTypes.None,
					},
				};
			}
			return state;
		case JobsActionTypes.UPDATE_JOB_SCHEDULE_SUCCESS:
			if (state.selectedJob !== null) {
				return {
					...state,
					isUpdatingJobSchedule: false,
					selectedJob: {
						...state.selectedJob,
						jobDefinition: {
							...state.selectedJob.jobDefinition,
							schedule: action.payload.jobSchedule,
						},
					},
					error: {
						message: "",
						type: ErrorTypes.None,
					},
					success: {
						type: SuccessTypes.OnUpdate,
					},
				};
			}
			return state;
		case JobsActionTypes.UPDATE_JOB_SCHEDULE_FAILURE:
			if (state.selectedJob !== null) {
				return {
					...state,
					isUpdatingJobSchedule: false,
					error: {
						message: "",
						type: ErrorTypes.OnUpdate,
					},
				};
			}
			return state;

		case JobsActionTypes.SET_SELECTED_JOB:
			return { ...state, selectedJob: action.payload.selectedJob };

		case JobsActionTypes.CLEAR_SELECTED_JOB_ERROR:
			if (state.selectedJob !== null) {
				return {
					...state,
					selectedJob: { ...state.selectedJob, hasFetchHistoryError: false },
				};
			}
			return state;

		case JobsActionTypes.SET_SELECTED_TENANT:
			return {
				...state,
				selectedTenant: action.payload.selectedTenant,
			};

		// --==
		case JobsActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case JobsActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};
		default:
			return state;
	}
}
