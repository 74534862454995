import { Dropdown, MessageBar } from "@fluentui/react";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { TeamTypeSettingsGuestAccess, TeamTypeSettingsPrivacyLevel } from "@one/core";
import { FormikProps } from "formik";
import { ITeamTypeFormValues } from "../../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";

interface AccessibilityProps {
	formikProps: FormikProps<ITeamTypeFormValues>;
	allowToAddUser?: boolean;
}

export default function Accessibility(props: AccessibilityProps) {
	const intl = useIntl();

	const { values, setFieldValue } = props.formikProps;

	const guestAccessOptions = [
		{
			key: TeamTypeSettingsGuestAccess.DeterminedByApplicant,
			text: intl.formatMessage({
				id: "settings.teams.types.determinedByApplicant",
				defaultMessage: "Vrij te bepalen",
			}),
		},
		{
			key: TeamTypeSettingsGuestAccess.NoAccess,
			text: intl.formatMessage({
				id: "settings.teams.types.noAccess",
				defaultMessage: "Geen toegang",
			}),
		},
		{
			key: TeamTypeSettingsGuestAccess.Access,
			text: intl.formatMessage({
				id: "settings.teams.types.access",
				defaultMessage: "Wel toegang",
			}),
		},
	];

	return <>
		<Dropdown
			disabled={props.allowToAddUser === false}
			required
			label={intl.formatMessage({
				id: "settings.teams.types.guestsHaveAccess",
				defaultMessage: "Guests have access",
			})}
			className="ilx-text-field-small"
			defaultSelectedKey={values.settings.guestAccess}
			options={guestAccessOptions}
			onChange={(event, option) => {
				if (option) {
					setFieldValue("settings", {
						...values.settings,
						guestAccess: option.key
					});
				}
			}}
		/>

		<Dropdown
			required
			label="Privacyniveau"
			className="ilx-text-field-small"
			defaultSelectedKey={values.settings.privacyLevel}
			options={[
				{
					key: TeamTypeSettingsPrivacyLevel.Private,
					text: "Privé"
				},
				{
					key: TeamTypeSettingsPrivacyLevel.Public,
					text: "Publiek"
				},
				{
					key: TeamTypeSettingsPrivacyLevel.Open,
					text: "Vrij te bepalen"
				}
			]}
			onChange={(event, option) => {
				if (option) {
					setFieldValue("settings", {
						...values.settings,
						privacyLevel: option.key
					});
				}
			}}
		/>

		{props.allowToAddUser === false && (
			<MessageBar styles={{ root: { marginTop: "6px" } }}>
				<FormattedMessage id="settings.teams.types.guestAccessNotEnabled" defaultMessage="Toegang voor gasten niet ingeschakeld" />
			</MessageBar>
		)}
	</>;
}