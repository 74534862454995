// Framework & third-party
import * as Yup from "yup";
import * as React from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import {
	ActionButton,
	Icon,
	MessageBar,
	MessageBarType,
	PrimaryButton,
	Separator,
	TextField,
	Toggle,
	TooltipHost,
} from "@fluentui/react";

// Store
import { resetSuccess, updateTerm, resetError } from "../../../../../store/taxonomy/actions";

// Models
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { ITerm } from "../../../../../models/responseModels/taxonomy/ITerm";
import { IUpdateTerm } from "../../../../../models/viewmodels/taxonomy/IUpdateTerm";
import { ErrorTypes, SuccessTypes } from "../../../../../models/enums";
import { TaxonomyItem } from "../../../../../models/enums/TaxonomyITems";
import { useEffect, useState } from "react";
import { AddTermPanelComponent } from "../selectedTermSetComponent/addTermPanelComponent/AddTermPanelComponent";
import { ITermSet } from "../../../../../models/responseModels/taxonomy/ITermSet";

export interface ISelectedGroupProps {
	term: ITerm;
	shouldOpenPanel?: boolean;
	termSetIsEditable: boolean;
	resetShouldOpenPanel: () => void;
}

const SelectedTerm = (props: WrappedComponentProps & ISelectedGroupProps) => {
	const { term, termSetIsEditable, intl } = props;
	const dispatch = useDispatch();
	const taxonomySlice = useSelector((state: IApplicationState) => state.taxonomySlice);

	const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
	const [termSet, setTermSet] = useState<ITermSet | null>(null);

	const [
		isLastActiveTerm_And_TermSetIsRequired,
		setIsLastActiveTerm_And_TermSetIsRequired,
	] = React.useState(false);

	useEffect(() => {
		if (props.shouldOpenPanel) {
			setPanelIsOpen(true);
			props.resetShouldOpenPanel();
		}
	}, [props]);

	useEffect(() => {
		if (
			taxonomySlice.success.type === SuccessTypes.OnCreate &&
			taxonomySlice.success.forType === TaxonomyItem.Term &&
			panelIsOpen
		) {
			setPanelIsOpen(false);
		}
	}, [taxonomySlice.success.type, taxonomySlice.success.forType, panelIsOpen]);

	React.useEffect(() => {
		if (taxonomySlice.taxonomy.termGroups) {
			taxonomySlice.taxonomy.termGroups.forEach((termGroup) => {
				for (let i = 0; i < termGroup.termSets.length; i++) {
					const termSetWithTerms = termGroup.termSets[i];

					if (termSetWithTerms !== undefined && termSetWithTerms.id === term.termSetId) {
						if (termSetWithTerms !== undefined) {
							setTermSet(termSetWithTerms);
							let newValue = false;

							// If the termSet is required and this is the last remaining active term
							if (termSetWithTerms.isRequired && term.isActive && term.isSelectable) {
								if (
									termSetWithTerms.terms.filter(
										(t) => t.isActive && t.isSelectable
									).length <= 1
								) {
									newValue = true;
								}
							}

							setIsLastActiveTerm_And_TermSetIsRequired(newValue);
						}
					}
				}
			});
		}
	}, [term, taxonomySlice.taxonomy.termGroups]);

	React.useEffect(() => {
		dispatch(resetSuccess());
	}, [dispatch, term]);

	const renderMessageBar = () => {
		if (
			taxonomySlice.success.type !== SuccessTypes.None &&
			taxonomySlice.success.forType === TaxonomyItem.Term
		) {
			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					onDismiss={() => dispatch(resetSuccess())}
					styles={{
						root: {
							margin: "-4rem -4rem 0.8rem -4rem",
							width: "calc(100% + 8rem)",
						},
					}}
				>
					{taxonomySlice.success.type === SuccessTypes.OnUpdate && (
						<FormattedMessage
							id="taxonomy.termSet.updated.success"
							defaultMessage="De termenset is successlvol geüpdatet"
						/>
					)}
					{taxonomySlice.success.type === SuccessTypes.OnCreate && (
						<FormattedMessage
							id="taxonomy.term.created.success"
							defaultMessage="De term is succesvol aangemaakt."
						/>
					)}
				</MessageBar>
			);
		}
		if (!termSetIsEditable) {
			return (
				<MessageBar
					messageBarType={MessageBarType.info}
					styles={{
						root: {
							margin: "-4rem -4rem 0.6rem -4rem",
							width: "calc(100% + 8rem)",
						},
					}}
				>
					<FormattedMessage
						id="taxonomy.term.notEditable"
						defaultMessage="Deze term is niet bewerkbaar."
					/>
				</MessageBar>
			);
		}
		if (
			taxonomySlice.error.type !== ErrorTypes.None &&
			taxonomySlice.error.forType === TaxonomyItem.Term
		) {
			if (taxonomySlice.error.type === ErrorTypes.OnUpdate) {
				return (
					<MessageBar
						messageBarType={MessageBarType.error}
						onDismiss={() => dispatch(resetError())}
						styles={{
							root: {
								margin: "-4rem -4rem 0.6rem -4rem",
								width: "calc(100% + 8rem)",
							},
						}}
					>
						<FormattedMessage
							id="taxonomy.term.updated.failure"
							defaultMessage="Er ging iets mis bij het updaten van de term."
						/>
					</MessageBar>
				);
			}
		}
		if (
			taxonomySlice.success.type !== SuccessTypes.None &&
			taxonomySlice.success.forType === TaxonomyItem.Term
		) {
			if (taxonomySlice.success.type === SuccessTypes.OnUpdate) {
				return (
					<MessageBar
						messageBarType={MessageBarType.success}
						onDismiss={() => dispatch(resetSuccess())}
						styles={{
							root: {
								margin: "-4rem -4rem 0.8rem -4rem",
								width: "calc(100% + 8rem)",
							},
						}}
					>
						<FormattedMessage
							id="taxonomy.term.updated.success"
							defaultMessage="De termenset is successlvol geüpdatet."
						/>
					</MessageBar>
				);
			}
		}
	};

	return (
		<div className="taxonomy-card-wrapper taxonomy-selected-wrapper ">
			{renderMessageBar()}
			<div className="taxonomy-selected">
				<div className="taxonomy-selected-topbar">
					<div className="taxonomy-selected-topbar-title">
						<h3>{term.name}</h3>
						<Icon iconName="Tag" />
					</div>
					<div>
						<ActionButton
							iconProps={{ iconName: "add" }}
							text="Nieuwe term"
							onClick={() => {
								dispatch(resetSuccess());
								setPanelIsOpen(true);
							}}
							disabled={!termSetIsEditable}
						/>
					</div>
				</div>
				<Separator />

				<div className="ilx-form-wrapper">
					<Formik
						enableReinitialize
						initialValues={{
							name: term.name !== null ? term.name : "",
							isActive: term.isActive,
							isSelectable: term.isSelectable,
						}}
						onSubmit={(values) => {
							const requestBody = {
								id: term.id,
								name: values.name || "",
								isSelectable: values.isSelectable,
								isActive: values.isActive,
								termSetId: term.termSetId,
								parentTermId: term.parent?.id,
							} as IUpdateTerm;

							dispatch(updateTerm(requestBody));
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.min(
									2,
									intl.formatMessage({
										id: "taxonomy.validation.name.minTwoChars",
										defaultMessage:
											"De naam moet ten minste twee tekens lang zijn.",
									})
								)
								.required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
							} = props;

							return (
								<form onSubmit={handleSubmit}>
									<TextField
										id="name"
										label={intl.formatMessage({
											id: "taxonomy.term.name.label",
											defaultMessage: "Naam",
										})}
										required
										placeholder={intl.formatMessage({
											id: "taxonomy.term.name.placeholder",
											defaultMessage: "De naam van de term",
										})}
										type="text"
										disabled={!termSetIsEditable}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										errorMessage={errors.name}
										className={"ilx-text-field"}
									/>

									<Toggle
										checked={values.isSelectable}
										disabled={
											!termSetIsEditable ||
											isLastActiveTerm_And_TermSetIsRequired
										}
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="taxonomy.term.isSelectable"
														defaultMessage="Is selecteerbaar"
													/>
													{isLastActiveTerm_And_TermSetIsRequired && (
														<TooltipHost
															content={intl.formatMessage({
																id:
																	"taxonomy.term.isActive.lastActive.tooltip",
																defaultMessage:
																	"Het is niet mogelijk om deze term inactief te maken omdat de bovenliggende termenset verplicht is en er hierbij minstens één term in gebruik moet blijven.",
															})}
														>
															<Icon iconName="Info" />
														</TooltipHost>
													)}
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.term.isSelectable.description"
														defaultMessage="Deze term is te selecteren. Deze instelling heeft geen invloed op eventueel onderliggende termen."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={() =>
											setFieldValue("isSelectable", !values.isSelectable)
										}
									/>

									<Toggle
										checked={values.isActive}
										disabled={
											!termSetIsEditable ||
											isLastActiveTerm_And_TermSetIsRequired
										}
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="general.isActive"
														defaultMessage="In gebruik"
													/>
													{isLastActiveTerm_And_TermSetIsRequired && (
														<TooltipHost
															content={intl.formatMessage({
																id:
																	"taxonomy.term.isActive.lastActive.tooltip",
																defaultMessage:
																	"Het is niet mogelijk om deze term inactief te maken omdat de bovenliggende termenset verplicht is en er hierbij minstens één term in gebruik moet blijven.",
															})}
														>
															<Icon iconName="Info" />
														</TooltipHost>
													)}
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.term.isActive.description"
														defaultMessage="Deze term en eventueel onderliggenden termen zijn actief. Een inactieve term zorgt ervoor dat deze term en de onderliggende termen niet worden getoond in de picker."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) => {
											setFieldValue("isActive", checked);
										}}
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.save",
											defaultMessage: "Opslaan",
										})}
										type="submit"
										disabled={
											!termSetIsEditable ||
											taxonomySlice.isUpdatingOrCreating ||
											(Object.keys(errors).length !== 0 &&
												errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
			{termSet !== null && (
				<AddTermPanelComponent
					isOpen={panelIsOpen}
					onClose={() => setPanelIsOpen(false)}
					termSet={termSet}
					parentTerm={term}
				/>
			)}
		</div>
	);
};

export const SelectedTermComponent = injectIntl(SelectedTerm);
