export default {
	// --== General ==--
	"general.makeAChoice": "Select an option",
	"general.yes": "Yes",
	"general.no": "No",
	"general.done": "Done",
	"general.new": "New",
	"general.edit": "Edit",
	"general.editShort": "Edit",
	"general.create": "Create",
	"general.save": "Save",
	"general.delete": "Delete",
	"general.role": "Role",
	"general.roles": "Roles",
	"general.information": "Information",
	"general.notSpecified": "Not specified",
	"general.noResults": "No results",
	"general.actions": "Actions",
	"general.navigationHeader": "Manage",
	"general.overview": "Overview",
	"general.superadmin": "Super admin management",
	"general.close": "Close",
	"general.retry": "Retry",
	"general.something-went-wrong": "Something went wrong.",
	"general.isActive": "Is active",
	"general.download": "Download",
	"general.add": "Add",
	"general.remove": "Remove",
	"general.noAccess": "No access",
	"general.navigate": "Navigate",

	"general.validation.required": "This field is required.",

	// --== Pickers ==--
	"general.pickers.people.placeholder": "Search for people by name",
	"general.pickers.noResults": "No results",

	// --== Home ==--
	"home.navigation.item": "Home",
	"home.welcome": "Welcome",
	"home.subtext": "Navigate to one the pages to start managing.",

	// --== Settings ==--
	"settings.general": "Settings",
	"settings.save.success": "The setting has been saved successfully.",
	"settings.save.error": "Something went wrong with updating the settings.",

	"settings.consent.required.prov.engine":
		"In order to make use of any of these settings we need an admin consent for the use of the One Provisioning Engine. Give this consent if you have not done already:",
	"settings.consent.button": "Admin consent",

	"settings.card.tenant.title": "Tenant settings",
	"settings.card.tenant.description": "Change the tenant settings",
	"settings.card.teams.title": "One for Teams settings",
	"settings.card.teams.description": "Change the One for Teams settings",
	"settings.card.events.title": "One for Events settings",
	"settings.card.events.description": "Change the One for Events settings",
	"settings.card.you.title": "One for You settings",
	"settings.card.you.description": "Change the One for You settings",

	"settings.tenant.importUsers.text": "Enable user synchronisation",
	"settings.tenant.enableTeamProvisioning.text": "Enable Teams Provisioning",
	"settings.tenant.enableTeamSync.text": "Enable teams and channel synchronisation",
	"settings.tenant.language.text": "The language on tenant level",
	"settings.teams.showProvisioningForm.text": "Show provisioning form for new teams to users",
	"settings.teams.archivingChangeOwnersToMembers": "Change owners to members when archiving a team, resulting in SharePoint users being moved to the Visitors group",
	"settings.teams.language.text": "The language for One for Teams",
	"settings.teams.groupSettings": "Group settings",
	"settings.teams.groupSettings.description":
		"When requesting a team it is possible to specify whether or not guests are allowed. The setting below will have impact on the field for this in the request form.",
	"settings.teams.groupSettings.allowToAddGuests": "Allow To Add Guests",
	"settings.teams.groupSettings.defaultValue": "Default value",

	"settings.teams.privacy.header": "Teams synchronization settings",
	"settings.teams.privacy.description":
		"The One for Teams solution requires that the existing teams are periodically synchronized. You can specifiy how munch information is stored.",
	"settings.teams.privacy.option.noStorage": "Little",
	"settings.teams.privacy.option.noStorage.desc1": "Metadata of teams are stored.",
	"settings.teams.privacy.option.minimalStorage": "Some",
	"settings.teams.privacy.option.minimalStorage.desc1":
		"Stores information about the state of the team as well. This allows for a filter on the IsArchived property.",
	"settings.teams.privacy.option.optimalPerformance": "More",
	"settings.teams.privacy.option.optimalPerformance.desc1": "The title and description of a team are stored as well.",
	"settings.teams.privacy.option.optimalPerformance.desc2": "Up to 600% increased performance.",

	"settings.teams.types.header": "Team types",
	"settings.teams.types.add.button": "Add team type",
	"settings.teams.types.update": "Update team type",
	"settings.teams.types.column.name": "Name",
	"settings.teams.types.column.domain": "Domain",
	"settings.teams.types.column.isActive": "Is active",
	"settings.teams.types.column.templateId": "Template ID",
	"settings.teams.types.column.approvalRequired": "Requires approval",
	"settings.teams.types.column.defaultOwners": "Default owners",
	"settings.teams.types.column.actions": "Actions",
	"settings.teams.types.error.onUpdate": "Something went wrong with updating the team type.",
	"settings.teams.types.error.onCreate": "Something went wrong with creating the team type.",

	"settings.teams.types.owners.label": "Default owners",
	"settings.teams.types.owners.tooltip": "Users that are selected here will always be added as an owner when a new team is created.",

	"settings.you.topdesk.title": "Topdesk connections",
	"settings.you.topdesk.api.url": "API URL",
	"settings.you.topdesk.api.username": "API Username",
	"settings.you.topdesk.api.password": "API Password",

	"settings.you.teamtailor.title": "Teamtailor connections",
	"settings.you.teamtailor.api.key": "API Key",

	"settings.you.connections.update.success": "The information has been updated successfully.",
	"settings.you.connections.update.error": "Something went wrong updating the information.",
	"settings.you.connections.fetch.error": "Something went wrong retrieving the information.",

	"settings.events.dashboardUrl.label": "Event dashboard location",
	"settings.events.dashboardUrl.description": "Specify on which page shared events will be opened.",
	"settings.events.centraleMailbox.label": "Event Mailbox settings",
	"settings.events.centraleMailbox.description": "Specify the central mailbox email address.",	
	"settings.events.centraleMailbox.message": "Provide a valid email address.",
	"settings.events.centraleMailbox.ORG": "Use mailbox of the organizer (Default)",
	"settings.events.centraleMailbox.CMB": "Use a central mailbox",
	"settings.events.access.label": "Access",
	"settings.events.restrictEventCreation.description": "Restrict the creation of events to a specific group of users.",
	"settings.events.restrictEventCreation.option.allUsers": "All users",
	"settings.events.restrictEventCreation.option.adminOnly": "Administrators",

	// --== Taxonomy ==--
	"taxonomy.navigation.item": "Taxonomy",
	"taxonomy.term.new": "New term",
	"taxonomy.termSet.new": "New term set",
	"taxonomy.none.selected": "Select a taxonomy item to edit, or to create underlying items.",

	"taxonomy.term.name.label": "Name",
	"taxonomy.term.name.placeholder": "The name of the term",
	"taxonomy.term.name.isSelectable": "Is selectable",
	"taxonomy.term.isSelectable.description": "This term is selectable. This setting has no impact on underlying terms.",
	"taxonomy.term.name.isActive": "Is active",
	"taxonomy.term.isActive.lastActive.tooltip":
		"It is not possible to make this term inactive because the parent term set is required and there has to be at least one underlying term active.",
	"taxonomy.term.notEditable": "This term is not editable",

	"taxonomy.term.isActive.description":
		"This term and underlying terms are active. When this term is inactive, this term and the underlying terms are not displayed in the picker.",

	"taxonomy.termSet.name.label": "Name",
	"taxonomy.termSet.name.placeholder": "The name of the term",
	"taxonomy.termSet.isRequired.label": "Is required",
	"taxonomy.termSet.isRequired.description": "This term set is required to fill in.",
	"taxonomy.termSet.hasNoActiveTerms.tooltip": "It is not possible to make this term set required because there are no active terms.",
	"taxonomy.termSet.isActive.label": "Is active",
	"taxonomy.termSet.isActive.description": "This term set is active.",

	"taxonomy.termSet.allowMultipleValues.label": "Multi select",
	"taxonomy.termSet.allowMultipleValues.description": "Whether or not it is possible to select multiple terms.",
	"taxonomy.termSet.isReassignable.label": "Is reassignable",
	"taxonomy.termSet.isReassignable.description": "This term selected for this term set can be changed.",
	"taxonomy.termSet.notEditable": "This term set is not editable.",

	"taxonomy.term.created.success": "The term has been created successfully.",
	"taxonomy.term.created.failure": "Something went wrong with creating the term.",
	"taxonomy.term.updated.success": "The term has been updated successfully.",
	"taxonomy.term.updated.failure": "Something went wrong with updating the term.",

	"taxonomy.termSet.created.success": "The term set has been created successfully.",
	"taxonomy.termSet.created.failure": "Something went wrong with creating the term set.",
	"taxonomy.termSet.updated.success": "The term set has been updated successfully.",
	"taxonomy.termSet.updated.failure": "Something went wrong with updating the term set.",

	"taxonomy.group.name.label": "Name",
	"taxonomy.group.isTenantWide.label": "Is tenant wide",
	"taxonomy.group.isTenantWide.description": "This term group can be used throughout the entire organisation.",

	"taxonomy.validation.name.minTwoChars": "The name should be at least two characters long.",

	// --== Page not found ==--
	"notfound.headertext": "This page does not exist.",
	"notfound.subtext": "Navigate to a different page using the navigation links.",

	// --== Page not found ==--
	"unauthorized.headertext": "Access denied.",
	"unauthorized.subtext": "You are unauthorized to view this page, please contact your site administrator.",

	// --== Subscription ==--
	"subscription.inactive": "Your subscription has expired, please contact your administrator",
	"subscription.none": "You don't have a subscription for One.",
	"subscription.error": "Something went wrong while retrieving your subscription.",

	// --== AccountPanel ==--
	"account.panel.headertext": "My Account",
	"account.panel.myProfile": "My profile",
	"account.panel.logout": "Logout",

	// --== Look up lists ==--
	"lookup.navigation.item": "Look up lists",
	"lookup.value.new": "New",
	"lookup.list.column.name": "Value",
	"lookup.list.column.isActive": "Is active",
	"lookup.list.column.actions": "Actions",
	"lookup.list.action.edit": "Edit",
	"lookup.list.action.delete": "Delete",

	"lookup.panel.create.label.name": "Value",
	"lookup.panel.create.label.isActive": "Is active",
	"lookup.panel.create.placeholder.name": "Enter a value",
	"lookup.panel.create.validation.name.minTwoChars": "The value should be at least two characters long.",
	"lookup.panel.create.create.validation.name.minTwoChars": "The value should be at least two characters long.",

	"lookup.panel.delete.contentDescription":
		"This value can only be removed if isn't currently being used. If you wish to disable further use of this value you can also set this value to 'inactive' in the edit panel.",
	"lookup.panel.delete.contentTitle": "Are you sure you want to delete this value?",

	"lookup.success.onCreate": "has been successfully created.",
	"lookup.success.onUpdate": "has been successfully updated.",
	"lookup.success.onDelete": "has been successfully deleted.",

	"lookup.error.onDelete": "This value is being used. Remove all references to it if you wish to remove it.",

	// Roles
	"roles.navigation.item": "Roles",

	"roles.modal.tab.permissions": "Permissions",
	"roles.modal.tab.usersWithThisRole": "Users with this role",
	"roles.modal.tab.assignUsers": "Assign users",

	"roles.modal.permissions.list.name": "Name",

	"roles.modal.add.tooltip.alreadyExists": "User already has this role.",
	"roles.modal.add.message.success": "The role has been assigned to the user.",
	"roles.modal.add.message.error": "Could not assign the role to the user.",
	"roles.modal.remove.message.success": "The role has been removed from the user.",
	"roles.modal.remove.message.error": "Could not remove the role from the user.",

	// --== Users == --
	"users.navigation.item": "Users",

	"users.succesMessage.onDelete": "User has been deleted successfully",
	"users.successMessage.onCreate": "User has been created successfully",

	"users.search.placeholder": "Search users by name",
	"users.search.enterSearchTerm": "Enter a search term in order to begin.",
	"users.search.noSearchResults": "No users found that match the entered search term",

	"users.list.column.givenName": "Given name",
	"users.list.column.displayName": "Name",
	"users.list.column.surname": "Surname",
	"users.list.column.email": "Email address",
	"users.list.column.userPrincipalName": "UPN",
	"users.list.column.scope": "Scope",
	"users.list.column.scope.global": "Global",
	"users.list.column.jobTitle": "Job title",
	"users.list.column.department": "Department",
	"users.list.column.officeLocation": "Office location",
	"users.list.column.hasAdminRole": "Is admin",

	"users.delete.panel.headerText": "Delete user",
	"users.delete.panel.contentTitle": "User:",
	"users.delete.panel.contentDescription": "Are you sure you want to delete this user and all related data?",
	"users.delete.error.onDelete": "Something went wrong while trying to delete this user.",

	"users.modal.info.contact.header": "Contact information",
	"users.modal.info.contact.name": "Name",
	"users.modal.info.contact.email": "Email address",
	"users.modal.info.occupation.header": "Job information",
	"users.modal.info.occupation.jobTitle": "Job title",
	"users.modal.info.occupation.department": "Department",
	"users.modal.info.occupation.officeLocation": "Office location",

	"users.modal.load.error": "Something went wrong with retrieving the user.",
	"users.modal.user.not.exists": "This user is not known within One yet, do you want to add this user?",
	"users.modal.user.not.exists.add": "Add user",
	"users.modal.user.not.exists.cancel": "Cancel",

	"users.modal.actions.header": "Actions",
	"users.modal.actions.deleteUser": "Delete user",
	"users.modal.actions.deleteUser.description": "With this action it is possible to delete the user within One.",

	"users.modal.roles.add.info": "The role that will be added to the user will be scoped globally.",
	"users.modal.roles.name.label": "Role",
	"users.modal.roles.isAdmin.label": "Is admin role",
	"users.modal.roles.actions.label": "Actions",
	"users.modal.roles.actions.cannotRemoveOwnAdminRole": "You can't remove your own administrator role",
	"users.modal.roles.addRoles": "Assign globally scoped roles",
	"users.modal.roles.noRoles": "This user does not have any roles assigned yet.",
	// Role descriptions
	"users.modal.roles.address.text": "Manages the addresses within One for You.",
	"users.modal.roles.admin.text": "Has all permissions across the One applications.",
	"users.modal.roles.announcement.text": "Manages the announcements within One for You.",
	"users.modal.roles.event.text": "Manages the events within One for Events.",
	"users.modal.roles.shortcut.text": "Manages the shared shortcuts within One for You.",
	"users.modal.roles.team.text": "Manages the team requests that users submit,",
	"users.modal.roles.usage.text": "Has the permissions to view the usage statistics",
	"users.modal.roles.user.text": "Is a regular user without any special permissions",
	"users.modal.roles.vacancy.text": "Manages the (internal) vacancies within One for You.",
	"users.modal.roles.startup.text": "Manages the startup messages that are displayed within One for You",
	"users.modal.roles.taxonomy.text": "Manages the taxonomy used within the One applications.",
	"users.modal.roles.quality.text": "Manages handbooks and can view submitted feedback.",
	"users.modal.roles.media.text": "Manages One for Media and can view submitted feedback.",
	"users.modal.roles.reporting.text": "Manages One for Reporting.",
	"users.modal.roles.informationManager.text": "Executes information management related tasks.",
	"users.modal.roles.news.text": "Has the permissions to pin news items.",

	"users.modal.roles.add.description": "Click on a role to assign it to the selected user.",
	"users.modal.roles.add.availableRoles": "Available roles",
	"users.modal.roles.add.noAvailableRoles": "There are no roles available to assign to this user.",
	"users.modal.roles.remove.headerText": "Remove role",
	"users.modal.roles.remove.contentDescription": "Are you sure you want to remove the selected role from this user?",

	// --== Audiences ==--
	"audiences.navigation.item": "Audiences",

	"audiences.new.button": "Audience",

	"audiences.list.column.name": "Name",
	"audiences.list.column.audienceGroups": "Security groups",
	"audiences.list.column.isActive": "Is active",
	"audiences.list.column.actions": "Actions",

	"audiences.panel.create.header": "New audience",
	"audiences.panel.edit.header": "Edit audience",

	"audiences.panel.label.name": "Name",
	"audiences.panel.placeholder.name": "Enter a name",
	"audiences.panel.validation.name.minTwoChars": "The name should be at least two characters long.",
	"audiences.panel.label.audienceGroups": "Security groups",
	"audiences.panel.placeholder.audienceGroups": "Select one or more groups",
	"audiences.panel.label.isActive": "Is active",

	"audiences.panel.delete.contentDescription":
		"This value can only be removed if isn't currently being used. If you wish to disable further use of this value you can also set this value to 'inactive' in the edit panel.",
	"audiences.panel.delete.contentTitle": "Are you sure you want to delete this value?",

	"audiences.error.onDelete": "This value is being used. Remove all references to it if you wish to remove it.",
	"audiences.error.onCreate": "Something went wrong with creating the audience",
	"audiences.error.onUpdate": "Something went wrong with updating the audience",
	"audiences.error.onInitialDataLoad": "Something went wrong with fetching the audiences.",
	"audiences.error.OnMetaDataLoad": "Something went wrong with fetching the security groups.",

	"audiences.info.noSecurityGroups": "Could not find any security groups.",

	"audiences.success.onCreate": "The audience has been created successfully.",
	"audiences.success.onUpdate": "The audience has been updated successfully.",
	"audiences.success.onDelete": "The audience has been deleted successfully.",

	// --== Usage ==--
	"usage.navigation.item": "Usage statistics",
	"usage.uniqueVisitorsChart.visitorsPerDay": "Unique visitors per day",
	"usage.uniqueVisitorsChart.audienceFiltersPlaceholder": "Audience filtering",
	"usage.uniqueVisitorsChart.audienceFiltersText": "Audiences",
	"usage.uniqueVisitorsChart.pastSevenDaysButtonText": "7 days",
	"usage.uniqueVisitorsChart.pastThirtyDaysButtonText": "30 days",
	"usage.uniqueVisitorsChart.pastNinetyDaysButtonText": "90 days",
	"usage.uniqueVisitorsChart.usersText": "Users",
	"usage.uniqueVisitorsChart.xLabel": "Date",
	"usage.uniqueVisitorsChart.yLabel": "Users",
	"usage.uniqueVisitorsChart.averageUniqueVisitors": "Average unique visitors per day",

	"usage.uniqueVisitorsChartPerSite.noResults": "No results have been found.",
	"usage.uniqueVisitorsChartPerSite.uniqueUsersPerSite": "Unique visitors per site",
	"usage.uniqueVisitorsChartPerSite.uniqueUsersFor": "Unique visitors for ",
	"usage.uniqueVisitorsChartPerSite.backToSiteCollection": "Back to site selection",
	"usage.uniqueVisitorsChartPerSite.noData": "There is not enough data available.",
	"usage.usage.uniqueVisitorsChartPerSite.noDataAiLinkText": "Install the One for You Analytics App",
	"usage.search.placeholder": "Search sites by name",

	"usage.pageviewsPerSite.title": "Page views",
	"usage.pageviewsPerSite.tableName": "Name",
	"usage.pageviewsPerSite.tableSiteTitle": "Site",
	"usage.pageviewsPerSite.tableViewCount": "views",
	"usage.allOptionsPlaceholder": "All sites",

	"usage.download.navigation.label": "Usage information download",
	"usage.download.datepicker.label": "Month",
	"usage.download.datepicker.placeholder": "Select month",

	"usage.download.instructions.header": "One for You usage reports",
	"usage.download.instructions.description":
		"Alongside the standard reports that are available in this portal, we provide you the possibility to take a look at extensive reports and execute your own analysis using the Power BI desktop application. To make use of this, please follow the following steps:",
	"usage.download.instructions.list.one": "Download and install the Power BI desktop application.",
	"usage.download.instructions.list.two": "Download the Power BI report file.",
	"usage.download.instructions.list.three":
		"Download the usage report files by using the download button on the left. Download the desired information and store these files in one location.",
	"usage.download.instructions.list.four": "Open the Power BI report file in the Power BI Desktop application.",
	"usage.download.instructions.endDescription": "Further instructions can be found in the Power BI report file.",

	// Role permissions overview
	"rolePermissions.navigationItem.title": "Role permissions",

	// Release page translations
	"releases.navigationItem.title": "Release notes",
	"releases.createForm.title": "Title",
	"releases.createForm.body": "Body",
	"releases.createForm.versionNumber": "Version number",
	"releases.createForm.Application": "Application",
	"releases.createForm.Link": "Link",
	"releases.createForm.PublishingDate": "Publishing date",
	"releases.page.createNewRelease": "Create new release note",
	"releases.page.noReleaseNotesYet": "There are no release notes yet.",
	"releases.page.version": "Version:",
	"releases.page.releaseNoteAddedSuccessMessage": "You have added a new release note successfully.",
	"releases.page.releaseNoteAddedFailedMessage": "Something went wrong adding a release note, please try again.",
	"releases.page.updateRelease": "Update release",
	"releases.viewmore": "Show more",

	"releases.table.title": "Title",
	"releases.table.versionNumber": "Version number",
	"releases.table.displayDate": "Publishing date",
	"releases.table.application": "Application",

	"dateTimePicker.Months.January": "January",
	"dateTimePicker.Months.February": "February",
	"dateTimePicker.Months.March": "March",
	"dateTimePicker.Months.April": "April",
	"dateTimePicker.Months.May": "May",
	"dateTimePicker.Months.June": "June",
	"dateTimePicker.Months.July": "July",
	"dateTimePicker.Months.August": "August",
	"dateTimePicker.Months.September": "September",
	"dateTimePicker.Months.October": "October",
	"dateTimePicker.Months.November": "November",
	"dateTimePicker.Months.December": "December",

	"dateTimePicker.Months.short.January": "Jan",
	"dateTimePicker.Months.short.February": "Feb",
	"dateTimePicker.Months.short.March": "Mar",
	"dateTimePicker.Months.short.April": "Apr",
	"dateTimePicker.Months.short.May": "May",
	"dateTimePicker.Months.short.June": "Jun",
	"dateTimePicker.Months.short.July": "Jul",
	"dateTimePicker.Months.short.August": "Aug",
	"dateTimePicker.Months.short.September": "Sep",
	"dateTimePicker.Months.short.October": "Oct",
	"dateTimePicker.Months.short.November": "Nov",
	"dateTimePicker.Months.short.December": "Dec",

	"dateTimePicker.days.monday": "monday",
	"dateTimePicker.days.tuesday": "tuesday",
	"dateTimePicker.days.wednesday": "wednesday",
	"dateTimePicker.days.thrusday": "thursday",
	"dateTimePicker.days.friday": "friday",
	"dateTimePicker.days.saturday": "saturday",
	"dateTimePicker.days.sunday": "sunday",

	"dateTimePicker.days.short.monday": "M",
	"dateTimePicker.days.short.tuesday": "T",
	"dateTimePicker.days.short.wednesday": "W",
	"dateTimePicker.days.short.thrusday": "T",
	"dateTimePicker.days.short.friday": "F",
	"dateTimePicker.days.short.saturday": "S",
	"dateTimePicker.days.short.sunday": "S",

	"dateTimePicker.goToToday": "Go To Today",
	"dateTimePicker.prevMonthAriaLabel": "Go to previous month",
	"dateTimePicker.nextMonthAriaLabel": "Go to next month",
	"dateTimePicker.prevYearAriaLabel": "Go to previous year",
	"dateTimePicker.nextYearAriaLabel": "Go to next year",
	"dateTimePicker.closeButtonAriaLabel": "Close date picker",

	// Jobs
	"jobs.tenant.select.label": "Select tenant",

	"jobs.navigationItem.title": "Jobs",
	"jobs.def.card.lastRunTime": "Last run time",
	"jobs.def.card.interval": "Interval",
	"jobs.def.card.scheduleType": "Schedule type",

	"jobs.status.failed": "Failed",
	"jobs.status.paused": "Paused",
	"jobs.status.running": "Running",
	"jobs.status.succeeded": "Succeeded",

	"jobs.scheduleType.minute": "Minute",
	"jobs.scheduleType.houtly": "Hour",
	"jobs.scheduleType.daily": "Day",
	"jobs.scheduleType.weekly": "Week",
	"jobs.scheduleType.monthly": "Month",
	"jobs.scheduleType.yearly": "Year",
	"jobs.scheduleType.onetime": "Once",

	"job.history.title": "Run history",
	"job.history.no.history": "This job has no runs yet.",
	"job.history.error.fetch": "Could not retrieve job history.",

	"job.history.list.column.startTime": "Start time",
	"job.history.list.column.endTime": "End time",
	"job.history.list.column.status": "Status",
	"job.history.list.column.statusReason": "Status reason",

	"job.schedule.doesNotExist": "This job does not have schedule yet and therefore does not run yet.",
	"job.schedule.create.button.label": "Create job schedule",
	"job.schedule.edit.button.label": "Edit job schedule",
	"job.schedule.panel.create.header": "Create job schedule",
	"job.schedule.panel.update.header": "Update job schedule",
	"job.schedule.panel.isActive.label": "Is active",
	"job.schedule.panel.retry.label": "Should retry if job fails",
	"job.schedule.panel.scheduleType.label": "Schedule type",
	"job.schedule.panel.interval.label": "Interval",
	"job.schedule.panel.interval.tooltip": "This is only available for the schedule type 'Minute'.",

	"job.schedule.panel.success.onCreate": "The job schedule has been created.",
	"job.schedule.panel.success.onUpdate": "The job schedule has been updated.",
	"job.schedule.panel.error.onCreate": "Something went wrong with creating this job schedule.",

	// Mail templates
	"mailTemplates.navigation.item": "Mail templates",
	"mail.template.header": "Template",
	"mail.template.subject.label": "Subject",
	"mail.template.body.label": "Body",
	"mail.template.revertToDefault": "Revert to default",
	"mail.template.revertToDefault.error": "Something went wrong with reverting back to the default template.",
	"mail.template.variable.tooltip": "Drag me into place!",

	"mail.template.revert.confirm.title": "Are you sure you want to revert to the default template?",
	"mail.template.revert.confirm.description":
		"With this action the selected custom template will be deleted and the default template will be used again.",

	"mail.template.label.header.events": "Events",
	"mail.template.label.eventRegistrationSpareListMail": "Event registration spare list",
	"mail.template.label.eventRegistrationSeatAvailable": "Spare list - new seat available",
	"mail.template.label.organizerEventCalendarItem": "Calendar invite for organizer - Event",
	"mail.template.label.organizerEventTopicCalendarItem": "Calendar invite for organizer - Topic",
	"mail.template.label.header.other": "Other",
	"mail.template.label.feedbackMail": "New feedback item notification",

	"mail.template.variable.header.event": "Event",
	"mail.template.variable.header.organizer": "Organizer",
	"mail.template.variable.header.eventregistration": "Registration",
	"mail.template.variable.header.topic": "Topic",
	"mail.template.variable.header.attendee": "Attendee",

	"mail.template.variable.header.feedback": "Feedback",
	"mail.template.variable.header.sender": "Sender",

	"mail.template.variable.[EventDate]": "Date",
	"mail.template.variable.[EventLink]": "Link",
	"mail.template.variable.[EventLocation]": "Location",
	"mail.template.variable.[EventName]": "Name",
	"mail.template.variable.[EventDescriptionIntro]": "Intro text",
	"mail.template.variable.[EventDescription]": "Description",
	"mail.template.variable.[EventRegistrationAllergyDescription]": "Allergy description",
	"mail.template.variable.[EventRegistrationIsAttendingMeals]": "Is attending meals (yes/no)",
	"mail.template.variable.[EventRegistrationEmailAddress]": "Email",
	"mail.template.variable.[EventRegistrationJobTitle]": "Job title",
	"mail.template.variable.[EventRegistrationName]": "Name",
	"mail.template.variable.[EventRegistrationStatus]": "Status",
	"mail.template.variable.[EventRegistrationPhone]": "Phonenumber",
	"mail.template.variable.[TopicDate]": "Date",
	"mail.template.variable.[TopicEventName]": "Event name",
	"mail.template.variable.[TopicLink]": "Link",
	"mail.template.variable.[TopicLocation]": "Location",
	"mail.template.variable.[TopicName]": "Name",
	"mail.template.variable.[TopicDescription]": "Description",
	"mail.template.variable.[AttendeeGivenName]": "Given name",
	"mail.template.variable.[AttendeeFullName]": "Full name",
	"mail.template.variable.[AttendeeDepartment]": "Department",
	"mail.template.variable.[AttendeeEmailAddres]": "Email",
	"mail.template.variable.[OrganizerGivenName]": "Given name",
	"mail.template.variable.[OrganizerFullName]": "Full name",
	"mail.template.variable.[OrganizerDepartment]": "Department",
	"mail.template.variable.[OrganizerEmailAddress]": "Email",

	"mail.template.variable.[FeedbackDescription]": "Description",
	"mail.template.variable.[FeedbackType]": "Type",
	"mail.template.variable.[FeedbackSource]": "Source",
	"mail.template.variable.[FeedbackLink]": "Link",
	"mail.template.variable.[SenderGivenName]": "Given name",
	"mail.template.variable.[SenderFullName]": "Full name",
	"mail.template.variable.[SenderDepartment]": "Department",
	"mail.template.variable.[SenderEmailAddres]": "Email",
	"mail.template.variable.[SenderEmailAddress]": "Email",

	"mail.template.error.onCreateOrUpdate": "Something went wrong with saving the changes.",
	"mail.template.error.onDetailDataLoad": "Could not retrieve the mailing template",
	"mail.template.success.onCreateOrUpdate": "Changes saved.",
	"mail.template.success.onDelete": "Successfully reverted to the standard template.",

	//feedback
	"feedback.navigationItem.title": "Feedback",
	"feedback.page.noFeedbackYet": "No feedback items available",
	"feedback.filter.placeholder": "Show all feedback items",
	"feedback.table.actions.edit": "Edit",
	"feedback.table.actions.view": "View",
	"feedback.table.actions.delete": "Delete",
	"feedback.table.createDate": "Create date",
	"feedback.table.createdByUser": "By user",
	"feedback.table.status": "Status",
	"feedback.table.description": "Description",
	"feedback.table.assignedTo": "Assigned to",
	"feedback.table.application": "Application",
	"feedback.table.feedbackType": "Type",
	"feedback.panel.edit.label.filename": "File name",
	"feedback.panel.edit.label.contentTypeName": "Content type",
	"feedback.panel.edit.label.fileSize": "File size",
	"feedback.panel.edit.label.description": "Description",
	"feedback.panel.edit.placeholder.description": "Describe the feedback",
	"feedback.panel.edit.label.itemId": "Subject item (ID)",
	"feedback.panel.edit.label.item": "Subject item",
	"feedback.panel.edit.label.modifiedDate": "Last modified at",
	"feedback.panel.edit.label.modifiedByUser": "Last modified by",
	"feedback.panel.edit.placeholder.assigedToUserId": "Select a person",
	"feedback.panel.edit.label.assigedToUserId": "Assigned to",
	"feedback.panel.edit.placeholder.status": "Select a status",
	"feedback.panel.edit.label.status": "Status",
	"feedback.panel.edit.comment.add": "Add comment",
	"feedback.panel.edit.placeholder.statusReason": "Describe the reason for changing the status",
	"feedback.panel.edit.label.statusReason": "Reason for changing status",
	"feedback.panel.edit.success.onUpdate": "Feedback successfully updated",
	"feedback.panel.edit.error.onUpdate": "Something went wrong saving the changes",
	"feedback.panel.edit.user.noResults": "No results",
	"feedback.panel.edit.user.loading": "Loading..",
	"feedback.panel.edit.user.status.notStarted": "Not started",
	"feedback.panel.edit.user.status.inProgress": "In progress",
	"feedback.panel.edit.user.status.rejected": "Rejected",
	"feedback.panel.edit.user.status.completed": "Completed",
	"feedback.panel.edit.label.comment": "Comment",
	"feedback.panel.edit.placeholder.comment": "Add comments to this item",
	"feedback.panel.edit.label.comment.noResults": "No comments available",
	"feedback.panel.error.delete": "Unable to remove this item. It's possible this item has already been removed.",
	"feedback.panel.error.contentTitle": "Are you sure you want to delete this item?",
	"feedback.panel.delete.contentDescription": "When this item is removed, this item is no longer accessible.",
};