import { FeedbackActionTypes, FeedbackState } from "./types";
import { FeedbackViewModel } from "../../models/viewmodels/feedback/FeedbackViewModel";
import { ErrorTypes, SuccessTypes } from "../../models/enums";

const initialState: FeedbackState = {
	listItems: [] as FeedbackViewModel[],
	listErrorMessage: "",
	listIsLoading: false,
	editPanelItem: {} as FeedbackViewModel,
	editPanelIsLoading: false,
	editPanelErrorMessage: "",
	deletePanelItem: {} as FeedbackViewModel,
	deletePanelIsLoading: false,
	deletePanelErrorMessage: "",
	error: {
		message: "",
		type: ErrorTypes.None,
	},
	success: {
		type: SuccessTypes.None,
	},
};

export function feedbackReducer(
	state = initialState,
	action: { type: FeedbackActionTypes; payload: any }
): FeedbackState {
	switch (action.type) {

		//list fetch
		case FeedbackActionTypes.FETCH_FEEDBACK_STARTED:
			return {
				...state,
				listIsLoading: true,
			};
		case FeedbackActionTypes.FETCH_FEEDBACK_SUCCESS:
			let items = [...action.payload.feedback];

			return {
				...state,
				listItems: items,
				listErrorMessage: "",
				listIsLoading: false,
			};
		case FeedbackActionTypes.FETCH_FEEDBACK_FAILURE:
			return {
				...state,
				listErrorMessage: action.payload.errorMessage,
				listIsLoading: false,
			};

		//fetch by id
		case FeedbackActionTypes.FETCH_FEEDBACK_BY_ID_STARTED:
			return {
				...state,
				editPanelIsLoading: true,
			};

		case FeedbackActionTypes.FETCH_FEEDBACK_BY_ID_SUCCESS:
			let panelItem = action.payload.feedback;

			return {
				...state,
				editPanelItem: panelItem,
				editPanelErrorMessage: "",
				editPanelIsLoading: false,
			};

		case FeedbackActionTypes.FETCH_FEEDBACK_BY_ID_FAILURE:
			return {
				...state,
				editPanelErrorMessage: action.payload.errorMessage,
				editPanelIsLoading: false,
			};

		//update
		case FeedbackActionTypes.UPDATE_FEEDBACK_ITEM_VALUE_SUCCESS:
			const updatedListItems = [...state.listItems].map(listItem => {
				if (listItem.id === action.payload.updatedFeedbackItem.id) {
					return action.payload.updatedFeedbackItem;
				}
				return listItem;
			})
			
			return {
				...state,
				editPanelItem: action.payload.updatedFeedbackItem,
				editPanelErrorMessage: "",
				editPanelIsLoading: false,
				listItems:updatedListItems,
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};

		case FeedbackActionTypes.UPDATE_FEEDBACK_ITEM_VALUE_FAILURE:
			return {
				...state,
				editPanelErrorMessage: action.payload.errorMessage,
				editPanelIsLoading: false,
				error: {
					message: "",
					type: ErrorTypes.OnUpdate,
				},
			};

		//delete
		case FeedbackActionTypes.DELETE_FEEDBACK_VALUE_STARTED:
			return {
				...state,
				deletePanelItem: action.payload,
				deletePanelErrorMessage: "",
				deletePanelIsLoading: true,
				success: {
					type: SuccessTypes.OnDelete,
				},
			};

		case FeedbackActionTypes.DELETE_FEEDBACK_VALUE_SUCCESS:
			let listItems = [...state.listItems].filter(l => l.id !== action.payload.feedbackToRemove.id);
			
			return {
				...state,
				listItems: listItems,
				deletePanelItem: {} as FeedbackViewModel,
				deletePanelErrorMessage: "",
				deletePanelIsLoading: false,
				success: {
					type: SuccessTypes.OnDelete,
				},
			};

		case FeedbackActionTypes.DELETE_FEEDBACK_VALUE_FAILURE:
			return {
				...state,
				deletePanelErrorMessage: action.payload.errorMessage,
				deletePanelIsLoading: false,
				error: {
					message: "",
					type: ErrorTypes.OnDelete,
				},
			};
		
		//resets
		case FeedbackActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};

		case FeedbackActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};



		default:
			return state;
	}
}
