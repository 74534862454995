import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { UsersState, UsersActionTypes } from "./types";
import { adalGraphFetch, adalOneApiFetch } from "../../adalConfig";
import { RoleResponse } from "../../models/responseModels/user/RoleResponse";
import { UserResponse } from "../../models/responseModels/user/UserResponse";
import { IRole } from "@one/core";

// --== RESET ==--
export const resetFetchedUserById = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: UsersActionTypes.RESET_FETCHED_USER_BY_ID,
		});
	};
};
export const resetShouldClosePanel = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: UsersActionTypes.RESET_SHOULD_CLOSE_PANEL,
		});
	};
};
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: UsersActionTypes.RESET_SUCCESS,
		});
	};
};
export const resetError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: UsersActionTypes.RESET_ERROR,
		});
	};
};

// --== Delete User ==--
export const deleteUser = (user: UserResponse) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(deleteUserRequest(user));
	};
};

const deleteUserRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (user: UserResponse) => {
	return async (dispatch: Dispatch) => {
		dispatch(deleteUserStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${user.id}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(deleteUserFailure(response.statusText));
					return;
				}

				dispatch(deleteUserSuccess(user));
			}
		} catch (e) {
			console.log(e);
			dispatch(deleteUserFailure("error"));
		}
	};
};

const deleteUserStarted = () => ({
	type: UsersActionTypes.DELETE_USER_STARTED,
});

const deleteUserSuccess = (deletedUser: UserResponse) => ({
	type: UsersActionTypes.DELETE_USER_SUCCESS,
	payload: {
		deletedUser,
	},
});

const deleteUserFailure = (errorMessage: string | object) => ({
	type: UsersActionTypes.DELETE_USER_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Remove userRole ==--
export const removeUserRole = (userId: number, roleToRemove: IRole) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(removeUserRoleRequest(userId, roleToRemove));
	};
};

const removeUserRoleRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (
	userId: number,
	roleToRemove: RoleResponse
) => {
	return async (dispatch: Dispatch) => {
		dispatch(removeUserRoleStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${userId}/roles/${roleToRemove.id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(removeUserRoleFailure(response.statusText));
					return;
				}

				dispatch(removeUserRoleSuccess(roleToRemove));
			}
		} catch (e) {
			console.log(e);
			dispatch(removeUserRoleFailure("error"));
		}
	};
};

const removeUserRoleStarted = () => ({
	type: UsersActionTypes.REMOVE_USER_ROLE_STARTED,
});

const removeUserRoleSuccess = (removedUserRole: RoleResponse) => ({
	type: UsersActionTypes.REMOVE_USER_ROLE_SUCCESS,
	payload: {
		removedUserRole,
	},
});

const removeUserRoleFailure = (errorMessage: string | object) => ({
	type: UsersActionTypes.REMOVE_USER_ROLE_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Add userRole ==--
export const addUserRole = (userId: number, roleToAdd: RoleResponse) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addUserRoleRequest(userId, roleToAdd));
	};
};

const addUserRoleRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (userId: number, roleToAdd: RoleResponse) => {
	return async (dispatch: Dispatch) => {
		dispatch(addUserRoleStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${userId}/roles`, {
				method: "PUT",
				body: JSON.stringify({
					userId: userId,
					roleId: roleToAdd.id,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 201) {
					dispatch(addUserRoleFailure(response.statusText));
					return;
				}

				dispatch(addUserRoleSuccess(roleToAdd));
			}
		} catch (e) {
			console.log(e);
			dispatch(addUserRoleFailure("error"));
		}
	};
};

const addUserRoleStarted = () => ({
	type: UsersActionTypes.ADD_USER_ROLE_STARTED,
});

const addUserRoleSuccess = (addedUserRole: RoleResponse) => ({
	type: UsersActionTypes.ADD_USER_ROLE_SUCCESS,
	payload: {
		addedUserRole,
	},
});

const addUserRoleFailure = (errorMessage: string | object) => ({
	type: UsersActionTypes.ADD_USER_ROLE_FAILURE,
	payload: {
		errorMessage,
	},
});
// --== Fetch all roles ==--
export const fetchAllRoles = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchAllRolesRequest());
	};
};

const fetchAllRolesRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchAllRolesStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/roles`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(fetchAllRolesFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(fetchAllRolesSuccess(result));
			}
		} catch (e) {
			dispatch(fetchAllRolesFailure("error"));
		}
	};
};

const fetchAllRolesStarted = () => ({
	type: UsersActionTypes.FETCH_ALL_ROLES_STARTED,
});

const fetchAllRolesSuccess = (allRoles: any) => ({
	type: UsersActionTypes.FETCH_ALL_ROLES_SUCCESS,
	payload: {
		allRoles,
	},
});

const fetchAllRolesFailure = (errorMessage: string | object) => ({
	type: UsersActionTypes.FETCH_ALL_ROLES_FAILURE,
	payload: {
		errorMessage,
	},
});

export const fetchRoleAssignmentsForUser: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (userId: number) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: UsersActionTypes.FETCH_USER_ROLE_ASSIGNMENTS_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${userId}/roles/assignments`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					throw new Error();
				}

				const result = await response.json();
				dispatch({
					type: UsersActionTypes.FETCH_USER_ROLE_ASSIGNMENTS_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: UsersActionTypes.FETCH_USER_ROLE_ASSIGNMENTS_FAILURE,
			});
		}
	};
};

// --== Fetch user by Id ==--
export const fetchUserByObjectId = (objectId: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchUserByObjectIdRequest(objectId));
	};
};

const fetchUserByObjectIdRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (userId: number) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchUserByObjectIdStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${userId}`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					if (response.status === 404) {
						dispatch(fetchUserByObjectIdFailure(response.status));
					} else {
						dispatch(fetchUserByObjectIdFailure(response.statusText));
					}
					return;
				}

				const result = await response.json();

				dispatch(fetchUserByObjectIdSuccess(result));
			}
		} catch (e) {
			dispatch(fetchUserByObjectIdFailure("error"));
		}
	};
};

const fetchUserByObjectIdStarted = () => ({
	type: UsersActionTypes.FETCH_USER_BY_ID_STARTED,
});

const fetchUserByObjectIdSuccess = (fetchedUserById: any) => ({
	type: UsersActionTypes.FETCH_USER_BY_ID_SUCCESS,
	payload: {
		fetchedUserById,
	},
});

const fetchUserByObjectIdFailure = (error: string | number | object) => ({
	type: UsersActionTypes.FETCH_USER_BY_ID_FAILURE,
	payload: {
		error: error,
	},
});

// --== Add user ==--
export const addUser = (objectId: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addUserRequest(objectId));
	};
};

const addUserRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (objectId: string) => {
	return async (dispatch: Dispatch) => {
		dispatch(addUserStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users`, {
				method: "POST",
				body: JSON.stringify({
					userObjectId: objectId,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 201) {
					dispatch(addUserFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(addUserSuccess(result));
			}
		} catch (e) {
			dispatch(addUserFailure("error"));
		}
	};
};

const addUserStarted = () => ({
	type: UsersActionTypes.ADD_USER_STARTED,
});

const addUserSuccess = (oneUser: UserResponse) => ({
	type: UsersActionTypes.ADD_USER_SUCCESS,
	payload: {
		oneUser,
	},
});

const addUserFailure = (error: string | number | object) => ({
	type: UsersActionTypes.ADD_USER_FAILURE,
	payload: {
		error: error,
	},
});

// --== Search users ==--
export const searchUsers = (query: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(searchUsersRequest(query));
	};
};

const searchUsersRequest: ActionCreator<ThunkAction<Promise<any>, UsersState, null, any>> = (query: string) => {
	return async (dispatch: Dispatch) => {
		dispatch(searchUsersStarted(query));
		try {
			const response: Response = await adalGraphFetch(
				fetch,
				`https://graph.microsoft.com/v1.0/users?$filter=startsWith(givenName,'${query}') or startsWith(surname,'${query}') or startsWith(mail,'${query}') or startsWith(displayName,'${query}')`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200) {
					dispatch(searchUsersFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(searchUsersSuccess(result.value));
			}
		} catch (e) {
			dispatch(searchUsersFailure("error"));
		}
	};
};

const searchUsersStarted = (query: string) => ({
	type: UsersActionTypes.SEARCH_USERS_STARTED,
	payload: {
		query,
	},
});

const searchUsersSuccess = (searchResult: any) => ({
	type: UsersActionTypes.SEARCH_USERS_SUCCESS,
	payload: {
		searchResult,
	},
});

const searchUsersFailure = (errorMessage: string | object) => ({
	type: UsersActionTypes.SEARCH_USERS_FAILURE,
	payload: {
		errorMessage,
	},
});
