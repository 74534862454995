import { Dropdown, IDropdownOption, IDropdownProps } from "@fluentui/react";
import React from "react";
import { useIntl } from "react-intl";
import { TeamTypeSettingsApproveBy } from "../../../../../../models/enums/TeamTypeSettingsApproveBy";

interface IApprovalByDropdownProps {
	selectedKeys: IDropdownProps["selectedKeys"];
	onChange: (option: IDropdownOption) => void;
}

export default function ApprovalByDropdown(props: IApprovalByDropdownProps) {
	const intl = useIntl();

	const requestApprovalOptions = [
		{
			key: TeamTypeSettingsApproveBy.TeamManagers,
			text: "Team managers",
		},
		{
			key: TeamTypeSettingsApproveBy.TeamMembers,
			text: "Team members",
		},
		{
			key: TeamTypeSettingsApproveBy.TeamOwners,
			text: "Team owners",
		},
		{
			key: TeamTypeSettingsApproveBy.TeamContactPersons,
			text: "Team contact persons"
		},
		{
			key: TeamTypeSettingsApproveBy.Archivaris,
			text: "Archivaris"
		},
	];

	return (
		<Dropdown
			required
			label={intl.formatMessage({
				id: "settings.teams.newTeam.requestApprovalBy",
				defaultMessage: "Goedkeuring aanvragen per",
			})}
			className="ilx-text-field-small"
			selectedKeys={props.selectedKeys}
			placeholder={intl.formatMessage({
				id: "settings.teams.newTeam.noApprovalRequired",
				defaultMessage: "Geen goedkeuring vereist",
			})}
			multiSelect
			options={requestApprovalOptions}
			onChange={(event, option) => {
				if (option) {
					props.onChange(option);
				}
			}}
		/>
	);
}