import React, { useState } from "react";
import { ReleasesViewModel } from "../../../../models/responseModels/Releases/ReleasesViewModel";
import {
	IColumn,
	DetailsList,
	DetailsListLayoutMode,
	CheckboxVisibility,
	IDetailsListProps,
	IDetailsRowStyles,
	DetailsRow,
	Panel,
	PanelType,
	Icon,
	Dropdown,
	IDropdownOption,
	ActionButton,
} from "@fluentui/react";
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { CreateReleaseFormComponent } from "./createReleaseFromComponent/CreateReleaseFormComponent";
import { useDispatch, useSelector } from "react-redux";
import { deleteRelease } from "../../../../store/releases/actions";
import { UpdateReleaseFormComponent } from "./createReleaseFromComponent/UpdateReleaseFormComponent";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ConfirmPanel } from "../../../reusable/confirmPanelComponent/ConfirmPanelComponent";
import { hasAccess, PermissionCodes } from "@one/core";

interface ReleasesTableProps {
	releases: ReleasesViewModel[];
}

function ReleasesTable(props: WrappedComponentProps & ReleasesTableProps) {
	const { intl } = props;

	const dispatch = useDispatch();
	const [panelIsOpen, setPanelIsOpen] = useState(false);
	const [selectedReleaseForPanel, setSelectedReleaseForPanel] = useState({} as ReleasesViewModel);
	const [activeFilter, setActiveFilter] = useState(0);
	const [createPanelIsOpen, setCreatePanelIsOpen] = useState(false);
	const [updatePanelIsOpen, setUpdatePanelIsOpen] = useState(false);
	const [deletePanelIsOpen, setDeletePanelIsOpen] = useState(false);
	const applicationDropdownList: Array<IDropdownOption> = [];
	const userSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	applicationDropdownList.push({ key: 0, text: "Toon alle releases", selected: true });
	props.releases.forEach((release: ReleasesViewModel) => {
		release.applicationName = release.application.name;

		const dropdownItem: IDropdownOption = {
			key: release.applicationId,
			text: release.applicationName,
		};

		let hasApplication = false;
		applicationDropdownList.filter((item: IDropdownOption) => {
			if (item.key === dropdownItem.key) {
				return (hasApplication = true);
			}

			return null;
		});

		if (!hasApplication) applicationDropdownList.push(dropdownItem);
	});

	const _columns: IColumn[] = [
		{
			key: "title",
			fieldName: "title",
			name: intl.formatMessage({ id: "releases.table.title", defaultMessage: "Titel" }),
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "versionNumber",
			fieldName: "versionNumber",
			name: intl.formatMessage({
				id: "releases.table.versionNumber",
				defaultMessage: "Versienummer",
			}),
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "displayDate",
			fieldName: "displayDate",
			name: intl.formatMessage({
				id: "releases.table.displayDate",
				defaultMessage: "Publicatiedatum",
			}),
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "applicationName",
			fieldName: "applicationName",
			name: intl.formatMessage({
				id: "releases.table.application",
				defaultMessage: "Applicatie",
			}),
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
	];

	// returns the row with additional styling
	const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
		const customStyles: Partial<IDetailsRowStyles> = {};
		if (props) {
			if (props.itemIndex % 2 === 1) {
				customStyles.root = {
					backgroundColor: "#f9f9f9",
					paddingTop: "11px",
					cursor: "pointer",
				};
			} else {
				customStyles.root = {
					backgroundColor: "#fff",
					paddingTop: "11px",
					cursor: "pointer",
				};
			}

			props.item.applicationName = props.item.application.name;
			props.item.displayDate = <FormattedDate value={props.item.publishingDate} />;

			return (
				<div onClick={() => togglePanel(props.item)}>
					<DetailsRow {...props} styles={customStyles} />
				</div>
			);
		}
		return null;
	};

	const togglePanel = (item) => {
		setSelectedReleaseForPanel(item);
		setPanelIsOpen(!panelIsOpen);
	};

	const toggleCreatePanel = () => {
		setCreatePanelIsOpen(!createPanelIsOpen);
	};

	const toggleUpdatePanel = () => {
		setUpdatePanelIsOpen(!updatePanelIsOpen);
	};

	const _dropdownChanged = (event, option) => {
		setActiveFilter(option.key);
	};

	const deleteReleaseClosePanel = (releaseModel) => {
		setPanelIsOpen(false);
		setDeletePanelIsOpen(false);

		dispatch(deleteRelease(selectedReleaseForPanel.id, selectedReleaseForPanel.applicationId));
	};

	let filteredReleases = props.releases;
	if (activeFilter > 0) {
		filteredReleases = props.releases.filter((release: ReleasesViewModel) => {
			if (release.applicationId === activeFilter) {
				return release;
			}

			return null;
		});
	}

	let createNewReleaseActionButton;
	if (Object.keys(userSlice.user).length !== 0 && userSlice.user.constructor === Object) {
		if (hasAccess(userSlice.user as any, [PermissionCodes.ManageApplicationReleases])) {
			createNewReleaseActionButton = (
				<ActionButton iconProps={{ iconName: "Add" }} onClick={toggleCreatePanel}>
					<FormattedMessage id="releases.page.createNewRelease" defaultMessage="Nieuwe release aanmaken" />
				</ActionButton>
			);
		}
	}

	let content;
	if (props.releases.length > 0) {
		content = (
			<React.Fragment>
				<Dropdown
					label=""
					options={applicationDropdownList}
					className="dropdown-wrapper"
					onChange={(event, option) => _dropdownChanged(event, option)}
				/>

				{createNewReleaseActionButton}

				<DetailsList
					items={filteredReleases}
					columns={_columns}
					layoutMode={DetailsListLayoutMode.justified}
					checkboxVisibility={CheckboxVisibility.hidden}
					onRenderRow={_onRenderRow}
				/>
			</React.Fragment>
		);
	} else {
		content = (
			<React.Fragment>
				{createNewReleaseActionButton}

				<p className="no-releases">
					<FormattedMessage id="releases.page.noReleaseNotesYet" defaultMessage="Er zijn nog geen releases." />{" "}
				</p>
			</React.Fragment>
		);
	}
	const openDeletePanelCloseSummaryPanel = () => {
		setPanelIsOpen(false);
		setDeletePanelIsOpen(!deletePanelIsOpen);
	};

	const cancelDeltePanelHandler = () => {
		setPanelIsOpen(true);
		setDeletePanelIsOpen(false);
	};

	const deleteReleaseNote = (
		<Icon iconName="ChromeClose" className="delete-release-note" onClick={() => openDeletePanelCloseSummaryPanel()} />
	);
	const EditReleaseNote = <Icon iconName="Edit" onClick={toggleUpdatePanel} style={{ marginRight: "15px" }} />;

	let editAndDeleteIcons;
	if (Object.keys(userSlice.user).length !== 0 && userSlice.user.constructor === Object) {
		if (hasAccess(userSlice.user as any, [PermissionCodes.ManageApplicationReleases])) {
			editAndDeleteIcons = (
				<div className="release-note-wrapper">
					{deleteReleaseNote}
					{EditReleaseNote}
				</div>
			);
		}
	}

	let moreLink;

	if (selectedReleaseForPanel.link !== "") {
		moreLink = (
			<div className="panel-content__link">
				<a target="_blank" rel="noopener noreferrer" href={selectedReleaseForPanel.link}>
					<FormattedMessage id="releases.viewmore" defaultMessage="Toon meer" />
				</a>
			</div>
		);
	} else {
		moreLink = "";
	}

	return (
		<div>
			{content}

			<Panel
				className="one-panel"
				isOpen={panelIsOpen}
				type={PanelType.smallFixedFar}
				isLightDismiss={true}
				onDismiss={() => togglePanel(selectedReleaseForPanel)}
				onLightDismissClick={() => togglePanel(selectedReleaseForPanel)}
				styles={{ main: { width: "56.3rem !important" } }}
				closeButtonAriaLabel="Close"
			>
				<section className="one-panel__panel-header">
					<div className="one-panel__panel-header--flex">
						<h1>{selectedReleaseForPanel.title}</h1>
						<div className="one-panel__panel-close" onClick={() => togglePanel(selectedReleaseForPanel)}>
							<Icon iconName="ChromeClose" />
						</div>
					</div>
				</section>
				<section className="one-panel__panel-content">
					<div className="panel-content__date-wrapper">
						<div className="inline-block">
							<FormattedMessage id="releases.page.version" defaultMessage="Versie:" /> {selectedReleaseForPanel.versionNumber}
						</div>
						<div className="inline-block">
							{editAndDeleteIcons}
							<FormattedDate value={selectedReleaseForPanel.publishingDate} />
						</div>
					</div>

					<div className="panel-content__body-wrapper">
						<span dangerouslySetInnerHTML={{ __html: selectedReleaseForPanel.body }}></span>
					</div>

					{moreLink}
				</section>
			</Panel>

			<Panel
				className="one-panel"
				isOpen={createPanelIsOpen}
				type={PanelType.smallFixedFar}
				isLightDismiss={true}
				onDismiss={() => toggleCreatePanel()}
				onLightDismissClick={() => toggleCreatePanel()}
				styles={{ main: { width: "56.3rem !important" } }}
				closeButtonAriaLabel="Close"
			>
				<CreateReleaseFormComponent toggleCreatePanel={toggleCreatePanel} applicationsList={applicationDropdownList} />
			</Panel>

			<Panel
				className="one-panel"
				isOpen={updatePanelIsOpen}
				type={PanelType.smallFixedFar}
				isLightDismiss={true}
				onDismiss={() => toggleUpdatePanel()}
				onLightDismissClick={() => toggleUpdatePanel()}
				styles={{ main: { width: "56.3rem !important" } }}
				closeButtonAriaLabel="Close"
			>
				<UpdateReleaseFormComponent
					toggleUpdatePanel={toggleUpdatePanel}
					applicationsList={applicationDropdownList}
					release={selectedReleaseForPanel}
				/>
			</Panel>

			<ConfirmPanel
				isOpen={deletePanelIsOpen}
				onConfirm={() => dispatch(() => deleteReleaseClosePanel(selectedReleaseForPanel))}
				onCancel={() => cancelDeltePanelHandler()}
				headerText={intl.formatMessage({
					id: "deleteReleasePanel-header",
					defaultMessage: "Verwijder release",
				})}
				contentTitle={`${intl.formatMessage({
					id: "deleteReleasePanel-contentTitle",
					defaultMessage: "Release:",
				})} ${selectedReleaseForPanel.title}`}
				contentDescription={intl.formatMessage({
					id: "deleteReleasePanel-contentDescription",
					defaultMessage: "Weet u zeker dat u deze release wilt verwijderen?",
				})}
				errorMessage={""}
			/>
		</div>
	);
}

export const ReleasesTableComponent = injectIntl(ReleasesTable);
