import { IconButton, IPersonaProps, Label, NormalPeoplePicker, TooltipHost } from "@fluentui/react";
import { FormikProps } from "formik";
import { useId } from "@fluentui/react-hooks";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ISlicePiece, LoadingOverlay, SlicePieceStatus, TextFieldErrorMessage } from "@one/core";
import { getUsersByTitleOrEmail } from "../../../../common/httpFunctions/Users";
import { adalGraphFetch } from "../../../../adalConfig";
import { ITeamTypeFormValues } from "../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";

export interface IBasicInformationProps {
	formikProps: FormikProps<ITeamTypeFormValues>;
	hasSubmitted: boolean;
	required?: boolean;
}

export const DefaultOwners = (props: IBasicInformationProps) => {
	const { formikProps, hasSubmitted } = props;
	const { values, errors, setFieldValue, setFieldTouched } = formikProps;
	const intl = useIntl();

	const [defaultOwnerPersonas, setDefaultOwnerPersonas] = useState<ISlicePiece<IPersonaProps[]>>({
		data: [],
		fetchStatus: SlicePieceStatus.None,
	});

	/**
	 * Search for users in the graph and map the results to an object the NormalPeoplePicker can work with.
	 */
	const searchUsers = async (query: string) => {
		try {
			if (query !== "") {
				const fetchedUsers = await getUsersByTitleOrEmail(query, true);
				return fetchedUsers.map((item) => ({ ...item, text: item.displayName, secondaryText: item.jobTitle }));
			}

			return [];
		} catch {
			return [];
		}
	};

	useEffect(() => {
		const fetchDefaultOwners = async () => {
			try {
				setDefaultOwnerPersonas({ ...defaultOwnerPersonas, fetchStatus: SlicePieceStatus.IsFetching });

				const fetchedPersonas: IPersonaProps[] = [];
				// Fetch owners
				for (let i = 0; i < values.defaultOwners.length; i++) {
					const objectId = values.defaultOwners[i];
					const response: Response = await adalGraphFetch(fetch, `https://graph.microsoft.com/v1.0/users/${objectId}`, {
						method: "GET",
					});
					const user = await response.json();
					let persona = {
						...user,
						text: user.displayName,
						secondaryText: user.jobTitle,
					} as IPersonaProps;

					fetchedPersonas.push(persona);
				}

				setDefaultOwnerPersonas({ data: fetchedPersonas, fetchStatus: SlicePieceStatus.Success });
			} catch (e) {
				setDefaultOwnerPersonas({ ...defaultOwnerPersonas, fetchStatus: SlicePieceStatus.Error });
			}
		};

		fetchDefaultOwners();
	}, []);

	return (
		<section>
			<div className="label-with-tooltip">
				<Label required={props.required}>
					<FormattedMessage id="settings.teams.types.owners.label" />
				</Label>
				<TooltipHost id={useId("OrganizerInfoToolTip")} content={intl.formatMessage({ id: "settings.teams.types.owners.tooltip" })}>
					<IconButton iconProps={{ iconName: "Info" }} />
				</TooltipHost>
			</div>

			<div style={{ position: "relative" }}>
				{defaultOwnerPersonas.fetchStatus === SlicePieceStatus.IsFetching && <LoadingOverlay />}
				<NormalPeoplePicker
					//@ts-ignore
					onResolveSuggestions={(filter) => searchUsers(filter, false)}
					className={!errors.defaultOwners ? "ilx-text-field" : ""}
					disabled={defaultOwnerPersonas.fetchStatus === SlicePieceStatus.IsFetching}
					inputProps={{
						placeholder:
							defaultOwnerPersonas.data.length < 1
								? intl.formatMessage({ id: "general.pickers.people.placeholder" })
								: undefined,
					}}
					pickerSuggestionsProps={{
						noResultsFoundText: intl.formatMessage({ id: "general.pickers.noResults" }),
					}}
					styles={{
						root: {
							margin: 0,
						},
						text: {
							// Change the border-color to the same used on a textfield with an error.
							borderColor: hasSubmitted && errors.defaultOwners ? "rgb(164, 38, 44)" : undefined,
						},
					}}
					// selectedItems={values.defaultOwners === undefined ? [] : values.defaultOwners}
					selectedItems={defaultOwnerPersonas.data}
					// onChange={(items) => setFieldValue("defaultOwners", items.length > 0 ? items : undefined)}
					onChange={(items) => {
						// Update internal state
						setDefaultOwnerPersonas({ ...defaultOwnerPersonas, data: items || [] });

						// Update form state
						setFieldValue(
							"defaultOwners",
							(items || []).map((i) => i.id)
						);
					}}
					onBlur={() => setFieldTouched("defaultOwners")}
				/>

				<div className={errors.defaultOwners ? "ilx-text-field" : ""}>
					<TextFieldErrorMessage errorMessage={errors.defaultOwners ? errors.defaultOwners.toString() : undefined} />
				</div>
			</div>
		</section>
	);
};
