import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchReleases, resetAddRequestNotification } from "../../../store/releases/actions";
import { ReleasesTableComponent } from "./releasesTable/ReleasesTableComponent";
import Loader from "react-loader-spinner";
import { MessageBar, MessageBarType } from "@fluentui/react";

export const ReleasesComponent = (props: any) => {
	const dispatch = useDispatch();
	const releasesSlice = useSelector((state: IApplicationState) => state.releasesSlice);

	let contentToShow;

	useEffect(() => {
		dispatch(fetchReleases());
	}, [dispatch]);

	if (releasesSlice.isLoading) {
		contentToShow = (
			<div className="loading-wrapper">
				<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
			</div>
		);
	} else {
		contentToShow = <ReleasesTableComponent releases={releasesSlice.releases} />;
	}

	let messageBar;
	if (releasesSlice.createRequestFired === true && releasesSlice.createErrorMessage === "") {
		messageBar = (
			<MessageBar
				messageBarType={MessageBarType.success}
				onDismiss={() => dispatch(resetAddRequestNotification())}
				className="notification-wrapper"
			>
				<FormattedMessage
					id="releases.page.releaseNoteAddedSuccessMessage"
					defaultMessage="U heeft een nieuwe release toegevoegd."
				/>
			</MessageBar>
		);
	} else if (
		releasesSlice.createRequestFired === true &&
		releasesSlice.createErrorMessage !== ""
	) {
		messageBar = (
			<MessageBar
				messageBarType={MessageBarType.error}
				onDismiss={() => dispatch(resetAddRequestNotification())}
				className="notification-wrapper"
			>
				<FormattedMessage
					id="releases.page.releaseNoteAddedFailedMessage"
					defaultMessage="Helaas, er is iets fout gegaan, probeer het later opnieuw."
				/>
			</MessageBar>
		);
	}

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage
					id="releases.navigationItem.title"
					defaultMessage="Releases informatie"
				/>
			</h1>
			<div className="main-container">
				{messageBar}
				{contentToShow}
			</div>
		</section>
	);
};
