export enum ErrorTypes {
	None,
	OnInitialDataLoad,
	OnRefreshDataLoad,
	OnDetailDataLoad,
	OnMetaDataLoad,
	OnCreate,
	OnUpdate,
	OnDelete,
	OnSearch,
}
