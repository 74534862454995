import { UsersActionTypes, UsersState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { UserResponse } from "../../models/responseModels/user/UserResponse";
import { sortArray } from "../../common/helperFunctions/ObjectsAndArrayHelpers";
import { SuccessTypes } from "../../models/enums/SuccessTypes";
import { SlicePieceStatus } from "@one/core";

const initialState: UsersState = {
	query: "",
	searchResult: [],
	fetchedUserById: null,
	fetchedUserRoleAssignments: { data: [], fetchStatus: SlicePieceStatus.None },
	allRoles: [],

	isSearching: false,
	isFetchingUserById: false,
	isFetchingAllRoles: false,
	isAddingUserRole: false,
	isRemovingUserRole: false,
	isCreatingUser: false,
	isDeletingUser: false,

	shouldClosePanel: false,

	success: {
		type: SuccessTypes.None,
	},
	error: {
		message: "",
		type: ErrorTypes.None,
	},
};

export function usersReducer(state = initialState, action: { type: UsersActionTypes; payload: any }): UsersState {
	switch (action.type) {
		// --== Search users ==--
		case UsersActionTypes.SEARCH_USERS_STARTED:
			return {
				...state,
				isSearching: true,
				query: action.payload.query,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.SEARCH_USERS_SUCCESS:
			return {
				...state,
				isSearching: false,
				searchResult: action.payload.searchResult,
			};
		case UsersActionTypes.SEARCH_USERS_FAILURE:
			return {
				...state,
				isSearching: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnSearch,
				},
			};

		// --== Fetch user by id ==--
		case UsersActionTypes.FETCH_USER_BY_ID_STARTED:
			return {
				...state,
				isFetchingUserById: true,
				fetchedUserById: null,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.FETCH_USER_BY_ID_SUCCESS:
			return {
				...state,
				isFetchingUserById: false,
				fetchedUserById: action.payload.fetchedUserById,
			};
		case UsersActionTypes.FETCH_USER_BY_ID_FAILURE:
			return {
				...state,
				isFetchingUserById: false,
				error: {
					message: action.payload.error,
					code: action.payload.error,
					type: ErrorTypes.OnDetailDataLoad,
				},
			};

		// --== Fetch role assignments for user by id ==--
		case UsersActionTypes.FETCH_USER_ROLE_ASSIGNMENTS_STARTED:
			return {
				...state,
				fetchedUserRoleAssignments: {
					...state.fetchedUserRoleAssignments,
					fetchStatus: SlicePieceStatus.IsFetching,
					data: [],
				},
			};
		case UsersActionTypes.FETCH_USER_ROLE_ASSIGNMENTS_SUCCESS:
			return {
				...state,
				fetchedUserRoleAssignments: {
					...state.fetchedUserRoleAssignments,
					fetchStatus: SlicePieceStatus.Success,
					data: action.payload,
				},
			};
		case UsersActionTypes.FETCH_USER_ROLE_ASSIGNMENTS_FAILURE:
			return {
				...state,
				fetchedUserRoleAssignments: {
					...state.fetchedUserRoleAssignments,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		// --== Fetch all roles  ==--
		case UsersActionTypes.FETCH_ALL_ROLES_STARTED:
			return {
				...state,
				isFetchingAllRoles: true,
				allRoles: [],
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.FETCH_ALL_ROLES_SUCCESS:
			return {
				...state,
				isFetchingAllRoles: false,
				allRoles: action.payload.allRoles,
			};
		case UsersActionTypes.FETCH_ALL_ROLES_FAILURE:
			return {
				...state,
				isFetchingAllRoles: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnMetaDataLoad,
				},
			};

		// --== Add user role  ==--
		case UsersActionTypes.ADD_USER_ROLE_STARTED:
			return {
				...state,
				isAddingUserRole: true,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.ADD_USER_ROLE_SUCCESS:
			const user: UserResponse = { ...state.fetchedUserById } as UserResponse;
			user.roles.push(action.payload.addedUserRole);
			user.roles = user.roles.sort(sortArray("name")).reverse();

			const newUserRoleAssignmentsAfterAdd = [...state.fetchedUserRoleAssignments.data];
			newUserRoleAssignmentsAfterAdd.push({
				role: action.payload.addedUserRole,
				user: user as any,
				applicationInstance: null,
			});

			return {
				...state,
				isAddingUserRole: false,
				fetchedUserById: user,
				fetchedUserRoleAssignments: {
					...state.fetchedUserRoleAssignments,
					data: newUserRoleAssignmentsAfterAdd,
				},
			};
		case UsersActionTypes.ADD_USER_ROLE_FAILURE:
			return {
				...state,
				isAddingUserRole: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnUpdate,
				},
			};

		// --== Remove user role  ==--
		case UsersActionTypes.REMOVE_USER_ROLE_STARTED:
			return {
				...state,
				isRemovingUserRole: true,
				shouldClosePanel: false,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.REMOVE_USER_ROLE_SUCCESS:
			const userAfterRemoveRole: UserResponse = { ...state.fetchedUserById } as UserResponse;
			userAfterRemoveRole.roles = userAfterRemoveRole.roles
				.filter((role) => role.id !== action.payload.removedUserRole.id)
				.sort(sortArray("name"))
				.reverse();
			return {
				...state,
				isRemovingUserRole: false,
				fetchedUserById: userAfterRemoveRole,
				fetchedUserRoleAssignments: {
					...state.fetchedUserRoleAssignments,
					data: [...state.fetchedUserRoleAssignments.data].filter(
						(ra) => ra.applicationInstance === null || ra.role.id !== action.payload.removedUserRole.id
					),
				},
			};
		case UsersActionTypes.REMOVE_USER_ROLE_FAILURE:
			return {
				...state,
				isRemovingUserRole: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnDelete,
				},
			};

		// --== Add user ==--
		case UsersActionTypes.ADD_USER_STARTED:
			return {
				...state,
				isCreatingUser: true,
				success: {
					type: SuccessTypes.None,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.ADD_USER_SUCCESS:
			return {
				...state,
				isCreatingUser: false,
				fetchedUserById: action.payload.oneUser,
				success: {
					type: SuccessTypes.OnCreate,
				},
			};
		case UsersActionTypes.ADD_USER_FAILURE:
			return {
				...state,
				isCreatingUser: false,
				error: {
					message: action.payload.error,
					type: ErrorTypes.OnCreate,
				},
			};

		// --== Delete User  ==--
		case UsersActionTypes.DELETE_USER_STARTED:
			return {
				...state,
				isDeletingUser: true,
				shouldClosePanel: false,
				success: {
					type: SuccessTypes.None,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case UsersActionTypes.DELETE_USER_SUCCESS:
			const userToDelete = action.payload.deletedUser;
			const searchResultAfterDelete = [...state.searchResult].filter((result) => result.id !== userToDelete.id);

			return {
				...state,
				isDeletingUser: false,
				searchResult: searchResultAfterDelete,
				success: {
					type: SuccessTypes.OnDelete,
				},
				shouldClosePanel: true,
			};
		case UsersActionTypes.DELETE_USER_FAILURE:
			return {
				...state,
				isDeletingUser: false,
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnDelete,
				},
			};

		// --== RESET ==--
		case UsersActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};
		case UsersActionTypes.RESET_ERROR:
			return {
				...state,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case UsersActionTypes.RESET_FETCHED_USER_BY_ID:
			return {
				...state,
				fetchedUserById: null,
			};
		case UsersActionTypes.RESET_SHOULD_CLOSE_PANEL:
			return {
				...state,
				shouldClosePanel: false,
			};

		default:
			return state;
	}
}
