import { IAddConnection } from "./../../models/responseModels/connection/IAddConnection";
import { IConnectionResponse } from "./../../models/responseModels/connection/IConnectionResponse";
import { ConnectionActionTypes, ConnectionState } from "./types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { adalOneApiFetch } from "../../adalConfig";
import { IUpdateConnection } from "../../models/responseModels/connection/IUpdateConnection";

export const resetError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ConnectionActionTypes.RESET_ERROR,
		});
	};
};
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ConnectionActionTypes.RESET_SUCCESS,
		});
	};
};

// --== Fetch app settings by id ==--
export const fetchConnectionByName = (name: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchConnectionByNameRequest(name));
	};
};

const fetchConnectionByNameRequest: ActionCreator<ThunkAction<Promise<any>, ConnectionState, null, any>> = (name: string) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchConnectionByNameStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/bcs/connections/${name}`,
				{}
			);

			if (response) {
				if (response.status === 404) {
					return;
				}

				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchConnectionByNameFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchConnectionByNameSuccess(result));
			}
		} catch (e) {
			dispatch(fetchConnectionByNameFailure("error"));
		}
	};
};

const fetchConnectionByNameStarted = () => ({
	type: ConnectionActionTypes.FETCH_CONNECTION_BY_NAME_STARTED,
});

const fetchConnectionByNameSuccess = (connection: any) => ({
	type: ConnectionActionTypes.FETCH_CONNECTION_BY_NAME_SUCCESS,
	payload: {
		...connection,
	},
});

const fetchConnectionByNameFailure = (errorMessage: string | object) => ({
	type: ConnectionActionTypes.FETCH_CONNECTION_BY_NAME_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update Connection by ID ==--
export const updateConnectionById = (body: IUpdateConnection) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateConnectionByIdRequest(body));
	};
};

const updateConnectionByIdRequest: ActionCreator<ThunkAction<Promise<any>, ConnectionState, null, any>> = (body: IUpdateConnection) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateConnectionByIdStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/bcs/connections`, {
				method: "PATCH",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(updateConnectionByIdFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateConnectionByIdSuccess(result));
			}
		} catch (e) {
			console.log(e);
			dispatch(updateConnectionByIdFailure("error"));
		}
	};
};

const updateConnectionByIdStarted = () => ({
	type: ConnectionActionTypes.UPDATE_CONNECTION_BY_ID_STARTED,
});

const updateConnectionByIdSuccess = (response: IConnectionResponse) => ({
	type: ConnectionActionTypes.UPDATE_CONNECTION_BY_ID_SUCCESS,
	payload: response as IConnectionResponse,
});

const updateConnectionByIdFailure = (errorMessage: string | object) => ({
	type: ConnectionActionTypes.UPDATE_CONNECTION_BY_ID_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Create Connection ==--
export const createConnection = (body: IAddConnection) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(addConnectionRequest(body));
	};
};

const addConnectionRequest: ActionCreator<ThunkAction<Promise<any>, ConnectionState, null, any>> = (body: IAddConnection) => {
	return async (dispatch: Dispatch) => {
		dispatch(createConnectionStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/bcs/connections`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
					dispatch(createConnectionFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(createConnectionSuccess(result));
			}
		} catch (e) {
			dispatch(createConnectionFailure("error"));
		}
	};
};

const createConnectionStarted = () => ({
	type: ConnectionActionTypes.CREATE_CONNECTION_STARTED,
});

const createConnectionSuccess = (response: IConnectionResponse) => ({
	type: ConnectionActionTypes.CREATE_CONNECTION_SUCCESS,
	payload: response as IConnectionResponse,
});

const createConnectionFailure = (errorMessage: string | object) => ({
	type: ConnectionActionTypes.CREATE_CONNECTION_FAILURE,
	payload: {
		errorMessage,
	},
});
