import React, { useEffect } from "react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import { fetchUsersPerSite } from "../../../../../store/analytics/actions";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { PrimaryButton } from "@fluentui/react";
import Loader from "react-loader-spinner";
import { calculateYAxisScale } from "../../../../../common/helperFunctions/GeneralHelpers";
import OneTooltip from "./CustomTooltip";

export interface ComponentProps {
	pageUrl: string;
	backToSiteSelection: () => void;
	parentTimeSpan: number;
	setParentTimeSpan: (timespan: number) => void;
}

const UniqueVisitorsPerPageChart = (props: WrappedComponentProps & ComponentProps) => {
	const { pageUrl, intl, backToSiteSelection } = props;
	let columnByNameMap = {};
	let graph;
	let formattedData: any = [];
	const dispatch = useDispatch();

	const analyticsSlice = useSelector((state: IApplicationState) => state.analyticsSlice);

	const checkActiveState = (days: number) => {
		if (props.parentTimeSpan === days) {
			return "active-item";
		}
		return "";
	};

	const sevenDaysClasses = checkActiveState(7);
	const thirtyDaysClasses = checkActiveState(30);
	const ninetyDaysClasses = checkActiveState(90);

	useEffect(() => {
		dispatch(fetchUsersPerSite(pageUrl, props.parentTimeSpan));
	}, [dispatch, props.parentTimeSpan]); // eslint-disable-line react-hooks/exhaustive-deps

	let header = (
		<React.Fragment>
			<div className="graphButtons-wrapper-small">
				<div className="fake-link" onClick={backToSiteSelection}>
					<FormattedMessage
						id="usage.uniqueVisitorsChartPerSite.backToSiteCollection"
						defaultMessage="Terug naar site selecteren"
					/>
				</div>
				<div>
					<PrimaryButton
						text={intl.formatMessage({
							id: "usage.uniqueVisitorsChart.pastSevenDaysButtonText",
							defaultMessage: "Afgelopen 7 dagen",
						})}
						className={sevenDaysClasses}
						onClick={() => props.setParentTimeSpan(7)}
					/>

					<PrimaryButton
						text={intl.formatMessage({
							id: "usage.uniqueVisitorsChart.pastThirtyDaysButtonText",
							defaultMessage: "Afgelopen 30 dagen",
						})}
						className={thirtyDaysClasses}
						onClick={() => props.setParentTimeSpan(30)}
					/>

					<PrimaryButton
						text={intl.formatMessage({
							id: "usage.uniqueVisitorsChart.pastNinetyDaysButtonText",
							defaultMessage: "Afgelopen 90 dagen",
						})}
						className={ninetyDaysClasses}
						onClick={() => props.setParentTimeSpan(90)}
					/>
				</div>
			</div>
		</React.Fragment>
	);

	// Formats the data for the table.
	if (
		analyticsSlice.pageUniqueVisitorsData != null &&
		Object.keys(analyticsSlice.pageUniqueVisitorsData).length !== 0 &&
		analyticsSlice.pageUniqueVisitorsData.constructor === Object
	) {
		if (analyticsSlice.pageUniqueVisitorsData.tables != null) {
			// make sure columnByNameMap functions as an enum with the available columns in an object.
			analyticsSlice.pageUniqueVisitorsData.tables[0].columns.map((column, i) => {
				return (columnByNameMap[i] = column.name);
			});

			// Loop through the row push an object with columnName: data in aggregatedData: {Host: *, USers: *, timestamp: *}
			analyticsSlice.pageUniqueVisitorsData.tables[0].rows.map((row, i) => {
				let obj = {};
				for (let i = 0; i < row.length; i++) {
					// truncate the date into the right format:
					if (columnByNameMap[i] === "timestamp") {
						let truncatedDate = row[i].toString().split("T")[0].substr(5, 10);

						// removed leading zeros in the months
						if (truncatedDate[0] === "0") {
							truncatedDate = truncatedDate.substr(1, truncatedDate.length);
						}
						obj[columnByNameMap[i]] = truncatedDate;
					} else {
						obj[columnByNameMap[i]] = row[i];
					}
				}
				return formattedData.push(obj);
			});
		}
	}

	if (analyticsSlice.pageUniqueVisitorsErrorMessage !== "") {
		graph = (
			<React.Fragment>
				{header}
				<p className="error-message">
					<FormattedMessage
						id="general.something-went-wrong"
						defaultMessage="Er is iets fout gegaan."
					/>
				</p>
			</React.Fragment>
		);
	} else if (formattedData.length === 0 && !analyticsSlice.pageUniqueVisitorsIsLoading) {
		graph = (
			<React.Fragment>
				{header}
				<p style={{ marginTop: "3rem" }}>
					{" "}
					<FormattedMessage
						id="usage.uniqueVisitorsChartPerSite.noData"
						defaultMessage="Er is niet genoeg data beschikbaar."
					/>
				</p>
			</React.Fragment>
		);
	} else if (analyticsSlice.pageUniqueVisitorsIsLoading) {
		graph = (
			<React.Fragment>
				{header}
				<div className="loading-wrapper">
					<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
				</div>
			</React.Fragment>
		);
	} else if (formattedData.length > 0 && !analyticsSlice.isLoadingSpSiteData) {
		const yAxisScale = calculateYAxisScale(formattedData, "viewCount");
		graph = (
			<React.Fragment>
				{header}
				<ResponsiveContainer width="100%" aspect={4.0 / 0.8}>
					<BarChart
						data={formattedData}
						margin={{ top: 30, right: 0, bottom: 20, left: 20 }}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey="timestamp"
							label={{
								value: intl.formatMessage({
									id: "usage.uniqueVisitorsChart.xLabel",
									defaultMessage: "Datum",
								}),
								position: "bottom",
							}}
						/>
						<YAxis
							label={{
								value: intl.formatMessage({
									id: "usage.uniqueVisitorsChart.yLabel",
									defaultMessage: "Gebruikers",
								}),
								angle: -90,
								position: "insideLeft",
							}}
							domain={[0, yAxisScale]}
						/>
						<Tooltip content={<OneTooltip />} />

						<Bar barSize={40} dataKey="viewCount" fill="#0078d4" />
					</BarChart>
				</ResponsiveContainer>
			</React.Fragment>
		);
	}

	return <div>{graph}</div>;
};

export const UniqueVisitorsPerPageChartComponent = injectIntl(UniqueVisitorsPerPageChart);
