import React from "react";
import "./navigationHeader.css";

const NavigationHeader = ({ headerText }) => {
  return (
    <div className="navigation-header">
      <h2>{headerText}</h2>
    </div>
  );
};

export default NavigationHeader;
