import { TextField, Label, ActionButton, Toggle } from "@fluentui/react";
import { FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { ITeamTypeFormValues } from "../../../../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";

export interface IBasicInformationProps {
	formikProps: FormikProps<ITeamTypeFormValues>;
	hasSubmitted: boolean;
}

export const BasicInformation = (props: IBasicInformationProps) => {
	const { formikProps, hasSubmitted } = props;
	const { values, errors, handleChange, handleBlur, setFieldValue } = formikProps;
	const intl = useIntl();

	return (
		<section>
			<TextField
				id="name"
				label={intl.formatMessage({
					id: "settings.teams.types.column.name",
					defaultMessage: "Naam",
				})}
				placeholder={intl.formatMessage({
					id: "lookup.panel.create.placeholder.name",
					defaultMessage: "Vul een waarde in",
				})}
				errorMessage={hasSubmitted ? errors.name : undefined}
				required
				type="text"
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.name}
				className="ilx-text-field"
				autoComplete="Off"
			/>

			<TextField
				id="domain"
				label={intl.formatMessage({
					id: "settings.teams.types.column.domain",
					defaultMessage: "Domein",
				})}
				placeholder={intl.formatMessage({
					id: "lookup.panel.create.placeholder.name",
					defaultMessage: "Vul een waarde in",
				})}
				type="text"
				errorMessage={hasSubmitted ? errors.domain : undefined}
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.domain ?? ""}
				className="ilx-text-field"
				autoComplete="Off"
			/>

			<TextField
				id="prefix"
				label={intl.formatMessage({
					id: "settings.teams.types.column.prefix",
					defaultMessage: "Prefix",
				})}
				placeholder={intl.formatMessage({
					id: "lookup.panel.create.placeholder.name",
					defaultMessage: "Vul een waarde in",
				})}
				type="text"
				errorMessage={hasSubmitted ? errors.prefix : undefined}
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.prefix ?? ""}
				className="ilx-text-field"
				autoComplete="Off"
			/>

			<TextField
				id="templateId"
				placeholder={intl.formatMessage({
					id: "settings.teams.types.column.templateId",
					defaultMessage: "Sjabloon ID",
				})}
				type="text"
				errorMessage={hasSubmitted ? errors.templateId : undefined}
				onChange={handleChange}
				onBlur={handleBlur}
				value={values.templateId ?? ""}
				className="ilx-text-field"
				onRenderLabel={() => (
					<div className="space-between">
						<Label>
							{intl.formatMessage({
								id: "settings.teams.types.column.templateId",
								defaultMessage: "Sjabloon ID",
							})}
						</Label>
						<ActionButton
							href="https://admin.teams.microsoft.com/teams/templates"
							target="_blank"
							iconProps={{ iconName: "AdminALogoInverse32" }}
						>
							Templates
						</ActionButton>
					</div>
				)}
				autoComplete="Off"
			/>

			<Toggle
				checked={values.isActive}
				className="ilx-toggle"
				label={intl.formatMessage({
					id: "lookup.panel.create.label.isActive",
					defaultMessage: "In gebruik",
				})}
				onText={intl.formatMessage({
					id: "general.yes",
					defaultMessage: "Ja",
				})}
				offText={intl.formatMessage({
					id: "general.no",
					defaultMessage: "Nee",
				})}
				onChange={() => setFieldValue("isActive", !values.isActive)}
			/>
		</section>
	);
};
