// Framework & third party
import React, { useEffect, useState } from "react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react";
// import Loader from "react-loader-spinner";

// Models
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { SuccessTypes } from "../../../../models/enums";

// Actions
import { resetSuccess, fetchAppSettingById } from "../../../../store/settings/actions";
import { fetchApplications } from "../../../../store/tenant/actions";

// Components

import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";
import { EventDashboardUrlComponent } from "./EventDashboardUrl";
import { EventCentralMailBoxComponent } from "./EventCentralMailBox";

const EventSettings = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const tenantSlice = useSelector((state: IApplicationState) => state.tenantSlice);
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);

	const eventsApp = tenantSlice.applications.filter((app) => app.name === "One for Events")[0];
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);
	// const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	/**
	 * Temporarily disabled
	 */
	// const [userHasAccess, setUserHasAccess] = React.useState(false);

	// React.useEffect(() => {
	// 	setUserHasAccess(
	// 		hasPermission(currentUserSlice.user, [PermissionCodes.ManageApplicationSettings])
	// 	);
	// }, [currentUserSlice.user]);

	const pathToPage = [
		{
			text: intl.formatMessage({
				id: "settings.general",
				defaultMessage: "Instellingen",
			}),
			key: "settings",
		},
		{
			text: intl.formatMessage({
				id: "settings.card.events.title",
				defaultMessage: "One for Events instellingen",
			}),
			key: "settings/eventsSettings",
		},
	];

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (settingsSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
		}
	}, [dispatch, firstRenderOccured, settingsSlice.success.type]);

	useEffect(() => {
		if (tenantSlice.applications.length === 0) {
			dispatch(fetchApplications());
		}
	}, [tenantSlice.applications.length, dispatch]);

	useEffect(() => {
		if (eventsApp !== undefined) {
			dispatch(fetchAppSettingById(eventsApp.id));
		}
	}, [eventsApp, dispatch]);

	/**
	 * Temporarily disabled
	 */

	// const renderEventCreationSettingUi = () => {
	// 	const settings = new Settings(settingsSlice.appSettingsById);
	// 	const RestrictEventCreationToAdminsOnlySetting = settings.getSettingByKey(
	// 		"settings",
	// 		"RestrictEventCreationToAdminsOnly"
	// 	);

	// 	const initialValues = {
	// 		RestrictEventCreationToAdminsOnly:
	// 			RestrictEventCreationToAdminsOnlySetting !== null
	// 				? RestrictEventCreationToAdminsOnlySetting.value
	// 				: "false",
	// 	};

	// 	return (
	// 		<Formik
	// 			initialValues={initialValues}
	// 			onSubmit={(values) => {
	// 				dispatch(
	// 					updateAppSettingsById(
	// 						eventsApp.id,
	// 						settings.convertSettingsToApiSaveFormat(values)
	// 					)
	// 				);
	// 			}}
	// 		>
	// 			{(props) => {
	// 				const {
	// 					values,
	// 					touched,
	// 					errors,
	// 					isSubmitting,
	// 					setSubmitting,
	// 					handleSubmit,
	// 					setFieldValue,
	// 				} = props;
	// 				if (
	// 					isSubmitting &&
	// 					(settingsSlice.success.type !== SuccessTypes.None ||
	// 						settingsSlice.error.type !== ErrorTypes.None)
	// 				) {
	// 					setSubmitting(false);
	// 				}
	// 				return (
	// 					<form onSubmit={handleSubmit}>
	// 						<label>
	// 							<span>
	// 								<FormattedMessage
	// 									id="settings.events.restrictEventCreation.description"
	// 									defaultMessage="Beperk het aanmaken van evenementen tot een gebruikersgroep."
	// 								/>
	// 							</span>
	// 						</label>
	// 						{errors.RestrictEventCreationToAdminsOnly &&
	// 							touched.RestrictEventCreationToAdminsOnly && (
	// 								<div className="input-feedback">
	// 									{errors.RestrictEventCreationToAdminsOnly}
	// 								</div>
	// 							)}

	// 						<Dropdown
	// 							selectedKey={values.RestrictEventCreationToAdminsOnly.toLowerCase()}
	// 							disabled={!userHasAccess}
	// 							style={{
	// 								margin: "1rem 0 3rem",
	// 							}}
	// 							onChange={(e, value) =>
	// 								value
	// 									? setFieldValue(
	// 											"RestrictEventCreationToAdminsOnly",
	// 											value.key
	// 									  )
	// 									: undefined
	// 							}
	// 							options={[
	// 								{
	// 									key: "false",
	// 									text: intl.formatMessage({
	// 										id: "settings.events.restrictEventCreation.option.allUsers",
	// 										defaultMessage: "Alle gebruikers",
	// 									}),
	// 								},
	// 								{
	// 									key: "true",
	// 									text: intl.formatMessage({
	// 										id: "settings.events.restrictEventCreation.option.adminOnly",
	// 										defaultMessage: "Administrators",
	// 									}),
	// 								},
	// 							]}
	// 						/>

	// 						<PrimaryButton type="submit" disabled={isSubmitting || !userHasAccess}>
	// 							<FormattedMessage id="general.save" defaultMessage="Opslaan" />
	// 						</PrimaryButton>
	// 					</form>
	// 				);
	// 			}}
	// 		</Formik>
	// 	);
	// };

	// const renderEventAccessUi = () => {
	// 	if (
	// 		tenantSlice.isFetchingApplications ||
	// 		settingsSlice.isFetchingAppSettingsById ||
	// 		settingsSlice.isLoading
	// 	) {
	// 		return (
	// 			<div className="loaderWrapper">
	// 				<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
	// 			</div>
	// 		);
	// 	}

	// 	return (
	// 		<div className="settings-setting-wrapper" test-name="settings-tile">
	// 			{!userHasAccess && (
	// 				<MessageBar
	// 					messageBarType={MessageBarType.info}
	// 					styles={{ root: { marginBottom: "10px" } }}
	// 				>
	// 					<FormattedMessage id="general.noAccess" defaultMessage="Geen toegang" />
	// 				</MessageBar>
	// 			)}
	// 			<h3>
	// 				<FormattedMessage id="settings.events.access.label" defaultMessage="Toegang" />
	// 			</h3>
	// 			<div className="settings-tile-body form-wrapper">
	// 				{renderEventCreationSettingUi()}
	// 			</div>
	// 		</div>
	// 	);
	// };

	return (
		<div>
			<BreadCrumbComponent path={pathToPage} />

			{settingsSlice.success.type !== SuccessTypes.None && (
				<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
					<FormattedMessage id="settings.save.success" defaultMessage="Instellingen zijn succesvol opgeslagen." />
				</MessageBar>
			)}

			<section className="setting-pages-wrapper">
				{/* {renderEventAccessUi()} */}
				<EventDashboardUrlComponent />
				<EventCentralMailBoxComponent/>
			</section>
		</div>
	);
};

export const EventSettingsComponent = injectIntl(EventSettings);