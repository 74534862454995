import { IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, TextField } from "@fluentui/react";
import * as React from "react";
import { hasValue } from "../../../utilities";

export interface ITextFieldErrorMessageProps {
	errorMessage: string | undefined;
	styles?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> | undefined;
}

/**
 * Renders the errorMessage of a textfield including it's animations without rendering the actual textfield.
 * This is useful since some FluentUi components don't support the errorMessage prop (yet).
 */
export const TextFieldErrorMessage = (props: ITextFieldErrorMessageProps) => {
	if (hasValue(props.errorMessage)) {
		return <TextField styles={{ wrapper: { display: "none" }, ...props.styles }} errorMessage={props.errorMessage} />;
	}

	return null;
};
