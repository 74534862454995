import { Dropdown, Label } from "@fluentui/react";
import { DefaultOwners } from "../../defaultOwners/DefaultOwners";
import { TeamTypeSettingsRequestBy } from "../../../../../models/enums/TeamTypeSettingsRequestBy";
import { TeamTypeSettingsApproveBy } from "../../../../../models/enums/TeamTypeSettingsApproveBy";
import React from "react";
import { useIntl } from "react-intl";
import { ITeamTypeFormValues } from "../../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";
import { FormikProps } from "formik";
import { ITeamTypeSettingProps } from "../../../../../interfaces/teamTypeSettings/ITeamTypeSettingProps";

interface NewTeamProps extends ITeamTypeSettingProps {
	formikProps: FormikProps<ITeamTypeFormValues>;
	hasSubmitted: boolean;
	defaultOwnersRequired?: boolean;
}

export default function NewTeam(props: NewTeamProps) {
	const intl = useIntl();

	return <>
		<Dropdown
			required
			label={intl.formatMessage({
				id: "settings.teams.newTeam.makeOwnerLabel",
				defaultMessage: "Maak contactpersoon eigenaar",
			})}
			className="ilx-text-field-small"
			selectedKey={Number(props.values.settings.newTeam.makeContactPersonOwner)}
			options={[
				{
					key: 0,
					text: intl.formatMessage({
						id: "general.no",
						defaultMessage: "Nee",
					}),
				},
				{
					key: 1,
					text: intl.formatMessage({
						id: "general.yes",
						defaultMessage: "Ja",
					}),
				},
			]}
			onChange={(event, option) => {
				if (option) {
					props.formikProps.setFieldValue("settings", {
						...props.values.settings,
						newTeam: {
							...props.values.settings.newTeam,
							makeContactPersonOwner: option.key
						}
					});
				}
			}}
		/>

		<DefaultOwners
			formikProps={props.formikProps}
			hasSubmitted={props.hasSubmitted}
			required={props.defaultOwnersRequired}
		/>

		<Dropdown
			required
			label="Tweede eigenaar verplicht"
			className="ilx-text-field-small"
			selectedKey={props.values.settings.newTeam.secondOwnerRequired ? 1 : 0}
			options={[
				{
					key: 0,
					text: intl.formatMessage({
						id: "general.no",
						defaultMessage: "Nee",
					}),
				},
				{
					key: 1,
					text: intl.formatMessage({
						id: "general.yes",
						defaultMessage: "Ja",
					}),
				},
			]}
			onChange={(event, option) => {
				if (option) {
					props.formikProps.setFieldValue("settings", {
						...props.values.settings,
						newTeam: {
							...props.values.settings.newTeam,
							secondOwnerRequired: option.key === 1
						}
					});
				}
			}}
		/>

		<Label>Kan ingediend worden door</Label>

		<Dropdown
			required
			className="ilx-text-field-small"
			selectedKeys={props.values.settings.newTeam.requestBy}
			placeholder="Iedereen kan indienen"
			multiSelect
			options={[
				{
					key: TeamTypeSettingsRequestBy.TeamManagers,
					text: intl.formatMessage({
						id: "settings.teams.newTeam.teamManagers",
						defaultMessage: "Team managers",
					}),
				},
				{
					key: TeamTypeSettingsRequestBy.Archivaris,
					text: intl.formatMessage({
						id: "settings.teams.newTeam.Archivist",
						defaultMessage: "Archivaris",
					}),
				}
			]}
			onChange={(event, option) => {
				if (option) {
					props.updateRequestOrApprovalBySettings("newTeam", "requestBy", option);
				}
			}}
			style={{ marginTop: "0.75rem" }}
		/>

		<Dropdown
			required
			label={intl.formatMessage({
				id: "settings.teams.newTeam.requestApprovalBy",
				defaultMessage: "Goedkeuring aanvragen per",
			})}
			className="ilx-text-field-small"
			selectedKeys={props.values.settings.newTeam.approvalBy}
			placeholder={intl.formatMessage({
				id: "settings.teams.newTeam.noApprovalRequired",
				defaultMessage: "Geen goedkeuring vereist",
			})}
			multiSelect
			options={[
				{
					key: TeamTypeSettingsApproveBy.Archivaris,
					text: intl.formatMessage({
						id: "settings.teams.newTeam.Archivist",
						defaultMessage: "Archivaris",
					}),
				},
				{
					key: TeamTypeSettingsApproveBy.TeamManagers,
					text: intl.formatMessage({
						id: "settings.teams.newTeam.teamManagers",
						defaultMessage: "Team managers",
					}),
				}
			]}
			onChange={(event, option) => {
				if (option) {
					props.updateRequestOrApprovalBySettings("newTeam", "approvalBy", option);
				}
			}}
		/>
	</>;
}