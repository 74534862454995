import { MessageBar, MessageBarType } from "@fluentui/react";
import { SlicePieceStatus } from "@one/core";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { RolesActionTypes } from "../../../../../store/roles/types";

export const RoleModalMessageBars = () => {
	const dispatch = useDispatch();
	const rolesSlice = useSelector((state: IApplicationState) => state.rolesSlice);
	const { roleModal } = rolesSlice;

	const timeOutDuration = 3000;

	React.useEffect(() => {
		if (roleModal.addRoleToUserStatus === SlicePieceStatus.Success) {
			setTimeout(() => {
				dispatch({
					type: RolesActionTypes.RESET_ADD_ROLE_TO_USER_STATUS,
				});
			}, timeOutDuration);
		}
	}, [roleModal.addRoleToUserStatus]);

	React.useEffect(() => {
		if (roleModal.removeRoleFromUserStatus === SlicePieceStatus.Success) {
			setTimeout(() => {
				dispatch({
					type: RolesActionTypes.RESET_REMOVE_ROLE_TO_USER_STATUS,
				});
			}, timeOutDuration);
		}
	}, [roleModal.removeRoleFromUserStatus]);

	return (
		<>
			{/* ---===[ Success ]===--- */}
			{roleModal.addRoleToUserStatus === SlicePieceStatus.Success && (
				<MessageBar messageBarType={MessageBarType.success}>
					<FormattedMessage id="roles.modal.add.message.success" />
				</MessageBar>
			)}
			{roleModal.removeRoleFromUserStatus === SlicePieceStatus.Success && (
				<MessageBar messageBarType={MessageBarType.success}>
					<FormattedMessage id="roles.modal.remove.message.success" />
				</MessageBar>
			)}

			{/* ---===[ Error ]===--- */}
			{roleModal.addRoleToUserStatus === SlicePieceStatus.Error && (
				<MessageBar
					messageBarType={MessageBarType.error}
					onDismiss={() =>
						dispatch({
							type: RolesActionTypes.RESET_ADD_ROLE_TO_USER_STATUS,
						})
					}
				>
					<FormattedMessage id="roles.modal.add.message.error" />
				</MessageBar>
			)}
			{roleModal.removeRoleFromUserStatus === SlicePieceStatus.Error && (
				<MessageBar
					messageBarType={MessageBarType.error}
					onDismiss={() =>
						dispatch({
							type: RolesActionTypes.RESET_REMOVE_ROLE_TO_USER_STATUS,
						})
					}
				>
					<FormattedMessage id="roles.modal.remove.message.error" />
				</MessageBar>
			)}
		</>
	);
};
