import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { IAudience } from "../../models/viewmodels/audiences/IAudience";
import { Group } from "@microsoft/microsoft-graph-types";

export enum AudiencesActionTypes {
	FETCH_GROUPS_STARTED = "FETCH_GROUPS_STARTED",
	FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS",
	FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE",

	FETCH_AUDIENCES_STARTED = "FETCH_AUDIENCES_STARTED",
	FETCH_AUDIENCES_SUCCESS = "FETCH_AUDIENCES_SUCCESS",
	FETCH_AUDIENCES_FAILURE = "FETCH_AUDIENCES_FAILURE",

	ADD_AUDIENCES_STARTED = "ADD_AUDIENCES_STARTED",
	ADD_AUDIENCES_SUCCESS = "ADD_AUDIENCES_SUCCESS",
	ADD_AUDIENCES_FAILURE = "ADD_AUDIENCES_FAILURE",

	UPDATE_AUDIENCES_STARTED = "UPDATE_AUDIENCES_STARTED",
	UPDATE_AUDIENCES_SUCCESS = "UPDATE_AUDIENCES_SUCCESS",
	UPDATE_AUDIENCES_FAILURE = "UPDATE_AUDIENCES_FAILURE",

	DELETE_AUDIENCES_STARTED = "DELETE_AUDIENCES_STARTED",
	DELETE_AUDIENCES_SUCCESS = "DELETE_AUDIENCES_SUCCESS",
	DELETE_AUDIENCES_FAILURE = "DELETE_AUDIENCES_FAILURE",

	RESET_SHOULD_CLOSE_PANEL = "RESET_SHOULD_CLOSE_PANEL",
	RESET_ERROR = "RESET_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",
}

export type AudiencesState = Readonly<{
	audiences: IAudience[];
	groups: Group[];

	isFetchingAudiences: boolean;
	isFetchingGroups: boolean;
	isCreating: boolean;

	shouldClosePanel: boolean;

	success: ISuccessState;
	error: IErrorState;
}>;
