import { MessageBar, MessageBarType, PrimaryButton, TextField,ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { hasAccess, PermissionCodes } from "@one/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "../../../../common/helperFunctions/SettingsHelpers";
import { ErrorTypes, SuccessTypes } from "../../../../models/enums";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { updateAppSettingsById } from "../../../../store/settings/actions";

const EventCentralMailBox = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const tenantSlice = useSelector((state: IApplicationState) => state.tenantSlice);
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const eventsApp = tenantSlice.applications.filter((app) => app.name === "One for Events")[0];
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	const [userHasAccess, setUserHasAccess] = React.useState(false);

	React.useEffect(() => {
		setUserHasAccess(hasAccess(currentUserSlice.user as any, [PermissionCodes.ManageApplicationSettings]));
	}, [currentUserSlice.user]);

	if (tenantSlice.isFetchingApplications || settingsSlice.isFetchingAppSettingsById || settingsSlice.isLoading) {
		return (
			<div className="loaderWrapper">
				<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
			</div>
		);
	}

	const settings = new Settings(settingsSlice.appSettingsById);
	const ONE_FOR_EVENTS_CENTRALMAILBOX = settings.getSettingByKey("settings", "ONE_FOR_EVENTS_CENTRALMAILBOX");

	const initialValues = {
		ONE_FOR_EVENTS_CENTRALMAILBOX: ONE_FOR_EVENTS_CENTRALMAILBOX !== null ? ONE_FOR_EVENTS_CENTRALMAILBOX.value : "",
		ONE_FOR_EVENTS_CHECKBOXKEY: ONE_FOR_EVENTS_CENTRALMAILBOX !== null && ONE_FOR_EVENTS_CENTRALMAILBOX.value !== "" && ONE_FOR_EVENTS_CENTRALMAILBOX.value !== null ? "CMB" : "ORG"
	};

	const options: IChoiceGroupOption[] = [
		{ key: 'ORG', text: intl.formatMessage({id: "settings.events.centraleMailbox.ORG"}) },
		{ key: 'CMB', text: intl.formatMessage({id: "settings.events.centraleMailbox.CMB"}) },
	  ];

	function isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	  }

	const getErrorMessage = (value: string): string => {
		
		return isValidEmail(value) ? '' : intl.formatMessage({id: "settings.events.centraleMailbox.message"});
	  };
	  

	return (
		<>
			<div className="settings-setting-wrapper" test-name="settings-tile">
				{!userHasAccess && (
					<MessageBar messageBarType={MessageBarType.info} styles={{ root: { marginBottom: "10px" } }}>
						<FormattedMessage id="general.noAccess" defaultMessage="Geen toegang" />
					</MessageBar>
				)}
				<h3>
					<FormattedMessage id="settings.events.centraleMailbox.label" defaultMessage="Event mailbox instellingen" />
				</h3>
				<div className="settings-tile-body ilx-form-wrapper">
					<Formik
						initialValues={initialValues}
						onSubmit={(values) => {
							const valuesToSend = {ONE_FOR_EVENTS_CENTRALMAILBOX: values.ONE_FOR_EVENTS_CENTRALMAILBOX}
							dispatch(updateAppSettingsById(eventsApp.id, settings.convertSettingsToApiSaveFormat(valuesToSend)));
						}}                     
					>
						{(props) => {
							const { values, touched, errors, isSubmitting, setSubmitting, handleSubmit, setFieldValue } = props;
                        
							if (
								isSubmitting &&
								(settingsSlice.success.type !== SuccessTypes.None || settingsSlice.error.type !== ErrorTypes.None)
							) {
								setSubmitting(false);
							}
							return (
								<form onSubmit={handleSubmit}>
									<ChoiceGroup
										// This is usually what you should do
										// label="Normal label"
										selectedKey={values.ONE_FOR_EVENTS_CHECKBOXKEY}
										options={options}
										onChange={(e, option) => {			
											setFieldValue("ONE_FOR_EVENTS_CHECKBOXKEY",option?.key);
											if (option?.key === "ORG"){
												setFieldValue("ONE_FOR_EVENTS_CENTRALMAILBOX", "");
											}
										}
											
										}
										styles={{
											root: {
												margin: "10px 0 30px 0",
											},
										}}										
									/>									
									<label>
										<span>
											<FormattedMessage
												id="settings.events.centraleMailbox.description"
												defaultMessage="Specificieer hier de Centrale Mailbox Email adres."
											/>
										</span>
									</label>
									{errors.ONE_FOR_EVENTS_CENTRALMAILBOX && touched.ONE_FOR_EVENTS_CENTRALMAILBOX && (
										<div className="input-feedback">{errors.ONE_FOR_EVENTS_CENTRALMAILBOX}</div>
									)}
									
									<div>
										<TextField label="Valid email:" underlined required placeholder="Enter text here"
											value={values.ONE_FOR_EVENTS_CENTRALMAILBOX}
											disabled={!userHasAccess || values.ONE_FOR_EVENTS_CHECKBOXKEY !== "CMB"}
											iconProps={{
												iconName: "Link",
											}}
											styles={{
												root: {
													margin: "10px 0 30px 0",
												},
											}}
											onChange={(e, value) => (value ? setFieldValue("ONE_FOR_EVENTS_CENTRALMAILBOX", value) : "")}
											onGetErrorMessage={(value) => values.ONE_FOR_EVENTS_CHECKBOXKEY === "CMB" ? getErrorMessage(value) : undefined}
										/>
									</div>

									<PrimaryButton type="submit" 
                                    disabled={isSubmitting || !userHasAccess || (values.ONE_FOR_EVENTS_CHECKBOXKEY === "CMB" &&!isValidEmail(values.ONE_FOR_EVENTS_CENTRALMAILBOX))}
                                    >
										<FormattedMessage id="general.save" defaultMessage="Opslaan" />
									</PrimaryButton>
									
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
		</>
	);
};

export const EventCentralMailBoxComponent = injectIntl(EventCentralMailBox);