import { IRoleAssignment, ISlicePiece } from "@one/core";
import { IErrorState } from "../../models/interfaces/IErrorState";
import { UserResponse } from "../../models/responseModels/user/UserResponse";
import { RoleResponse } from "../../models/responseModels/user/RoleResponse";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { User } from "@microsoft/microsoft-graph-types";

export enum UsersActionTypes {
	SEARCH_USERS_STARTED = "SEARCH_USERS_STARTED",
	SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS",
	SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE",

	FETCH_USER_BY_ID_STARTED = "FETCH_USER_BY_ID_STARTED",
	FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS",
	FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE",

	FETCH_USER_ROLE_ASSIGNMENTS_STARTED = "FETCH_USER_ROLE_ASSIGNMENTS_STARTED",
	FETCH_USER_ROLE_ASSIGNMENTS_SUCCESS = "FETCH_USER_ROLE_ASSIGNMENTS_SUCCESS",
	FETCH_USER_ROLE_ASSIGNMENTS_FAILURE = "FETCH_USER_ROLE_ASSIGNMENTS_FAILURE",

	FETCH_ALL_ROLES_STARTED = "FETCH_ALL_ROLES_STARTED",
	FETCH_ALL_ROLES_SUCCESS = "FETCH_ALL_ROLES_SUCCESS",
	FETCH_ALL_ROLES_FAILURE = "FETCH_ALL_ROLES_FAILURE",

	ADD_USER_ROLE_STARTED = "ADD_USER_ROLE_STARTED",
	ADD_USER_ROLE_SUCCESS = "ADD_USER_ROLE_SUCCESS",
	ADD_USER_ROLE_FAILURE = "ADD_USER_ROLE_FAILURE",

	REMOVE_USER_ROLE_STARTED = "REMOVE_USER_ROLE_STARTED",
	REMOVE_USER_ROLE_SUCCESS = "REMOVE_USER_ROLE_SUCCESS",
	REMOVE_USER_ROLE_FAILURE = "REMOVE_USER_ROLE_FAILURE",

	ADD_USER_STARTED = "ADD_USER_STARTED",
	ADD_USER_SUCCESS = "ADD_USER_SUCCESS",
	ADD_USER_FAILURE = "ADD_USER_FAILURE",

	DELETE_USER_STARTED = "DELETE_USER_STARTED",
	DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
	DELETE_USER_FAILURE = "DELETE_USER_FAILURE",

	RESET_SHOULD_CLOSE_PANEL = "U_RESET_SHOULD_CLOSE_PANEL",
	RESET_FETCHED_USER_BY_ID = "RESET_FETCHED_USER_BY_ID",
	RESET_SUCCESS = "RESET_SUCCESS",
	RESET_ERROR = "RESET_ERROR",
}

export type UsersState = Readonly<{
	query: string;
	searchResult: User[];
	fetchedUserById: UserResponse | null;
	fetchedUserRoleAssignments: ISlicePiece<IRoleAssignment[]>;
	allRoles: RoleResponse[];

	isSearching: boolean;
	isFetchingUserById: boolean;
	isFetchingAllRoles: boolean;
	isAddingUserRole: boolean;
	isRemovingUserRole: boolean;
	isCreatingUser: boolean;
	isDeletingUser: boolean;

	shouldClosePanel: boolean;
	success: ISuccessState;
	error: IErrorState;
}>;
