import Loader from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChoiceGroup, IChoiceGroupOptionProps, PrimaryButton } from "@fluentui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { ApplicationsResponse } from "../../../../../models/responseModels/tenant/ApplicationsResponse";
import { updateTeamCrawlSetting } from "../../../../../store/settings/actions";
import { TeamSyncOptions } from "../../../../../models/enums";

export interface IPrivacySettingsProps {
	teamsApp: ApplicationsResponse;
}

export const PrivacySettingsComponent = (props: IPrivacySettingsProps) => {
	const { teamsApp } = props;
	const intl = useIntl();

	const dispatch = useDispatch();
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const [selectedValue, setSelectedValue] = useState<string>("");

	const onSubmit = async () => {
		try {
			if (teamsApp) {
				dispatch(updateTeamCrawlSetting(parseInt(selectedValue)));
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		if (selectedValue === "") {
			let newValue: string = TeamSyncOptions.MinimalStorage.toString();
			settingsSlice.appSettingsById.settings.forEach((setting) => {
				if (setting.key === "OneForTeams_CrawlSetting") {
					newValue = setting.value;
				}
			});

			setSelectedValue(newValue);
		}
	}, [settingsSlice.appSettingsById]);

	const onRenderLabel = (
		props: IChoiceGroupOptionProps | undefined,
		render: ((props?: IChoiceGroupOptionProps | undefined) => JSX.Element | null) | undefined
	) => {
		let descriptionItems: string[] = [];

		switch (props?.key) {
			case TeamSyncOptions.NoStorage.toString():
				descriptionItems = [intl.formatMessage({ id: "settings.teams.privacy.option.noStorage.desc1" })];
				break;
			case TeamSyncOptions.MinimalStorage.toString():
				descriptionItems = [intl.formatMessage({ id: "settings.teams.privacy.option.minimalStorage.desc1" })];
				break;
			case TeamSyncOptions.OptimalPerformance.toString():
				descriptionItems = [
					intl.formatMessage({ id: "settings.teams.privacy.option.optimalPerformance.desc1" }),
					intl.formatMessage({ id: "settings.teams.privacy.option.optimalPerformance.desc2" }),
				];
				break;
		}

		return (
			<div className="team-privacy-option">
				<h3>{props?.text}</h3>
				<ul>
					{descriptionItems.map((text, i) => (
						<li key={i}>
							<span></span>
							{text}
						</li>
					))}
				</ul>
			</div>
		);
	};

	const choiceGroupStyles = {
		root: { flex: 1, height: "unset", padding: "2px" },
		choiceFieldWrapper: { flex: 1, height: "100%" },
		field: { display: "flex !important", padding: 0, height: "100%", justifyContent: "start" },
		labelWrapper: { maxWidth: "unset", height: "unset" },
		innerField: { minHeight: "40px", alignItems: "end", display: "flex" },
	};

	return (
		<div className="settings-setting-wrapper-large" test-name="settings-tile">
			<div className="settings-tile-body form-wrapper">
				{settingsSlice.isFetchingGroupSettings ? (
					<div className="loaderWrapper">
						<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
					</div>
				) : (
					<>
						<h3 style={{ marginBottom: "10px" }}>
							<FormattedMessage id="settings.teams.privacy.header" />
						</h3>
						<p>
							<FormattedMessage id="settings.teams.privacy.description" />
						</p>

						<ChoiceGroup
							style={{ margin: "5px 0" }}
							styles={{
								flexContainer: {
									flexWrap: "no-wrap",
								},
							}}
							selectedKey={selectedValue}
							options={[
								{
									key: TeamSyncOptions.NoStorage.toString(),
									text: intl.formatMessage({ id: "settings.teams.privacy.option.noStorage" }),
									iconProps: { iconName: "LaptopSecure" },
									onRenderLabel: onRenderLabel,
									styles: choiceGroupStyles,
								},
								{
									key: TeamSyncOptions.MinimalStorage.toString(),
									text: intl.formatMessage({ id: "settings.teams.privacy.option.minimalStorage" }),
									iconProps: { iconName: "Database" },
									onRenderLabel: onRenderLabel,
									styles: choiceGroupStyles,
								},

								{
									key: TeamSyncOptions.OptimalPerformance.toString(),
									text: intl.formatMessage({ id: "settings.teams.privacy.option.optimalPerformance" }),
									iconProps: { iconName: "LightningBolt" },
									onRenderLabel: onRenderLabel,
									styles: choiceGroupStyles,
								},
							]}
							onChange={(e, option) => (option !== undefined ? setSelectedValue(option.key) : undefined)}
						/>

						<PrimaryButton
							disabled={settingsSlice.isFetchingAppSettingsById || settingsSlice.isUpdatingAppSettingsById}
							onClick={() => onSubmit()}
						>
							<FormattedMessage id="general.save" defaultMessage="Opslaan" />
						</PrimaryButton>
					</>
				)}
			</div>
		</div>
	);
};
