import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import Loader from "react-loader-spinner";
import { PrimaryButton, MessageBar, MessageBarType, Dropdown, TextField } from "@fluentui/react";
import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";
import { Settings } from "../../../../common/helperFunctions/SettingsHelpers";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import {
	clearError,
	fetchAppSettingById,
	fetchGroupSettingsGraph,
	resetSuccess,
	updateAppSettingsById,
} from "../../../../store/settings/actions";
import { fetchApplications } from "../../../../store/tenant/actions";
import { SuccessTypes, ErrorTypes } from "../../../../models/enums";
import { TeamTypesComponent } from "./teamTypesComponent/TeamTypesComponent";
import { GroupSettingsComponent } from "./groupSettingsComponent/GroupSettingsComponent";
import { PrivacySettingsComponent } from "./privacySettingsComponent/PrivacySettingsComponent";

const TeamsSettings = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const tenantSlice = useSelector((state: IApplicationState) => state.tenantSlice);
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);

	const teamsApp = tenantSlice.applications.filter((app) => app.name === "One for Teams")[0];
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (settingsSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
			if (settingsSlice.error.type !== ErrorTypes.None) {
				dispatch(clearError());
			}
		}
	}, [dispatch, firstRenderOccured, settingsSlice.success.type, settingsSlice.error.type]);

	useEffect(() => {
		if (tenantSlice.applications.length === 0) {
			dispatch(fetchApplications());
		}
	}, [tenantSlice.applications.length, dispatch]);

	useEffect(() => {
		dispatch(fetchGroupSettingsGraph());
	}, [dispatch]);

	useEffect(() => {
		if (teamsApp !== undefined) {
			dispatch(fetchAppSettingById(teamsApp.id));
		}
	}, [teamsApp, dispatch]);

	const pathToPage = [
		{
			text: intl.formatMessage({
				id: "settings.general",
				defaultMessage: "Instellingen",
			}),
			key: "settings",
		},
		{
			text: intl.formatMessage({
				id: "settings.card.teams.title",
				defaultMessage: "One for Teams instellingen",
			}),
			key: "settings/teamsSettings",
		},
	];

	const renderSettingUi = () => {
		const settings = new Settings(settingsSlice.appSettingsById);
		const showProvisioningFormSetting = settings.getSettingByKey("settings", "ShowProvisioningForm");
		const languageSetting = settings.getSettingByKey("settings", "Language");

		const initialValues = {
			ShowProvisioningForm: showProvisioningFormSetting !== null ? showProvisioningFormSetting.value : "false",
			Language: languageSetting !== null ? languageSetting.value : "nl",
			ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS: settings.getSettingByKey("settings", "ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS")?.value ?? "false",
			ONE_FOR_TEAMS_ARCHIVING_NEW_OWNER_ID: settings.getSettingByKey("settings", "ONE_FOR_TEAMS_ARCHIVING_NEW_OWNER_ID")?.value ?? "",
			ONE_FOR_TEAMS_RELATED_MAILS_MAILBOX: settings.getSettingByKey("settings", "ONE_FOR_TEAMS_RELATED_MAILS_MAILBOX")?.value ?? ""
		};

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					dispatch(updateAppSettingsById(teamsApp.id, settings.convertSettingsToApiSaveFormat(values)));
				}}
			>
				{(props) => {
					const { values, touched, errors, isSubmitting, setSubmitting, handleSubmit, setFieldValue } = props;

					if (
						isSubmitting &&
						(settingsSlice.success.type !== SuccessTypes.None || settingsSlice.error.type !== ErrorTypes.None)
					) {
						setSubmitting(false);
					}
					return (
						<form onSubmit={handleSubmit}>
							<label>
								<span>
									<FormattedMessage
										id="settings.teams.showProvisioningForm.text"
										defaultMessage="Toon aanvraagformulier voor nieuwe teams aan gebruikers"
									/>
								</span>
							</label>
							{errors.ShowProvisioningForm && touched.ShowProvisioningForm && (
								<div className="input-feedback">{errors.ShowProvisioningForm}</div>
							)}

							<div style={{ margin: "1rem 0 3rem" }}>
								<Dropdown
									selectedKey={values.ShowProvisioningForm.toLowerCase()}
									onChange={(e, value) => (value ? setFieldValue("ShowProvisioningForm", value.key) : undefined)}
									options={[
										{
											key: "true",
											text: intl.formatMessage({
												id: "general.yes",
												defaultMessage: "Ja",
											}),
										},
										{
											key: "false",
											text: intl.formatMessage({
												id: "general.no",
												defaultMessage: "No",
											}),
										},
									]}
								/>
							</div>

							<label>
								<span>
									<FormattedMessage id="settings.teams.archivingChangeOwnersToMembers" />
								</span>
							</label>
							{errors.ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS && touched.ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS && (
								<div className="input-feedback">{errors.ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS}</div>
							)}

							<div style={{ margin: "1rem 0 3rem" }}>
								<Dropdown
									selectedKey={values.ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS.toLowerCase()}
									onChange={(_, value) => value ? setFieldValue("ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS", value.key) : undefined}
									options={[
										{
											key: "true",
											text: intl.formatMessage({ id: "general.yes" })
										},
										{
											key: "false",
											text: intl.formatMessage({ id: "general.no" })
										},
									]}
								/>
							</div>

							{values.ONE_FOR_TEAMS_ARCHIVING_CHANGE_OWNERS_TO_MEMBERS.toLowerCase() === "true" && <>
								<label>
									<span>ID van nieuwe team eigenaar bij wijzigen van eigenaren naar leden (verplicht)</span>
								</label>

								<div style={{ margin: "1rem 0 3rem" }}>
									<TextField
										value={values.ONE_FOR_TEAMS_ARCHIVING_NEW_OWNER_ID}
										onChange={(_, value) => value ? setFieldValue("ONE_FOR_TEAMS_ARCHIVING_NEW_OWNER_ID", value) : undefined}
									/>
								</div>
							</>}

							<label>
								<span>
									<FormattedMessage id="settings.teams.language.text" defaultMessage="De taal voor One for Teams" />
								</span>
							</label>
							{errors.Language && touched.Language && <div className="input-feedback">{errors.Language}</div>}

							<div style={{ margin: "1rem 0 3rem" }}>
								<Dropdown
									selectedKey={values.Language.toLowerCase()}
									onChange={(_, value) => value ? setFieldValue("Language", value.key) : undefined}
									options={[
										{
											key: "nl",
											text: "Nederlands",
										},
										{
											key: "en",
											text: "English",
										},
									]}
								/>
							</div>

							<label>
								<span>UPN van gebruiker waarmee e-mail verstuurd wordt</span>
							</label>

							<div style={{ margin: "1rem 0 3rem" }}>
								<TextField
									value={values.ONE_FOR_TEAMS_RELATED_MAILS_MAILBOX}
									onChange={(_, value) => value ? setFieldValue("ONE_FOR_TEAMS_RELATED_MAILS_MAILBOX", value) : undefined}
								/>
							</div>

							<PrimaryButton type="submit" style={{ marginTop: "1.5rem" }} disabled={isSubmitting}>
								<FormattedMessage id="general.save" defaultMessage="Opslaan" />
							</PrimaryButton>
						</form>
					);
				}}
			</Formik>
		);
	};

	const determineContent = () => {
		if (tenantSlice.isFetchingApplications || settingsSlice.isFetchingAppSettingsById || settingsSlice.isLoading) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className="settings-setting-wrapper" test-name="settings-tile">
					<div className="settings-tile-body">
						{renderSettingUi()}
					</div>
				</div>
				<GroupSettingsComponent />
				<PrivacySettingsComponent teamsApp={teamsApp} />
			</React.Fragment>
		);
	};

	return (
		<div>
			<BreadCrumbComponent path={pathToPage} />

			{settingsSlice.success.type !== SuccessTypes.None && (
				<MessageBar messageBarType={MessageBarType.success} onDismiss={() => dispatch(resetSuccess())}>
					<FormattedMessage id="settings.save.success" defaultMessage="Instellingen zijn succesvol opgeslagen." />
				</MessageBar>
			)}
			{settingsSlice.error.type !== ErrorTypes.None && (
				<MessageBar messageBarType={MessageBarType.error} onDismiss={() => dispatch(clearError())}>
					<FormattedMessage id="settings.save.error" defaultMessage="Er ging iets mis bij het opslaan van de instellingen." />
				</MessageBar>
			)}

			<section className="setting-pages-wrapper">
				<>{determineContent()}</>
				<TeamTypesComponent />
			</section>
		</div>
	);
};

export const TeamsSettingsComponent = injectIntl(TeamsSettings);
