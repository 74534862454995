// Framework & third-party
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

// Store & models
import { fetchJobDefinitions, setSelectedJob, setSelectedTenant } from "../../../store/jobs/actions";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { JobDefinitionCardComponent } from "./jobDefinitionCardComponent/JobDefinitionCardComponent";
import { SelectedJobComponent } from "./selectedJobComponent/SelectedJobComponent";
import { fetchTenants } from "../../../store/tenant/actions";
import { Dropdown, PrimaryButton } from "@fluentui/react";
import { JobsActionTypes } from "../../../store/jobs/types";

const Jobs = (props: WrappedComponentProps) => {
	const dispatch = useDispatch();
	const jobsSlice = useSelector((state: IApplicationState) => state.jobsSlice);
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);
	const tenantSlice = useSelector((state: IApplicationState) => state.tenantSlice);

	// Fetch jobs on component init.
	useEffect(() => {
		dispatch(fetchTenants());
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchJobDefinitions());
	}, [jobsSlice.selectedTenant, dispatch]);

	useEffect(() => {
		if (currentUserSlice?.user?.tenant !== undefined && currentUserSlice?.user?.tenant !== null) {
			dispatch(setSelectedTenant(currentUserSlice.user.tenant));
		}
	}, [dispatch, currentUserSlice]);

	const hasSelectedJob = jobsSlice.selectedJob !== null && jobsSlice.selectedJob.jobDefinition !== undefined;

	const renderContent = () => {
		if (jobsSlice.isFetchingDefinitions) {
			return (
				<div className="loading-wrapper">
					<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
				</div>
			);
		}

		if (hasSelectedJob) {
			return <SelectedJobComponent />;
		}

		return (
			<div className="jobs-definitions-container">
				{jobsSlice.jobDefinitions.map((jobDefinition) => (
					<JobDefinitionCardComponent
						key={jobDefinition.id}
						definition={jobDefinition}
						schedule={jobDefinition.schedule}
						onClick={() =>
							dispatch(
								setSelectedJob({
									jobDefinition: jobDefinition,
									history: null,
									isFetchingHistory: false,
								})
							)
						}
					/>
				))}
			</div>
		);
	};

	return (
		<section>
			{!hasSelectedJob && (
				<>
					<h1 className="page-title">
						<FormattedMessage id="jobs.navigationItem.title" defaultMessage="Jobs" />
					</h1>
					<div className="consent-button-container">
						<FormattedMessage
							id="settings.consent.required.prov.engine"
							defaultMessage="Om gebruik te maken van deze instelling is goedkeuring voor de One Provisioning Engine nodig."
						/>
						<PrimaryButton
							text={props.intl.formatMessage({
								id: "settings.consent.button",
								defaultMessage: "Admin consent",
							})}
							iconProps={{ iconName: "Admin" }}
							href={process.env.REACT_APP_ONE_PROVISIONING_ENGINE_CONSENT_URL}
							target="_blank"
						/>
					</div>

					<div style={{ marginBottom: "30px" }}>
						<Dropdown
							style={{ maxWidth: "102.5rem" }}
							label={props.intl.formatMessage({
								id: "jobs.tenant.select.label",
								defaultMessage: "Selecteer tenant",
							})}
							selectedKey={jobsSlice.selectedTenant === null ? currentUserSlice.user.tenant.id : jobsSlice.selectedTenant.id}
							disabled={tenantSlice.tenants === undefined || tenantSlice.tenants.length === 0}
							options={
								tenantSlice.tenants !== undefined
									? tenantSlice.tenants.map((tenant) => {
											return {
												key: tenant.id,
												text: tenant.name,
												data: tenant,
											};
									  })
									: []
							}
							onChange={(e, option) => {
								if (option !== undefined) {
									dispatch({
										type: JobsActionTypes.SET_SELECTED_TENANT,
										payload: {
											selectedTenant: option.data,
										},
									});
								}
							}}
						/>
					</div>
				</>
			)}

			<div className="jobs-page">{renderContent()}</div>
		</section>
	);
};

export const JobsComponent = injectIntl(Jobs);
