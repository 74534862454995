import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { TenantActionTypes, TenantState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";

// --== Fetch settings ==--
export const fetchApplications = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchApplicationsRequest());
	};
};

const fetchApplicationsRequest: ActionCreator<
	ThunkAction<Promise<any>, TenantState, null, any>
> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchApplicationsStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenant/applications`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchApplicationsFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchApplicationsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchApplicationsFailure("error"));
		}
	};
};

const fetchApplicationsStarted = () => ({
	type: TenantActionTypes.FETCH_APPLICATIONS_STARTED,
});

const fetchApplicationsSuccess = (applications: any) => ({
	type: TenantActionTypes.FETCH_APPLICATIONS_SUCCESS,
	payload: {
		applications,
	},
});

const fetchApplicationsFailure = (errorMessage: string | object) => ({
	type: TenantActionTypes.FETCH_APPLICATIONS_FAILURE,
	payload: {
		errorMessage,
	},
});

// ---=== Fetch tenants ===---
export const fetchTenants = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchTenantsRequest());
	};
};

const fetchTenantsRequest: ActionCreator<
	ThunkAction<Promise<any>, TenantState, null, any>
> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchTenantsStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/tenants/`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchTenantsFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(fetchTenantsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchTenantsFailure("error"));
		}
	};
};

const fetchTenantsStarted = () => ({
	type: TenantActionTypes.FETCH_TENANTS_STARTED,
});

const fetchTenantsSuccess = (tenants: any) => ({
	type: TenantActionTypes.FETCH_TENANTS_SUCCESS,
	payload: {
		tenants,
	},
});

const fetchTenantsFailure = (errorMessage: string | object) => ({
	type: TenantActionTypes.FETCH_TENANTS_FAILURE,
	payload: {
		errorMessage,
	},
});
