import * as React from "react";
import { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { fetchTaxonomy } from "../../../store/taxonomy/actions";

import { ISelectedTaxonomyItemViewModel } from "../../../models/viewmodels/taxonomy/ISelectedTaxonomyItemViewModel";
import { TaxonomyTreeComponent } from "./taxonomyTreeComponent/TaxonomyTreeComponent";
import { TaxonomyItem } from "../../../models/enums/TaxonomyITems";
import { SelectedGroupComponent } from "./selected/selectedGroupComponent/SelectedGroupComponent";

import { ITermGroup } from "../../../models/responseModels/taxonomy/ITermGroup";
import { ITerm } from "../../../models/responseModels/taxonomy/ITerm";
import { ITermSet } from "../../../models/responseModels/taxonomy/ITermSet";
import {
	SelectedTaxonomyComponent,
	SelectedTermSetComponent,
	SelectedTermComponent,
} from "./selected";
import { ITaxonomy } from "../../../models/responseModels/taxonomy/ITaxonomy";
import { NoneSelectedComponent } from "./selected/noneSelectedComponent/NoneSelectedComponent";

const Taxonomy = () => {
	const dispatch = useDispatch();
	const [selectedItem, setSelectedItem] = useState<ISelectedTaxonomyItemViewModel | null>(null);

	useEffect(() => {
		dispatch(fetchTaxonomy());
	}, [dispatch]);

	const renderCurrentSelected = () => {
		if (selectedItem?.type === TaxonomyItem.TermGroup) {
			return (
				<SelectedGroupComponent
					group={selectedItem.item as ITermGroup}
					shouldOpenPanel={!!selectedItem.openCreatePanel}
					resetShouldOpenPanel={() =>
						setSelectedItem({ ...selectedItem, openCreatePanel: false })
					}
				/>
			);
		}
		if (selectedItem?.type === TaxonomyItem.TermSet) {
			return (
				<SelectedTermSetComponent
					termSet={selectedItem.item as ITermSet}
					shouldOpenPanel={!!selectedItem.openCreatePanel}
					resetShouldOpenPanel={() =>
						setSelectedItem({ ...selectedItem, openCreatePanel: false })
					}
				/>
			);
		}
		if (selectedItem?.type === TaxonomyItem.Term) {
			return (
				<SelectedTermComponent
					term={selectedItem.item as ITerm}
					termSetIsEditable={!!selectedItem.termSetIsEditable}
					shouldOpenPanel={!!selectedItem.openCreatePanel}
					resetShouldOpenPanel={() =>
						setSelectedItem({ ...selectedItem, openCreatePanel: false })
					}
				/>
			);
		}
		if (selectedItem?.type === TaxonomyItem.Taxonomy) {
			return <SelectedTaxonomyComponent taxonomy={selectedItem.item as ITaxonomy} />;
		}
		if (selectedItem === null) {
			return <NoneSelectedComponent />;
		}
	};

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="taxonomy.navigation.item" defaultMessage="Taxonomie" />
			</h1>
			<div className="taxonomy-page">
				<TaxonomyTreeComponent
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
				/>

				{renderCurrentSelected()}
			</div>
		</section>
	);
};

export const TaxonomyComponent = injectIntl(Taxonomy);
