import { PermissionCodes } from "../enums";
import { IOneUser } from "../interfaces";
import { hasValue } from "./GeneralFunctions";

/**
 * Pass the One User and a list of permissions. If the user has at least one of the passed permission this function will result true. 
 */
export const hasAccess = (oneUser: IOneUser, permissions: PermissionCodes[]) => {
	if (hasValue(oneUser) && hasValue(oneUser.permissions)) {
		for (let i = 0; i < oneUser.permissions.length; i++) {
			const userPermission = oneUser.permissions[i];

			for (let n = 0; n < permissions.length; n++) {
				const requiredPermission = permissions[n];
				if (userPermission.code === requiredPermission) {
					return true;
				}
			}
		}
	}

	return false;
};
