import { Icon } from "@fluentui/react";
import React from "react";

import "./navigationItem.css";

const NavigationItem = ({ title, iconName, onClick = undefined }) => {
  let iconUI;
  if (iconName !== undefined) {
    iconUI = <Icon iconName={iconName} />;
  }

  return (
    <div className="navigation-item" onClick={onClick}>
      <div className="navigation-item__icon">{iconUI}</div>
      <div className="navigation-item__title">
        <span>{title}</span>
      </div>
    </div>
  );
};

export default NavigationItem;
