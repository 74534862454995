// usage (desc):    const [name] = obj.sort(sortArray([property]))
// usage (asc):     const [name] = obj.sort(sortArray([property])).reverse();
export const sortArray = (property: string) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		var result = a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
		return result * sortOrder;
	};
};
// usage (desc):    const [name] = obj.sort(sortArray([property]))
// usage (asc):     const [name] = obj.sort(sortArray([property])).reverse();
export const sortArrayIgnoreCase = (property: string) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		var result =
			a[property].toLowerCase() < b[property].toLowerCase()
				? 1
				: a[property].toLowerCase() > b[property].toLowerCase()
				? -1
				: 0;
		return result * sortOrder;
	};
};

export const objectToArray = (obj) => {
	var result = Object.keys(obj).map((key) => {
		return obj[key];
	});

	return result;
};

// usage
// const [name] = sortArrayMultipleProperties([array], [sortBy]);
// Params: SortBy:
// let sortBy = [
// 	{
// 		prop: "isFavorite",
// 		direction: -1
// 	},
// 	{
// 		prop: "title",
// 		direction: 1
// 	}
// ];
// 		Array: Can be any array.
export const sortArrayMultipleProperties = (array, sortBy) => {
	array.sort(function (a, b) {
		let i = 0,
			result = 0;
		while (i < sortBy.length && result === 0) {
			result =
				sortBy[i].direction *
				(a[sortBy[i].prop].toString().toLowerCase() <
				b[sortBy[i].prop].toString().toLowerCase()
					? -1
					: a[sortBy[i].prop].toString().toLowerCase() >
					  b[sortBy[i].prop].toString().toLowerCase()
					? 1
					: 0);
			i++;
		}
		return result;
	});
	return array;
};
