export enum PinnedItemScope {
	/// <summary>
	///    A pinned item for all applications and sites.
	/// </summary>
	Tenant,
	///  /// <summary>
	///    Pinned item for the current site.
	/// </summary>
	Site,
	/// <summary>
	///    Pinned item for the current webpart instance.
	/// </summary>
	///
	WebPart,
}
