import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailsList, SelectionMode, IColumn, ActionButton, PrimaryButton, DefaultButton } from "@fluentui/react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { fetchRoleAssignmentsForUser, removeUserRole } from "../../../../../store/users/actions";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { UserResponse } from "../../../../../models/responseModels/user/UserResponse";
import { ErrorTypes } from "../../../../../models/enums/ErrorTypes";
import { ConfirmPanel } from "../../../../reusable/confirmPanelComponent/ConfirmPanelComponent";
import { AddRolesPanelComponent } from "./addRolesPanelComponent/AddRolesPanelComponent";
import { IRole, IRoleAssignment } from "@one/core";

export interface RolesTabProps {
	user: UserResponse;
}

const RolesTab = (props: WrappedComponentProps & RolesTabProps) => {
	const { user, intl } = props;
	const dispatch = useDispatch();
	const usersSlice = useSelector((state: IApplicationState) => state.usersSlice);
	const currentUser = useSelector((state: IApplicationState) => state.currentUserSlice.user);

	const [addRolePanelIsOpen, setAddRolePanelIsOpen] = useState(false);
	const [roleToDeleteForUser, setRoleToDeleteForUser] = useState<IRole | null>(null);

	React.useEffect(() => {
		if (roleToDeleteForUser == null) {
			dispatch(fetchRoleAssignmentsForUser(user.id));
		}
	}, [, roleToDeleteForUser]);

	React.useEffect(() => {
		if (addRolePanelIsOpen) {
			setAddRolePanelIsOpen(false);
		}
		if (roleToDeleteForUser !== null) {
			setRoleToDeleteForUser(null);
		}

		// dispatch(fetchRoleAssignmentsForUser(user.id));
	}, [usersSlice.fetchedUserRoleAssignments.data]);

	const columns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({ id: "users.modal.roles.name.label", defaultMessage: "Rol" }),
			fieldName: "name",
			minWidth: 125,
			// maxWidth: 150,
			isResizable: false,
		},
		{
			key: "scope",
			name: intl.formatMessage({
				id: "users.list.column.scope",
				defaultMessage: "Scope",
			}),
			minWidth: 150,
			isResizable: true,
		},
		{
			key: "actions",
			name: intl.formatMessage({
				id: "users.modal.roles.actions.label",
				defaultMessage: "Acties",
			}),
			fieldName: "actions",
			minWidth: 100,
			maxWidth: 100,
			isResizable: false,
		},
	];
	// Add custom rendering for specific columns
	const _renderItemColumn = (roleAssignment: IRoleAssignment, index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}

		const fieldContent = roleAssignment.role[column.fieldName as keyof IRoleAssignment] as string;
		const instance = roleAssignment.applicationInstance;

		switch (column.key) {
			case "scope":
				return (
					<div className="column-field" title={instance?.title}>
						{instance !== null ? (
							<span>{instance.title || "-"}</span>
						) : (
							<span>
								<FormattedMessage id="users.list.column.scope.global" />
							</span>
						)}
					</div>
				);
			case "actions":
				if (currentUser.id === user.id && roleAssignment.role.isAdmin) {
					return (
						<div
							className="column-field"
							title={intl.formatMessage({
								id: "users.modal.roles.actions.cannotRemoveOwnAdminRole",
								defaultMessage: "U kunt niet de adminstrator rol bij uzelf verwijderen.",
							})}
						>
							<span>{"-"}</span>
						</div>
					);
				}
				return (
					<div className="column-field">
						{instance?.configuration?.siteUrl ? (
							<DefaultButton
								title={intl.formatMessage({ id: "general.navigate" })}
								iconProps={{ iconName: "OpenInNewTab" }}
								href={instance.configuration.siteUrl}
								target="_blank"
								styles={{
									root: {
										width: "100%",
									},
								}}
							/>
						) : (
							<PrimaryButton
								title={intl.formatMessage({ id: "lookup.list.action.delete" })}
								iconProps={{ iconName: "Trash" }}
								// disabled={roleModal.role === null || roleModal.removeRoleFromUserStatus === SlicePieceStatus.IsFetching}
								onClick={() => setRoleToDeleteForUser(roleAssignment.role)}
								styles={{
									root: {
										width: "100%",
									},
								}}
							/>
						)}
					</div>
				);

			default:
				return (
					<div className="column-field">
						<span>{fieldContent || "-"}</span>
					</div>
				);
		}
	};

	return (
		<div className="user-modal-content">
			<ActionButton iconProps={{ iconName: "Add" }} onClick={() => setAddRolePanelIsOpen(true)}>
				<FormattedMessage id="users.modal.roles.addRoles" defaultMessage="Voeg rollen toe" />
			</ActionButton>

			{usersSlice.fetchedUserRoleAssignments.data.length === 0 && (
				<div className="no-user-roles-container">
					<FormattedMessage id="users.modal.roles.noRoles" defaultMessage="Deze gebruiker heeft nog geen toegekende rollen." />
				</div>
			)}
			{usersSlice.fetchedUserRoleAssignments.data.length > 0 && (
				<DetailsList
					items={[...usersSlice.fetchedUserRoleAssignments.data]}
					// items={user.roles}
					selectionMode={SelectionMode.none}
					columns={columns}
					onRenderItemColumn={_renderItemColumn}
				/>
			)}

			<AddRolesPanelComponent
				userId={user.id}
				isOpen={addRolePanelIsOpen}
				onClose={() => setAddRolePanelIsOpen(false)}
				rolesToFilterOut={usersSlice.fetchedUserRoleAssignments.data
					.filter((ra) => ra.applicationInstance === null)
					.map((ra) => ra.role)}
			/>

			<ConfirmPanel
				isOpen={roleToDeleteForUser !== null}
				onConfirm={() => {
					if (roleToDeleteForUser !== null) {
						dispatch(removeUserRole(user.id, roleToDeleteForUser));
					}
				}}
				onCancel={() => setRoleToDeleteForUser(null)}
				headerText={intl.formatMessage({
					id: "users.modal.roles.remove.headerText",
					defaultMessage: "Ontneem rol",
				})}
				contentTitle={`${intl.formatMessage({
					id: "general.role",
					defaultMessage: "Rol",
				})}: ${(roleToDeleteForUser?.name || "").toLowerCase() || ""}`}
				contentDescription={intl.formatMessage({
					id: "users.modal.roles.remove.contentDescription",
					defaultMessage: "Weet u zeker dat u deze gebruiker de geselecteerde rol wilt ontnemen?",
				})}
				errorMessage={usersSlice.error.type === ErrorTypes.OnDelete ? usersSlice.error.message : ""}
			/>
		</div>
	);
};

export const RolesTabComponent = injectIntl(RolesTab);
