// Framework & Third-party
import * as React from "react";
import {
	Panel,
	PanelType,
	Icon,
	MessageBarType,
	MessageBar,
	PrimaryButton,
	Toggle,
	IDropdownOption,
	TextField,
	Dropdown,
	ChoiceGroup,
	IChoiceGroupOption,
} from "@fluentui/react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

// Models & stores
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ErrorTypes } from "../../../../models/enums";
import { resetError, addAudience } from "../../../../store/audiences/actions";
import { useState, useEffect } from "react";
import { IAddAudienceGroup } from "../../../../models/viewmodels/audiences/IAddAudienceGroup";
import { IAddAudience } from "../../../../models/viewmodels/audiences/IAddAudience";
import { AudienceTypes, AudienceVisibility } from "@one/core";

export interface ICreateAudiencePanelProps {
	isOpen: boolean;
	onClose: () => void;
}

const CreateAudiencePanel = (props: WrappedComponentProps & ICreateAudiencePanelProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const audiencesSlice = useSelector((state: IApplicationState) => state.audiencesSlice);

	const [mappedGroups, setMappedGroups] = useState<IDropdownOption[]>([]);

	useEffect(() => {
		if (audiencesSlice.groups.length !== 0) {
			const newlyMappedGroups = audiencesSlice.groups.map((group) => {
				const labelToUse = group.displayName !== undefined ? group.displayName : "";
				const valueToUse = group.id !== undefined ? group.id : "";
				return { key: valueToUse, text: labelToUse } as IDropdownOption;
			});

			setMappedGroups(newlyMappedGroups);
		}
	}, [audiencesSlice.groups]);

	return (
		<Panel
			className="one-panel"
			isOpen={props.isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={props.onClose}
			isLightDismiss
			styles={{
				scrollableContent: {
					overflow: "visible",
				},
			}}
			onLightDismissClick={props.onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage id="audiences.panel.create.header" defaultMessage="Nieuwe doelgroep" />
					</h1>

					<div className="one-panel__panel-close" onClick={props.onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			{audiencesSlice.error.type === ErrorTypes.OnCreate && (
				<MessageBar messageBarType={MessageBarType.error} onDismiss={() => dispatch(resetError())}>
					<FormattedMessage id="audiences.error.onCreate" defaultMessage="Er ging iets mis bij het aanmaken van deze doelgroep" />
				</MessageBar>
			)}
			{audiencesSlice.groups.length === 0 && (
				<MessageBar messageBarType={MessageBarType.info} onDismiss={() => dispatch(resetError())}>
					<FormattedMessage id="audiences.info.noSecurityGroups" defaultMessage="Er zijn geen beveiligingsgroepen gevonden." />
				</MessageBar>
			)}
			<section className="one-panel__panel-content">
				<div className="ilx-form-wrapper">
					<Formik
						enableReinitialize
						initialValues={{
							name: "",
							audienceGroups: [] as unknown as IDropdownOption[],
							isActive: true,
							type: AudienceTypes.One,
							visibility: AudienceVisibility.Public,
						}}
						onSubmit={(values) => {
							let audienceGroupsToSend: IAddAudienceGroup[] = [];
							if (values.type === AudienceTypes.One) {
								audienceGroupsToSend = values.audienceGroups.map((a: IDropdownOption) => {
									return { groupId: a.key.toString(), name: a.text };
								});
							}
							const requestBody = {
								name: values.name,
								audienceGroups: audienceGroupsToSend,
								isActive: values.isActive,
								type: values.type,
								visibility: values.visibility,
							} as IAddAudience;
							dispatch(addAudience(requestBody));
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.min(
									2,
									intl.formatMessage({
										id: "audiences.panel.validation.name.minTwoChars",
										defaultMessage: "De naam moet ten minste twee tekens lang zijn.",
									})
								)
								.required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
							audienceGroups: Yup.array().required(
								intl.formatMessage({
									id: "general.validation.required",
									defaultMessage: "Dit veld is verplicht.",
								})
							),
						})}
					>
						{(props) => {
							const {
								values,
								touched,
								errors,
								isSubmitting,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
								setFieldTouched,
							} = props;

							console.log(errors);

							return (
								<form onSubmit={handleSubmit}>
									<ChoiceGroup
										label={"Type"}
										selectedKey={values.type.toString()}
										onChange={(
											ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
											option?: IChoiceGroupOption | undefined
										) => {
											if (option) {
												const newValue = parseInt(option.key);
												setFieldValue("type", newValue);

												if (newValue === AudienceTypes.One) {
													setFieldValue("audienceGroups", []);
												} else if (newValue === AudienceTypes.SharePoint) {
													setFieldValue("audienceGroups", [{}]);
												}
											}
										}}
										options={[
											{
												key: AudienceTypes.One.toString(),
												text: "One",
											},
											{
												key: AudienceTypes.SharePoint.toString(),
												text: "SharePoint",
											},
										]}
									/>

									<TextField
										required
										id="name"
										placeholder={intl.formatMessage({
											id: "audiences.panel.placeholder.name",
											defaultMessage: "Vul een naam in",
										})}
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										label={intl.formatMessage({
											id: "audiences.panel.label.name",
											defaultMessage: "Naam",
										})}
										className={errors.name && touched.name ? "error ilx-text-field-small" : "ilx-text-field-small"}
										errorMessage={errors.name && touched.name ? errors.name : undefined}
									/>

									{values.type === AudienceTypes.One && (
										<div className="select-container">
											<Dropdown
												required
												label={intl.formatMessage({
													id: "audiences.panel.label.audienceGroups",
													defaultMessage: "Groepen",
												})}
												className="ilx-text-field-small"
												options={mappedGroups}
												errorMessage={
													errors.audienceGroups && touched.audienceGroups
														? errors.audienceGroups.toString()
														: undefined
												}
												multiSelect
												onChange={(event, option) => {
													let currentAudienceGroups = [...values.audienceGroups];

													if (option?.selected) {
														currentAudienceGroups.push({
															key: option?.key,
															text: option?.text,
														});
													} else {
														currentAudienceGroups = currentAudienceGroups.filter((e) => e.key !== option?.key);
													}

													setFieldValue("audienceGroups", currentAudienceGroups);

													// setFieldValue(
													// 	"audienceGroups",
													// 	value !== null ? value : []
													// )
												}}
												onBlur={() => {
													if (!touched.audienceGroups) {
														setFieldTouched("audienceGroups", true);
													}
												}}
												placeholder={intl.formatMessage({
													id: "audiences.panel.placeholder.audienceGroups",
													defaultMessage: "Selecteer één of meer groepen",
												})}
											/>
										</div>
									)}

									<div className="select-container">
										<Dropdown
											required
											label={intl.formatMessage({
												id: "audiences.panel.label.visibility",
												defaultMessage: "Zichtbaarheid",
											})}
											className="ilx-text-field-small"
											selectedKey={values.visibility}
											options={[
												{
													key: AudienceVisibility.Private,
													text: "Private",
												},
												{
													key: AudienceVisibility.Public,
													text: "Public",
												},
											]}
											onChange={(_event, option) => {
												if (option) {
													setFieldValue("visibility", option.key);
												}
											}}
										/>
									</div>

									<Toggle
										checked={values.isActive}
										className="toggle-input ilx-text-field"
										label={intl.formatMessage({
											id: "audiences.panel.label.isActive",
											defaultMessage: "In gebruik",
										})}
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) => setFieldValue("isActive", checked)}
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.create",
											defaultMessage: "Aanmaken",
										})}
										type="submit"
										disabled={
											isSubmitting ||
											Object.keys(touched).length === 0 ||
											(Object.keys(errors).length !== 0 && errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</section>
		</Panel>
	);
};

export const CreateAudiencePanelComponent = injectIntl(CreateAudiencePanel);
