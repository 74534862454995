import { ApplicationsResponse } from "../../models/responseModels/tenant/ApplicationsResponse";

export enum applicationActionTypes {
    FETCH_APPLICATIONS_STARTED = "FETCH_APPLICATIONS_STARTED",
    FETCH_APPLICATIONS_SUCCESS = "FETCH_APPLICATIONS_SUCCESS",
    FETCH_APPLICATIONS_FAILURE = "FETCH_APPLICATIONS_FAILURE",

}

export type ApplicationState = Readonly<{
    applications: ApplicationsResponse[];
    errorMessage: string;
    isLoading: boolean;
}>;