import { sortArray } from "./../../common/helperFunctions/ObjectsAndArrayHelpers";
import { RolesActionTypes, RolesState } from "./types";
import { SlicePieceStatus, IRole, IOneUser, IRoleAssignment } from "@one/core";
import { IRoleEnrichedOneUser } from "../../models/interfaces/IRoleEnrichedOneUser";

const initialState: RolesState = {
	roles: {
		data: null,
		fetchStatus: SlicePieceStatus.None,
	},
	roleModal: {
		role: null,
		roleAssignments: {
			data: [],
			fetchStatus: SlicePieceStatus.None,
		},
		permissions: {
			data: [],
			fetchStatus: SlicePieceStatus.None,
		},
		userSearchResult: {
			data: [],
			fetchStatus: SlicePieceStatus.None,
		},
		addRoleToUserStatus: SlicePieceStatus.None,
		removeRoleFromUserStatus: SlicePieceStatus.None,
	},
	rolePermissionsOverview: {
		data: {
			assignments: [],
			permissions: [],
			roles: [],
		},
		fetchStatus: SlicePieceStatus.None,
	},
};

export function rolesReducer(state = initialState, action: { type: RolesActionTypes; payload: any }): RolesState {
	switch (action.type) {
		// Fetch roles
		case RolesActionTypes.FETCH_ROLES_STARTED:
			return {
				...state,
				roles: {
					...state.roles,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case RolesActionTypes.FETCH_ROLES_SUCCESS:
			return {
				...state,
				roles: {
					...state.roles,
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case RolesActionTypes.FETCH_ROLES_FAILED:
			return {
				...state,
				roles: {
					...state.roles,
					fetchStatus: SlicePieceStatus.Error,
				},
			};

		// RoleModal
		case RolesActionTypes.SET_ROLE_MODAL_ROLE:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					role: action.payload,
				},
			};
		case RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_STARTED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					roleAssignments: {
						...state.roleModal.roleAssignments,
						fetchStatus: SlicePieceStatus.IsFetching,
					},
				},
			};
		case RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_SUCCESS:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					roleAssignments: {
						...state.roleModal.roleAssignments,
						data: action.payload.sort((a, b) => {
							if (a.user.displayName < b.user.displayName) {
								return -1;
							}
							if (a.user.displayName > b.user.displayName) {
								return 1;
							}
							return 0;
						}),
						fetchStatus: SlicePieceStatus.Success,
					},
				},
			};
		case RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_FAILED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					roleAssignments: {
						...state.roleModal.roleAssignments,
						fetchStatus: SlicePieceStatus.Error,
					},
				},
			};

		case RolesActionTypes.SEARCH_USERS_FOR_ROLE_STARTED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					userSearchResult: {
						...state.roleModal.userSearchResult,
						fetchStatus: SlicePieceStatus.IsFetching,
					},
				},
			};
		case RolesActionTypes.SEARCH_USERS_FOR_ROLE_SUCCESS:
			let enrichedResult: IRoleEnrichedOneUser[] = action.payload;

			enrichedResult = enrichedResult.map((user) => {
				const userAlreadyHasRole =
					state.roleModal.roleAssignments.data.filter((u) => u.applicationInstance == null && u.user.id === user.id).length > 0;
				return { ...user, hasCurrentRole: userAlreadyHasRole };
			});

			return {
				...state,
				roleModal: {
					...state.roleModal,
					userSearchResult: {
						...state.roleModal.userSearchResult,
						data: enrichedResult,
						fetchStatus: SlicePieceStatus.Success,
					},
				},
			};
		case RolesActionTypes.SEARCH_USERS_FOR_ROLE_FAILED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					userSearchResult: {
						...state.roleModal.userSearchResult,
						fetchStatus: SlicePieceStatus.Error,
					},
				},
			};
		case RolesActionTypes.RESET_SEARCH_USERS_FOR_ROLE:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					userSearchResult: initialState.roleModal.userSearchResult,
				},
			};

		// Add Role to User
		case RolesActionTypes.ADD_ROLE_TO_USER_STARTED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					addRoleToUserStatus: SlicePieceStatus.IsFetching,
				},
			};
		case RolesActionTypes.ADD_ROLE_TO_USER_SUCCESS:
			const newUsers = [...state.roleModal.roleAssignments.data];
			newUsers.unshift({ user: action.payload.user, role: action.payload.role, applicationInstance: null } as IRoleAssignment);

			const newSearchResult = [...state.roleModal.userSearchResult.data].map((u) => {
				if (u.id === action.payload.user.id) {
					return {
						...u,
						hasCurrentRole: true,
					};
				}
				return u;
			});

			return {
				...state,
				roleModal: {
					...state.roleModal,
					roleAssignments: {
						...state.roleModal.roleAssignments,
						data: newUsers,
					},
					userSearchResult: {
						...state.roleModal.userSearchResult,
						data: newSearchResult,
					},
					addRoleToUserStatus: SlicePieceStatus.Success,
				},
			};
		case RolesActionTypes.ADD_ROLE_TO_USER_FAILED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					addRoleToUserStatus: SlicePieceStatus.Error,
				},
			};
		case RolesActionTypes.RESET_ADD_ROLE_TO_USER_STATUS:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					addRoleToUserStatus: SlicePieceStatus.None,
				},
			};

		// Remove role from user
		case RolesActionTypes.REMOVE_ROLE_TO_USER_STARTED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					removeRoleFromUserStatus: SlicePieceStatus.IsFetching,
				},
			};
		case RolesActionTypes.REMOVE_ROLE_TO_USER_SUCCESS:
			const newUsersAfterRoleRemove = [...state.roleModal.roleAssignments.data].filter((ur) => {
				if (ur.role.id === action.payload.role.id && ur.user.id === action.payload.user.id) {
					if (action.payload.applicationInstance === null) {
						if (ur.applicationInstance === null) {
							return false;
						}
					} else {
						if (ur.applicationInstance !== null && action.payload.applicationInstance.id === ur.applicationInstance?.id) {
							return false;
						}
					}
				}
				return true;
			});

			return {
				...state,
				roleModal: {
					...state.roleModal,
					roleAssignments: {
						...state.roleModal.roleAssignments,
						data: newUsersAfterRoleRemove,
					},
					removeRoleFromUserStatus: SlicePieceStatus.Success,
				},
			};
		case RolesActionTypes.REMOVE_ROLE_TO_USER_FAILED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					removeRoleFromUserStatus: SlicePieceStatus.Error,
				},
			};
		case RolesActionTypes.RESET_REMOVE_ROLE_TO_USER_STATUS:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					removeRoleFromUserStatus: SlicePieceStatus.None,
				},
			};

		// Fetch permissions
		case RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_STARTED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					permissions: {
						...state.roleModal.permissions,
						fetchStatus: SlicePieceStatus.IsFetching,
					},
				},
			};
		case RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_SUCCESS:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					permissions: {
						...state.roleModal.permissions,
						data: action.payload,
						fetchStatus: SlicePieceStatus.Success,
					},
				},
			};
		case RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_FAILED:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					permissions: {
						...state.roleModal.permissions,
						fetchStatus: SlicePieceStatus.Error,
					},
				},
			};
		case RolesActionTypes.RESET_FETCH_PERMISSIONS_FOR_ROLE_STATUS:
			return {
				...state,
				roleModal: {
					...state.roleModal,
					permissions: {
						...state.roleModal.permissions,
						fetchStatus: SlicePieceStatus.None,
					},
				},
			};

		// Fetch role permissions overview
		case RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_STARTED:
			return {
				...state,
				rolePermissionsOverview: {
					...state.rolePermissionsOverview,
					fetchStatus: SlicePieceStatus.IsFetching,
				},
			};
		case RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_SUCCESS:
			return {
				...state,
				rolePermissionsOverview: {
					data: action.payload,
					fetchStatus: SlicePieceStatus.Success,
				},
			};
		case RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_FAILED:
			return {
				...state,
				rolePermissionsOverview: {
					...state.rolePermissionsOverview,

					fetchStatus: SlicePieceStatus.Error,
				},
			};

		case RolesActionTypes.RESET_ROLE_MODAL:
			return {
				...state,
				roleModal: initialState.roleModal,
			};
		default:
			return state;
	}
}
