import { ReleasesViewModel } from "./../../models/responseModels/Releases/ReleasesViewModel";
import { AddReleaseViewModel } from "./../../models/responseModels/Releases/AddReleaseViewModel";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ReleasesActionTypes, ReleasesState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";

export const fetchReleases = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(releasesRequest());
	};
};

const releasesRequest: ActionCreator<ThunkAction<Promise<any>, ReleasesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(releasesStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applications/releases`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchReleasesFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(fetchReleasesSuccess(result));
			}
		} catch (e) {
			dispatch(fetchReleasesFailure("error"));
		}
	};
};

const releasesStarted = () => ({
	type: ReleasesActionTypes.FETCH_RELEASES_STARTED,
});

const fetchReleasesSuccess = (releases: any) => ({
	type: ReleasesActionTypes.FETCH_RELEASES_SUCCESS,
	payload: {
		releases,
	},
});

const fetchReleasesFailure = (errorMessage: string | object) => ({
	type: ReleasesActionTypes.FETCH_RELEASES_FAILURE,
	payload: {
		errorMessage,
	},
});

/* #region Add release */

export const resetAddRequestNotification = () => ({
	type: ReleasesActionTypes.RESET_ADDREQUESTNOTIFICATION,
});

export const addRelease = (releaseData: AddReleaseViewModel) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(releaseRequest(releaseData));
	};
};

const releaseRequest: ActionCreator<ThunkAction<Promise<any>, ReleasesState, null, any>> = (releaseData) => {
	return async (dispatch: Dispatch) => {
		dispatch(addReleaseStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applications/releases`,
				{
					method: "POST",
					body: JSON.stringify(releaseData),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
					dispatch(addReleaseFailure(response.statusText));
					return;
				}

				const result: ReleasesViewModel = await response.json();
				dispatch(addReleasesSuccess(result));
			}
		} catch (e) {
			dispatch(addReleaseFailure("error"));
		}
	};
};

const addReleaseStarted = () => ({
	type: ReleasesActionTypes.FETCH_ADDRELEASE_STARTED,
});

const addReleasesSuccess = (newRelease) => ({
	type: ReleasesActionTypes.FETCH_ADDRELEASE_SUCCESS,
	payload: {
		newRelease,
	},
});

const addReleaseFailure = (createErrorMessage: string | object) => ({
	type: ReleasesActionTypes.FETCH_ADDRELEASE_FAILURE,
	payload: {
		createErrorMessage,
	},
});

/* #endregion */

// #region deleteRegion
export const deleteRelease = (id: number, appId: number) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(deleteRequest(id, appId));
	};
};

const deleteRequest: ActionCreator<ThunkAction<Promise<any>, ReleasesState, null, any>> = (
	releaseId: number,
	applicationId: number
) => {
	return async (dispatch: Dispatch) => {
		dispatch(deleteReleaseStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applications/${applicationId}/releases/${releaseId}?applicationId=${applicationId}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
					dispatch(deleteReleaseFailure(response.statusText));
					return;
				}

				const result: any = await response.json();
				dispatch(deleteReleaseSuccess(result, releaseId));
			}
		} catch (e) {
			console.log("error in action", e);
			dispatch(deleteReleaseFailure("error"));
		}
	};
};

const deleteReleaseStarted = () => ({
	type: ReleasesActionTypes.DELETE_RELEASE_STARTED,
});

const deleteReleaseSuccess = (succeed: boolean, releaseId: number) => ({
	type: ReleasesActionTypes.DELETE_RELEASE_SUCCESS,
	payload: {
		releaseId,
	},
});

const deleteReleaseFailure = (errorMessage: string | object) => ({
	type: ReleasesActionTypes.DELETE_RELEASE_FAILURE,
	payload: {
		errorMessage,
	},
});

// #endRegion

// #region updateRelease
export const updateRelease = (release: AddReleaseViewModel) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateRequest(release));
	};
};

const updateRequest: ActionCreator<ThunkAction<Promise<any>, ReleasesState, null, any>> = (
	release: AddReleaseViewModel
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateReleaseStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applications/releases`,
				{
					method: "PATCH",
					body: JSON.stringify(release),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
					dispatch(updateReleaseFailure(response.statusText));
					return;
				}

				const result: any = await response.json();
				dispatch(updateReleaseSuccess(result));
			}
		} catch (e) {
			console.log("error in action", e);
			dispatch(updateReleaseFailure("error"));
		}
	};
};

const updateReleaseStarted = () => ({
	type: ReleasesActionTypes.UPDATE_RELEASE_STARTED,
});

const updateReleaseSuccess = (release: ReleasesViewModel) => ({
	type: ReleasesActionTypes.UPDATE_RELEASE_SUCCESS,
	payload: {
		release,
	},
});

const updateReleaseFailure = (errorMessage: string | object) => ({
	type: ReleasesActionTypes.UPDATE_RELEASE_FAILURE,
	payload: {
		errorMessage,
	},
});

// #endRegion
