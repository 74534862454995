import React, { useEffect, useState, Fragment, useCallback } from "react";
import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";
import { adalOneApiFetch } from "../../../../adalConfig";
import { PrimaryButton, Spinner, TextField, Toggle } from "@fluentui/react";

type Connection = {
	id?: string;
	name: string;
	connectionTypeId: ConnectionType;
	values: ConnectionValue[];
}

type ConnectionValue = {
	key: string;
	value: string;
	isSecure?: boolean;
};

enum ConnectionType {
	OpenAiService = 4,
	AiSearchService = 5,
	CopilotExport = 7
}

export const CopilotSettingsComponent = () => {
	const [ connections, setConnections ] = useState<Connection[]>([]);
	const [ provisioning, setProvisioning ] = useState(false);

	const loadConnections = useCallback(() => {
		const connectionTypes = [
			ConnectionType.OpenAiService,
			ConnectionType.AiSearchService,
			ConnectionType.CopilotExport,
		];

		const requests: Promise<any>[] = [];

		for (const connectionType of connectionTypes) {
			const request = adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/bcs/connections/types/${connectionType}`, {})
				.then((response: Response) => response.status === 200 ? response.json() : null);

			requests.push(request);
		}

		return Promise.all(requests).then((connections) => connections.filter((connection) => !!connection));
	}, []);

	const runProvisioning = () => {
		setProvisioning(true);

		adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/copilotManagement/search`, { method: "POST" })
			.finally(() => setProvisioning(false));
	};

	useEffect(() => {
		loadConnections().then(setConnections);
	}, [ loadConnections ]);

	const updateConnections = () => {
		const requests: Promise<any>[] = [];

		for (const connection of connections) {
			let method = "POST";
			let body: any = { name: connection.name, connectionTypeId: connection.connectionTypeId, values: connection.values };
			let success = 201;

			if (connection.id !== undefined) {
				method = "PATCH";
				body = { id: connection.id, values: connection.values };
				success = 200;
			}

			const request = adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/bcs/connections`, {
				method,
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			}).then((response: Response) => response.status === success ? response.json() : null);

			requests.push(request);
		}

		Promise.all(requests).then(() => loadConnections()).then(setConnections);
	};

	const setConnectionValue = (type: ConnectionType, connectionValue: ConnectionValue) => {
		setConnections((state) => {
			const newState = [ ...state ];
			const connectionIndex = newState.findIndex((connection) => connection.connectionTypeId === type);

			if (connectionIndex === -1) {
				return newState;
			}

			const valueIndex = newState[connectionIndex].values.findIndex((value) => value.key === connectionValue.key);

			if (valueIndex === -1) {
				return newState;
			}

			newState[connectionIndex].values[valueIndex] = {
				...newState[connectionIndex].values[valueIndex],
				...connectionValue,
			};

			return newState;
		});
	};

	const connectionDefinitions = [
		{
			name: "Azure OpenAI Service",
			type: ConnectionType.OpenAiService,
			fields: [
				{
					key: "openAIEndpoint",
					label: "Endpoint",
				},
				{
					key: "openAIApiKey",
					label: "API key",
				},
				{
					key: "openAIDeploymentModel",
					label: "Deployment model",
				},
			],
		},
		{
			name: "Azure AI Search Service",
			type: ConnectionType.AiSearchService,
			fields: [
				{
					key: "aiSearchEndpoint",
					label: "Endpoint",
				},
				{
					key: "aiSearchManagementKey",
					label: "Management key",
				},
				{
					key: "aiSearchQueryKey",
					label: "Query key",
				},
				{
					key: "aiSearchSharePointAppId",
					label: "SharePoint app ID",
				},
				{
					key: "aiSearchSharePointAppSecret",
					label: "SharePoint app secret",
				},
				{
					key: "aiSearchTenantId",
					label: "Tenant ID",
				},
			],
		},
		{
			name: "Copilot export",
			type: ConnectionType.CopilotExport,
			fields: [
				{
					key: "storageAccountName",
					label: "Storage account name",
				},
				{
					key: "storageAccountKey",
					label: "Storage account key",
				},
			],
		},
	];

	return (
		<div>
			<BreadCrumbComponent
				path={[
					{
						text: "Instellingen",
						key: "settings",
					},
					{
						text: "One Copilot instellingen",
						key: "settings/copilotSettings",
					},
				]}
			/>

			<section>
				<div className="settings-setting-wrapper">
					<div className="settings-tile-body">
						<h2>Resource provisioning</h2>

						<PrimaryButton disabled={provisioning} onClick={() => runProvisioning()}>
							{provisioning && <Spinner styles={{ root: { marginRight: "10px" } }} />}Run provisioning
						</PrimaryButton>
					</div>
				</div>

				<div className="settings-setting-wrapper">
					<div className="settings-tile-body">
						<h2>Connecties</h2>

						{connectionDefinitions.map((connectionDefinition, connectionIndex) => {
							const existingConnection = connections.find((existingConnection) => existingConnection.connectionTypeId === connectionDefinition.type);

							return (
								<Fragment key={connectionIndex}>
									<h3 style={{ marginBottom: "0.5em" }}>{connectionDefinition.name}</h3>

									{!existingConnection && (
										<PrimaryButton onClick={() => {
											setConnections((state) => {
												return [
													...state,
													{
														name: connectionDefinition.name,
														connectionTypeId: connectionDefinition.type,
														values: connectionDefinition.fields.map((field) => {
															return {
																key: field.key,
																value: "",
																isSecure: false,
															};
														}),
													},
												];
											});
										}}>
											Nieuw
										</PrimaryButton>
									)}

									{existingConnection && connectionDefinition.fields.map((field, fieldIndex) => {
										const existingValue = existingConnection.values.find((value) => value.key === field.key);

										if (!existingValue) {
											return null;
										}

										return <Fragment key={fieldIndex}>
											<TextField
												label={field.label}
												value={existingValue.value}
												onChange={(_, value) => value && setConnectionValue(connectionDefinition.type, { ...existingValue, value })}
												styles={{ wrapper: { marginBottom: existingConnection.id && fieldIndex < connectionDefinition.fields.length - 1 ? "1em" : undefined } }}
											/>

											{!existingConnection.id && (
												<Toggle
													label="Is secure"
													checked={existingValue.isSecure}
													onChange={(_, isSecure) => isSecure !== undefined && setConnectionValue(connectionDefinition.type, { ...existingValue, isSecure })}
													styles={{ container: { marginBottom: fieldIndex < connectionDefinition.fields.length - 1 ? "1em" : undefined } }}
												/>
											)}
										</Fragment>;
									})}

									<hr style={{ margin: "2em 0" }} />
								</Fragment>
							);
						})}

						<PrimaryButton onClick={() => updateConnections()}>Opslaan</PrimaryButton>
					</div>
				</div>
			</section>
		</div>
	);
};
