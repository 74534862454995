import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { IMailTemplate } from "../../models/viewmodels/mailTemplates/IMailTemplate";
import { IManageMailTemplate } from "../../models/viewmodels/mailTemplates/IManageMailTemplate";

export enum MailTemplatesActionTypes {
	CLEAR_ERROR = "CLEAR_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",

	FETCH_MAIL_TEMPLATES_STARTED = "FETCH_MAIL_TEMPLATES_STARTED",
	FETCH_MAIL_TEMPLATES_SUCCESS = "FETCH_MAIL_TEMPLATES_SUCCESS",
	FETCH_MAIL_TEMPLATES_FAILURE = "FETCH_MAIL_TEMPLATES_FAILURE",

	ADD_MAIL_TEMPLATES_STARTED = "ADD_MAIL_TEMPLATES_STARTED",
	ADD_MAIL_TEMPLATES_SUCCESS = "ADD_MAIL_TEMPLATES_SUCCESS",
	ADD_MAIL_TEMPLATES_FAILURE = "ADD_MAIL_TEMPLATES_FAILURE",

	UPDATE_MAIL_TEMPLATES_STARTED = "UPDATE_MAIL_TEMPLATES_STARTED",
	UPDATE_MAIL_TEMPLATES_SUCCESS = "UPDATE_MAIL_TEMPLATES_SUCCESS",
	UPDATE_MAIL_TEMPLATES_FAILURE = "UPDATE_MAIL_TEMPLATES_FAILURE",

	DELETE_MAIL_TEMPLATES_STARTED = "DELETE_MAIL_TEMPLATES_STARTED",
	DELETE_MAIL_TEMPLATES_SUCCESS = "DELETE_MAIL_TEMPLATES_SUCCESS",
	DELETE_MAIL_TEMPLATES_FAILURE = "DELETE_MAIL_TEMPLATES_FAILURE",

	FETCH_MANAGE_MAIL_TEMPLATE_STARTED = "FETCH_MANAGE_MAIL_TEMPLATE_STARTED",
	FETCH_MANAGE_MAIL_TEMPLATE_SUCCESS = "FETCH_MANAGE_MAIL_TEMPLATE_SUCCESS",
	FETCH_MANAGE_MAIL_TEMPLATE_FAILURE = "FETCH_MANAGE_MAIL_TEMPLATE_FAILURE",
}

export type MailTemplatesState = Readonly<{
	mailTemplates: IMailTemplate[];
	currentManageMailTemplate: IManageMailTemplate | null;

	loaders: {
		isLoadingInitialData: boolean;
		isLoadingDetailData: boolean;
		isCreating: boolean;
		isUpdating: boolean;
		isDeleting: boolean;
	};

	error: IErrorState;
	success: ISuccessState;
}>;
