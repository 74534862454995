import { LookupListsResponse } from "../../models/responseModels/lookupLists/LookupListsResponse";
import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";

export enum LookupListsActionTypes {
	CLEAR_ERROR = "CLEAR_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",
	UPDATE_SHOULD_CLOSE_PANEL = "UPDATE_SHOULD_CLOSE_PANEL",

	FETCH_LOOKUPLIST_STARTED = "FETCH_LOOKUPLIST_STARTED",
	FETCH_LOOKUPLIST_SUCCESS = "FETCH_LOOKUPLIST_SUCCESS",
	FETCH_LOOKUPLIST_FAILURE = "FETCH_LOOKUPLIST_FAILURE",

	CREATE_LOOKUPLIST_VALUE_STARTED = "CREATE_LOOKUPLIST_VALUE_STARTED",
	CREATE_LOOKUPLIST_VALUE_SUCCESS = "CREATE_LOOKUPLIST_VALUE_SUCCESS",
	CREATE_LOOKUPLIST_VALUE_FAILURE = "CREATE_LOOKUPLIST_VALUE_FAILURE",

	UPDATE_LOOKUPLIST_VALUE_STARTED = "UPDATE_LOOKUPLIST_VALUE_STARTED",
	UPDATE_LOOKUPLIST_VALUE_SUCCESS = "UPDATE_LOOKUPLIST_VALUE_SUCCESS",
	UPDATE_LOOKUPLIST_VALUE_FAILURE = "UPDATE_LOOKUPLIST_VALUE_FAILURE",

	DELETE_LOOKUPLIST_VALUE_STARTED = "DELETE_LOOKUPLIST_VALUE_STARTED",
	DELETE_LOOKUPLIST_VALUE_SUCCESS = "DELETE_LOOKUPLIST_VALUE_SUCCESS",
	DELETE_LOOKUPLIST_VALUE_FAILURE = "DELETE_LOOKUPLIST_VALUE_FAILURE",
}

export type LookupListsState = Readonly<{
	lookupLists: LookupListsResponse[];
	shouldClosePanel: boolean;

	isCreating: boolean;
	isLoadingInitialData: boolean;
	isDeleting: boolean;

	error: IErrorState;
	success: ISuccessState;
}>;
