import React from "react";
import "./leftNavigation.css";

const LeftNavigation = ({ children }) => {
  return (
    <aside id="left-navigation">
      <nav className="left-navigation">{children}</nav>
    </aside>
  );
};

export default LeftNavigation;
