import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@fluentui/react";

const HamburgerUiListItem = props => {
  return (
    <li key={props.title}>
      {props.navbarLink}
      <div
        className="mobile-menu__menu-item-wrapper"
        onClick={() => props.hamburgerMenuToggle(false)}
      >
        <div className="mobile-menu__icon">
          <Icon iconName={props.icon} />
        </div>
        <div className="mobile-menu__text">{props.title}</div>
      </div>
      {props.closeNavbarLink}
    </li>
  );
};

HamburgerUiListItem.propTypes = {
  title: PropTypes.string,
  hamburgerMenuToggle: PropTypes.func,
  icon: PropTypes.string
};

export default HamburgerUiListItem;
