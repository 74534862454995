let timeout = 0;

export function delay(fn: any, ms: any) {
	if (timeout) clearTimeout(timeout);
	(timeout as any) = setTimeout(fn, ms);
}

// returns the IE version, returns 0 if the userAgent is not IE.
export function GetIEVersion() {
	var sAgent = window.navigator.userAgent;
	var Idx = sAgent.indexOf("MSIE");

	// If IE, return version number.
	if (Idx > 0) {
		return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
	}
	// If IE 11 then look for Updated user agent string.
	else if (!!navigator.userAgent.match(/Trident\/7\./)) {
		return 11;
	} else {
		return 0; //It is not IE
	}
}

export const hasValue = (input: any) => {
	return input !== null && input !== undefined && input !== "";
};
