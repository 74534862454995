import { MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import { hasAccess, PermissionCodes } from "@one/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "../../../../common/helperFunctions/SettingsHelpers";
import { ErrorTypes, SuccessTypes } from "../../../../models/enums";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { updateAppSettingsById } from "../../../../store/settings/actions";

const EventDashboardUrl = (props: WrappedComponentProps) => {
	// const { intl } = props;
	const dispatch = useDispatch();
	const tenantSlice = useSelector((state: IApplicationState) => state.tenantSlice);
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const eventsApp = tenantSlice.applications.filter((app) => app.name === "One for Events")[0];
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	const [userHasAccess, setUserHasAccess] = React.useState(false);

	React.useEffect(() => {
		setUserHasAccess(hasAccess(currentUserSlice.user as any, [PermissionCodes.ManageApplicationSettings]));
	}, [currentUserSlice.user]);

	if (tenantSlice.isFetchingApplications || settingsSlice.isFetchingAppSettingsById || settingsSlice.isLoading) {
		return (
			<div className="loaderWrapper">
				<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
			</div>
		);
	}

	const settings = new Settings(settingsSlice.appSettingsById);
	const ONE_FOR_EVENTS_DASHBOARD_URL = settings.getSettingByKey("settings", "ONE_FOR_EVENTS_DASHBOARD_URL");

	const initialValues = {
		ONE_FOR_EVENTS_DASHBOARD_URL: ONE_FOR_EVENTS_DASHBOARD_URL !== null ? ONE_FOR_EVENTS_DASHBOARD_URL.value : "false",
	};

	return (
		<>
			<div className="settings-setting-wrapper" test-name="settings-tile">
				{!userHasAccess && (
					<MessageBar messageBarType={MessageBarType.info} styles={{ root: { marginBottom: "10px" } }}>
						<FormattedMessage id="general.noAccess" defaultMessage="Geen toegang" />
					</MessageBar>
				)}
				<h3>
					<FormattedMessage id="settings.events.dashboardUrl.label" defaultMessage="Evenementen dashboard locatie" />
				</h3>
				<div className="settings-tile-body ilx-form-wrapper">
					<Formik
						initialValues={initialValues}
						onSubmit={(values) => {
							dispatch(updateAppSettingsById(eventsApp.id, settings.convertSettingsToApiSaveFormat(values)));
						}}
					>
						{(props) => {
							const { values, touched, errors, isSubmitting, setSubmitting, handleSubmit, setFieldValue } = props;
							if (
								isSubmitting &&
								(settingsSlice.success.type !== SuccessTypes.None || settingsSlice.error.type !== ErrorTypes.None)
							) {
								setSubmitting(false);
							}
							return (
								<form onSubmit={handleSubmit}>
									<label>
										<span>
											<FormattedMessage
												id="settings.events.dashboardUrl.description"
												defaultMessage="Specificieer hier naar welke pagina gedeelde evenementen naartoe dienen te verwijzen."
											/>
										</span>
									</label>
									{errors.ONE_FOR_EVENTS_DASHBOARD_URL && touched.ONE_FOR_EVENTS_DASHBOARD_URL && (
										<div className="input-feedback">{errors.ONE_FOR_EVENTS_DASHBOARD_URL}</div>
									)}

									<TextField
										value={values.ONE_FOR_EVENTS_DASHBOARD_URL}
										disabled={!userHasAccess}
										iconProps={{
											iconName: "Link",
										}}
										styles={{
											root: {
												margin: "10px 0 30px 0",
											},
										}}
										onChange={(e, value) => (value ? setFieldValue("ONE_FOR_EVENTS_DASHBOARD_URL", value) : undefined)}
									/>

									<PrimaryButton type="submit" disabled={isSubmitting || !userHasAccess}>
										<FormattedMessage id="general.save" defaultMessage="Opslaan" />
									</PrimaryButton>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
		</>
	);
};

export const EventDashboardUrlComponent = injectIntl(EventDashboardUrl);
