import { ReleasesViewModel } from "./../../models/responseModels/Releases/ReleasesViewModel";
import { ReleasesActionTypes, ReleasesState } from "./types";
import { sortArray } from "../../common/helperFunctions/ObjectsAndArrayHelpers";

const initialState: ReleasesState = {
	releases: [] as ReleasesViewModel[],
	errorMessage: "",
	isLoading: false,
	createErrorMessage: "",
	createRequestFired: false,
	deleteIsLoading: false,
	deleteErrorMessage: "",
	updateRequestErrorMessage: "",
	updateRequestFired: false,
};

export function releasesReducer(
	state = initialState,
	action: { type: ReleasesActionTypes; payload: any }
): ReleasesState {
	switch (action.type) {
		case ReleasesActionTypes.FETCH_RELEASES_STARTED:
			return {
				...state,
				isLoading: true,
			};
		case ReleasesActionTypes.FETCH_RELEASES_SUCCESS:
			let releases = [...action.payload.releases];

			return {
				...state,
				releases: releases,
				errorMessage: "",
				isLoading: false,
			};
		case ReleasesActionTypes.FETCH_RELEASES_FAILURE:
			return {
				...state,
				errorMessage: action.payload.errorMessage,
				isLoading: false,
			};

		case ReleasesActionTypes.FETCH_ADDRELEASE_STARTED:
			return {
				...state,
				isLoading: true,
				createErrorMessage: "",
				createRequestFired: true,
			};
		case ReleasesActionTypes.FETCH_ADDRELEASE_SUCCESS:
			const release: ReleasesViewModel = Object.assign(action.payload.newRelease);
			const convertedDate = release.publishingDate.toString().substring(0, 10);

			release.publishingDate = convertedDate;
			let allReleases = [...state.releases];
			allReleases.push(release);
			allReleases = allReleases.sort(sortArray("publishingDate"));

			return {
				...state,
				releases: allReleases,
				createErrorMessage: "",
				isLoading: false,
				errorMessage: "",
			};

		case ReleasesActionTypes.FETCH_ADDRELEASE_FAILURE:
			return {
				...state,
				createErrorMessage: action.payload.createErrorMessage,
				isLoading: false,
			};

		case ReleasesActionTypes.RESET_ADDREQUESTNOTIFICATION:
			return {
				...state,
				createRequestFired: false,
			};

		case ReleasesActionTypes.DELETE_RELEASE_STARTED:
			return {
				...state,
				deleteIsLoading: true,
			};
		case ReleasesActionTypes.DELETE_RELEASE_SUCCESS:
			const updatedReleases = [...state.releases].filter((release: ReleasesViewModel) => {
				if (release.id !== action.payload.releaseId) {
					return release;
				}

				return null;
			});

			return {
				...state,
				releases: updatedReleases,
				deleteErrorMessage: "",
				deleteIsLoading: false,
			};

		case ReleasesActionTypes.DELETE_RELEASE_FAILURE:
			return {
				...state,
				deleteErrorMessage: action.payload.errorMessage,
				deleteIsLoading: false,
			};

		case ReleasesActionTypes.UPDATE_RELEASE_STARTED:
			return {
				...state,
				isLoading: true,
				updateRequestErrorMessage: "",
				updateRequestFired: true,
			};
		case ReleasesActionTypes.UPDATE_RELEASE_SUCCESS:
			const allReleaseNotes: ReleasesViewModel[] = [...state.releases];
			const updatedRelease: ReleasesViewModel = Object.assign(action.payload.release);
			updatedRelease.publishingDate = updatedRelease.publishingDate.toString().substring(0, 10);

			let updatedReleaseNotes: ReleasesViewModel[] = allReleaseNotes.map((release) => {
				if (release.id === updatedRelease.id) {
					release = updatedRelease;
				}

				return release;
			});

			updatedReleaseNotes = updatedReleaseNotes.sort(sortArray("publishingDate"));

			return {
				...state,
				releases: updatedReleaseNotes,
				updateRequestErrorMessage: "",
				updateRequestFired: true,
				isLoading: false,
			};

		case ReleasesActionTypes.UPDATE_RELEASE_FAILURE:
			return {
				...state,
				updateRequestErrorMessage: action.payload.createErrorMessage,
				updateRequestFired: true,
				isLoading: false,
			};

		default:
			return state;
	}
}
