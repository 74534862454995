import { Icon, IconButton } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";

export interface TaxonomyTreeNavRowProps {
	depth: number;
	headerText: string;

	headerIcon?: string;
	isActive?: boolean;
	hideChevron?: boolean;
	isRoot?: boolean;
	notSelectable?: Boolean;

	onHeaderClick?: () => void;
	onAddClick?: () => void;
	addIconTitle?: string;
}

export const TaxonomyTreeNavRowComponent = (
	props: React.PropsWithChildren<TaxonomyTreeNavRowProps>
) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className={`collapse-container`}>
			<div
				className={`collapse-header collapse-header${props.isActive ? "--active" : ""} ${
					!props.notSelectable ? "collapse-header-selectable" : ""
				}`}
			>
				<div className={"collapse-active-indicator"}></div>
				{props.isRoot ? (
					<Icon iconName={"Home"} className={"collapse-chevron"} />
				) : (
					<Icon
						style={{
							marginLeft: `${23 * props.depth}px`,
							transform: isOpen ? "rotate(-90deg)" : undefined,
						}}
						iconName={props.hideChevron ? undefined : "ChevronDown"}
						className={"collapse-chevron"}
						onClick={() => setIsOpen(!isOpen)}
					/>
				)}

				<div
					className={"collapse-header-text"}
					onClick={(e) => {
						if (props.onHeaderClick && !props.notSelectable) {
							props.onHeaderClick();
							e.stopPropagation();
						}
					}}
				>
					{props.headerIcon && <Icon iconName={props.headerIcon} />}
					<span title={props.headerText}>{props.headerText}</span>
				</div>
				{props.onAddClick !== undefined && (
					<div className={"collapse-actions"}>
						<IconButton
							onClick={props.onAddClick}
							title={props.addIconTitle}
							iconProps={{ iconName: "Add" }}
							styles={{ icon: { fontSize: "1.4rem", color: "#333" } }}
						/>
					</div>
				)}
			</div>
			{(isOpen || props.isRoot) && <div className="collapse-content">{props.children}</div>}
		</div>
	);
};
