import { UserViewModel } from "../../models/viewmodels/UserViewModel";
import { CurrentUserActionTypes, CurrentUserState } from "./types";

const initialState: CurrentUserState = {
	user: {} as UserViewModel,
	errorMessage: "",
	isLoading: false
};

export function currentUserReducer(
	state = initialState,
	action: { type: CurrentUserActionTypes; payload: any }
): CurrentUserState {
	switch (action.type) {
		case CurrentUserActionTypes.FETCH_CURRENT_USER_STARTED:
			return {
				...state,
				isLoading: true
			};
		case CurrentUserActionTypes.FETCH_CURRENT_USER_SUCCESS:
			const user = { ...action.payload.user };
			return {
				...state,
				user,
				errorMessage: "",
				isLoading: false
			};
		case CurrentUserActionTypes.FETCH_CURRENT_USER_FAILURE:
			return {
				...state,
				errorMessage: action.payload.errorMessage,
				isLoading: false
			};

		default:
			return state;
	}
}
