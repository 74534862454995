import React, { useEffect, useState } from "react";
import {
	Modal,
	Icon,
	MessageBar,
	MessageBarType,
	PrimaryButton,
	Pivot,
	PivotItem,
	DetailsList,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	DefaultButton,
	Link,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";

import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { fetchPermissionsForRole, fetchRoleAssignments, removeRoleFromUser, setRoleModal } from "../../../../store/roles/actions";
import { IOneUser, IRoleAssignment, SlicePieceStatus } from "@one/core";
import { FormattedMessage, useIntl } from "react-intl";
import { AddUserToRoleTab } from "./addUserToRoleTab/AddUserToRoleTab";
import { RoleModalMessageBars } from "./messageBars/RoleModalMessageBars";
import { RolesActionTypes } from "../../../../store/roles/types";
import { PermissionsTab } from "./permissionsTab/PermissionsTab";

export const RoleModal = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const rolesSlice = useSelector((state: IApplicationState) => state.rolesSlice);
	const { roleModal } = rolesSlice;

	const columns: IColumn[] = [
		{
			key: "displayName",
			name: intl.formatMessage({
				id: "users.list.column.displayName",
				defaultMessage: "Naam",
			}),
			fieldName: "displayName",
			minWidth: 75,
			isResizable: true,
		},
		{
			key: "userPrincipalName",
			name: intl.formatMessage({
				id: "users.list.column.userPrincipalName",
			}),
			fieldName: "userPrincipalName",
			minWidth: 175,
			isResizable: true,
		},
		{
			key: "scope",
			name: intl.formatMessage({
				id: "users.list.column.scope",
				defaultMessage: "Scope",
			}),
			minWidth: 150,
			isResizable: true,
		},
		{
			key: "actions",
			name: "",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
		},
	];

	const _closeModal = () => {
		dispatch(setRoleModal(null));
	};

	// Add custom rendering for specific columns
	const _renderItemColumn = (roleAssignment: IRoleAssignment, _index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = roleAssignment.user[column.fieldName as keyof IRoleAssignment] as string;
		const instance = roleAssignment.applicationInstance;

		switch (column.key) {
			case "actions":
				return (
					<div className="column-field">
						{instance !== null ? (
							<DefaultButton
								title={intl.formatMessage({ id: "general.navigate" })}
								iconProps={{ iconName: "OpenInNewTab" }}
								href={instance.configuration.siteUrl}
								target="_blank"
								styles={{
									root: {
										width: "100%",
									},
								}}
							/>
						) : (
							<PrimaryButton
								title={intl.formatMessage({ id: "general.remove" })}
								iconProps={{ iconName: "Trash" }}
								disabled={roleModal.role === null || roleModal.removeRoleFromUserStatus === SlicePieceStatus.IsFetching}
								onClick={() => dispatch(removeRoleFromUser(roleAssignment, roleModal.role?.id))}
								styles={{
									root: {
										width: "100%",
									},
								}}
							/>
						)}
					</div>
				);
			case "scope":
				return (
					<div className="column-field" title={instance?.title}>
						{instance !== null ? (
							<span>{instance.title || "-"}</span>
						) : (
							<span>
								<FormattedMessage id="users.list.column.scope.global" />
							</span>
						)}
					</div>
				);
			default:
				return (
					<div className="column-field" title={fieldContent}>
						<span>{fieldContent || "-"}</span>
					</div>
				);
		}
	};

	React.useEffect(() => {
		if (roleModal.role !== null) {
			dispatch(fetchRoleAssignments(roleModal.role.id));
			dispatch(fetchPermissionsForRole(roleModal.role.id));
		}
	}, [roleModal.role]);

	return (
		<Modal
			onDismiss={() => _closeModal()}
			isBlocking={false}
			scrollableContentClassName="model-body"
			containerClassName="modal-wrapper"
			isOpen={rolesSlice.roleModal.role !== null}
		>
			<section className="role-modal-header-wrapper">
				<div className="role-wrapper">
					<div className="role-wrapper__info">
						<h1>{roleModal.role?.name}</h1>
					</div>
				</div>
				<div className="role-modal-close-button" onClick={() => _closeModal()}>
					<Icon iconName="ChromeClose" />
				</div>
			</section>

			<div className="role-modal-body-wrapper">
				<RoleModalMessageBars />

				<section className="role-modal-body" style={{ height: "100%", overflow: "auto" }}>
					<Pivot
						onLinkClick={() => {
							if (
								roleModal.userSearchResult.data.length !== 0 ||
								roleModal.userSearchResult.fetchStatus !== SlicePieceStatus.None
							) {
								dispatch({ type: RolesActionTypes.RESET_SEARCH_USERS_FOR_ROLE });
							}
						}}
					>
						<PivotItem headerText={intl.formatMessage({ id: "roles.modal.tab.permissions" })}>
							<PermissionsTab />
						</PivotItem>
						<PivotItem
							headerText={intl.formatMessage({
								id: "roles.modal.tab.usersWithThisRole",
							})}
						>
							<ShimmeredDetailsList
								items={roleModal.roleAssignments.data}
								selectionMode={SelectionMode.none}
								columns={columns}
								onRenderItemColumn={_renderItemColumn}
								enableShimmer={roleModal.roleAssignments.fetchStatus === SlicePieceStatus.IsFetching}
							/>
							{roleModal.roleAssignments.data.length === 0 &&
								roleModal.roleAssignments.fetchStatus !== SlicePieceStatus.IsFetching && (
									<div style={{ display: "flex", justifyContent: "center" }}>
										<span>
											<FormattedMessage id="general.noResults" />
										</span>
									</div>
								)}
						</PivotItem>
						<PivotItem headerText={intl.formatMessage({ id: "roles.modal.tab.assignUsers" })}>
							<AddUserToRoleTab />
						</PivotItem>
					</Pivot>
				</section>
			</div>
		</Modal>
	);
};
