import moment from "moment";

export interface FormattedDateObject {
	original: Date;
	hourString: string;
	minuteString: string;
	hourMinuteString: string;
	dateString: string;
}

export interface ComparedDates {
	differenceInHours: number;
	spansMulitpleDays: boolean;
}

export enum RecentDates {
	Yesterday,
	Today,
	Tomorrow,
	Other,
}

/**
 * @deprecated
 */
export const formatDateToObject = (date: Date) => {
	const hourString = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
	const minuteString = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();
	const dateString = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

	const formattedDateObject: FormattedDateObject = {
		original: date,
		hourString,
		minuteString,
		hourMinuteString: `${hourString}:${minuteString}`,
		dateString,
	};

	return formattedDateObject;
};

/**
 * @deprecated
 */
export const compareDates = (startDate: Date, endDate: Date) => {
	const differenceInHours = (endDate.getTime() - startDate.getTime()) / 3600000;
	const spansMulitpleDays =
		endDate.getDate() !== startDate.getDate() ||
		endDate.getMonth() !== startDate.getMonth() ||
		endDate.getFullYear() !== startDate.getFullYear();

	const comparedDates: ComparedDates = {
		differenceInHours,
		spansMulitpleDays,
	};

	return comparedDates;
};

/**
 * @deprecated
 */
export const yesterdayTodayOrTomorrow = (input: string): RecentDates => {
	const today = moment();
	const tomorrow = moment(today).add(1, "days");
	const yesterday = moment(today).subtract(1, "days");
	const receivedDate = new Date(input);
	const isToday =
		receivedDate.getDate() === today.toDate().getDate() &&
		receivedDate.getMonth() === today.toDate().getMonth() &&
		receivedDate.getFullYear() === today.toDate().getFullYear();
	const isTomorrow =
		receivedDate.getDate() === tomorrow.toDate().getDate() &&
		receivedDate.getMonth() === tomorrow.toDate().getMonth() &&
		receivedDate.getFullYear() === tomorrow.toDate().getFullYear();
	const isYesterday =
		receivedDate.getDate() === yesterday.toDate().getDate() &&
		receivedDate.getMonth() === yesterday.toDate().getMonth() &&
		receivedDate.getFullYear() === yesterday.toDate().getFullYear();

	if (isToday) return RecentDates.Today;
	else if (isTomorrow) return RecentDates.Tomorrow;
	else if (isYesterday) return RecentDates.Yesterday;
	else return RecentDates.Other;
};

export const getEventStartEndDateString = (startInput: string, endInput: string) => {
    const now = moment();

    const startDate = moment(startInput);
    const endDate = moment(endInput);

    let startDateString = startDate.format("ddd") + " " + startDate.format("lll");
    let endDateString = endDate.format("ddd") + " " + endDate.format("lll");

    // Remove the year if the date is in the current year.
    if (startDate.year() === now.year()) {
        startDateString = startDateString.replace(now.year().toString(), "");
    }
    // Remove the year if the date is in the current year.
    if (endDate.year() === now.year()) {
        endDateString = endDateString.replace(now.year().toString(), "");
    }

    // If the start and end date are on the same day, remove everything from the endDateString except the time.
    if (
        startDate.month() === endDate.month() &&
        startDate.date() === endDate.date()
    ) {
        endDateString = endDate.format("LT")
    }
    
    return `${startDateString} - ${endDateString}`;
};

export const dateStringIsNotNullAndInPast = (input: string) => {
	return input !== null && new Date() > new Date(input);
};
