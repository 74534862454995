import { ActionButton, DetailsList, DetailsListLayoutMode, IColumn, Icon, Label, PrimaryButton, SelectionMode } from "@fluentui/react";
import { GroupSetting } from "@microsoft/microsoft-graph-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SuccessTypes } from "../../../../../models/enums";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { fetchTeamTypes, resetSuccess } from "../../../../../store/teamTypes/actions";
import { CreateTeamTypePanelComponent } from "./addTeamTypePanel/CreateTeamTypePanel";
import { EditTeamTypePanelComponent } from "./editTeamTypePanel/EditTeamTypePanel";
import { ITeamType } from "@one/core";

const TeamTypes = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const teamTypesSlice = useSelector((state: IApplicationState) => state.teamTypesSlice);
	const settingsSlice = useSelector((state: IApplicationState) => state.settingsSlice);
	const [createPanelIsOpen, setCreatePanelIsOpen] = useState(false);
	const [editPanelIsOpenFor, setEditPanelIsOpenFor] = useState<ITeamType | null>(null);
	const [allowToAddUserSettingValue, setAllowToAddUserSettingValue] = useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchTeamTypes());
	}, [dispatch]);

	// Close an open panel after a succesful create or edit.
	useEffect(() => {
		if (teamTypesSlice.success.type === SuccessTypes.OnCreate && createPanelIsOpen) {
			setCreatePanelIsOpen(false);
			dispatch(resetSuccess());
		}
		if (teamTypesSlice.success.type === SuccessTypes.OnUpdate && editPanelIsOpenFor !== null) {
			setEditPanelIsOpenFor(null);
			dispatch(resetSuccess());
		}
	}, [teamTypesSlice.success, createPanelIsOpen, editPanelIsOpenFor, dispatch]);

	const columns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({
				id: "settings.teams.types.column.name",
				defaultMessage: "Naam",
			}),
			fieldName: "name",
			minWidth: 175,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "domain",
			name: intl.formatMessage({
				id: "settings.teams.types.column.domain",
				defaultMessage: "Domein",
			}),
			fieldName: "domain",
			minWidth: 175,
			maxWidth: 250,
			isResizable: true,
		},
		{
			key: "templateId",
			name: intl.formatMessage({
				id: "settings.teams.types.column.templateId",
				defaultMessage: "Sjabloon ID",
			}),
			fieldName: "templateId",
			minWidth: 175,
			maxWidth: 250,
			isResizable: true,
		},
		{
			key: "isActive",
			name: intl.formatMessage({
				id: "settings.teams.types.column.isActive",
				defaultMessage: "In gebruik",
			}),
			fieldName: "isActive",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
		},
		// {
		// 	key: "approvalRequired",
		// 	name: intl.formatMessage({
		// 		id: "settings.teams.types.column.approvalRequired",
		// 		defaultMessage: "Goedkeuring vereist",
		// 	}),
		// 	fieldName: "approvalRequired",
		// 	minWidth: 125,
		// 	maxWidth: 150,
		// 	isResizable: true,
		// },
		{
			key: "defaultOwners",
			name: intl.formatMessage({
				id: "settings.teams.types.column.defaultOwners",
			}),
			fieldName: "defaultOwners",
			minWidth: 125,
			maxWidth: 150,
			isResizable: true,
		},
		{
			key: "actions",
			name: intl.formatMessage({
				id: "settings.teams.types.column.actions",
				defaultMessage: "Acties",
			}),
			fieldName: "actions",
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
		},
	];

	const checkGroupSettingsForAllowToAddUserSetting = (groupSettings: GroupSetting[]) => {
		if (groupSettings) {
			groupSettings.forEach((groupSetting) => {
				if (groupSetting.values) {
					for (let i = 0; i < groupSetting.values.length; i++) {
						const settingValue = groupSetting.values[i];
						if (settingValue.name === "AllowToAddGuests") {
							if (settingValue.value === undefined) {
								setAllowToAddUserSettingValue(false);
							} else {
								setAllowToAddUserSettingValue(settingValue.value?.toLowerCase() === "true");
							}
							break;
						}
					}
				}
			});
		}
	};

	useEffect(() => {
		checkGroupSettingsForAllowToAddUserSetting(settingsSlice.groupSettings);
	}, [settingsSlice.groupSettings]);

	const _renderItemColumn = (item: ITeamType, index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = item[column.fieldName as keyof ITeamType] as string;
		switch (column.key) {
			case "name":
				return (
					<div className="column-field">
						<span>{item.name}</span>
					</div>
				);
			case "isActive":
				return <div className="column-field column-field__is-active ">{item.isActive ? <Icon iconName="CheckMark" /> : null}</div>;
			case "approvalRequired":
				return (
					<div className="column-field column-field__is-active">
						{item.approvalRequired ? <Icon iconName="CheckMark" /> : null}
					</div>
				);
			case "defaultOwners":
				if (item.defaultOwners === null || item.defaultOwners.length === 0) {
					return (
						<div className="column-field">
							<span></span>
						</div>
					);
				}
				return <div className="column-field column-field">{item.defaultOwners.length}</div>;
			case "actions":
				return (
					<div className="column-field">
						<PrimaryButton
							onClick={() => setEditPanelIsOpenFor(item)}
							iconProps={{
								iconName: "Edit",
							}}
						/>
					</div>
				);
			default:
				return (
					<div className="column-field">
						<span>{fieldContent}</span>
					</div>
				);
		}
	};

	return (
		<div className="settings-setting-wrapper-full-width">
			<div className="space-between">
				<Label>
					<FormattedMessage id="settings.teams.types.header" defaultMessage="Teamtypes" />
				</Label>
				<ActionButton iconProps={{ iconName: "Add" }} onClick={() => setCreatePanelIsOpen(true)}>
					<FormattedMessage id="settings.teams.types.add.button" defaultMessage="Teamtype toevoegen" />
				</ActionButton>
			</div>
			<div style={{ width: "99%" }}>
				<DetailsList
					items={teamTypesSlice.teamTypes || []}
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={SelectionMode.none}
					columns={columns}
					onRenderItemColumn={(item, index, column) => _renderItemColumn(item as ITeamType, index, column)}
				/>
			</div>

			<CreateTeamTypePanelComponent isOpen={createPanelIsOpen} onClose={() => setCreatePanelIsOpen(false)} />
			<EditTeamTypePanelComponent
				teamType={editPanelIsOpenFor}
				isOpen={editPanelIsOpenFor !== null}
				onClose={() => setEditPanelIsOpenFor(null)}
				allowToAddUser={allowToAddUserSettingValue}
			/>
		</div>
	);
};

export const TeamTypesComponent = injectIntl(TeamTypes);
