import { Label } from "@fluentui/react";
import React from "react";
import ApprovalByDropdown from "../elements/approvalByDropdown/ApprovalByDropdown";
import { ITeamTypeSettingProps } from "../../../../../interfaces/teamTypeSettings/ITeamTypeSettingProps";
import RequestByDropdown from "../elements/requestByDropdown/RequestByDropdown";

export default function ArchiveTeam(props: ITeamTypeSettingProps) {
	return <>
		<Label>Kan ingediend worden door</Label>

		<RequestByDropdown
			selectedKeys={props.values.settings.archiveTeam.requestBy}
			onChange={(option) => props.updateRequestOrApprovalBySettings("archiveTeam", "requestBy", option)}
		/>

		<ApprovalByDropdown
			selectedKeys={props.values.settings.archiveTeam.approvalBy}
			onChange={(option) => props.updateRequestOrApprovalBySettings("archiveTeam", "approvalBy", option)}
		/>
	</>;
}