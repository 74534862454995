// Framework & third-party
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import {
	ActionButton,
	Icon,
	MessageBar,
	MessageBarType,
	PrimaryButton,
	Separator,
	TextField,
	Toggle,
	TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";

// Store

// Models
import { ErrorTypes, SuccessTypes } from "../../../../../models/enums";
import { TaxonomyItem } from "../../../../../models/enums/TaxonomyITems";
import { ITermSet } from "../../../../../models/responseModels/taxonomy/ITermSet";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { IUpdateTermSet } from "../../../../../models/viewmodels/taxonomy/IUpdateTermSet";
import { resetError, resetSuccess, updateTermSet } from "../../../../../store/taxonomy/actions";

// Components
import { AddTermPanelComponent } from "./addTermPanelComponent/AddTermPanelComponent";

export interface ISelectedTermSetProps {
	termSet: ITermSet;
	shouldOpenPanel?: boolean;
	resetShouldOpenPanel: () => void;
}

const SelectedTermSet = (props: WrappedComponentProps & ISelectedTermSetProps) => {
	const { termSet, intl } = props;
	const dispatch = useDispatch();
	const taxonomySlice = useSelector((state: IApplicationState) => state.taxonomySlice);

	const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
	const [hasActiveTerms, setHasActiveTerms] = useState(false);

	useEffect(() => {
		if (props.shouldOpenPanel) {
			setPanelIsOpen(true);
			props.resetShouldOpenPanel();
		}
	}, [props]);

	React.useEffect(() => {
		dispatch(resetSuccess());
	}, [dispatch, termSet]);

	React.useEffect(() => {
		const activeTerms = termSet.terms.filter((t) => t.isActive);
		setHasActiveTerms(activeTerms.length > 0);
	}, [termSet, taxonomySlice.success.type]);

	useEffect(() => {
		if (
			taxonomySlice.success.type === SuccessTypes.OnCreate &&
			taxonomySlice.success.forType === TaxonomyItem.TermSet &&
			panelIsOpen
		) {
			setPanelIsOpen(false);
		}
	}, [taxonomySlice.success.type, taxonomySlice.success.forType, panelIsOpen]);

	const renderMessageBar = () => {
		if (
			taxonomySlice.error.type !== ErrorTypes.None &&
			taxonomySlice.error.forType === TaxonomyItem.TermSet
		) {
			if (taxonomySlice.error.type === ErrorTypes.OnUpdate) {
				return (
					<MessageBar
						messageBarType={MessageBarType.error}
						onDismiss={() => dispatch(resetError())}
						styles={{
							root: {
								margin: "-4rem -4rem 0.8rem -4rem",
								width: "calc(100% + 8rem)",
							},
						}}
					>
						<FormattedMessage
							id="taxonomy.term.updated.failure"
							defaultMessage="Er ging iets mis bij het updaten van de term."
						/>
					</MessageBar>
				);
			}
		}
		if (
			taxonomySlice.success.type !== SuccessTypes.None &&
			taxonomySlice.success.forType === TaxonomyItem.TermSet
		) {
			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					onDismiss={() => dispatch(resetSuccess())}
					styles={{
						root: {
							margin: "-4rem -4rem 0.8rem -4rem",
							width: "calc(100% + 8rem)",
						},
					}}
				>
					{taxonomySlice.success.type === SuccessTypes.OnUpdate && (
						<FormattedMessage
							id="taxonomy.termSet.updated.success"
							defaultMessage="De termenset is successlvol geüpdatet"
						/>
					)}
					{taxonomySlice.success.type === SuccessTypes.OnCreate && (
						<FormattedMessage
							id="taxonomy.term.created.success"
							defaultMessage="De term is succesvol aangemaakt."
						/>
					)}
				</MessageBar>
			);
		}
		if (!termSet.isEditable) {
			return (
				<MessageBar
					messageBarType={MessageBarType.info}
					styles={{
						root: {
							margin: "-4rem -4rem 0.8rem -4rem",
							width: "calc(100% + 8rem)",
						},
					}}
				>
					<FormattedMessage
						id="taxonomy.termSet.notEditable"
						defaultMessage="Deze term is niet bewerkbaar."
					/>
				</MessageBar>
			);
		}
	};

	return (
		<section className="taxonomy-card-wrapper taxonomy-selected-wrapper ">
			{renderMessageBar()}
			<div className="taxonomy-selected">
				<div className="taxonomy-selected-topbar">
					<div className="taxonomy-selected-topbar-title">
						<h3>{termSet.name}</h3>
						<Icon iconName="BulletedList" />
					</div>
					<div>
						<ActionButton
							iconProps={{ iconName: "add" }}
							text="Nieuwe term"
							onClick={() => {
								dispatch(resetSuccess());
								setPanelIsOpen(true);
							}}
							disabled={!termSet.isEditable}
						/>
					</div>
				</div>
				<Separator />
				<div className="ilx-form-wrapper">
					<Formik
						enableReinitialize
						initialValues={{
							name: termSet.name !== null ? termSet.name : "",
							isActive: termSet.isActive,
							isRequired: termSet.isRequired,
							isReassignable: termSet.isReassignable,
							allowMultipleValues: termSet.allowMultipleValues,
						}}
						onSubmit={(values) => {
							const requestBody = {
								id: termSet.id,
								name: values.name,
								isReassignable: values.isReassignable,
								isClassification: termSet.isClassification,
								isActive: values.isActive,
								isTeamType: termSet.isTeamType,
								isRequired: values.isRequired,
								allowMultipleValues: values.allowMultipleValues,
								termGroupId: termSet.termGroupId,
							} as IUpdateTermSet;

							dispatch(updateTermSet(requestBody));
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.min(
									2,
									intl.formatMessage({
										id: "taxonomy.validation.name.minTwoChars",
										defaultMessage:
											"De naam moet ten minste twee tekens lang zijn.",
									})
								)
								.required(
									intl.formatMessage({
										id: "general.validation.required",
										defaultMessage: "Dit veld is verplicht.",
									})
								),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue,
							} = props;

							return (
								<form onSubmit={handleSubmit}>
									<TextField
										id="name"
										disabled={!termSet.isEditable}
										label={intl.formatMessage({
											id: "taxonomy.termSet.name.label",
											defaultMessage: "Naam",
										})}
										required
										placeholder={intl.formatMessage({
											id: "taxonomy.termSet.name.placeholder",
											defaultMessage: "De naam van de termenset",
										})}
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.name}
										autoComplete={"off"}
										errorMessage={errors.name}
										className={"ilx-text-field"}
									/>

									<Toggle
										checked={values.allowMultipleValues}
										disabled={!termSet.isEditable}
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="taxonomy.termSet.allowMultipleValues.label"
														defaultMessage="Mutli-select"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.allowMultipleValues.description"
														defaultMessage="Bij deze termenset is het mogelijk om meerdere terms te selecteren."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) => {
											setFieldValue("allowMultipleValues", checked);
										}}
									/>

									<Toggle
										checked={values.isRequired}
										disabled={
											!termSet.isEditable ||
											(!values.isActive && !values.isRequired) ||
											(!values.isRequired && !hasActiveTerms)
										}
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="taxonomy.termSet.isRequired.label"
														defaultMessage="Is verplicht"
													/>
													{!values.isRequired && !hasActiveTerms && (
														<TooltipHost
															content={intl.formatMessage({
																id:
																	"taxonomy.termSet.hasNoActiveTerms.tooltip",
																defaultMessage:
																	"Het is niet mogelijk om deze termenset verplicht te maken omdat er geen actieve termen zijn.",
															})}
														>
															<Icon iconName="Info" />
														</TooltipHost>
													)}
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.isRequired.description"
														defaultMessage="Deze termenset is verplicht om in te vullen."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) =>
											setFieldValue("isRequired", checked)
										}
									/>
									<Toggle
										checked={values.isActive}
										disabled={!termSet.isEditable}
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="general.isActive"
														defaultMessage="In gebruik"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.isActive.description"
														defaultMessage="Deze termenset is actief."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) => {
											setFieldValue("isActive", checked);
											if (!checked && values.isRequired) {
												setFieldValue("isRequired", false);
											}
										}}
									/>
									<Toggle
										checked={values.isReassignable}
										disabled={!termSet.isEditable}
										label={
											<div className="ilx-label-with-tooltip">
												<label>
													<FormattedMessage
														id="taxonomy.termSet.isReassignable.label"
														defaultMessage="Is aanpasbaar"
													/>
												</label>
												<span>
													<FormattedMessage
														id="taxonomy.termSet.isReassignable.description"
														defaultMessage="De geselecteerde waarde van deze termenset mag gewijzigd worden."
													/>
												</span>
											</div>
										}
										className="ilx-toggle"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) =>
											setFieldValue("isReassignable", checked)
										}
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.save",
											defaultMessage: "Opslaan",
										})}
										type="submit"
										disabled={
											!termSet.isEditable ||
											taxonomySlice.isUpdatingOrCreating ||
											(Object.keys(errors).length !== 0 &&
												errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
			<AddTermPanelComponent
				isOpen={panelIsOpen}
				onClose={() => setPanelIsOpen(false)}
				termSet={termSet}
			/>
		</section>
	);
};

export const SelectedTermSetComponent = injectIntl(SelectedTermSet);
