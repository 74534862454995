import { ISlicePiece } from "@one/core";
import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { IConnectionResponse } from "../../models/responseModels/connection/IConnectionResponse";

export enum ConnectionActionTypes {
	RESET_ERROR = "RESET_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",

	FETCH_CONNECTION_BY_NAME_STARTED = "FETCH_CONNECTION_BY_NAME_STARTED",
	FETCH_CONNECTION_BY_NAME_SUCCESS = "FETCH_CONNECTION_BY_NAME_SUCCESS",
	FETCH_CONNECTION_BY_NAME_FAILURE = "FETCH_CONNECTION_BY_NAME_FAILURE",

	UPDATE_CONNECTION_BY_ID_STARTED = "UPDATE_CONNECTION_BY_ID_STARTED",
	UPDATE_CONNECTION_BY_ID_SUCCESS = "UPDATE_CONNECTION_BY_ID_SUCCESS",
	UPDATE_CONNECTION_BY_ID_FAILURE = "UPDATE_CONNECTION_BY_ID_FAILURE",

	CREATE_CONNECTION_STARTED = "CREATE_CONNECTION_STARTED",
	CREATE_CONNECTION_SUCCESS = "CREATE_CONNECTION_SUCCESS",
	CREATE_CONNECTION_FAILURE = "CREATE_CONNECTION_FAILURE",
}

export type ConnectionState = Readonly<{
	connections: ISlicePiece<IConnectionResponse[]>;
	success: ISuccessState;
	error: IErrorState;
}>;
