import { TeamSyncOptions } from "./../../models/enums/TeamSyncOptions";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { SettingsActionTypes, SettingsState } from "./types";
import { adalGraphFetch, adalOneApiFetch } from "../../adalConfig";
import { SettingsTypes } from "../../models/enums/SettingsTypes";
import { ISettingsBody } from "../../models/interfaces/ISettingsBody";
import { GroupSetting } from "@microsoft/microsoft-graph-types";

// --== RESET ==--
export const resetSuccess = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SettingsActionTypes.RESET_SUCCESS,
		});
	};
};
export const clearError = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SettingsActionTypes.CLEAR_ERROR,
		});
	};
};

// --== Update setting ==--
export const updateSettings = (type: SettingsTypes, body: ISettingsBody) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateSettingsRequest(type, body));
	};
};

const updateSettingsRequest: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, any>> = (
	type: SettingsTypes,
	body: ISettingsBody
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateSettingsStarted());
		try {
			const endpoint = type === SettingsTypes.Application ? "applicationSettings" : "tenantSettings";
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/${endpoint}`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(updateSettingsFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateSettingsSuccess(type, result));
			}
		} catch (e) {
			dispatch(updateSettingsFailure("error"));
		}
	};
};

const updateSettingsStarted = () => ({
	type: SettingsActionTypes.UPDATE_SETTINGS_STARTED,
});

const updateSettingsSuccess = (type: SettingsTypes, response: any) => ({
	type: SettingsActionTypes.UPDATE_SETTINGS_SUCCESS,
	payload: {
		type,
		settings: response.settings,
	},
});

const updateSettingsFailure = (errorMessage: string | object) => ({
	type: SettingsActionTypes.UPDATE_SETTINGS_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Update teams crawl setting ==--
export const updateTeamCrawlSetting = (newValue: TeamSyncOptions) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: SettingsActionTypes.UPDATE_TEAM_CRAWL_SETTING_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applicationSettings/teams/crawl`,
				{
					method: "POST",
					body: JSON.stringify({
						value: newValue,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: SettingsActionTypes.UPDATE_TEAM_CRAWL_SETTING_FAILURE,
						payload: {
							errorMessage: response.statusText,
						},
					});
					return;
				}

				const result = await response.json();

				dispatch({
					type: SettingsActionTypes.UPDATE_TEAM_CRAWL_SETTING_SUCCESS,
					payload: {
						...result,
					},
				});
			}
		} catch (e) {
			console.log(e);
			dispatch({
				type: SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_FAILURE,
				payload: {
					errorMessage: "error",
				},
			});
		}
	};
};

// --== Update app setting by ID ==--
export const updateAppSettingsById = (id: number, body: ISettingsBody) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(updateAppSettingsByIdRequest(id, body));
	};
};

const updateAppSettingsByIdRequest: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, any>> = (
	id: number,
	body: ISettingsBody
) => {
	return async (dispatch: Dispatch) => {
		dispatch(updateAppSettingsByIdStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applicationSettings/${id}`,
				{
					method: "POST",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(updateAppSettingsByIdFailure(response.statusText));
					return;
				}

				const result = await response.json();

				dispatch(updateAppSettingsByIdSuccess(result));
			}
		} catch (e) {
			console.log(e);
			dispatch(updateAppSettingsByIdFailure("error"));
		}
	};
};

const updateAppSettingsByIdStarted = () => ({
	type: SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_STARTED,
});

const updateAppSettingsByIdSuccess = (response: any) => ({
	type: SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_SUCCESS,
	payload: {
		settings: response.settings,
	},
});

const updateAppSettingsByIdFailure = (errorMessage: string | object) => ({
	type: SettingsActionTypes.UPDATE_APP_SETTINGS_BY_ID_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch settings ==--
export const fetchSettings = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchSettingsRequest());
	};
};

const fetchSettingsRequest: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchSettingsStarted());
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/settings`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchSettingsFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchSettingsSuccess(result));
			}
		} catch (e) {
			dispatch(fetchSettingsFailure("error"));
		}
	};
};

const fetchSettingsStarted = () => ({
	type: SettingsActionTypes.FETCH_SETTINGS_STARTED,
});

const fetchSettingsSuccess = (settings: any) => ({
	type: SettingsActionTypes.FETCH_SETTINGS_SUCCESS,
	payload: {
		settings,
	},
});

const fetchSettingsFailure = (errorMessage: string | object) => ({
	type: SettingsActionTypes.FETCH_SETTINGS_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch app settings by id ==--
export const fetchAppSettingById = (id: number) => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchAppSettingByIdRequest(id));
	};
};

const fetchAppSettingByIdRequest: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, any>> = (id: number) => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchAppSettingByIdStarted());
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/applicationSettings/${id}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchAppSettingByIdFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchAppSettingByIdSuccess(result));
			}
		} catch (e) {
			dispatch(fetchAppSettingByIdFailure("error"));
		}
	};
};

const fetchAppSettingByIdStarted = () => ({
	type: SettingsActionTypes.FETCH_APP_SETTINGS_BY_ID_STARTED,
});

const fetchAppSettingByIdSuccess = (settings: any) => ({
	type: SettingsActionTypes.FETCH_APP_SETTINGS_BY_ID_SUCCESS,
	payload: {
		settings,
	},
});

const fetchAppSettingByIdFailure = (errorMessage: string | object) => ({
	type: SettingsActionTypes.FETCH_APP_SETTINGS_BY_ID_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch groupsettings from graph ==--
export const fetchGroupSettingsGraph = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchGroupSettingsGraphRequest());
	};
};

const fetchGroupSettingsGraphRequest: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchGroupSettingsGraphStarted());
		try {
			const response: Response = await adalGraphFetch(fetch, `https://graph.microsoft.com/v1.0/groupSettings/`, {});

			// If the reponse code is not positive, throw an error.
			if (response) {
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchGroupSettingsGraphFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchGroupSettingsGraphSuccess(result.value));
			}
		} catch (e) {
			dispatch(fetchGroupSettingsGraphFailure("error"));
		}
	};
};

const fetchGroupSettingsGraphStarted = () => ({
	type: SettingsActionTypes.FETCH_GROUP_SETTINGS_GRAPH_STARTED,
});

const fetchGroupSettingsGraphSuccess = (groupSettings: GroupSetting[]) => ({
	type: SettingsActionTypes.FETCH_GROUP_SETTINGS_GRAPH_SUCCESS,
	payload: {
		groupSettings,
	},
});

const fetchGroupSettingsGraphFailure = (errorMessage: string | object) => ({
	type: SettingsActionTypes.FETCH_GROUP_SETTINGS_GRAPH_FAILURE,
	payload: {
		errorMessage,
	},
});

// --== Fetch groupsettings from graph ==--
export const setAllowToAddGuests = (newValue: boolean, id: string = "") => {
	return (dispatch: Dispatch<any>) => {
		dispatch(setAllowToAddGuestsRequest(newValue, id));
	};
};

const setAllowToAddGuestsRequest: ActionCreator<ThunkAction<Promise<any>, SettingsState, null, any>> = (newValue: boolean, id: string) => {
	return async (dispatch: Dispatch) => {
		dispatch(setAllowToAddGuestsStarted());
		try {
			const response: Response = await adalGraphFetch(fetch, `https://graph.microsoft.com/v1.0/groupSettings/${id}`, {
				method: id === "" ? "POST" : "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					displayName: "Group.Unified",
					templateId: "62375ab9-6b52-47ed-826b-58e47e0e304b",
					values: [
						{
							name: "AllowToAddGuests",
							value: newValue ? "true" : "false",
						},
					],
				}),
			});

			// If the reponse code is not positive, throw an error.
			if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
				dispatch(setAllowToAddGuestsFailure(response.statusText));
				return;
			}

			//only do this for POST because PATH doesn't give any response
			const result = response.status === 201 ? await response.json() : [];
			dispatch(setAllowToAddGuestsSuccess(id, result));
		} catch (e) {
			console.log(e);

			dispatch(setAllowToAddGuestsFailure("error"));
		}
	};
};

const setAllowToAddGuestsStarted = () => ({
	type: SettingsActionTypes.SET_GROUP_SETTING_GUESTS_STARTED,
});

const setAllowToAddGuestsSuccess = (id: string, result: GroupSetting) => ({
	type: SettingsActionTypes.SET_GROUP_SETTING_GUESTS_SUCCESS,
	payload: {
		id: id,
		result: result,
	},
});

const setAllowToAddGuestsFailure = (errorMessage: string | object) => ({
	type: SettingsActionTypes.SET_GROUP_SETTING_GUESTS_FAILURE,
	payload: {
		errorMessage,
	},
});
