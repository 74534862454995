import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { TenantResponse } from "../../models/responseModels/tenant/TenantResponse";
import { IJobDefinition } from "../../models/viewmodels/jobs/IJobDefinition";
import { IJobHistory } from "../../models/viewmodels/jobs/IJobHistory";
import { IJobSchedule } from "../../models/viewmodels/jobs/IJobSchedule";

export enum JobsActionTypes {
	FETCH_JOB_DEFINITIONS_STARTED = "FETCH_JOB_DEFINITIONS_STARTED",
	FETCH_JOB_DEFINITIONS_SUCCESS = "FETCH_JOB_DEFINITIONS_SUCCESS",
	FETCH_JOB_DEFINITIONS_FAILURE = "FETCH_JOB_DEFINITIONS_FAILURE",

	FETCH_JOB_SCHEDULE_BY_ID_STARTED = "FETCH_JOB_SCHEDULE_BY_ID_STARTED",
	FETCH_JOB_SCHEDULE_BY_ID_SUCCESS = "FETCH_JOB_SCHEDULE_BY_ID_SUCCESS",
	FETCH_JOB_SCHEDULE_BY_ID_FAILURE = "FETCH_JOB_SCHEDULE_BY_ID_FAILURE",

	FETCH_JOB_HISTORY_STARTED = "FETCH_JOB_HISTORY_STARTED",
	FETCH_JOB_HISTORY_SUCCESS = "FETCH_JOB_HISTORY_SUCCESS",
	FETCH_JOB_HISTORY_FAILURE = "FETCH_JOB_HISTORY_FAILURE",

	CREATE_JOB_SCHEDULE_STARTED = "CREATE_JOB_SCHEDULE_STARTED",
	CREATE_JOB_SCHEDULE_SUCCESS = "CREATE_JOB_SCHEDULE_SUCCESS",
	CREATE_JOB_SCHEDULE_FAILURE = "CREATE_JOB_SCHEDULE_FAILURE",

	UPDATE_JOB_SCHEDULE_STARTED = "UPDATE_JOB_SCHEDULE_STARTED",
	UPDATE_JOB_SCHEDULE_SUCCESS = "UPDATE_JOB_SCHEDULE_SUCCESS",
	UPDATE_JOB_SCHEDULE_FAILURE = "UPDATE_JOB_SCHEDULE_FAILURE",

	SET_SELECTED_JOB = "SET_SELECTED_JOB",
	CLEAR_SELECTED_JOB_ERROR = "CLEAR_SELECTED_JOB_ERROR",

	SET_SELECTED_TENANT = "SET_SELECTED_TENANT",

	RESET_ERROR = "RESET_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",
}

export interface ISelectedJob {
	jobDefinition: IExtendedJobDefinition;
	isFetchingHistory: boolean;
	history: IJobHistory[] | null;
	hasFetchHistoryError?: boolean;
}

export interface IExtendedJobDefinition extends IJobDefinition {
	schedule?: IJobSchedule;
	isFetchingSchedule?: boolean;
}

export type JobsState = Readonly<{
	jobDefinitions: IExtendedJobDefinition[];
	selectedJob: ISelectedJob | null;

	isFetchingDefinitions: boolean;
	isCreatingJobSchedule: boolean;
	isUpdatingJobSchedule: boolean;

	selectedTenant: TenantResponse | null;

	error: IErrorState;
	success: ISuccessState;
}>;
