import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "@fluentui/react";

export const PageNotFoundComponent = () => {
	return (
		<div className="page-not-found-container">
			<Icon iconName="Page" />
			<h1>
				<FormattedMessage
					id="notfound.headertext"
					defaultMessage="Deze pagina bestaat helaas niet."
				/>
			</h1>
			<p>
				<FormattedMessage
					id="notfound.subtext"
					defaultMessage="Navigeer naar een andere pagina door gebruik te maken van de navigatielinks."
				/>
			</p>
		</div>
	);
};
