import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, authorizeFunction, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			// check if the user complies to the authorize function
			const hasAccess = authorizeFunction(rest.currentUser);

			if (hasAccess) {
				return <Component {...props} />;
			} else {
				return (
					<Redirect
						to={{
							pathname: rest.redirectTo,
						}}
					/>
				);
			}
		}}
	/>
);

export default PrivateRoute;
