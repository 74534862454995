import * as React from "react";
import "./CardContent.scss";

export interface CardContentProps {
    children?: React.ReactNode;
    /**
     * Additional custom inline styles on the root
     */
    style?: React.CSSProperties;
    /**
     * Additional custom className on the root
     */
    className?: string;

    /**
     * Callback when the card is clicked.
     */
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const CardContent = (props: CardContentProps) => {
    let className = "one-card-content";
    if (props.className !== undefined) {
        className += " " + props.className;
    }
    return (
        <div className={className} style={props.style} onClick={props.onClick}>
            {props.children}
        </div>
    );
};
