import { ApplicationsResponse } from './../../models/responseModels/Applications/ApplicationsResponse';
import { applicationActionTypes, ApplicationState } from "./types";

const initialState: ApplicationState = {
    applications: [] as ApplicationsResponse[],
    errorMessage: "",
    isLoading: false
};

export function applicationsReducer(
    state = initialState,
    action: { type: applicationActionTypes; payload: any }
): ApplicationState {
    switch (action.type) {
        case applicationActionTypes.FETCH_APPLICATIONS_STARTED:
            return {
                ...state,
                isLoading: true
            };
        case applicationActionTypes.FETCH_APPLICATIONS_SUCCESS:
            let applications = [...action.payload.applications];
            return {
                ...state,
                applications,
                errorMessage: "",
                isLoading: false
            };
        case applicationActionTypes.FETCH_APPLICATIONS_FAILURE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                isLoading: false
            };

        default:
            return state;
    }
}
