import { Icon } from "@fluentui/react";
import * as React from "react";
import { useEffect } from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { JobScheduleType } from "../../../../models/enums/JobScheduleType";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { IJobDefinition } from "../../../../models/viewmodels/jobs/IJobDefinition";
import { IJobSchedule } from "../../../../models/viewmodels/jobs/IJobSchedule";
import { fetchJobScheduleById } from "../../../../store/jobs/actions";

export interface IJobDefinitionCardProps {
	definition: IJobDefinition;
	schedule?: IJobSchedule;
	onClick: () => void;
}

export const JobDefinitionCardComponent = (props: IJobDefinitionCardProps) => {
	const { definition, schedule } = props;

	const dispatch = useDispatch();
	const jobsSlice = useSelector((state: IApplicationState) => state.jobsSlice);

	// Fetch jobs on component init.
	useEffect(() => {
		if ((schedule === null || schedule === undefined) && jobsSlice.selectedTenant !== null) {
			dispatch(fetchJobScheduleById(definition.id, jobsSlice.selectedTenant));
		}
	}, [dispatch, definition.id, schedule, jobsSlice.selectedTenant]);

	const determineScheduleTypeText = (scheduleType: JobScheduleType) => {
		switch (scheduleType) {
			case JobScheduleType.Minute:
				return (
					<FormattedMessage id="jobs.scheduleType.minute" defaultMessage="Elke minuut" />
				);
			case JobScheduleType.Hourly:
				return <FormattedMessage id="jobs.scheduleType.hourly" defaultMessage="Elk uur " />;
			case JobScheduleType.Daily:
				return <FormattedMessage id="jobs.scheduleType.daily" defaultMessage="Elke dag" />;
			case JobScheduleType.Weekly:
				return (
					<FormattedMessage id="jobs.scheduleType.weekly" defaultMessage="Elke week" />
				);
			case JobScheduleType.Monthly:
				return (
					<FormattedMessage id="jobs.scheduleType.monthly" defaultMessage="Elke maand" />
				);
			case JobScheduleType.Yearly:
				return <FormattedMessage id="jobs.scheduleType.yearly" defaultMessage="Elk jaar" />;
			default:
				return (
					<FormattedMessage id="jobs.scheduleType.onetime" defaultMessage="Eenmalig" />
				);
		}
	};

	return (
		<div className="job-definition-card" onClick={props.onClick}>
			<div className="job-definition-card-header">{definition.title}</div>
			<div className="job-definition-card-body">
				<p>{definition.description}</p>
				{schedule !== null && schedule !== undefined && !schedule.isDisabled && (
					<div className="job-definition-card-body-detail">
						{schedule.lastRunTime !== null && schedule.lastRunTime !== undefined && (
							<div>
								<p>
									<Icon iconName="Play" />{" "}
									<FormattedMessage
										id="jobs.def.card.lastRunTime"
										defaultMessage="Laatst uitgevoerd"
									/>
									:
								</p>
								<p>
									<FormattedDate value={schedule.lastRunTime} />
									{" - "}
									<FormattedTime value={schedule.lastRunTime} />
								</p>
							</div>
						)}
						{schedule.scheduleType !== JobScheduleType.OneTime && (
							<div>
								<p>
									<Icon iconName="Refresh" />{" "}
									<FormattedMessage
										id="jobs.def.card.interval"
										defaultMessage="Interval"
									/>
									:
								</p>
								<p>{schedule.interval}</p>
							</div>
						)}
						<div>
							<p>
								<Icon iconName="ScheduleEventAction" />{" "}
								<FormattedMessage
									id="jobs.def.card.scheduleType"
									defaultMessage="Schema type"
								/>
								:
							</p>
							<p>{determineScheduleTypeText(schedule.scheduleType)}</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
