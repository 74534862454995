import * as React from "react";

import { MessageBar, MessageBarType, Panel } from "@fluentui/react";
import { Icon } from "@fluentui/react";
import { injectIntl, WrappedComponentProps } from "react-intl";

export interface ConfirmPanelProps {
	isOpen: boolean;

	onConfirm: () => void;
	onCancel: () => void;

	onClose?: () => void; // The onClose function defaults to the provided onCancel function.

	headerText: string;
	errorMessage?: string;
	contentTitle: string;
	contentDescription: string;
	contentConfirmText?: string;
	contentCancelText?: string;
}

const ConfirmPanelComponent = (props: WrappedComponentProps & ConfirmPanelProps) => {
	const { intl, isOpen, headerText, errorMessage, contentTitle, contentDescription } = props;
	const onClose = props.onClose === undefined ? props.onCancel : props.onClose;

	let errorMessageUi: JSX.Element | null = null;
	const contentConfirmText =
		props.contentConfirmText !== undefined
			? props.contentConfirmText
			: intl.formatMessage({ id: "general.yes", defaultMessage: "Ja" });
	const contentCancelText =
		props.contentCancelText !== undefined
			? props.contentCancelText
			: intl.formatMessage({ id: "general.no", defaultMessage: "Nee" });

	if (errorMessage !== undefined && errorMessage !== "") {
		errorMessageUi = (
			<MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
		);
	}

	return (
		<Panel
			headerText=""
			isOpen={isOpen}
			closeButtonAriaLabel="Close"
			isLightDismiss
			onLightDismissClick={onClose}
			className="one-panel"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>{headerText}</h1>

					<div className="one-panel__panel-close" onClick={onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			{errorMessageUi}

			<section className="one-panel__panel-content">
				<h3>{contentTitle}</h3>
				<label>{contentDescription}</label>
				<div className="action-container">
					<div
						className="action-container__action-item action-container__action-item--approve"
						onClick={props.onConfirm}
					>
						<Icon iconName={"Accept"} />
						<span className="action-container__action-item__label">
							{contentConfirmText}
						</span>
					</div>
					<div
						className="action-container__action-item action-container__action-item--reject"
						onClick={props.onCancel}
					>
						<Icon iconName={"ChromeClose"} />
						<span className="action-container__action-item__label">
							{contentCancelText}
						</span>
					</div>
				</div>
			</section>
		</Panel>
	);
};

export const ConfirmPanel = injectIntl(ConfirmPanelComponent);
