import { IOneUser, IRole, IRoleAssignment, IRolePermissionsOveriew } from "@one/core";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { adalOneApiFetch } from "../../adalConfig";

import { RolesActionTypes, RolesState } from "./types";

export const addRoleToUser: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = (user: IOneUser, role: IRole) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: RolesActionTypes.ADD_ROLE_TO_USER_STARTED,
			});
			if (role === null) {
				throw Error();
			}
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${user.id}/roles`,
				{
					method: "PUT",
					body: JSON.stringify({
						userId: user.id,
						roleId: role.id,
					}),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status === 201) {
					await response.json();
					dispatch({
						type: RolesActionTypes.ADD_ROLE_TO_USER_SUCCESS,
						payload: {
							user,
							role,
						},
					});
					return;
				}
			}
			dispatch({
				type: RolesActionTypes.ADD_ROLE_TO_USER_FAILED,
			});
		} catch (error) {
			dispatch({
				type: RolesActionTypes.ADD_ROLE_TO_USER_FAILED,
			});
		}
	};
};

export const removeRoleFromUser: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = (
	roleAssignment: IRoleAssignment,
	roleId: number
) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({
				type: RolesActionTypes.REMOVE_ROLE_TO_USER_STARTED,
			});
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users/${roleAssignment.user.id}/roles/${roleId}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status === 200) {
					await response.json();
					dispatch({
						type: RolesActionTypes.REMOVE_ROLE_TO_USER_SUCCESS,
						payload: roleAssignment,
					});
					return;
				}
			}
			dispatch({
				type: RolesActionTypes.REMOVE_ROLE_TO_USER_FAILED,
			});
		} catch (error) {
			dispatch({
				type: RolesActionTypes.REMOVE_ROLE_TO_USER_FAILED,
			});
		}
	};
};
export const setRoleModal: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = (role: IRole | null) => {
	return async (dispatch: Dispatch) => {
		if (role === null) {
			dispatch({
				type: RolesActionTypes.RESET_ROLE_MODAL,
			});
		} else {
			dispatch({
				type: RolesActionTypes.SET_ROLE_MODAL_ROLE,
				payload: role,
			});
		}
	};
};

export const fetchRoleAssignments: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = (roleId: number) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/roles/${roleId}/assignments`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_FAILED,
					});
					return;
				}

				const result = await response.json();

				dispatch({
					type: RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: RolesActionTypes.FETCH_ROLE_ASSIGNMENTS_FAILED,
			});
		}
	};
};
export const fetchPermissionsForRole: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = (roleId: number) => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/roles/${roleId}/permissions`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_FAILED,
					});
					return;
				}

				const result = await response.json();

				dispatch({
					type: RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: RolesActionTypes.FETCH_PERMISSIONS_FOR_ROLE_FAILED,
			});
		}
	};
};
export const searchUsersToAddToRole: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = (query: string) => {
	return async (dispatch: Dispatch) => {
		if (query === undefined || query.trim() === "") {
			dispatch({
				type: RolesActionTypes.RESET_SEARCH_USERS_FOR_ROLE,
			});
			return;
		}

		dispatch({
			type: RolesActionTypes.SEARCH_USERS_FOR_ROLE_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/users?query=${query}`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: RolesActionTypes.SEARCH_USERS_FOR_ROLE_FAILED,
					});
					return;
				}

				const result = await response.json();

				dispatch({
					type: RolesActionTypes.SEARCH_USERS_FOR_ROLE_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: RolesActionTypes.SEARCH_USERS_FOR_ROLE_FAILED,
			});
		}
	};
};

// --== Fetch roles
export const fetchRoles: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: RolesActionTypes.FETCH_ROLES_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/roles`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: RolesActionTypes.FETCH_ROLES_FAILED,
					});
					return;
				}

				const result: IRole[] = await response.json();

				dispatch({
					type: RolesActionTypes.FETCH_ROLES_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: RolesActionTypes.FETCH_ROLES_FAILED,
			});
		}
	};
};

// --== Fetch roles
export const fetchRolePermissionsOverview: ActionCreator<ThunkAction<Promise<any>, RolesState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch({
			type: RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_STARTED,
		});
		try {
			const response: Response = await adalOneApiFetch(fetch, `${process.env.REACT_APP_ONE_API_URL}/api/v1.0/roles/overview`, {});

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch({
						type: RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_FAILED,
					});
					return;
				}

				const result: IRolePermissionsOveriew = await response.json();

				dispatch({
					type: RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_SUCCESS,
					payload: result,
				});
			}
		} catch (e) {
			dispatch({
				type: RolesActionTypes.FETCH_ROLE_PERMISSIONS_OVERVIEW_FAILED,
			});
		}
	};
};
