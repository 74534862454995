import { IAiTablesResponse } from './../../models/responseModels/ApplicationInsights/IAiRowResponse';

import { Site } from '@microsoft/microsoft-graph-types'



export enum AnalyticsActionTypes {
    FETCH_INTRANET_WIDE_UNIQUE_VISITORS_STARTED = "FETCH_INTRANET_WIDE_UNIQUE_VISITORS_STARTED",
    FETCH_INTRANET_WIDE_UNIQUE_VISITORS_SUCCESS = "FETCH_INTRANET_WIDE_UNIQUE_VISITORS_SUCCESS",
    FETCH_INTRANET_WIDE_UNIQUE_VISITORS_FAILURE = "FETCH_INTRANET_WIDE_UNIQUE_VISITORS_FAILURE",

    FETCH_SP_SITE_STARTED = "FETCH_SP_SITE_STARTED",
    FETCH_SP_SITE_SUCCESS = "FETCH_SP_SITE_SUCCESS",
    FETCH_SP_SITE_FAILURE = "FETCH_SP_SITE_FAILURE",
    EMPTY_SEARCHED_SITE_COLLECTION = 'EMPTY_SEARCHED_SITE_COLLECTION',

    FETCH_SP_SITE_ANALYTIC_DATA_STARTED = "FETCH_SP_SITE_ANALYTIC_DATA_STARTED",
    FETCH_SP_SITE_ANALYTIC_DATA_SUCCESS = "FETCH_SP_SITE_ANALYTIC_DATA_SUCCESS",
    FETCH_SP_SITE_ANALYTIC_DATA_FAILURE = "FETCH_SP_SITE_ANALYTIC_DATA_FAILURE",

    FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_STARTED = "FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_STARTED",
    FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_SUCCESS = "FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_SUCCESS",
    FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_FAILURE = "FETCH_SP_SITE_ANALYTICS_DATA_90_DAYS_FAILURE",

    FETCH_PAGE_VIEWS_STARTED = "FETCH_PAGE_VIEWS_STARTED",
    FETCH_PAGE_VIEWS_SUCCESS = "FETCH_PAGE_VIEWS_SUCCESS",
    FETCH_PAGE_VIEWS_FAILURE = "FETCH_PAGE_VIEWS_FAILURE",

    FETCH_USER_ANALYTICS_PER_SITE_STARTED = "FETCH_USER_ANALYTICS_PER_SITE_STARTED",
    FETCH_USER_ANALYTICS_PER_SITE_SUCCESS = "FETCH_USER_ANALYTICS_PER_SITE_SUCCESS",
    FETCH_USER_ANALYTICS_PER_SITE_FAILURE = "FETCH_USER_ANALYTICS_PER_SITE_FAILURE",
}


export type AnalyticsState = Readonly<{
    intranetWideUniqueVisitors: IAiTablesResponse;
    siteGraphSearchedSitesCollection: Site[];
    siteAnalyticalData: IAiTablesResponse;
    siteData90Days: IAiTablesResponse;
    siteGraphSearchedSitesSearchTerm: '';
    pageViewsPerSite: IAiTablesResponse;
    errorMessage: string;
    isLoading: boolean;
    isLoadingSpSiteData: boolean;
    errorMessageSpSiteData: string;
    pageViewsDataIsLoading: boolean;
    pageViewsDataErrorMessage: string;
    pageUniqueVistorsSelectedSiteId: string;
    pageUniqueVisitorsIsLoading: boolean;
    pageUniqueVisitorsErrorMessage: string;
    pageUniqueVisitorsData: IAiTablesResponse;
}>;
