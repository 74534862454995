import React, { useEffect, useState } from "react";
import {
	SearchBox,
	DetailsList,
	IColumn,
	SelectionMode,
	IDetailsListProps,
	DetailsRow,
	Icon,
	MessageBar,
	MessageBarType,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import Loader from "react-loader-spinner";

import { searchUsers, resetSuccess, resetShouldClosePanel } from "../../../store/users/actions";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { UserResponse } from "../../../models/responseModels/user/UserResponse";
import { ErrorTypes, SuccessTypes } from "../../../models/enums";
import { delay } from "../../../common/helperFunctions/GeneralHelpers";

import { UserModalComponent } from "./userModalComponent/UserModalComponent";
import { User } from "@microsoft/microsoft-graph-types";
import { Card, CardContent } from "@one/web";

export const Users = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const usersSlice = useSelector((state: IApplicationState) => state.usersSlice);

	const [activeUserModal, setActiveUserModal] = useState<User | null>(null);
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (usersSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
		}
	}, [dispatch, firstRenderOccured, usersSlice.success.type]);

	// Close the modal after a user has been deleted
	if (usersSlice.shouldClosePanel && activeUserModal !== null) {
		setActiveUserModal(null);
		dispatch(resetShouldClosePanel());
	}

	const columns: IColumn[] = [
		{
			key: "givenName",
			name: intl.formatMessage({
				id: "users.list.column.givenName",
				defaultMessage: "Voornaam",
			}),
			fieldName: "givenName",
			minWidth: 75,
			maxWidth: 150,
			isResizable: true,
		},
		{
			key: "surname",
			name: intl.formatMessage({
				id: "users.list.column.surname",
				defaultMessage: "Achternaam",
			}),
			fieldName: "surname",
			minWidth: 75,
			maxWidth: 150,
			isResizable: true,
		},
		{
			key: "jobTitle",
			name: intl.formatMessage({
				id: "users.list.column.jobTitle",
				defaultMessage: "Functie",
			}),
			fieldName: "jobTitle",
			minWidth: 200,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: "emailAddress",
			name: intl.formatMessage({
				id: "users.list.column.email",
				defaultMessage: "E-mailadres",
			}),
			fieldName: "mail",
			minWidth: 300,
			maxWidth: 300,
			isResizable: true,
		},
		{
			key: "officeLocation",
			name: intl.formatMessage({
				id: "users.list.column.officeLocation",
				defaultMessage: "Locatie",
			}),
			fieldName: "officeLocation",
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
		},
	];

	// Add custom className and onClick to rows
	const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
		if (props) {
			const user = props.item as User;
			return (
				<div onClick={() => setActiveUserModal(user)}>
					<DetailsRow {...props} className="user-search-result-row" />
				</div>
			);
		}
		return null;
	};

	// Add custom rendering for specific columns
	const _renderItemColumn = (
		user: UserResponse,
		index: number | undefined,
		column: IColumn | undefined
	) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = user[column.fieldName as keyof UserResponse] as string;

		switch (column.key) {
			default:
				return (
					<div className="column-field">
						<span>{fieldContent || "-"}</span>
					</div>
				);
		}
	};

	const onSearchBoxChange = (query: string | undefined) => {
		if (query !== undefined) {
			delay(() => dispatch(searchUsers(query)), query !== "" ? 500 : 0);
		}
	};

	const determineSearchResultContentUi = () => {
		const shouldShowDetailsList =
			usersSlice.searchResult.length > 0 &&
			!usersSlice.isSearching &&
			usersSlice.error.type !== ErrorTypes.OnSearch;

		if (usersSlice.isSearching) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}
		if (shouldShowDetailsList) {
			return (
				<DetailsList
					items={usersSlice.searchResult}
					columns={columns}
					selectionMode={SelectionMode.none}
					onRenderRow={_onRenderRow}
					onRenderItemColumn={(item, index, column) =>
						_renderItemColumn(item as UserResponse, index, column)
					}
				/>
			);
		}
		if (!shouldShowDetailsList && usersSlice.query === "") {
			return (
				<div className="users-text-container ">
					<Icon iconName="Group" />
					<FormattedMessage
						id="users.search.enterSearchTerm"
						defaultMessage="Voer een zoekterm in om te beginnen."
					/>
				</div>
			);
		}

		return (
			<div className="users-text-container ">
				<FormattedMessage
					id="users.search.noSearchResults"
					defaultMessage="Geen gebruikers gevonden die overeenkomen met de ingevulde zoekterm."
				/>
			</div>
		);
	};

	const searchResultContentUi = determineSearchResultContentUi();

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="users.navigation.item" defaultMessage="Gebruikers" />
			</h1>
			<div className="main-container">
				<div className="users-page">
					<SearchBox
						placeholder={intl.formatMessage({
							id: "users.search.placeholder",
							defaultMessage: "Zoek naar gebruikers op basis van naam.",
						})}
						className="users-search-bar"
						onSearch={(query) => dispatch(searchUsers(query))}
						onChange={(e, query) => onSearchBoxChange(query)}
					/>
					{usersSlice.success.type === SuccessTypes.OnDelete && (
						<MessageBar
							styles={{
								root: {
									width: "calc(100% - 24px)",
									marginLeft: "12px",
								},
							}}
							messageBarType={MessageBarType.success}
							onDismiss={() => dispatch(resetSuccess())}
						>
							<FormattedMessage
								id=" users.succesMessage.onDelete"
								defaultMessage="Gebruiker is succesvol verwijderd."
							/>
						</MessageBar>
					)}
					<div className="search-result-container">{searchResultContentUi}</div>
					{activeUserModal !== null && (
						<UserModalComponent
							isOpen={activeUserModal !== null}
							onDismiss={() => setActiveUserModal(null)}
							user={activeUserModal}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export const UsersComponent = injectIntl(Users);
