import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { CurrentUserActionTypes, CurrentUserState } from "./types";
import { adalOneApiFetch } from "../../adalConfig";

export const fetchCurrentUser = () => {
	return (dispatch: Dispatch<any>) => {
		dispatch(fetchCurrentUserRequest());
	};
};

const fetchCurrentUserRequest: ActionCreator<ThunkAction<Promise<any>, CurrentUserState, null, any>> = () => {
	return async (dispatch: Dispatch) => {
		dispatch(fetchCurrentUserStarted());

		try {
			const response: Response = await adalOneApiFetch(
				fetch,
				`${process.env.REACT_APP_ONE_API_URL}/api/v1.0/me`,
				{}
			);

			if (response) {
				// If the reponse code is not positive, throw an error.
				if (response.status !== 200 && response.status !== 204) {
					dispatch(fetchCurrentUserFailure(response.statusText));
					return;
				}

				const result = await response.json();
				dispatch(fetchCurrentUserSuccess(result));
			}
		} catch (e) {
			dispatch(fetchCurrentUserFailure("error"));
		}
	};
};

const fetchCurrentUserStarted = () => ({
	type: CurrentUserActionTypes.FETCH_CURRENT_USER_STARTED
});

const fetchCurrentUserSuccess = (user: any) => ({

	type: CurrentUserActionTypes.FETCH_CURRENT_USER_SUCCESS,
	payload: {
		user
	}
});

const fetchCurrentUserFailure = (errorMessage: string | object) => ({
	type: CurrentUserActionTypes.FETCH_CURRENT_USER_FAILURE,
	payload: {
		errorMessage
	}
});
