import { User } from "@microsoft/microsoft-graph-types";
import { adalGraphFetch } from "../../adalConfig";
import anonymousUserImage from "../../assets/images/anonymous-user-gravatar.png";

export const fetchUserPhotoUrl = async (userObjectId: string) => {
	try {
		const response: any = await adalGraphFetch(fetch, `https://graph.microsoft.com/v1.0/users/${userObjectId}/photo/$value`, {
			method: "GET",
			responseType: "blob",
		});

		if (response.status !== 200) {
			return anonymousUserImage;
		}

		const blob = await response.blob();
		const blobUrl = window.URL.createObjectURL(blob);

		return blobUrl;
	} catch (error) {
		console.log("Error while fetching userImage");
		return anonymousUserImage;
	}
};

export const getUsersByTitleOrEmail = async (query: string, filterOutGuests: boolean = false) => {
	return new Promise<User[]>(async (resolve, reject) => {
		try {
			let filterParams = `(startswith(mail,'${query}') or startswith(givenName,'${query}') or startswith(surname,'${query}') or startswith(displayName,'${query}'))`;
			if (filterOutGuests) {
				filterParams += "and (userType ne 'Guest')";
			}

			// $count=true is required for the userType filter to work for some reason
			const response: Response = await adalGraphFetch(
				fetch,
				`https://graph.microsoft.com/v1.0/users?$count=true&$filter=${filterParams}`,
				{
					headers: {
						"Content-Type": "application/json",
						ConsistencyLevel: "eventual",
					},
				}
			);
			const result = await response.json();
			resolve(result.value);
		} catch (error) {
			reject(error);
		}
	});
};
