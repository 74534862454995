import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useSelector } from "react-redux";

import { TaxonomyItem } from "../../../../models/enums/TaxonomyITems";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";
import { ITaxonomy } from "../../../../models/responseModels/taxonomy/ITaxonomy";
import { ITerm } from "../../../../models/responseModels/taxonomy/ITerm";
import { ITermGroup } from "../../../../models/responseModels/taxonomy/ITermGroup";
import { ITermSet } from "../../../../models/responseModels/taxonomy/ITermSet";
import { ISelectedTaxonomyItemViewModel } from "../../../../models/viewmodels/taxonomy/ISelectedTaxonomyItemViewModel";

import { TaxonomyTreeNavRowComponent } from "./taxonomyNavRowComponent/TaxonomyNavRowComponent";

export interface ITaxonomyTreeProps {
	selectedItem: ISelectedTaxonomyItemViewModel | null;
	setSelectedItem: (newValue: ISelectedTaxonomyItemViewModel | null) => void;
}

const TaxonomyTree = (props: WrappedComponentProps & ITaxonomyTreeProps) => {
	const taxonomySlice = useSelector((state: IApplicationState) => state.taxonomySlice);
	const { selectedItem, setSelectedItem, intl } = props;

	const [taxonomyToUse, setTaxonomyToUse] = useState<ITaxonomy | null>(null);

	useEffect(() => {
		const newTaxonomyToUse = {
			termGroups: taxonomySlice.taxonomy.termGroups
				.map((group) => {
					return {
						...group,
						termSets: group.termSets.map((termSet) => {
							return {
								...termSet,
								terms: [...termSet.terms],
							};
						}),
					};
				})
				.filter((group) => group !== undefined),
		};

		setTaxonomyToUse(newTaxonomyToUse as ITaxonomy);
	}, [taxonomySlice.taxonomy]);

	if (
		taxonomySlice.taxonomy === undefined ||
		taxonomySlice.taxonomy === null ||
		taxonomyToUse === null
	) {
		return null;
	}

	const renderTermsWithChildren = (
		term: ITerm,
		termSetIsEditable: boolean,
		depth: number = 2
	) => {
		let termIsActive = false;
		if (selectedItem !== null && selectedItem?.type === TaxonomyItem.Term) {
			termIsActive = (selectedItem.item as ITerm).id === term.id;
		}

		return (
			<TaxonomyTreeNavRowComponent
				key={term.id}
				headerText={term.name}
				headerIcon="Tag"
				hideChevron={!term.childTerms || term.childTerms.length === 0}
				depth={depth}
				isActive={termIsActive}
				onHeaderClick={() =>
					setSelectedItem({
						item: term,
						type: TaxonomyItem.Term,
						termSetIsEditable,
					})
				}
				onAddClick={
					termSetIsEditable
						? () =>
								setSelectedItem({
									item: term,
									type: TaxonomyItem.Term,
									openCreatePanel: true,
									termSetIsEditable,
								})
						: undefined
				}
				addIconTitle={intl.formatMessage({
					id: "taxonomy.term.new",
					defaultMessage: "Nieuwe term",
				})}
			>
				{term.childTerms &&
					term.childTerms.length > 0 &&
					term.childTerms.map((child) =>
						renderTermsWithChildren(child, termSetIsEditable, depth + 1)
					)}
			</TaxonomyTreeNavRowComponent>
		);
	};

	return (
		<div className="taxonomy-tree-wrapper taxonomy-card-wrapper ">
			<TaxonomyTreeNavRowComponent
				headerText={intl.formatMessage({
					id: "taxonomy.navigation.item",
					defaultMessage: "Taxonomie",
				})}
				depth={0}
				isRoot
				notSelectable
				hideChevron={true}
				isActive={selectedItem !== null && selectedItem?.type === TaxonomyItem.Taxonomy}
				onHeaderClick={() =>
					setSelectedItem(
						taxonomyToUse === null
							? null
							: { item: taxonomyToUse, type: TaxonomyItem.Taxonomy }
					)
				}
			>
				{taxonomyToUse.termGroups.map((group) => {
					let termGroupIsActive = false;
					if (selectedItem !== null && selectedItem?.type === TaxonomyItem.TermGroup) {
						termGroupIsActive = (selectedItem.item as ITermGroup).id === group.id;
					}
					return (
						<TaxonomyTreeNavRowComponent
							key={group.id}
							headerText={group.name}
							headerIcon="FabricFolder"
							depth={0}
							hideChevron={group.termSets.length === 0}
							isActive={termGroupIsActive}
							onHeaderClick={() =>
								setSelectedItem({ item: group, type: TaxonomyItem.TermGroup })
							}
							onAddClick={() =>
								setSelectedItem({
									item: group,
									type: TaxonomyItem.TermGroup,
									openCreatePanel: true,
								})
							}
							addIconTitle={intl.formatMessage({
								id: "taxonomy.termSet.new",
								defaultMessage: "Nieuwe termenset",
							})}
						>
							{group.termSets.map((termSet) => {
								// Filter out the teamType
								if (termSet.isTeamType) return null;

								let termSetIsActive = false;
								if (
									selectedItem !== null &&
									selectedItem?.type === TaxonomyItem.TermSet
								) {
									termSetIsActive =
										(selectedItem.item as ITermSet).id === termSet.id;
								}
								return (
									<TaxonomyTreeNavRowComponent
										key={termSet.id}
										headerText={termSet.name}
										headerIcon="BulletedList"
										hideChevron={termSet.terms.length === 0}
										depth={1}
										isActive={termSetIsActive}
										onHeaderClick={() =>
											setSelectedItem({
												item: termSet,
												type: TaxonomyItem.TermSet,
											})
										}
										onAddClick={
											termSet.isEditable
												? () =>
														setSelectedItem({
															item: termSet,
															type: TaxonomyItem.TermSet,
															openCreatePanel: true,
														})
												: undefined
										}
										addIconTitle={intl.formatMessage({
											id: "taxonomy.term.new",
											defaultMessage: "Nieuwe term",
										})}
									>
										{termSet.terms.map((term) => {
											return renderTermsWithChildren(
												term,
												termSet.isEditable
											);
										})}
									</TaxonomyTreeNavRowComponent>
								);
							})}
						</TaxonomyTreeNavRowComponent>
					);
				})}
			</TaxonomyTreeNavRowComponent>
		</div>
	);
};

export const TaxonomyTreeComponent = injectIntl(TaxonomyTree);
