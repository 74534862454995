import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	ActionButton,
	DetailsList,
	DetailsListLayoutMode,
	IColumn,
	Icon,
	MessageBar,
	MessageBarType,
	SelectionMode,
} from "@fluentui/react";
import Loader from "react-loader-spinner";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";

import {
	fetchLookupLists,
	deleteLookupListValue,
	updateShouldClosePanel,
	clearError,
	resetSuccess,
} from "../../../store/lookupLists/actions";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { LookupListValueResponse } from "../../../models/responseModels/lookupLists/LookupListValueResponse";
import { TabsComponent, TabDataObject } from "../../reusable/tabsComponent/TabsComponent";
import { EditListValuePanelComponent } from "./editListValuePanelComponent/EditListValuePanelComponent";
import { CreateListValuePanelComponent } from "./createListValuePanelComponent/CreateListValuePanelComponent";
import { LookupListsResponse } from "../../../models/responseModels/lookupLists/LookupListsResponse";
import { ConfirmPanel } from "../../reusable/confirmPanelComponent/ConfirmPanelComponent";
import { ErrorTypes } from "../../../models/enums/ErrorTypes";
import { sortArray } from "../../../common/helperFunctions/ObjectsAndArrayHelpers";
import { SuccessTypes } from "../../../models/enums";

const LookupLists = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const lookupListsSlice = useSelector((state: IApplicationState) => state.lookupListsSlice);

	const [activeLookupList, setActiveLookupList] = useState<LookupListsResponse | null>(null);
	const [activeTabId, setActiveTabId] = useState(0);
	const [previousActiveTabId, setPreviousActiveTabId] = useState<number | null>(null);
	const [tabData, setTabData] = useState<TabDataObject[]>([]);
	const [detailListItems, setDetailListItems] = useState<any[]>([]);
	const [firstRenderOccured, setFirstRenderOccured] = useState(false);

	const [creatingListValueForList, setCreatingListValueForList] =
		useState<LookupListsResponse | null>(null);
	const [currentlyEditingListValue, setCurrentlyEditingListValue] =
		useState<LookupListValueResponse | null>(null);
	const [currentlyDeletingListValue, setCurrentlyDeletingListValue] =
		useState<LookupListValueResponse | null>(null);

	const columns: IColumn[] = [
		{
			key: "name",
			name: intl.formatMessage({ id: "lookup.list.column.name", defaultMessage: "Waarde" }),
			fieldName: "name",
			minWidth: 100,
			maxWidth: 125,
			isResizable: true,
		},
		{
			key: "isActive",
			name: intl.formatMessage({
				id: "lookup.list.column.isActive",
				defaultMessage: "In gebruik",
			}),
			fieldName: "isActive",
			minWidth: 100,
			maxWidth: 125,
			isResizable: true,
		},
		{
			key: "actions",
			name: intl.formatMessage({
				id: "lookup.list.column.actions",
				defaultMessage: "Acties",
			}),
			fieldName: "actions",
			minWidth: 100,
			maxWidth: 150,
			isResizable: true,
		},
	];

	useEffect(() => {
		dispatch(fetchLookupLists());
	}, [dispatch]);

	// Clear the success message if this still exists
	useEffect(() => {
		if (!firstRenderOccured) {
			setFirstRenderOccured(true);

			if (lookupListsSlice.success.type !== SuccessTypes.None) {
				dispatch(resetSuccess());
			}
		}
	}, [dispatch, firstRenderOccured, lookupListsSlice.success.type]);

	// Close any panel if necessary
	useEffect(() => {
		setCurrentlyDeletingListValue(null);
		setCurrentlyEditingListValue(null);
		setCreatingListValueForList(null);
		dispatch(updateShouldClosePanel(false));
	}, [lookupListsSlice.shouldClosePanel, dispatch]);

	useEffect(() => {
		const provideTabData = () => {
			if (
				!lookupListsSlice.isLoadingInitialData &&
				lookupListsSlice.lookupLists !== undefined &&
				lookupListsSlice.lookupLists.length > 0
			) {
				const newTabData: TabDataObject[] = [];

				for (let i = 0; i < lookupListsSlice.lookupLists.length; i++) {
					const lookupList = lookupListsSlice.lookupLists[i];
					const includeExclamationMark = lookupList.lookupListValues.length === 0;

					newTabData.push({
						id: i,
						name: lookupList.displayName,
						title: lookupList.displayName,
						includeExclamationMark,
					});
				}

				setTabData(newTabData);

				//Set the activeLookupList
				if (activeLookupList === null) {
					setActiveLookupList(lookupListsSlice.lookupLists[0]);
				}
			}
		};

		provideTabData();
	}, [lookupListsSlice, activeLookupList]);

	// Clear the success message if the user switches tabs
	useEffect(() => {
		if (
			activeTabId !== previousActiveTabId &&
			lookupListsSlice.success.type !== SuccessTypes.None
		) {
			dispatch(resetSuccess());
		}
		setPreviousActiveTabId(activeTabId);
	}, [activeTabId, previousActiveTabId, lookupListsSlice.success.type, dispatch]);

	useEffect(() => {
		if (lookupListsSlice.lookupLists[activeTabId] !== undefined) {
			setActiveLookupList(lookupListsSlice.lookupLists[activeTabId]);
			setDetailListItems(
				lookupListsSlice.lookupLists[activeTabId].lookupListValues
					.sort(sortArray("name"))
					.reverse()
			);
		}
	}, [activeTabId, lookupListsSlice.lookupLists]);

	const _renderItemColumn = (
		item: LookupListValueResponse,
		index: number | undefined,
		column: IColumn | undefined
	) => {
		if (column === undefined) {
			return null;
		}
		const fieldContent = item[column.fieldName as keyof LookupListValueResponse] as string;
		switch (column.key) {
			case "name":
				return (
					<div className="column-field">
						<span>{item.name}</span>
					</div>
				);
			case "isActive":
				return (
					<div className="column-field column-field__is-active">
						{item.isActive ? <Icon iconName="CheckMark" /> : null}
					</div>
				);
			case "actions":
				return (
					<div className="column-field column-field__action">
						<span onClick={() => setCurrentlyEditingListValue(item)}>
							{intl.formatMessage({
								id: "lookup.list.action.edit",
								defaultMessage: "Bewerk",
							})}
						</span>
						<span>|</span>
						<span onClick={() => setCurrentlyDeletingListValue(item)}>
							{intl.formatMessage({
								id: "lookup.list.action.delete",
								defaultMessage: "Verwijder",
							})}
						</span>
					</div>
				);
			default:
				return <div className="column-field">{fieldContent}</div>;
		}
	};

	const renderSuccessBarUi = () => {
		if (lookupListsSlice.success.type !== SuccessTypes.None) {
			let message: JSX.Element | null = null;
			if (lookupListsSlice.success.type === SuccessTypes.OnCreate) {
				message = (
					<span>{`${activeLookupList?.displayName} ${intl.formatMessage({
						id: "lookup.success.onCreate",
						defaultMessage: "is succesvol aangemaakt.",
					})}`}</span>
				);
			} else if (lookupListsSlice.success.type === SuccessTypes.OnUpdate) {
				message = (
					<span>{`${activeLookupList?.displayName} ${intl.formatMessage({
						id: "lookup.success.onUpdate",
						defaultMessage: "is succesvol bijgewerkt.",
					})}`}</span>
				);
			} else if (lookupListsSlice.success.type === SuccessTypes.OnDelete) {
				message = (
					<span>{`${activeLookupList?.displayName} ${intl.formatMessage({
						id: "lookup.success.onDelete",
						defaultMessage: "is succesvol verwijderd.",
					})}`}</span>
				);
			}

			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					onDismiss={() => dispatch(resetSuccess())}
				>
					{message}
				</MessageBar>
			);
		}
	};

	const renderNewButtonUi = () => {
		if (activeLookupList !== null && !lookupListsSlice.isLoadingInitialData) {
			return (
				<ActionButton
					iconProps={{ iconName: "Add" }}
					onClick={() => setCreatingListValueForList(activeLookupList)}
				>
					{activeLookupList.displayName}
				</ActionButton>
			);
		}
	};

	const successBarUi = renderSuccessBarUi();
	const newButtonUi = renderNewButtonUi();

	if (lookupListsSlice.isLoadingInitialData) {
		return (
			<div className="loaderWrapper">
				<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
			</div>
		);
	}
	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="lookup.navigation.item" defaultMessage="Opzoeklijsten" />
			</h1>
			{successBarUi}
			<div className="main-container">
				<div className="lookuplist-page">
					<TabsComponent
						activeTabId={activeTabId}
						setActiveTab={setActiveTabId}
						tabData={tabData}
					/>

					<div className="lookuplist-new-button">{newButtonUi}</div>

					<DetailsList
						items={detailListItems}
						layoutMode={DetailsListLayoutMode.justified}
						selectionMode={SelectionMode.none}
						columns={columns}
						onRenderItemColumn={(item, index, column) =>
							_renderItemColumn(item as LookupListValueResponse, index, column)
						}
					/>

					<CreateListValuePanelComponent
						isOpen={creatingListValueForList !== null}
						onClose={() => setCreatingListValueForList(null)}
						lookupList={creatingListValueForList}
					/>
					<EditListValuePanelComponent
						isOpen={currentlyEditingListValue !== null}
						onClose={() => setCurrentlyEditingListValue(null)}
						lookupList={activeLookupList}
						listValue={currentlyEditingListValue}
					/>
					<ConfirmPanel
						isOpen={currentlyDeletingListValue !== null}
						errorMessage={
							lookupListsSlice.error.type === ErrorTypes.OnDelete
								? intl.formatMessage({
										id: "lookup.error.onDelete",
										defaultMessage:
											"Deze waarde is momenteel in gebruik. Verwijder alle referenties naar deze waarde om deze te verwijderen.",
								  })
								: undefined
						}
						contentDescription={intl.formatMessage({
							id: "lookup.panel.delete.contentDescription",
							defaultMessage:
								"Deze waarde kan alleen worden verwijderd wanneer deze op geen enkele plek wordt gebruikt. Indien u verder gebruik van deze waarde wilt voorkomen kunt u deze waarde op 'inactief' zetten in het bewerk paneel.",
						})}
						contentTitle={intl.formatMessage({
							id: "lookup.panel.delete.contentTitle",
							defaultMessage: "Weet u zeker dat u deze waarde wilt verwijderen?",
						})}
						headerText={
							currentlyDeletingListValue?.name ||
							intl.formatMessage({
								id: "general.delete",
								defaultMessage: "Verwijder",
							})
						}
						onCancel={() => {
							if (lookupListsSlice.error.type !== ErrorTypes.None) {
								dispatch(clearError());
							}
							setCurrentlyDeletingListValue(null);
						}}
						onConfirm={() => {
							if (activeLookupList !== null && currentlyDeletingListValue !== null) {
								dispatch(
									deleteLookupListValue(
										activeLookupList,
										currentlyDeletingListValue.id
									)
								);
							}
						}}
					/>
				</div>
			</div>
		</section>
	);
};

export const LookupListsComponent = injectIntl(LookupLists);
