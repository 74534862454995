// Framework & Third-party
import * as React from "react";
import {
	Panel,
	PanelType,
	Icon,
	MessageBarType,
	MessageBar,
	PrimaryButton,
	Toggle,
	Dropdown,
	SpinButton,
} from "@fluentui/react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import { ErrorTypes } from "../../../../../models/enums";
import { createJobSchedule, resetError } from "../../../../../store/jobs/actions";
import { JobScheduleType } from "../../../../../models/enums/JobScheduleType";
import { ICreateJobSchedule } from "../../../../../models/viewmodels/jobs/ICreateJobSchedule";

export interface ICreateJobSchedulePanelProps {
	isOpen: boolean;
	onClose: () => void;
}

const CreateJobSchedulePanel = (props: WrappedComponentProps & ICreateJobSchedulePanelProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const jobsSlice = useSelector((state: IApplicationState) => state.jobsSlice);

	return (
		<Panel
			className="one-panel"
			isOpen={props.isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={props.onClose}
			isLightDismiss
			styles={{
				scrollableContent: {
					overflow: "visible",
				},
			}}
			onLightDismissClick={props.onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage
							id="job.schedule.panel.create.header"
							defaultMessage="Nieuw uitvoeringsschema"
						/>
					</h1>

					<div className="one-panel__panel-close" onClick={props.onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			{jobsSlice.error.type === ErrorTypes.OnCreate && (
				<MessageBar
					messageBarType={MessageBarType.error}
					onDismiss={() => dispatch(resetError())}
				>
					<FormattedMessage
						id="job.schedule.panel.error.onCreate"
						defaultMessage="Er ging iets mis bij het aanmaken van dit uitvoeringsschema"
					/>
				</MessageBar>
			)}

			<section className="one-panel__panel-content">
				<div className="form-wrapper">
					<Formik
						enableReinitialize
						initialValues={{
							isActive: true,
							retry: true,
							scheduleType: 0,
							interval: 1,
						}}
						onSubmit={(values) => {
							const requestBody = {
								isDisabled: !values.isActive,
								interval: values.interval as number,
								retry: values.retry,
								scheduleType: values.scheduleType,
							} as ICreateJobSchedule;
							if (
								jobsSlice.selectedJob !== undefined &&
								jobsSlice.selectedJob !== null &&
								jobsSlice.selectedTenant !== undefined &&
								jobsSlice.selectedTenant !== null
							) {
								dispatch(
									createJobSchedule(
										jobsSlice.selectedJob.jobDefinition.id,
										jobsSlice.selectedTenant.id,
										requestBody
									)
								);
							}
						}}
					>
						{(props) => {
							const {
								values,
								errors,
								handleSubmit,
								setFieldValue,
								handleBlur,
							} = props;
							return (
								<form onSubmit={handleSubmit}>
									{/* ---=== Is Active ===--- */}
									<div className="form-field-label">
										<label>
											<FormattedMessage
												id="job.schedule.panel.isActive.label"
												defaultMessage="In gebruik"
											/>
										</label>
										<span className="form-field-label__required-star">*</span>
									</div>
									<Toggle
										checked={values.isActive}
										className="toggle-input"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) =>
											setFieldValue("isActive", checked)
										}
									/>

									{/* ---=== Retry ===--- */}
									<div className="form-field-label">
										<label>
											<FormattedMessage
												id="job.schedule.panel.retry.label"
												defaultMessage="Probeer opnieuw indien job faalt"
											/>
										</label>
										<span className="form-field-label__required-star">*</span>
									</div>
									<Toggle
										checked={values.retry}
										className="toggle-input"
										onText={intl.formatMessage({
											id: "general.yes",
											defaultMessage: "Ja",
										})}
										offText={intl.formatMessage({
											id: "general.no",
											defaultMessage: "Nee",
										})}
										onChange={(e, checked) => setFieldValue("retry", checked)}
									/>

									<div className="form-field-label">
										<label>
											<FormattedMessage
												id="job.schedule.panel.scheduleType.label"
												defaultMessage="Type uitvoeringsschema"
											/>
										</label>
										<span className="form-field-label__required-star">*</span>
									</div>
									<Dropdown
										selectedKey={values.scheduleType}
										style={{
											margin: "0 0 3rem",
										}}
										onChange={(e, value) =>
											value
												? setFieldValue("scheduleType", value.key)
												: undefined
										}
										options={[
											{
												key: JobScheduleType.Yearly,
												text: intl.formatMessage({
													id: "jobs.scheduleType.yearly",
													defaultMessage: "Jaar",
												}),
											},
											{
												key: JobScheduleType.Monthly,
												text: intl.formatMessage({
													id: "jobs.scheduleType.monthly",
													defaultMessage: "Maand",
												}),
											},
											{
												key: JobScheduleType.Weekly,
												text: intl.formatMessage({
													id: "jobs.scheduleType.weekly",
													defaultMessage: "Week",
												}),
											},
											{
												key: JobScheduleType.Daily,
												text: intl.formatMessage({
													id: "jobs.scheduleType.daily",
													defaultMessage: "Dag",
												}),
											},
											{
												key: JobScheduleType.Minute,
												text: intl.formatMessage({
													id: "jobs.scheduleType.minute",
													defaultMessage: "Minuut",
												}),
											},
											{
												key: JobScheduleType.OneTime,
												text: intl.formatMessage({
													id: "jobs.scheduleType.onetime",
													defaultMessage: "Eenmalig",
												}),
											},
										]}
									/>

									<div
										className="form-field-label"
										title={
											values.scheduleType !== JobScheduleType.Minute
												? intl.formatMessage({
														id: "job.schedule.panel.interval.tooltip",
														defaultMessage:
															"Dit is enkel instelbaar voor het uitvoeringsschema 'Minuut'.",
												  })
												: undefined
										}
									>
										<label>
											<FormattedMessage
												id="job.schedule.panel.interval.label"
												defaultMessage="Interval"
											/>
										</label>
										<span className="form-field-label__required-star">*</span>
									</div>
									<SpinButton
										id="interval"
										className="ilx-spin-button"
										value={
											values.scheduleType !== JobScheduleType.Minute
												? "1"
												: values.interval.toString()
										}
										disabled={values.scheduleType !== JobScheduleType.Minute}
										onIncrement={(value) => {
											setFieldValue("interval", parseInt(value) + 1);
										}}
										onDecrement={(value) => {
											const prevValue = parseInt(value);
											if (prevValue - 1 > 0) {
												setFieldValue("interval", prevValue - 1);
											}
										}}
										onValidate={(value) => {
											const parsed = parseInt(value);
											if (!isNaN(parsed) && parsed <= 59 && parsed > 0) {
												setFieldValue("interval", parsed);
											}
										}}
										onBlur={handleBlur}
										title={
											values.scheduleType !== JobScheduleType.Minute
												? intl.formatMessage({
														id: "job.schedule.panel.interval.tooltip",
														defaultMessage:
															"Dit is enkel instelbaar voor het uitvoeringsschema 'Minuut'.",
												  })
												: undefined
										}
										min={1}
										max={59}
										step={1}
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.create",
											defaultMessage: "Aanmaken",
										})}
										type="submit"
										disabled={
											jobsSlice.isCreatingJobSchedule ||
											(Object.keys(errors).length !== 0 &&
												errors.constructor === Object)
										}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</section>
		</Panel>
	);
};

export const CreateJobSchedulePanelComponent = injectIntl(CreateJobSchedulePanel);
