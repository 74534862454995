import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

class CustomTooltip extends React.Component<WrappedComponentProps> {
	render() {
		// @ts-ignore
		const { label, active, payload } = this.props;

		if (active && payload && payload.length > 0) {
			return (
				<div className="tooltip-wrapper">
					<h5>Details</h5>

					<p>
						{" "}
						<FormattedMessage
							id="usage.uniqueVisitorsChart.xLabel"
							defaultMessage="Datum"
						/>
						: {label}
					</p>
					<p>
						<FormattedMessage
							id="usage.uniqueVisitorsChart.yLabel"
							defaultMessage="Gebruikers"
						/>
						: {payload[0].value}
					</p>
				</div>
			);
		}

		return null;
	}
}
export default injectIntl(CustomTooltip);
