import React from "react";
import { Icon } from "@fluentui/react";

export interface AlertProps {
	message: string;
	type: "success" | "warning" | "danger";
	onDismiss?: () => void;
}

export const AlertComponent = (props: AlertProps) => {
	return (
		<div className={`one-alert one-alert--${props.type}`}>
			{props.message}
			{props.onDismiss !== undefined && (
				<Icon iconName="ChromeClose" onClick={props.onDismiss} />
			)}
		</div>
	);
};
