import { TeamTypesActionTypes, TeamTypesState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums";

const initialState: TeamTypesState = {
	teamTypes: [],

	loaders: {
		isCreating: false,
		isUpdating: false,
		isLoadingInitialData: false,
		isDeleting: false,
	},

	error: {
		message: "",
		type: ErrorTypes.None,
	},
	success: {
		type: SuccessTypes.None,
	},
};

export function teamTypesReducer(
	state = initialState,
	action: { type: TeamTypesActionTypes; payload: any }
): TeamTypesState {
	switch (action.type) {
		case TeamTypesActionTypes.CLEAR_ERROR:
			return {
				...state,
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case TeamTypesActionTypes.RESET_SUCCESS:
			return {
				...state,
				success: {
					type: SuccessTypes.None,
				},
			};

		// --== Fetch Lookup List ==--
		case TeamTypesActionTypes.FETCH_TEAMTYPES_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isLoadingInitialData: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
			};
		case TeamTypesActionTypes.FETCH_TEAMTYPES_SUCCESS:
			return {
				...state,
				teamTypes: action.payload.teamTypes,
				loaders: {
					...state.loaders,
					isLoadingInitialData: false,
				},
			};
		case TeamTypesActionTypes.FETCH_TEAMTYPES_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isLoadingInitialData: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnInitialDataLoad,
				},
			};

		// --== CREATE team type ==--
		case TeamTypesActionTypes.CREATE_TEAMTYPE_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isCreating: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
				success: {
					type: SuccessTypes.None,
				},
			};
		case TeamTypesActionTypes.CREATE_TEAMTYPE_SUCCESS:
			const teamTypesAfterCreate = [...state.teamTypes];
			teamTypesAfterCreate.push(action.payload.createdTeamType);

			return {
				...state,
				teamTypes: teamTypesAfterCreate,
				loaders: {
					...state.loaders,
					isCreating: false,
				},
				success: {
					type: SuccessTypes.OnCreate,
				},
			};
		case TeamTypesActionTypes.CREATE_TEAMTYPE_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isCreating: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnCreate,
				},
			};

		// --== UPDATE team type ==--
		case TeamTypesActionTypes.UPDATE_TEAMTYPE_STARTED:
			return {
				...state,
				loaders: {
					...state.loaders,
					isUpdating: true,
				},
				error: {
					message: "",
					type: ErrorTypes.None,
				},
				success: {
					type: SuccessTypes.None,
				},
			};
		case TeamTypesActionTypes.UPDATE_TEAMTYPE_SUCCESS:
			const teamTypesAfterupdate = [...state.teamTypes].map((type) => {
				if (action.payload.updatedTeamType.id === type.id) {
					return action.payload.updatedTeamType;
				}
				return type;
			});
			return {
				...state,
				teamTypes: teamTypesAfterupdate,
				loaders: {
					...state.loaders,
					isUpdating: false,
				},
				success: {
					type: SuccessTypes.OnUpdate,
				},
			};
		case TeamTypesActionTypes.UPDATE_TEAMTYPE_FAILURE:
			return {
				...state,
				loaders: {
					...state.loaders,
					isUpdating: false,
				},
				error: {
					message: action.payload.errorMessage,
					type: ErrorTypes.OnUpdate,
				},
			};

		default:
			return state;
	}
}
