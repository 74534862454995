// Framework & third-party
import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ActionButton, Icon, Separator } from "@fluentui/react";

// Store

// Models
import { ITaxonomy } from "../../../../../models/responseModels/taxonomy/ITaxonomy";

export interface ISelectedGroupProps {
	taxonomy: ITaxonomy;
}

const SelectedTaxonomy = (props: WrappedComponentProps & ISelectedGroupProps) => {
	// const { taxonomy, intl } = props;
	// const dispatch = useDispatch();
	// const taxonomySlice = useSelector((state: IApplicationState) => state.taxonomySlice);

	return (
		<div className="taxonomy-card-wrapper taxonomy-selected-wrapper ">
			<div className="taxonomy-selected">
				<div className="taxonomy-selected-topbar">
					<div className="taxonomy-selected-topbar-title">
						<h3>Taxonomy</h3>
						<Icon iconName="Home" />
					</div>
					<div>
						<ActionButton iconProps={{ iconName: "add" }} text="TermGroup" />
					</div>
				</div>
				<Separator />
			</div>
		</div>
	);
};

export const SelectedTaxonomyComponent = injectIntl(SelectedTaxonomy);
