import React, { useState, useEffect } from "react";
import { IAiTablesResponse } from "../../../models/responseModels/ApplicationInsights/IAiTablesResponse";
import { UniqueVisitorsChartComponent } from "./charts/uniqueVisitorsIntranetChart/UniqueVisitorsIntranetChart";
import { useSelector, useDispatch } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchTenantWideUniqueVisitors } from "../../../store/analytics/actions";
import { UniqueVisitorsPerSiteChartComponent } from "./charts/uniqueVisitorsPerSiteChartComponent/uniqueVisitorsPerSiteChartComponent";
import { PageViewsPerSiteComponent } from "./charts/PageViewsPerSite/pageViewsPerSite";
import { FormattedMessage } from "react-intl";
import { fetchAudiences } from "../../../store/audiences/actions";
import { ActionButton, IDropdownOption } from "@fluentui/react";
import { delay } from "../../../common/helperFunctions/GeneralHelpers";
import { withRouter, RouteComponentProps } from "react-router-dom";

const Usage = (props: RouteComponentProps) => {
	const dispatch = useDispatch();
	const audiencesSlice = useSelector((state: IApplicationState) => state.audiencesSlice);
	const analyticsSlice = useSelector((state: IApplicationState) => state.analyticsSlice);
	const [audienceIdsCSV, setAudienceIdsCSV] = useState("");
	const [uniqueVisitorsAudienceFilters, setUniqueVisitorsAudienceFilters] = useState<
		IDropdownOption[]
	>([]);
	const [uniqueVisitorAmountDays, setUniqueVisitorAmountDays] = useState<number>(30);
	const [
		uniqueVisitorsOnIntranetViewData,
		setUniqueVisitorsOnIntranetViewData,
	] = useState<IAiTablesResponse>({} as IAiTablesResponse);

	let formattedData: any = [];
	let columnByNameMap = {};
	let avgVisitorsADay: number = 0;

	// In hooks there exists a bug where we would not want to update a state variable and thus not
	// causing a loop to rerender the uniqueVisitorAmountDays variable. Thus I decied to ignore it
	// until a fix is created. See: https://github.com/facebook/react/issues/15865
	useEffect(() => {
		dispatch(fetchTenantWideUniqueVisitors(uniqueVisitorAmountDays));
		changeViewUniqueVisitorsAmountDays(uniqueVisitorAmountDays);

		// Fetch audiences if these aren't fetched yet.
		if (audiencesSlice.audiences.length === 0 && !audiencesSlice.isFetchingAudiences) {
			dispatch(fetchAudiences());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (analyticsSlice.intranetWideUniqueVisitors.tables != null) {
			const data: IAiTablesResponse = { ...analyticsSlice.intranetWideUniqueVisitors };
			setUniqueVisitorsOnIntranetViewData(data);
		}
	}, [analyticsSlice]);

	useEffect(() => {
		let newAudienceIdsCSV = "";
		for (let i = 0; i < uniqueVisitorsAudienceFilters.length; i++) {
			const option = uniqueVisitorsAudienceFilters[i];
			i > 0 ? (newAudienceIdsCSV += `,${option.key}`) : (newAudienceIdsCSV += option.key);
		}

		delay(
			() => {
				setAudienceIdsCSV(newAudienceIdsCSV);
			},
			newAudienceIdsCSV.length > 0 ? 1000 : 0
		);
	}, [uniqueVisitorsAudienceFilters]);

	useEffect(() => {
		dispatch(fetchTenantWideUniqueVisitors(uniqueVisitorAmountDays, audienceIdsCSV));
	}, [uniqueVisitorAmountDays, audienceIdsCSV, dispatch]);

	useEffect(() => {
		changeViewUniqueVisitorsAmountDays(uniqueVisitorAmountDays);
	}, [uniqueVisitorsOnIntranetViewData, uniqueVisitorAmountDays]);

	// Formats the data for the table.
	if (
		uniqueVisitorsOnIntranetViewData != null &&
		Object.keys(uniqueVisitorsOnIntranetViewData).length !== 0 &&
		uniqueVisitorsOnIntranetViewData.constructor === Object
	) {
		if (uniqueVisitorsOnIntranetViewData.tables != null) {
			// make sure columnByNameMap functions as an enum with the available columns in an object.
			uniqueVisitorsOnIntranetViewData.tables[0].columns.map((column, i) => {
				return (columnByNameMap[i] = column.name);
			});

			// Loop through the row push an object with columnName: data in aggregatedData: {Host: *, USers: *, timestamp: *}
			uniqueVisitorsOnIntranetViewData.tables[0].rows.map((row, i) => {
				let obj = {};
				for (let i = 0; i < row.length; i++) {
					// truncate the date into the right format:
					if (columnByNameMap[i] === "timestamp") {
						let truncatedDate = row[i].toString().split("T")[0].substr(5, 10);

						// removed leading zeros in the months
						if (truncatedDate[0] === "0") {
							truncatedDate = truncatedDate.substr(1, truncatedDate.length);
						}
						obj[columnByNameMap[i]] = truncatedDate;
					} else {
						// calculate average visitors a day
						if (columnByNameMap[i] === "Users") {
							avgVisitorsADay += Number(row[i]);
						}

						obj[columnByNameMap[i]] = row[i];
					}
				}
				return formattedData.push(obj);
			});
		}
	}

	// calculate the average unique visitors a day
	if (
		uniqueVisitorsOnIntranetViewData != null &&
		Object.keys(uniqueVisitorsOnIntranetViewData).length !== 0 &&
		uniqueVisitorsOnIntranetViewData.constructor === Object
	) {
		if (uniqueVisitorsOnIntranetViewData.tables != null) {
			if (uniqueVisitorsOnIntranetViewData.tables[0].rows.length === 0) {
				avgVisitorsADay = 0;
			} else {
				avgVisitorsADay = Math.floor(
					avgVisitorsADay / uniqueVisitorsOnIntranetViewData.tables[0].rows.length
				);
			}
		}
	}

	const changeViewUniqueVisitorsAmountDays = async (days: number) => {
		setUniqueVisitorAmountDays(days);
	};

	return (
		<section>
			<div className="page-title-container">
				<h1 className="page-title">
					<FormattedMessage
						id="usage.navigation.item"
						defaultMessage="Gebruikersstatistieken"
					/>
				</h1>
				<ActionButton
					iconProps={{ iconName: "Download" }}
					onClick={() => props.history.push(`/usage/download`)}
				>
					<FormattedMessage
						id="usage.download.navigation.label"
						defaultMessage="Gebruiksgegevens downloaden"
					/>
				</ActionButton>
			</div>

			<div className="one-grid-container usage-container">
				<div className="one-grid-full">
					<UniqueVisitorsChartComponent
						errorMessage={analyticsSlice.errorMessage}
						aggregatedData={formattedData}
						numberOfDays={uniqueVisitorAmountDays}
						avgVisitorsADay={avgVisitorsADay}
						changeViewUniqueVisitorsAmountDays={changeViewUniqueVisitorsAmountDays}
						activeAudienceFilters={uniqueVisitorsAudienceFilters}
						setActiveAudienceFilters={setUniqueVisitorsAudienceFilters}
					/>
				</div>
				<div className="one-grid-full">
					<UniqueVisitorsPerSiteChartComponent />
				</div>
				<div className="one-grid-full">
					<PageViewsPerSiteComponent />
				</div>
			</div>
		</section>
	);
};

export const UsageComponent = withRouter(Usage);
