import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { ITeamType } from "@one/core";

export enum TeamTypesActionTypes {
	CLEAR_ERROR = "CLEAR_ERROR",
	RESET_SUCCESS = "RESET_SUCCESS",

	FETCH_TEAMTYPES_STARTED = "FETCH_TEAMTYPES_STARTED",
	FETCH_TEAMTYPES_SUCCESS = "FETCH_TEAMTYPES_SUCCESS",
	FETCH_TEAMTYPES_FAILURE = "FETCH_TEAMTYPES_FAILURE",

	CREATE_TEAMTYPE_STARTED = "CREATE_TEAMTYPE_STARTED",
	CREATE_TEAMTYPE_SUCCESS = "CREATE_TEAMTYPE_SUCCESS",
	CREATE_TEAMTYPE_FAILURE = "CREATE_TEAMTYPE_FAILURE",

	UPDATE_TEAMTYPE_STARTED = "UPDATE_TEAMTYPE_STARTED",
	UPDATE_TEAMTYPE_SUCCESS = "UPDATE_TEAMTYPE_SUCCESS",
	UPDATE_TEAMTYPE_FAILURE = "UPDATE_TEAMTYPE_FAILURE",
}

export type TeamTypesState = Readonly<{
	teamTypes: ITeamType[];

	loaders: {
		isCreating: boolean;
		isUpdating: boolean;
		isLoadingInitialData: boolean;
		isDeleting: boolean;
	};

	error: IErrorState;
	success: ISuccessState;
}>;
