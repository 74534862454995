import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./navButton.css";

const navButton = ({
                     iconName,
                     faIcon,
                     photoUrl,
                     setNotificationsIsOpenHandler,
                     type,
                     setAccountIsOpenHandler,
                     link
                   }) => {
  let content;

  if (type === "account") {
    content = (
      <div className="navbar__navbutton" onClick={setAccountIsOpenHandler}>
        <img src={photoUrl} alt="user image" />
      </div>
    );
  } else if (type === "settings") {
    content = link;
  } else if (type === "notifications") {
    content = (
      <div className="navbar__navbutton" onClick={() => setNotificationsIsOpenHandler(true)}>
        <FontAwesomeIcon icon={faIcon} />
      </div>
    );
  }

  return <React.Fragment>{content} </React.Fragment>;
};

export default navButton;
