import React from "react";
import { useSelector } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { FormattedMessage } from "react-intl";

export const HomeComponent = () => {
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);

	return (
		<div className="home-container">
			<h2>
				<FormattedMessage id="home.welcome" defaultMessage="Welkom" /> {currentUserSlice.user.givenName}
			</h2>
			<p>
				<FormattedMessage
					id="home.subtext"
					defaultMessage="Navigeer naar een van de pagina's om te beginnen met beheren."
				/>
			</p>
		</div>
	);
};
