import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./NavWaffle.css";
import { Icon } from "@fluentui/react";

const NavWaffle = props => {
  return (
    <React.Fragment>
      <a href="https://portal.office.com" target="_blank" rel="noopener noreferrer" id="nav-waffle-link">
        <div id="nav-waffle" className="navbar-waffle" role="presentation">
          <Icon iconName="Waffle" />
        </div>
      </a>

      <div className="navbar-hamburger" onClick={() => props.hamburgerMenuToggle(!props.hamburgerMenuIsOpen)}>
        <FontAwesomeIcon icon="bars" />
      </div>
    </React.Fragment>
  );
};

export default NavWaffle;
