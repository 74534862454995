// Framework & third-party
import {
	ActionButton,
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	Label,
	MessageBar,
	MessageBarType,
	Pivot,
	PivotItem,
	PrimaryButton,
	TextField,
} from "@fluentui/react";
import Loader from "react-loader-spinner";
import ReactQuill, { Quill } from "react-quill";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from "react-intl";

// Models & stores
import { ErrorTypes, SuccessTypes } from "../../../models/enums";
import { MailTemplateCodes } from "../../../models/enums/MailTemplateCodes";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import {
	addMailTemplate,
	clearError,
	deleteMailTemplate,
	fetchMailTemplates,
	fetchManageMailTemplate,
	resetSuccess,
	updateMailTemplate,
} from "../../../store/mailTemplates/actions";

// Components
import { ConfirmPanel } from "../../reusable/confirmPanelComponent/ConfirmPanelComponent";

// Replace the default <p></p> wrapper with <div></div> wrapper to fix spacing issues.
var Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

// Add 'https://' before links if it is missing.
var Link = Quill.import("formats/link");
class MyLink extends Link {
	static create(value) {
		let node = super.create(value);
		value = this.sanitize(value);
		// Only add https:// to a link if there is no 'link' present in the link. E.g. [EventLink].
		if (value.toLowerCase().indexOf("link") === -1) {
			if (!value.startsWith("https")) {
				value = "https://" + value;
			}
			node.setAttribute("href", value);
		}
		return node;
	}
}
Quill.register(MyLink);

export const EditMailTemplateComponent = (props: WrappedComponentProps) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const mailTemplatesSlice = useSelector((state: IApplicationState) => state.mailTemplatesSlice);

	const [mailSubject, setMailSubject] = useState("");
	const [mailBody, setMailBody] = useState("");
	const [confirmPanelIsOpen, setConfirmPanelIsOpen] = useState(false);
	const [selectedMailTemplate, setSelectedMailTemplate] = useState(MailTemplateCodes.EventRegistrationSeatAvailable);

	const dropDownOptions: IDropdownOption[] = [
		{ key: "events", text: intl.formatMessage({ id: "mail.template.label.header.events" }), itemType: DropdownMenuItemType.Header },
		{
			key: MailTemplateCodes.EventRegistrationSpareListMail,
			text: intl.formatMessage({
				id: "mail.template.label.eventRegistrationSpareListMail",
				defaultMessage: "Evenement reservelijst registratie",
			}),
		},
		{
			key: MailTemplateCodes.EventRegistrationSeatAvailable,
			text: intl.formatMessage({
				id: "mail.template.label.eventRegistrationSeatAvailable",
				defaultMessage: "Reservelijst - Plaats vrijgekomen",
			}),
		},
		{
			key: MailTemplateCodes.OrganizerEventCalendarItem,
			text: intl.formatMessage({
				id: "mail.template.label.organizerEventCalendarItem",
				defaultMessage: "Agenda uitnodiging evenement - Organisator",
			}),
		},
		{
			key: MailTemplateCodes.OrganizerEventTopicCalendarItem,
			text: intl.formatMessage({
				id: "mail.template.label.organizerEventTopicCalendarItem",
				defaultMessage: "Agenda uitnodiging sessie - Organisator",
			}),
		},
		{ key: "other", text: intl.formatMessage({ id: "mail.template.label.header.other" }), itemType: DropdownMenuItemType.Header },
		{
			key: MailTemplateCodes.FeedbackMail,
			text: intl.formatMessage({
				id: "mail.template.label.feedbackMail",
			}),
		},
	];

	// Clear the success message after 5 seconds.
	React.useEffect(() => {
		if (mailTemplatesSlice.success.type !== SuccessTypes.None) {
			setTimeout(() => {
				dispatch(resetSuccess());
			}, 5000);
		}
	}, [mailTemplatesSlice.success.type]);

	useEffect(() => {
		if (mailTemplatesSlice.success.type === SuccessTypes.OnDelete && confirmPanelIsOpen) {
			setConfirmPanelIsOpen(false);
		}
	}, [mailTemplatesSlice.success, confirmPanelIsOpen]);

	useEffect(() => {
		document.addEventListener("dragstart", function (event) {
			//@ts-ignore
			event.dataTransfer.setData("Text", event.target.id);
		});

		dispatch(fetchMailTemplates());
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchManageMailTemplate(selectedMailTemplate));
	}, [dispatch, selectedMailTemplate]);

	useEffect(() => {
		const manageMail = mailTemplatesSlice.currentManageMailTemplate;
		if (manageMail !== null) {
			if (manageMail.customMailTemplate === null) {
				setMailBody(manageMail.defaultMailTemplate.body.replace("<br>", "<p><br></p>"));
				setMailSubject(manageMail.defaultMailTemplate.subject);
			} else {
				setMailBody(manageMail.customMailTemplate.body.replace("<br>", "<p><br></p>"));
				setMailSubject(manageMail.customMailTemplate.subject);
			}
		}
	}, [mailTemplatesSlice.currentManageMailTemplate]);

	const onSubmit = () => {
		if (mailTemplatesSlice.currentManageMailTemplate && mailTemplatesSlice.currentManageMailTemplate?.customMailTemplate) {
			dispatch(
				updateMailTemplate({
					id: mailTemplatesSlice.currentManageMailTemplate.customMailTemplate.id,
					subject: mailSubject,
					body: mailBody,
				})
			);
		} else {
			dispatch(
				addMailTemplate({
					subject: mailSubject,
					body: mailBody,
					templateCode: selectedMailTemplate,
				})
			);
		}
	};

	const renderMessageBar = () => {
		const errorType = mailTemplatesSlice.error.type;
		const successType = mailTemplatesSlice.success.type;

		if (errorType !== ErrorTypes.None) {
			return (
				<MessageBar
					messageBarType={MessageBarType.error}
					onDismiss={() => dispatch(clearError())}
					styles={{
						root: { marginBottom: "1rem" },
					}}
				>
					{(errorType === ErrorTypes.OnCreate || errorType === ErrorTypes.OnDelete) && (
						<FormattedMessage
							id="mail.template.error.onCreateOrUpdate"
							defaultMessage="Er ging iets mis bij het bewerken van het e-mail template."
						/>
					)}
					{errorType === ErrorTypes.OnDetailDataLoad && (
						<FormattedMessage
							id="mail.template.error.onDetailDataLoad"
							defaultMessage="Er ging iets mis bij het ophalen van het template."
						/>
					)}
				</MessageBar>
			);
		}

		if (successType !== SuccessTypes.None) {
			return (
				<MessageBar
					messageBarType={MessageBarType.success}
					onDismiss={() => dispatch(resetSuccess())}
					styles={{
						root: { marginBottom: "1rem" },
					}}
				>
					{(successType === SuccessTypes.OnCreate || successType === SuccessTypes.OnUpdate) && (
						<FormattedMessage
							id="mail.template.success.onCreateOrUpdate"
							defaultMessage="Het e-mail template is succesvol bijgewerkt."
						/>
					)}
					{successType === SuccessTypes.OnDelete && (
						<FormattedMessage id="mail.template.success.onDelete" defaultMessage="Succesvol het originele template hersteld." />
					)}
				</MessageBar>
			);
		}

		return null;
	};

	const renderLoader = () => {
		return (
			<div className="loaderWrapper">
				<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
			</div>
		);
	};

	const shouldHideContent =
		mailTemplatesSlice.error.type === ErrorTypes.OnDetailDataLoad ||
		mailTemplatesSlice.loaders.isLoadingInitialData ||
		mailTemplatesSlice.loaders.isLoadingDetailData;

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="mailTemplates.navigation.item" />
			</h1>

			<div className="settings-setting-wrapper-full-width" test-name="settings-tile">
				{renderMessageBar()}
				<h3>
					<FormattedMessage id="mail.template.header" defaultMessage="Mail template" />
				</h3>
				<div className="settings-tile-body">
					<div className="mail-template-manage-header">
						<Dropdown
							options={dropDownOptions}
							selectedKey={selectedMailTemplate}
							onChange={(e, option) =>
								option !== undefined ? setSelectedMailTemplate(option.key as MailTemplateCodes) : null
							}
							disabled={
								mailTemplatesSlice.loaders.isCreating ||
								mailTemplatesSlice.loaders.isUpdating ||
								mailTemplatesSlice.loaders.isLoadingDetailData
							}
						/>
						{mailTemplatesSlice.currentManageMailTemplate &&
							mailTemplatesSlice.currentManageMailTemplate?.customMailTemplate &&
							!shouldHideContent && (
								<ActionButton
									iconProps={{ iconName: "RemoveFromTrash" }}
									text={intl.formatMessage({
										id: "mail.template.revertToDefault",
										defaultMessage: "Herstel origineel",
									})}
									disabled={
										mailTemplatesSlice.loaders.isCreating ||
										mailTemplatesSlice.loaders.isUpdating ||
										mailTemplatesSlice.loaders.isDeleting
									}
									onClick={() => {
										setConfirmPanelIsOpen(true);
									}}
								/>
							)}
						<ConfirmPanel
							headerText={intl.formatMessage({
								id: "mail.template.revertToDefault",
								defaultMessage: "Herstel origineel",
							})}
							contentTitle={intl.formatMessage({
								id: "mail.template.revert.confirm.title",
								defaultMessage: "Weet u zeker dat u het template wil herstellen?",
							})}
							contentDescription={intl.formatMessage({
								id: "mail.template.revert.confirm.description",
								defaultMessage:
									"Met deze actie maakt u alle wijzigingen aan het geselecteerde template ongedaan en wordt het standaard template weer gebruikt.",
							})}
							isOpen={confirmPanelIsOpen}
							errorMessage={
								mailTemplatesSlice.error.type === ErrorTypes.OnDelete
									? intl.formatMessage({
										id: "mail.template.revertToDefault.error",
										defaultMessage: "Er ging iets mis bij het herstellen van het originiele template.",
									})
									: undefined
							}
							onClose={() => setConfirmPanelIsOpen(false)}
							onCancel={() => setConfirmPanelIsOpen(false)}
							onConfirm={() => {
								if (
									mailTemplatesSlice.currentManageMailTemplate &&
									mailTemplatesSlice.currentManageMailTemplate.customMailTemplate
								) {
									dispatch(deleteMailTemplate(mailTemplatesSlice.currentManageMailTemplate.customMailTemplate));
								}
							}}
						/>
					</div>
					<div className="mail-template-wrapper">
						{shouldHideContent ? (
							renderLoader()
						) : (
							<>
								<div className="mail-template-quill-container">
									<Label>
										<FormattedMessage id="mail.template.subject.label" defaultMessage="Onderwerp" />
									</Label>
									<TextField
										value={mailSubject}
										onChange={(e, value) => setMailSubject(value || "")}
										autoComplete="off"
										underlined
										disabled={mailTemplatesSlice.loaders.isCreating || mailTemplatesSlice.loaders.isUpdating}
									/>

									<Label style={{ marginTop: "1rem" }}>
										<FormattedMessage id="mail.template.body.label" defaultMessage="Inhoud" />
									</Label>
									<ReactQuill
										value={mailBody}
										onChange={(newValue) => setMailBody(newValue)}
										readOnly={mailTemplatesSlice.loaders.isCreating || mailTemplatesSlice.loaders.isUpdating}
									/>
								</div>

								<div className="mail-template-legend-container">
									<Pivot
										styles={{
											root: {
												display: "flex",
												flexWrap: "wrap",
											},
										}}
									>
										{mailTemplatesSlice.currentManageMailTemplate?.defaultMailTemplate.templateVariables.map(
											(variables) => (
												<PivotItem
													key={variables.header}
													headerText={intl.formatMessage({
														id: `mail.template.variable.header.${variables.header.toLowerCase()}`,
														defaultMessage: variables.header,
													})}
													title={variables.header}
												>
													<div className="variable-container">
														{variables.fields.map((t) => (
															<div className="variable" key={t}>
																<p
																	draggable={
																		mailTemplatesSlice.loaders.isCreating ||
																			mailTemplatesSlice.loaders.isUpdating
																			? "false"
																			: "true"
																	}
																	title={intl.formatMessage({
																		id: "mail.template.variable.tooltip",
																		defaultMessage: "Sleep mij op de juiste plaats!",
																	})}
																	id={t}
																>
																	<FormattedMessage
																		id={`mail.template.variable.${t}`}
																		defaultMessage={t}
																	/>
																</p>
															</div>
														))}
													</div>
												</PivotItem>
											)
										)}
									</Pivot>
								</div>
							</>
						)}
					</div>
				</div>
				{!shouldHideContent && (
					<div className="mail-template-save-button">
						<PrimaryButton
							onClick={() => onSubmit()}
							disabled={
								mailTemplatesSlice.loaders.isCreating ||
								mailTemplatesSlice.loaders.isUpdating ||
								mailTemplatesSlice.loaders.isLoadingDetailData
							}
						>
							<FormattedMessage id="general.save" defaultMessage="Opslaan" />
						</PrimaryButton>
					</div>
				)}
			</div>
		</section>
	);
};
