import { Icon } from "@fluentui/react";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export const NoneSelectedComponent = () => {
	return (
		<section
			className="taxonomy-card-wrapper taxonomy-selected-wrapper"
			style={{
				display: "flex",
				justifyContent: "center",
			}}
		>
			<div className="taxonomy-selected-none">
				<Icon iconName="FabricFormLibrary" />
				<span>
					<FormattedMessage
						id="taxonomy.none.selected"
						defaultMessage="Selecteer een taxonomie-item om deze te bewerken, of om een onderliggend item aan te maken."
					/>
				</span>
			</div>
		</section>
	);
};
