import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { FormattedMessage, useIntl } from "react-intl";
import {
	Checkbox,
	ConstrainMode,
	DetailsList,
	DetailsListLayoutMode,
	DetailsRow,
	Dropdown,
	IColumn,
	IDetailsHeaderProps,
	IDetailsListProps,
	IDetailsRowStyles,
	IDropdownOption,
	IRenderFunction,
	ScrollablePane,
	ScrollbarVisibility,
	SelectionMode,
	Sticky,
	StickyPositionType,
	TextField,
	TooltipHost,
} from "@fluentui/react";
import { fetchRolePermissionsOverview } from "../../../store/roles/actions";
import { IPermission, IRole } from "@one/core";

export const RolePermissionsOverviewComponent = () => {
	const dispatch = useDispatch();
	const rolesSlice = useSelector((state: IApplicationState) => state.rolesSlice);
	const intl = useIntl();
	const [permissionFilter, setPermissionFilter] = useState<string>("all");

	useEffect(() => {
		dispatch(fetchRolePermissionsOverview());
	}, []);

	const columns: IColumn[] = [
		{
			key: "permission",
			name: "Permission",
			minWidth: 150,
			maxWidth: 200,
			isResizable: true,
		},
		...rolesSlice.rolePermissionsOverview.data.roles.map((role) => {
			return {
				key: role.id.toString(),
				name: `${role.id} ${role.name}`,
				minWidth: 100,
				maxWidth: 150,
				isResizable: true,
			} as IColumn;
		}),
	];

	// Add custom rendering for specific columns
	const _renderItemColumn = (permission: IPermission, index: number | undefined, column: IColumn | undefined) => {
		if (column === undefined) {
			return null;
		}

		switch (column.key) {
			case "permission":
				return (
					<div className="column-field">
						<span>{`${permission.id} : ${permission.name}`}</span>
					</div>
				);
			default:
				const roleId = column.key;
				const isAssigned =
					rolesSlice.rolePermissionsOverview.data.assignments.filter(
						(assignment) => assignment.roleId.toString() == roleId && assignment.permissionId == permission.id
					).length > 0;

				return (
					<div className="column-field">
						<span>
							<Checkbox
								checked={isAssigned}
								title={`Role: ${column.name} \nPermission: ${permission.id} ${permission.name}`}
							/>
						</span>
					</div>
				);
		}
	};

	const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
		return (
			<Sticky stickyPosition={StickyPositionType.Both}>
				<div>{defaultRender!(headerProps)}</div>
			</Sticky>
		);
	};

	// returns the row with additional styling
	const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
		if (props) {
			const customStyles: Partial<IDetailsRowStyles> = {};

			if (props.itemIndex % 2 === 1) {
				customStyles.root = {
					backgroundColor: "#f9f9f9",
				};
			} else {
				customStyles.root = {
					backgroundColor: "#fff",
				};
			}
			return (
				<div title={`${props.item.id} ${props.item.name}`}>
					<DetailsRow {...props} styles={customStyles} />
				</div>
			);
		}
		return null;
	};

	const generatePermissionDropdownItems = () => {
		const result: IDropdownOption<any>[] = [
			{
				key: "all",
				text: "All",
			},
		];

		rolesSlice.rolePermissionsOverview.data.permissions.forEach((item) => {
			const firstChar = item.id.toString().charAt(0);
			if (firstChar) {
				// If the result does not contain an option for this type yet.
				if (result.filter((i) => i.key === firstChar).length === 0) {
					var text = firstChar + "000";
					switch (firstChar) {
						case "2":
							text = "Team Management";
							break;
						case "3":
							text = "Event Management";
							break;
						case "4":
							text = "Portal Management";
							break;
						case "5":
							text = "Administrative";
							break;
						case "6":
							text = "Media Management";
							break;
						case "7":
							text = "Quality Management";
							break;
						case "9":
							text = "Reporting Portal Management";
							break;
					}

					result.push({
						key: firstChar,
						text,
					} as IDropdownOption<any>);
				}
			}
		});

		return result;
	};

	return (
		<React.Fragment>
			<h1 className="page-title">
				<FormattedMessage id="rolePermissions.navigationItem.title" />
			</h1>
			<div className="main-container">
				<div className="role-permissions-overview-toprow">
					<Dropdown
						label="Permissies"
						selectedKey={permissionFilter}
						options={generatePermissionDropdownItems()}
						onChange={(e, context) => {
							setPermissionFilter(context?.key.toString() || "all");
						}}
					/>
					<div className="role-permissions-overview-toprow-info">
						<TextField
							readOnly
							label="Totaal aantal permissies"
							value={rolesSlice.rolePermissionsOverview.data.permissions.length.toString()}
							borderless
						/>
						<TextField
							readOnly
							label="Totaal aantal rollen"
							value={rolesSlice.rolePermissionsOverview.data.roles.length.toString()}
							borderless
						/>
					</div>
				</div>
				<div className="role-permissions-overview-container">
					<ScrollablePane>
						<DetailsList
							columns={columns}
							items={
								permissionFilter === "all"
									? rolesSlice.rolePermissionsOverview.data.permissions
									: rolesSlice.rolePermissionsOverview.data.permissions.filter(
											(i) => i.id.toString().charAt(0) === permissionFilter
									  )
							}
							layoutMode={DetailsListLayoutMode.fixedColumns}
							selectionMode={SelectionMode.none}
							constrainMode={ConstrainMode.unconstrained}
							onRenderDetailsHeader={onRenderDetailsHeader}
							onRenderItemColumn={_renderItemColumn}
							onRenderRow={_onRenderRow}
						/>
					</ScrollablePane>
				</div>
			</div>
		</React.Fragment>
	);
};
