import { UserViewModel } from "../../models/viewmodels/UserViewModel";

export enum CurrentUserActionTypes {
	FETCH_CURRENT_USER_STARTED = "FETCH_CURRENT_USER_STARTED",
	FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS",
	FETCH_CURRENT_USER_FAILURE = "FETCH_CURRENT_USER_FAILURE"
}

export type CurrentUserState = Readonly<{
	user: UserViewModel;
	errorMessage: string;
	isLoading: boolean;
}>;
