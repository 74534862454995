import { TaxonomyItem } from "../../models/enums/TaxonomyITems";
import { IErrorState } from "../../models/interfaces/IErrorState";
import { ISuccessState } from "../../models/interfaces/ISuccessState";
import { ITaxonomy } from "../../models/responseModels/taxonomy/ITaxonomy";

export enum TaxonomyActionTypes {
	FETCH_TAXONOMY_STARTED = "FETCH_TAXONOMY_STARTED",
	FETCH_TAXONOMY_SUCCESS = "FETCH_TAXONOMY_SUCCESS",
	FETCH_TAXONOMY_FAILURE = "FETCH_TAXONOMY_FAILURE",

	ADD_TERM_STARTED = "ADD_TERM_STARTED",
	ADD_TERM_SUCCESS = "ADD_TERM_SUCCESS",
	ADD_TERM_FAILURE = "ADD_TERM_FAILURE",
	UPDATE_TERM_STARTED = "UPDATE_TERM_STARTED",
	UPDATE_TERM_SUCCESS = "UPDATE_TERM_SUCCESS",
	UPDATE_TERM_FAILURE = "UPDATE_TERM_FAILURE",

	ADD_TERMSET_STARTED = "ADD_TERMSET_STARTED",
	ADD_TERMSET_SUCCESS = "ADD_TERMSET_SUCCESS",
	ADD_TERMSET_FAILURE = "ADD_TERMSET_FAILURE",
	UPDATE_TERMSET_STARTED = "UPDATE_TERMSET_STARTED",
	UPDATE_TERMSET_SUCCESS = "UPDATE_TERMSET_SUCCESS",
	UPDATE_TERMSET_FAILURE = "UPDATE_TERMSET_FAILURE",

	UPDATE_TERMGROUP_STARTED = "UPDATE_TERMGROUP_STARTED",
	UPDATE_TERMGROUP_SUCCESS = "UPDATE_TERMGROUP_SUCCESS",
	UPDATE_TERMGROUP_FAILURE = "UPDATE_TERMGROUP_FAILURE",

	RESET_SUCCESS = "RESET_SUCCESS",
	RESET_ERROR = "RESET_ERROR",
}

export type TaxonomyState = Readonly<{
	taxonomy: ITaxonomy;
	isFetchingTaxonomy: boolean;

	isUpdatingOrCreating: boolean;

	success: ISuccessState & { forType: TaxonomyItem | null };
	error: IErrorState & { forType: TaxonomyItem | null };
}>;
