import React, { useEffect } from "react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import Loader from "react-loader-spinner";

import { SettingsCardComponent } from "./settingsCardComponent/SettingsCardComponent";
import { useSelector, useDispatch } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchApplications } from "../../../store/tenant/actions";
import { hasAccess, PermissionCodes } from "@one/core";

const Settings = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const tenantSlice = useSelector((state: IApplicationState) => state.tenantSlice);
	const currentUser = useSelector((state: IApplicationState) => state.currentUserSlice.user);

	useEffect(() => {
		if (tenantSlice.applications.length === 0) {
			dispatch(fetchApplications());
		}
	}, [tenantSlice.applications.length, dispatch]);

	const renderSettingsCardsUi = () => {
		if (tenantSlice.isFetchingApplications) {
			return (
				<div className="loaderWrapper">
					<Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
				</div>
			);
		}
		let settingsCardsUi: JSX.Element[] = [];

		if (hasAccess(currentUser as any, [PermissionCodes.ManageTenantSettings])) {
			settingsCardsUi.push(
				<SettingsCardComponent
					key="tenantSettings"
					title={intl.formatMessage({
						id: "settings.card.tenant.title",
						defaultMessage: "Tenant-instellingen",
					})}
					description={intl.formatMessage({
						id: "settings.card.tenant.description",
						defaultMessage: "Wijzig de tenant-instellingen",
					})}
					routeTo="settings/tenantSettings"
				/>
			);
		}

		for (let i = 0; i < tenantSlice.applications.length; i++) {
			const app = tenantSlice.applications[i];
			switch (app.name) {
				case "One":
					// settingsCardsUi.push();
					break;
				case "One for Teams":
					if (hasAccess(currentUser as any, [PermissionCodes.ManageTeamsSettings])) {
						settingsCardsUi.push(
							<SettingsCardComponent
								key={app.name}
								title={intl.formatMessage({
									id: "settings.card.teams.title",
									defaultMessage: "One for Teams instellingen",
								})}
								description={intl.formatMessage({
									id: "settings.card.teams.description",
									defaultMessage: "Wijzig de One for Teams instellingen",
								})}
								routeTo="settings/teamsSettings"
								icon={app.icon}
							/>
						);
					}
					break;
				case "One for Events":
					if (hasAccess(currentUser as any, [PermissionCodes.ManageEventsSettings])) {
						settingsCardsUi.push(
							<SettingsCardComponent
								key={app.name}
								title={intl.formatMessage({
									id: "settings.card.events.title",
									defaultMessage: "One for Events instellingen",
								})}
								description={intl.formatMessage({
									id: "settings.card.events.description",
									defaultMessage: "Wijzig de One for Events instellingen",
								})}
								routeTo="settings/eventsSettings"
								icon={app.icon}
							/>
						);
					}
					break;
				case "One for You":
					// The One for You settings should be visible to the SocialManagerRole because the connections can me managed from here.
					if (hasAccess(currentUser as any, [PermissionCodes.ManageOneForYouSettings])) {
						settingsCardsUi.push(
							<SettingsCardComponent
								key={app.name}
								title={intl.formatMessage({
									id: "settings.card.you.title",
									defaultMessage: "One for You instellingen",
								})}
								description={intl.formatMessage({
									id: "settings.card.you.description",
									defaultMessage: "Wijzig de One for You instellingen",
								})}
								routeTo="settings/youSettings"
								icon={app.icon}
							/>
						);
					}
					break;
				case "One Copilot":
					settingsCardsUi.push(
						<SettingsCardComponent
							key={app.name}
							title="One Copilot instellingen"
							description="Wijzig de One Copilot instellingen"
							routeTo="settings/copilotSettings"
							icon={app.icon}
						/>
					);
					break;
				case "Dynamics CRM":
					// settingsCardsUi.push();
					break;
				case "One Admin Portal":
					// settingsCardsUi.push();
					break;
				default:
					break;
			}
		}

		return settingsCardsUi;
	};

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="settings.general" defaultMessage="Instellingen" />
			</h1>
			<div className="settings-container">{renderSettingsCardsUi()}</div>
		</section>
	);
};

export const SettingsComponent = injectIntl(Settings);
