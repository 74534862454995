import { TenantActionTypes, TenantState } from "./types";
import { ErrorTypes } from "../../models/enums/ErrorTypes";
import { SuccessTypes } from "../../models/enums/SuccessTypes";
import { ApplicationsResponse } from "../../models/responseModels/tenant/ApplicationsResponse";

const initialState: TenantState = {
	isFetchingApplications: false,
	applications: [] as ApplicationsResponse[],

	isFetchingTenants: false,
	tenants: [],

	success: {
		type: SuccessTypes.None,
	},
	error: {
		type: ErrorTypes.None,
		message: "",
	},
};

export function tenantReducer(
	state = initialState,
	action: { type: TenantActionTypes; payload: any }
): TenantState {
	switch (action.type) {
		case TenantActionTypes.FETCH_APPLICATIONS_STARTED:
			return {
				...state,
				isFetchingApplications: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TenantActionTypes.FETCH_APPLICATIONS_SUCCESS:
			return {
				...state,
				applications: action.payload.applications,
				isFetchingApplications: false,
			};
		case TenantActionTypes.FETCH_APPLICATIONS_FAILURE:
			return {
				...state,
				isFetchingApplications: false,
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		// --=== Tenants ===--
		case TenantActionTypes.FETCH_TENANTS_STARTED:
			return {
				...state,
				isFetchingTenants: true,
				error: {
					type: ErrorTypes.None,
					message: "",
				},
			};
		case TenantActionTypes.FETCH_TENANTS_SUCCESS:
			return {
				...state,
				tenants: action.payload.tenants,
				isFetchingTenants: false,
			};
		case TenantActionTypes.FETCH_TENANTS_FAILURE:
			return {
				...state,
				isFetchingTenants: false,
				error: {
					type: ErrorTypes.OnInitialDataLoad,
					message: action.payload.errorMessage,
				},
			};

		default:
			return state;
	}
}
