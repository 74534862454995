import { Icon } from "@fluentui/react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

export interface SettingsCardProps {
	title: string;
	description: string;
	routeTo: string;
	icon?: string;
}

const SettingsCard = (props: RouteComponentProps & SettingsCardProps) => {
	const openSettingsPage = (settingsPageToOpen) => {
		props.history.push(`/${settingsPageToOpen}`);
	};
	return (
		<div
			className="settings-card"
			onClick={() => openSettingsPage(props.routeTo)}
			test-name="settings-card"
		>
			<div className="settings-card-header">{props.title}</div>
			<div className="settings-card-body">{props.description}</div>
			{props.icon && (
				<div className="settings-card-icon-container">
					<Icon iconName={props.icon} />
				</div>
			)}
		</div>
	);
};

export const SettingsCardComponent = withRouter(SettingsCard);
