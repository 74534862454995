import React, { useState, useEffect } from "react";
import { SearchBox, Icon, PrimaryButton, Dropdown, IDropdownOption } from "@fluentui/react";
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import { delay, calculateYAxisScale } from "../../../../../common/helperFunctions/GeneralHelpers";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../../../models/interfaces/IApplicationState";
import {
	fetchSearchedSitesCollection,
	emptySearchedSitesCollection,
	fetchSpSiteAnalyticData,
	fetchSpSiteAnalyticData90Days,
} from "../../../../../store/analytics/actions";
import { Site } from "@microsoft/microsoft-graph-types";
import { SiteCharViewModes } from "../../../../../models/enums";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";
import OneTooltip from "./CustomTooltip";
import Loader from "react-loader-spinner";

const UniqueVisitorsPerSiteChart = (props: WrappedComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	let content;
	let columnByNameMap = {};
	let formattedData: any = [];

	const audiencesSlice = useSelector((state: IApplicationState) => state.audiencesSlice);
	const analyticsSlice = useSelector((state: IApplicationState) => state.analyticsSlice);

	const [audienceIdsCSV, setAudienceIdsCSV] = useState<string>("");
	const [activeAudienceFilters, setActiveAudienceFilters] = useState<IDropdownOption[]>([]);
	const [searchValue, setSearchValue] = useState<string>("");
	const [selectedSite, setSelectedSite] = useState<Site>({} as Site);
	const [daysTimespan, setDaysTimespan] = useState<number>(30);
	const [viewMode, setViewMode] = useState(SiteCharViewModes.SitesListCollection);
	const [linkToActivateSharepointAi, setlinkToActivateSharepointAi] = useState("");

	const checkActiveState = (days: number) => {
		return daysTimespan === days ? "active-item" : "";
	};

	const sevenDaysClasses = checkActiveState(7);
	const thirtyDaysClasses = checkActiveState(30);
	const ninetyDaysClasses = checkActiveState(90);

	const SearchSites = (input) => {
		setSearchValue(input);
	};

	const onSearchBoxChange = (query: string | undefined) => {
		if (query !== undefined) {
			delay(() => SearchSites(query), query !== "" ? 500 : 0);
		}
	};

	useEffect(() => {
		if (searchValue !== "" && searchValue !== undefined) {
			dispatch(fetchSearchedSitesCollection(searchValue));
		} else {
			dispatch(emptySearchedSitesCollection());
		}
	}, [dispatch, searchValue]);

	useEffect(() => {
		if (viewMode === SiteCharViewModes.SiteChart) {
			dispatch(fetchSpSiteAnalyticData(selectedSite.id, daysTimespan));
			dispatch(fetchSpSiteAnalyticData90Days(selectedSite.id, 90));
		}
	}, [dispatch, viewMode]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (viewMode === SiteCharViewModes.SiteChart) {
			dispatch(fetchSpSiteAnalyticData(selectedSite.id, daysTimespan, audienceIdsCSV));
		}
	}, [daysTimespan, dispatch, viewMode, audienceIdsCSV]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let newAudienceIdsCSV = "";
		for (let i = 0; i < activeAudienceFilters.length; i++) {
			const option = activeAudienceFilters[i];
			i > 0 ? (newAudienceIdsCSV += `,${option.key}`) : (newAudienceIdsCSV += option.key);
		}

		delay(
			() => {
				setAudienceIdsCSV(newAudienceIdsCSV);
			},
			newAudienceIdsCSV.length > 0 ? 1000 : 0
		);
	}, [activeAudienceFilters]);

	const determineDropDownOptions = (): IDropdownOption[] => {
		const options = audiencesSlice.audiences.map((audience) => {
			return {
				key: audience.id,
				text: audience.name !== null ? audience.name : audience.id.toString(),
			};
		});

		return options;
	};

	const onDropdownChange = (changedOption: IDropdownOption | undefined) => {
		if (changedOption === undefined) {
			return;
		}

		let newOptions = [...activeAudienceFilters];
		if (changedOption.selected) {
			newOptions.push(changedOption);
		} else {
			newOptions = newOptions.filter((option) => option.key !== changedOption.key);
		}

		setActiveAudienceFilters(newOptions);
	};

	// when the viewmode is on sitesList collection we give the user the opportunity
	// to search a site and render it's results
	if (viewMode === SiteCharViewModes.SitesListCollection) {
		// populates the list of sites (if any) to display after a search.
		const populateSitesCollection = () => {
			let populatedValues;

			if (
				analyticsSlice.siteGraphSearchedSitesCollection.length > 0 &&
				!analyticsSlice.isLoadingSpSiteData
			) {
				populatedValues = analyticsSlice.siteGraphSearchedSitesCollection.map((site) => {
					return (
						<div
							className="media-item-wrapper"
							onClick={() => setActiveSite(site)}
							key={site.id}
						>
							<div className="media-item-image">
								<Icon iconName="SharepointAppIcon16" className="logo-styling" />
							</div>
							<div className="media-item-details">
								<div>{site.displayName}</div>
								<div>
									<i>
										{site.webUrl?.split(".com")[1] === ""
											? "/"
											: site.webUrl?.split(".com")[1]}
									</i>
								</div>
							</div>
						</div>
					);
				});
				return populatedValues;
			} else if (
				analyticsSlice.siteGraphSearchedSitesSearchTerm !== "" &&
				!analyticsSlice.isLoadingSpSiteData
			) {
				return (
					<div>
						<p className="no-results-wrapper">
							<FormattedMessage
								id="usage.uniqueVisitorsChartPerSite.noResults"
								defaultMessage="Er zijn geen resultaten."
							/>
						</p>
					</div>
				);
			} else if (analyticsSlice.isLoadingSpSiteData) {
				return (
					<div className="loading-wrapper">
						<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
					</div>
				);
			}
		};

		content = (
			<React.Fragment>
				<h1>
					<FormattedMessage
						id="usage.uniqueVisitorsChartPerSite.uniqueUsersPerSite"
						defaultMessage="Unieke gebruikers per site"
					/>
				</h1>
				<SearchBox
					placeholder={intl.formatMessage({
						id: "usage.search.placeholder",
						defaultMessage: "Zoek naar sites op basis van naam.",
					})}
					value={searchValue}
					className="searchbar"
					// onSearch={(query) => searchUsers(query)}
					onChange={(e, query) => onSearchBoxChange(query)}
				/>
				{populateSitesCollection()}
			</React.Fragment>
		);
	}

	if (viewMode === SiteCharViewModes.SiteChart) {
		// check if there is any data within the 90 days, if not contruct a link to the sharepoint app catalog to add the package.
		// Formats the data for the table.
		if (
			analyticsSlice.siteData90Days != null &&
			Object.keys(analyticsSlice.siteData90Days).length !== 0 &&
			analyticsSlice.siteData90Days.constructor === Object
		) {
			if (analyticsSlice.siteData90Days.tables != null) {
				// make sure columnByNameMap functions as an enum with the available columns in an object.
				if (
					analyticsSlice.siteData90Days.tables[0].rows.length === 0 &&
					linkToActivateSharepointAi !==
						`${selectedSite.webUrl}/_layouts/15/addanapp.aspx`
				) {
					setlinkToActivateSharepointAi(
						`${selectedSite.webUrl}/_layouts/15/addanapp.aspx`
					);
				}
			}
		}

		// Formats the data for the table.
		if (
			analyticsSlice.siteAnalyticalData != null &&
			Object.keys(analyticsSlice.siteAnalyticalData).length !== 0 &&
			analyticsSlice.siteAnalyticalData.constructor === Object
		) {
			if (analyticsSlice.siteAnalyticalData.tables != null) {
				// make sure columnByNameMap functions as an enum with the available columns in an object.
				analyticsSlice.siteAnalyticalData.tables[0].columns.map((column, i) => {
					return (columnByNameMap[i] = column.name);
				});

				// Loop through the row push an object with columnName: data in aggregatedData: {Host: *, USers: *, timestamp: *}
				analyticsSlice.siteAnalyticalData.tables[0].rows.map((row, i) => {
					let obj = {};
					for (let i = 0; i < row.length; i++) {
						// truncate the date into the right format:
						if (columnByNameMap[i] === "timestamp") {
							let truncatedDate = row[i].toString().split("T")[0].substr(5, 10);

							// removed leading zeros in the months
							if (truncatedDate[0] === "0") {
								truncatedDate = truncatedDate.substr(1, truncatedDate.length);
							}
							obj[columnByNameMap[i]] = truncatedDate;
						} else {
							obj[columnByNameMap[i]] = row[i];
						}
					}
					return formattedData.push(obj);
				});
			}
		}

		let header = (
			<React.Fragment>
				<h1 style={{ marginLeft: "0" }}>
					<FormattedMessage
						id="usage.uniqueVisitorsChartPerSite.uniqueUsersFor"
						defaultMessage="Unieke gebruikers voor "
					/>

					{selectedSite.displayName}
				</h1>
				<div className="graphButtons-wrapper-small">
					<div
						className="fake-link"
						onClick={() => setViewMode(SiteCharViewModes.SitesListCollection)}
					>
						<FormattedMessage
							id="usage.uniqueVisitorsChartPerSite.backToSiteCollection"
							defaultMessage="Terug naar site selecteren"
						/>
					</div>
					<div className="chart-filter-options">
						<div className="chart-audience-filter">
							<Dropdown
								placeholder={intl.formatMessage({
									id: "usage.uniqueVisitorsChart.audienceFiltersPlaceholder",
									defaultMessage: "Filter op doelgroepen",
								})}
								options={determineDropDownOptions()}
								multiSelect
								onChange={(e, option) => onDropdownChange(option)}
								onRenderTitle={() => (
									<div>
										<FormattedMessage
											id="usage.uniqueVisitorsChart.audienceFiltersText"
											defaultMessage="Doelgroepen"
										/>{" "}
										({activeAudienceFilters.length})
									</div>
								)}
							/>
						</div>
						<PrimaryButton
							text={intl.formatMessage({
								id: "usage.uniqueVisitorsChart.pastSevenDaysButtonText",
								defaultMessage: "Afgelopen 7 dagen",
							})}
							className={sevenDaysClasses}
							onClick={() => setDaysTimespan(7)}
						/>

						<PrimaryButton
							text={intl.formatMessage({
								id: "usage.uniqueVisitorsChart.pastThirtyDaysButtonText",
								defaultMessage: "Afgelopen 30 dagen",
							})}
							className={thirtyDaysClasses}
							onClick={() => setDaysTimespan(30)}
						/>

						<PrimaryButton
							text={intl.formatMessage({
								id: "usage.uniqueVisitorsChart.pastNinetyDaysButtonText",
								defaultMessage: "Afgelopen 90 dagen",
							})}
							className={ninetyDaysClasses}
							onClick={() => setDaysTimespan(90)}
						/>
					</div>
				</div>
			</React.Fragment>
		);

		let graph;
		if (analyticsSlice.errorMessageSpSiteData !== "") {
			graph = (
				<React.Fragment>
					{header}
					<p className="error-message">
						<FormattedMessage
							id="general.something-went-wrong"
							defaultMessage="Er is iets fout gegaan."
						/>
					</p>
				</React.Fragment>
			);
		} else if (formattedData.length === 0 && !analyticsSlice.isLoadingSpSiteData) {
			const linkToSp =
				linkToActivateSharepointAi !== "" ? (
					<a
						className="fake-link"
						href={linkToActivateSharepointAi}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FormattedMessage
							id="usage.usage.uniqueVisitorsChartPerSite.noDataAiLinkText"
							defaultMessage="Installeer de One for You Analytics App."
						/>
					</a>
				) : null;
			graph = (
				<React.Fragment>
					{header}
					<p style={{ marginTop: "3rem" }}>
						{" "}
						<FormattedMessage
							id="usage.uniqueVisitorsChartPerSite.noData"
							defaultMessage="Er is niet genoeg data beschikbaar."
						/>
						{linkToSp}
					</p>
				</React.Fragment>
			);
		} else if (analyticsSlice.isLoadingSpSiteData) {
			graph = (
				<React.Fragment>
					{header}
					<div className="loading-wrapper">
						<Loader type="ThreeDots" color="#0078d4" height={50} width={50} />
					</div>
				</React.Fragment>
			);
		} else if (formattedData.length > 0 && !analyticsSlice.isLoadingSpSiteData) {
			const yAxisScale = calculateYAxisScale(formattedData, "Users");
			graph = (
				<React.Fragment>
					{header}

					<ResponsiveContainer width="100%" aspect={4.0 / 0.8}>
						<BarChart
							data={formattedData}
							margin={{ top: 30, right: 0, bottom: 20, left: 20 }}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="timestamp"
								label={{
									value: intl.formatMessage({
										id: "usage.uniqueVisitorsChart.xLabel",
										defaultMessage: "Datum",
									}),
									position: "bottom",
								}}
							/>
							<YAxis
								label={{
									value: intl.formatMessage({
										id: "usage.uniqueVisitorsChart.yLabel",
										defaultMessage: "Gebruikers",
									}),
									angle: -90,
									position: "insideLeft",
								}}
								domain={[0, yAxisScale]}
							/>
							<Tooltip content={<OneTooltip />} />

							<Bar barSize={40} dataKey="Users" fill="#0078d4" />
						</BarChart>
					</ResponsiveContainer>
				</React.Fragment>
			);
		}

		content = <div className="chart-wrapper-2">{graph}</div>;
	}

	const setActiveSite = (selectedSite) => {
		setSelectedSite(selectedSite);
		setViewMode(SiteCharViewModes.SiteChart);
	};

	return (
		<section className="chart-unique-wrapper" style={{ width: "100%" }}>
			{content}
		</section>
	);
};

export const UniqueVisitorsPerSiteChartComponent = injectIntl(UniqueVisitorsPerSiteChart);
