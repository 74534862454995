import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelType, Icon, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { WrappedComponentProps, injectIntl, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import { IApplicationState } from "../../../../../../models/interfaces/IApplicationState";
import { ErrorTypes } from "../../../../../../models/enums";
import { createTeamType } from "../../../../../../store/teamTypes/actions";
import { BasicInformation } from "./parts/BasicInformation";
import { fetchGroupSettingsGraph } from "../../../../../../store/settings/actions";
import { TeamTypeSettingsGuestAccess, TeamTypeSettingsPrivacyLevel } from "@one/core";
import { ITeamTypeFormValues } from "../../../../../../interfaces/teamTypeSettings/ITeamTypeFormValues";
import { Settings } from "../../../../../reusable/teamTypes/settings/Settings";
export interface CreateTeamTypePanelProps {
	isOpen: boolean;
	onClose: () => void;
}

const CreateTeamTypePanel = (props: WrappedComponentProps & CreateTeamTypePanelProps) => {
	const { isOpen, onClose, intl } = props;
	const dispatch = useDispatch();
	const teamTypesSlice = useSelector((state: IApplicationState) => state.teamTypesSlice);
	const [hasSubmitted, setHasSubmitted] = useState(false);

	// Cleanup on close
	React.useEffect(() => {
		if (!isOpen) {
			setHasSubmitted(false);
		}

		if (isOpen) {
			dispatch(fetchGroupSettingsGraph());
		}
	}, [isOpen]);

	const createValidationSchema = () => {
		return Yup.object().shape({
			name: Yup.string()
				.min(
					2,
					intl.formatMessage({
						id: "lookup.panel.create.create.validation.name.minTwoChars",
						defaultMessage: "De waarde moet ten minste twee tekens lang zijn.",
					})
				)
				.required(
					intl.formatMessage({
						id: "general.validation.required",
						defaultMessage: "Dit veld is verplicht.",
					})
				),
			defaultOwners: Yup.array().when("settings", {
				is: (value) => value.newTeam.makeContactPersonOwner === 0,
				then: Yup.array().min(1, "Dit veld is verplicht.")
			})
		});
	};

	return (
		<Panel
			className="one-panel"
			isOpen={isOpen}
			type={PanelType.smallFixedFar}
			onDismiss={onClose}
			isLightDismiss
			onLightDismissClick={onClose}
			closeButtonAriaLabel="Close"
		>
			<section className="one-panel__panel-header">
				<div className="one-panel__panel-header--flex">
					<h1>
						<FormattedMessage id="settings.teams.types.add.button" defaultMessage="Teamtype toevoegen" />
					</h1>

					<div className="one-panel__panel-close" onClick={onClose}>
						<Icon iconName="ChromeClose" />
					</div>
				</div>
			</section>
			<section className="one-panel__panel-content">
				{teamTypesSlice.error.type === ErrorTypes.OnCreate && (
					<MessageBar messageBarType={MessageBarType.error}>
						<FormattedMessage
							id="settings.teams.types.error.onCreate"
							defaultMessage="Er ging iets mis met het aanmaken van het teamtype."
						/>
					</MessageBar>
				)}

				<div className="ilx-form-wrapper">
					<Formik
						// enableReinitialize
						initialValues={
							{
								name: "",
								domain: "",
								prefix: "",
								templateId: "",
								isActive: true,
								approvalRequired: false,
								defaultOwners: [],
								settings: {
									newTeam: {
										makeContactPersonOwner: true,
										secondOwnerRequired: false,
										requestBy: [],
										approvalBy: [],
									},
									upgradeGroup: {
										requestBy: [],
										approvalBy: [],
									},
									addMembers: {
										requestBy: [],
										approvalBy: [],
									},
									archiveTeam: {
										requestBy: [],
										approvalBy: [],
									},
									deleteTeam: {
										requestBy: [],
										approvalBy: [],
									},
									guestAccess: TeamTypeSettingsGuestAccess.DeterminedByApplicant,
									privacyLevel: TeamTypeSettingsPrivacyLevel.Private,
								}
							} as ITeamTypeFormValues
						}
						onSubmit={(values: ITeamTypeFormValues) => {
							dispatch(
								createTeamType({
									name: values.name,
									domain: values.domain ?? "",
									prefix: values.prefix ?? "",
									templateId: values.templateId ?? "",
									approvalRequired: values.approvalRequired,
									isActive: values.isActive,
									defaultOwners: values.defaultOwners,
									settings: {
										...values.settings,
										newTeam: {
											...values.settings.newTeam,
											makeContactPersonOwner: !!values.settings.newTeam.makeContactPersonOwner,
										},
									}
								})
							);
						}}
						validationSchema={createValidationSchema()}
					>
						{(formikProps) => {
							const { handleSubmit, values } = formikProps;

							return (
								<form onSubmit={handleSubmit}>
									<BasicInformation formikProps={formikProps} hasSubmitted={hasSubmitted} />

									<Settings
										formikProps={formikProps}
										hasSubmitted={hasSubmitted}
										defaultOwnersRequired={!values.settings.newTeam.makeContactPersonOwner}
										mode="create"
									/>

									<PrimaryButton
										text={intl.formatMessage({
											id: "general.create",
											defaultMessage: "Aanmaken",
										})}
										onClick={() => setHasSubmitted(true)}
										type="submit"
										disabled={teamTypesSlice.loaders.isCreating}
									/>
								</form>
							);
						}}
					</Formik>
				</div>
			</section>
		</Panel>
	);
};

export const CreateTeamTypePanelComponent = injectIntl(CreateTeamTypePanel);
