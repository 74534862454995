import { DetailsList, DetailsListLayoutMode, DetailsRow, IColumn, IDetailsListProps, SelectionMode, Spinner } from "@fluentui/react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../../models/interfaces/IApplicationState";
import { fetchRoles, setRoleModal } from "../../../store/roles/actions";
import { RoleModal } from "./RoleModal/RoleModal";

export const RolesComponent = () => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const rolesSlice = useSelector((state: IApplicationState) => state.rolesSlice);

	const columns: IColumn[] = [
		{
			key: "name",
			// name: intl.formatMessage({ id: "lookup.list.column.name", defaultMessage: "Waarde" }),
			name: "Rol",
			fieldName: "name",
			minWidth: 150,
			maxWidth: 225,
			isResizable: true,
		},
		{
			key: "code",
			// name: intl.formatMessage({ id: "lookup.list.column.name", defaultMessage: "Waarde" }),
			name: "Code",
			fieldName: "code",
			minWidth: 150,
			maxWidth: 225,
			isResizable: true,
		},
	];

	// returns the row with additional styling
	const _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
		if (props) {
			return (
				<div onClick={() => dispatch(setRoleModal(props.item))} style={{ cursor: "pointer" }}>
					<DetailsRow {...props} />
				</div>
			);
		}
		return null;
	};

	React.useEffect(() => {
		dispatch(fetchRoles());
	}, []);

	return (
		<section>
			<h1 className="page-title">
				<FormattedMessage id="roles.navigation.item" defaultMessage="Rollen" />
			</h1>
			<div className="main-container">
				<div className="lookuplist-page">
					{rolesSlice.roles.data === null ? (
						<div>
							<Spinner />
						</div>
					) : (
						<DetailsList
							items={rolesSlice.roles.data}
							layoutMode={DetailsListLayoutMode.justified}
							selectionMode={SelectionMode.none}
							columns={columns}
							onRenderRow={_onRenderRow}
							styles={{
								headerWrapper: {
									"> div": {
										paddingTop: 0,
									},
								},
							}}
							// onRenderItemColumn={(item, index, column) =>
							// 	_renderItemColumn(item as LookupListValueResponse, index, column)
							// }
						/>
					)}
				</div>
			</div>

			<RoleModal />
		</section>
	);
};
