import { createTheme, ITheme } from "@fluentui/react";

/*
    Gets the __themeState__ from the window and creates a UI fabric theme with this.
*/
export const getThemeFromWindow = (): ITheme => {
	const ThemeColorsFromWindow: any = (window as any).__themeState__.theme;
	return createTheme({
		palette: ThemeColorsFromWindow,
	});
};
