// Framework & Third-pary
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import {
	MessageBarType,
	MessageBar,
} from "@fluentui/react";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Models
import { SuccessTypes, ErrorTypes } from "../../../../models/enums";
import { IApplicationState } from "../../../../models/interfaces/IApplicationState";

// Store & actions
import { resetSuccess } from "../../../../store/settings/actions";

// Components & functions
import { BreadCrumbComponent } from "../../../reusable/breadCrumbComponent/BreadCrumbComponent";
import { hasAccess, PermissionCodes } from "@one/core";
import { fetchApplications } from "../../../../store/tenant/actions";
import { fetchConnectionByName } from "../../../../store/connection/actions";
import { TopdeskTile } from "./TopdeskTileComponent/TopdeskTile";
import { TeamtailorTile } from "./TeamtailorTileComponent/TeamtailorTile";

const YouSettings = (props: WrappedComponentProps & RouteComponentProps) => {
	const { intl } = props;
	const dispatch = useDispatch();
	const currentUserSlice = useSelector((state: IApplicationState) => state.currentUserSlice);
	const connectionSlice = useSelector((state: IApplicationState) => state.connectionSlice);
	const [userHasAccess, setUserHasAccess] = React.useState(false);

	React.useEffect(() => {
		setUserHasAccess(hasAccess(currentUserSlice.user as any, [PermissionCodes.ManageApplicationSettings]));
	}, [currentUserSlice.user]);

	useEffect(() => {
		dispatch(fetchApplications());
		dispatch(fetchConnectionByName("Topdesk"));
		dispatch(fetchConnectionByName("Teamtailor"));
	}, [dispatch]);

	// Used for breadcrumbs
	const pathToPage = [
		{
			text: intl.formatMessage({
				id: "settings.general",
				defaultMessage: "Instellingen",
			}),
			key: "settings",
		},
		{
			text: intl.formatMessage({
				id: "settings.card.you.title",
				defaultMessage: "One for You instellingen",
			}),
			key: "settings/youSettings",
		},
	];

	const renderMessageBarUi = () => {
		if (connectionSlice.success.type !== SuccessTypes.None || connectionSlice.error.type !== ErrorTypes.None) {
			let messageBarType = connectionSlice.success.type !== SuccessTypes.None ? MessageBarType.success : MessageBarType.error;
			let successMessage =
				connectionSlice.success.type === SuccessTypes.OnUpdate || connectionSlice.success.type === SuccessTypes.OnCreate ? (
					<FormattedMessage id="settings.you.connections.update.success" defaultMessage="Gegevens zijn successvol bijgewerkt." />
				) : (
					<FormattedMessage
						id="settings.you.connections.update.error"
						defaultMessage="Er is iets misgegaan bij het opslaan van de gegevens."
					/>
				);

			return (
				<div className={"settings-message-bar-container"}>
					<MessageBar messageBarType={messageBarType} onDismiss={() => dispatch(resetSuccess())}>
						{successMessage}
					</MessageBar>
				</div>
			);
		}
	};

	return (
		<div>
			<BreadCrumbComponent path={pathToPage} />
			
			{renderMessageBarUi()}

			<section className="setting-pages-wrapper">
				{userHasAccess && (
					<div>
						<TopdeskTile intl={intl} />
						<TeamtailorTile intl={intl} />
					</div>
				)}
			</section>
		</div>
	);
};

export const YouSettingsComponent = withRouter(injectIntl(YouSettings));
