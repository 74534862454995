import { ICachedData, ICacheService } from "../interfaces";

export class CacheService implements ICacheService {
	private cacheKey: string;
	private ttl = 3600;

	/**
	 * Prepare the service for use.
	 *
	 * @param cacheKey
	 */
	public setup(cacheKey: string) {
		this.cacheKey = cacheKey;
	}

	/**
	 * Change the TTL of the cache.
	 *
	 * @param ttlInSeconds
	 */
	public setTtl(ttlInSeconds: number) {
		this.ttl = ttlInSeconds;
	}

	/**
	 * Get data from the cache.
	 *
	 * @param key
	 * @param defaultValue
	 */
	public get<T>(key: string | string[], defaultValue?: T): T | null {
		const data = localStorage.getItem(this.getFullKey(key));

		if (!data) {
			return defaultValue ?? null;
		}

		const cachedData: ICachedData<T> = JSON.parse(data);

		if (!cachedData) {
			return defaultValue ?? null;
		}

		const now = new Date();
		const validUntil = new Date(cachedData.validUntil);

		if (now > validUntil) {
			return defaultValue ?? null;
		}

		return cachedData.data;
	}

	/**
	 * Store data in the cache.
	 *
	 * @param key
	 * @param data
	 */
	public put(key: string | string[], data: any) {
		const validUntil = new Date();

		validUntil.setSeconds(validUntil.getSeconds() + this.ttl);

		const cachedData: ICachedData<any> = {
			validUntil: validUntil.getTime(),
			data
		};

		localStorage.setItem(this.getFullKey(key), JSON.stringify(cachedData));
	}

	/**
	 * Get cached data if it's available, otherwise fetch the data
	 * using the provided function and cache its response.
	 *
	 * @param key
	 * @param fetcher
	 * @param forceFetch
	 */
	public async getOrPut<T>(key: string | string[], fetcher: () => Promise<T>, forceFetch = false): Promise<T> {
		let data = this.get<T>(key);

		if (!data || forceFetch) {
			data = await fetcher();

			this.put(key, data);
		}

		return data;
	}

	/**
	 * Get the full cache key based on the provided key.
	 *
	 * @param key
	 * @private
	 */
	private getFullKey(key: string | string[]) {
		if (Array.isArray(key)) {
			key = key.join("|");
		}

		return `${this.cacheKey}|${key}`;
	}
}

export const cacheServiceInstance = new CacheService();