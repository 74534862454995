export enum TeamRelationValidity {
	// Valid
	Valid = 2000,

	// Invalid
	Invalid = 4000,
	CurrentTeamAlreadyHasParent = 4001,
	OtherTeamAlreadyHasParent = 4002,
	RefenceTeamIsSameAsCurrent = 4003,
}
